import React, { useState, useEffect } from "react";
import "../ClientDashboard/clientdashboardstyle.css";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import CreateUnit from "./createUnit";
import EditUnit from "./editUnit";
import { toast } from "react-toastify";
import { Table } from "../Table/Table";
import { logout } from '../../redux/action';
import {
  APIRequest,
  LIST_CLIENT_UNIT,
  LIST_CITY,
  LIST_UNIT,
  LIST_STORE,
  DELETE_CLIENT_UNIT,
  CLIENT_UNIT_EXIST_OR_NOT,
  ADD_STORE
} from "../../api";
import Detailsunit from "./detailsunit";
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
import { number } from "yup";
import ReusableModal from "../Modal/reusablemodel";
import AddStoreDialog from "./AddStoreDialog";
import RegularDropDown from "../../Component/common/DropdownMenus/RegularDropdown";
import * as Constants from "../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from "../../Component/common/Global/commonFunctions";
import CommonLoader from '../../commonComponent/loader/commonLoader';

const UnitMaster = () => {

  const [addunitype, setaddunitypeShow] = useState(false);
  const [editunitype, seteditunitypeShow] = useState(false);
  const [unitData, setUnitData] = useState({});
  const [city, setCity] = useState([]);
  const [unit, setUnit] = useState([]);
  const [store, setStore] = useState([]);
  const [editData, setEditData] = useState();
  const [unitdetails, Setunitdetails] = useState();
  const [unitdetailsshow, Setunitdetailsshow] = useState(false);
  const [unitactive, Setunitactive] = useState(true);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { clientId } = useParams(number);
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [Id, setId] = useState();
  const [showStore, setShowStore] = useState(false);
  const [storeData, setStoreData] = useState({});
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [unitLoading, setUnitLoading] = useState(false);
  const [deleteUnitLoading, setDeleteUnitLoading] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    fetchClientUnit();

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_UNIT)
      .reqURL("unitType/getUnitTypeList")
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_STORE)
      .reqURL(`storeType/clientStoreTypeList?clientId=${clientId}&organisationId=${user?.organisationId}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStoreList = () => {
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_STORE)
      .reqURL(`storeType/clientStoreTypeList?clientId=${clientId}&organisationId=${user?.organisationId}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const loadMasterList = (searchText) => {
    if(searchText.length >= 3){
      new APIRequest.Builder()
        .post()
        .setReqId(LIST_CITY)
        .reqURL("city/getCityList")
        .jsonParams({ search: searchText})
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const editUnitModel = (e) => {
    setEditData(e);
    seteditunitypeShow(true);
  };
  const addStore = (e) => {
    setShowStore(true);
  }
  const fetchClientUnit = () => {
    // setEditData();
    setUnitLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_CLIENT_UNIT)
      .reqURL(`clientUnit/getClientUnit/${clientId}?page=1&limit=15`)
      .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const getClientActiveInactiveList = (unitactive = true) => {
    let url = unitactive ? `clientUnit/getClientUnit/${clientId}?page=1&limit=15` : `clientUnit/getClientUnit/${clientId}?page=1&limit=15&isActive=false`;
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_CLIENT_UNIT)
      .reqURL(url)
      .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const changeStatus = (id) => {
    new APIRequest.Builder()
      .post()
      .setReqId(CLIENT_UNIT_EXIST_OR_NOT)
      .jsonParams({ "clientUnitId": id })
      .reqURL(`/clientUnit/isClientUnitStoreTypeExisting`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();

  };

  const handleDeactivateUnit = () =>{
    setDeleteUnitLoading(true);
    new APIRequest.Builder()
    .post()
    .setReqId(DELETE_CLIENT_UNIT)
    .jsonParams({ isActive: "false", userId: user.userId })
    .reqURL(`clientUnit/changeClientUnitIsActiveStatus/${Id}`)
    .response(onResponse)
    .error(onError)
    .build()
    .doRequest();
  }

  const handleReactiveteUnit = () => {
    setDeleteUnitLoading(true);
    new APIRequest.Builder()
    .post()
    .setReqId(DELETE_CLIENT_UNIT)
    .jsonParams({ isActive: "true", userId: user.userId })
    .reqURL(`clientUnit/changeClientUnitIsActiveStatus/${Id}`)
    .response(onResponse)
    .error(onError)
    .build()
    .doRequest();
  }

  const onResponse = (response, reqId) => {

    switch (reqId) {
      case LIST_CLIENT_UNIT:
        setUnitData(response.data?.data);
        isComponentDidMountFlag(true);
        setUnitLoading(false);
        break;
      case LIST_CITY:
        setCity(response?.data.data.records);
        break;
      case LIST_UNIT:
        setUnit(response?.data.data.records);
        break;
      case LIST_STORE:
        setStore(response?.data.data);
        break;
      case DELETE_CLIENT_UNIT:
        toast.success(`${response?.data?.message}`)
        setConfrimDialog(false);
        fetchClientUnit(true);
        Setunitactive(true);
        setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
        setDeleteUnitLoading(false);
        break;
      case CLIENT_UNIT_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.clientUnitId);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_CLIENT_UNIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
          setUnitLoading(false);
          navigate(`/Client/Unitmaster/${clientId}/${randomStringGenerator(30)}`);
        }else{
          setUnitLoading(false);
        }
        break;
      case LIST_CITY:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      case LIST_UNIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      case LIST_STORE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      case DELETE_CLIENT_UNIT:
        toast.success(`${response?.data?.message}`)
        setDeleteUnitLoading(false);
        break;
      case CLIENT_UNIT_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      default:
        break;
    }
  };

  const handleTableChange = (type, newState) => {
    let url = unitactive == true ? `clientUnit/getClientUnit/${clientId}?isActive=true&page=${type === "search" || type === "sort" ? newState.page : newState.page
      }` : `clientUnit/getClientUnit/${clientId}?isActive=false&page=${type === "search" || type === "sort" ? newState.page : newState.page
      }`;
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === "unit_type") {
        url = url + `&sortColumn=unitTypeName`
      }
      if (newState.sortField === "company_name") {
        url = url + `&sortColumn=clientUnitName`
      }
      if (newState.sortField === "city") {
        url = url + `&sortColumn=City`
      }
      if (newState.sortField === "state") {
        url = url + `&sortColumn=State`
      }
      if (newState.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }else{
      setSort('');
      setSortOrder('');
    }

    new APIRequest.Builder()
      .post()
      .setReqId(LIST_CLIENT_UNIT)
      .reqURL(url)
      .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };
  const handleUnitActive = (record) => {

    const response = window.confirm("Are you sure, you want to Active this Unit?");
    if (response) {
      const onResponse = (response, reqId) => {
        toast.success(`${response.data.message}`);
        fetchClientUnit();
      }
      new APIRequest.Builder()
        .post()
        .setReqId(DELETE_CLIENT_UNIT)
        .jsonParams({ isActive: "true", userId: user.userId })
        .reqURL(`clientUnit/changeClientUnitIsActiveStatus/${record.clientUnitId}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }
  const handleActiveInactiveChange = (selected) => {
    selected.status === "Inactive" ? Setunitactive(false) : Setunitactive(true);
    getClientActiveInactiveList(selected.status === "Inactive" ? false : true);
    setSelectedOption(selected.status);
    showDropdownItem(false)
  }

  const clearFilter = () => {
    Setunitactive(true);
    setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
    setSearch('');
    setSort('');
    setSortOrder('');
    isComponentDidMountFlag(false);
    fetchClientUnit();
  }

  return (
    <>
      <div className="container-fluid p-0">
        <div className="main_tabs">
          <div className="row">
            <div className="col-md-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-settings-tab"
                >
                  <div className="master_box">
                    <div className="master_boxinfo">
                      <div className="clients_menu">
                        <a href="/Client/client" className="text-primary tab">Clients</a>
                        <a className="btn btn-primary" href={`/Client/Unitmaster/${clientId}`}>
                          Unit
                        </a>
                      </div>
                      {unitLoading &&
                        <CommonLoader loading={unitLoading} />
                      }
                      <div className="table-responsive pt-2 pb-5">
                        {componentDidMountFlag && 
                        <>
                          <div className='deactivelist'>
                            <RegularDropDown 
                              placeholder={selectedOption}
                              dropdownArr={Constants.activeInactiveStatus}
                              labelParam="status"
                              onDropDownItemClick={handleActiveInactiveChange}
                              isFocusRequired={true}
                              show={isShowDropDownItem}
                              onToggle={(isOpen) => showDropdownItem(isOpen)}
                              defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                            />
                          </div>
                          <div className="add_btns">
                            {!isEmptyArray(unitData?.records) && (search != "" || sort != "" || selectedOption == "Inactive") && 
                              <button
                                type="button"
                                className="btn btn-primary"
                                variant="primary"
                                onClick={() => clearFilter()}
                              >
                                <i
                                  className="fa fa-filter"
                                  aria-hidden="true"
                                ></i>{" "}
                                Clear
                              </button>
                            }
                            {unitactive && <button
                              type="button"
                              className="btn btn-primary"
                              variant="primary"
                              onClick={setaddunitypeShow}
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Unit
                            </button>}
                          </div>
                          <i className="fa fa-search search_icon" aria-hidden="true"></i>
                        </>
                        }
                        {componentDidMountFlag && unitData?.records && (
                          <Table
                            data={unitData?.records?.map((item, index) => {
                              return {
                                ...item,
                                key: index,
                                index: (unitData?.page - 1) * unitData.limit + index + 1,
                                id: item.clientUnitId,
                                unit_type: item?.UnitType?.unitTypeName,
                                company_name: item?.clientUnitName,
                                city: item.City?.cityName,
                                state: item.City?.State?.stateName
                                ,
                              };
                            })}
                            columns={[
                              {
                                dataField: "index",
                                text: "No",
                                headerClasses: "sr_no",
                                style: { cursor: "pointer" },
                                events: {
                                  onClick: (e, column, columnIndex, row, rowIndex) => {
                                    Setunitdetails(row); Setunitdetailsshow(true)
                                  },
                                }
                              },
                              {
                                dataField: "unit_type",
                                text: "Unit Type",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                  onClick: (e, column, columnIndex, row, rowIndex) => {
                                    Setunitdetails(row); Setunitdetailsshow(true)
                                  },
                                }
                              },
                              {
                                dataField: "company_name",
                                text: "Name",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                  onClick: (e, column, columnIndex, row, rowIndex) => {
                                    Setunitdetails(row); Setunitdetailsshow(true)
                                  },
                                }
                              },
                              {
                                dataField: "city",
                                text: "City",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                  onClick: (e, column, columnIndex, row, rowIndex) => {
                                    Setunitdetails(row); Setunitdetailsshow(true)
                                  },
                                }
                              },
                              {
                                dataField: "state",
                                text: "State",
                                sort: true,
                                style: { cursor: "pointer" },
                                events: {
                                  onClick: (e, column, columnIndex, row, rowIndex) => {
                                    Setunitdetails(row); Setunitdetailsshow(true)
                                  },
                                }
                              },
                              {
                                dataField: "action",
                                text: "Action",
                                headerClasses: "action",
                                style: { cursor: "pointer" },
                                formatter: (cell, e) => {
                                  return (
                                    e?.isActive === "true" ?
                                      <div className="edit_icons">
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i
                                              className="fa fa-ellipsis-v"
                                              aria-hidden="true"
                                            ></i>
                                          </button>
                                          <ul
                                            className="dropdown-menu p-1"
                                            aria-labelledby="dropdownMenuButton1"
                                          >
                                            {e.mainLocation != "Y" &&
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => editUnitModel(e)}
                                                ><i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                                  Edit
                                                </a>
                                              </li>
                                            }
                                            {/* <li>
                                              <a
                                                className="dropdown-item"
                                                onClick={() => { navigate(`EmployeeMaster/${e.id}`) }
                                                }
                                              ><i className="fa fa-eye me-1" aria-hidden="true"></i>
                                                View Employee
                                              </a>
                                            </li> */}
                                            {e.mainLocation != "Y" &&
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => changeStatus(e.clientUnitId)}
                                                ><i className="fa fa-trash me-1" aria-hidden="true"></i>
                                                  Delete
                                                </a>
                                              </li>
                                            }
                                            {e.mainLocation == "Y" &&
                                              <li>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() => {
                                                    setShowStore(true)
                                                  setStoreData(e)}}
                                                >
                                                  {e?.ClientUnitStoreMap.length != 0 ? 
                                                  <i className="fa fa-pencil me-1" aria-hidden="true"></i> : 
                                                  <i className="fa fa-plus me-1" aria-hidden="true"></i>}
                                                  {e?.ClientUnitStoreMap.length != 0 ? "Edit Store" : "Add Store"}
                                                </a>
                                              </li>
                                            }
                                          </ul>
                                        </div>
                                      </div> :
                                      <div className='edit_icons'>
                                        <div className="dropdown">
                                          <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                          </button>
                                          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li><a className="dropdown-item" onClick={() => {
                                              setId(e?.id)
                                              setConfrimDialog(true);
                                            }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                                          </ul>
                                        </div>
                                      </div>
                                  );
                                },
                              },
                            ]}
                            handleTableChange={handleTableChange}
                            totalSize={unitData?.total ?? 0}
                            currentPage={unitData?.page ?? 1}
                            sizePerPage={unitData?.limit ?? 15}
                          />
                        )}
                        {componentDidMountFlag && isEmptyArray(unitData?.records) && 
                          <div className="no-items-layout">
                            <div className="no-items-card">
                              <h6>
                                {
                                  Constants.message.noRecordsWarning
                                }
                              </h6>
                            </div>
                          </div>
                        }
                      </div>

                      <CreateUnit
                        addunitype={addunitype}
                        setaddunitypeShow={setaddunitypeShow}
                        clientId={clientId}
                        fetchClientUnit={fetchClientUnit}
                        unit={unit}
                        store={store}
                        city={city}
                        fetchStoreList={fetchStoreList}
                        loadMasterList={loadMasterList}
                      />

                      {editData && (
                        <EditUnit
                          addunitype={editunitype}
                          setaddunitypeShow={seteditunitypeShow}
                          clientId={clientId}
                          fetchClientUnit={fetchClientUnit}
                          unit={unit}
                          store={store}
                          editData={editData}
                          city={city}
                          fetchStoreList={fetchStoreList}
                          loadMasterList={loadMasterList}
                        />
                      )}
                      {storeData && (
                        <AddStoreDialog
                          showStore={showStore}
                          setShowStore={setShowStore}
                          fetchClientUnit={fetchClientUnit}
                          store={store}
                          clientId={clientId}
                          fetchStoreList={fetchStoreList}
                          storeData={storeData}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages2"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab2"
                >
                </div>
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </div>
      <ReusableModal
        show={confrimDialog}
        onClose={() => setConfrimDialog(false)}
        loading={deleteUnitLoading}
        onConfirm={unitactive ? handleDeactivateUnit : handleReactiveteUnit}
        message={unitactive ? "Are you sure, you want to delete this unit?" : "Are you sure, you want to reactivate this unit?"}
      />
      <Detailsunit unitdetailsshow={unitdetailsshow} Setunitdetailsshow={Setunitdetailsshow} unitdetails={unitdetails} />
    </>
  );
}

export default UnitMaster;
