import React from 'react';
import '../Client_Admin/clientstyle.css'

function ClientAdmin() {

    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-7'>
                        <div className='register_box'>
                            <form className='registerForm'>
                                <h1 className='mb-5'><img src='assets/images/user_icon.png' className='img-fluid me-3'></img> client admin</h1>
                                <div className='row'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className="mb-4">
                                                <label htmlFor="exampleInputtittle" className="form-label">Title</label>
                                                <select className="form-select" aria-label="Default select example">
                                                    <option selected>Mr.</option>
                                                    <option value="1">Ms.</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInputfname" className="form-label">First Name--</label>
                                            <input type="text" className="form-control" id="exampleInputfname" placeholder='John' />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInputsmname" className="form-label">Middle Name</label>
                                            <input type="text" className="form-control" id="exampleInputsmname" placeholder='Andrew' />
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInputlastname" className="form-label">Last Name</label>
                                            <input type="text" className="form-control" id="exampleInputlastname" placeholder='Smith' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInputemail" className="form-label">Email ID</label>
                                            <input type="email" className="form-control" id="exampleInputemail" placeholder='john.smith@example.com' />
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className="mb-4">
                                            <label htmlFor="exampleInpunumbr" className="form-label">Phone No *</label>
                                            <input type="number" className="form-control" id="exampleInpunumbr" placeholder='(000) 1234 5678' pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" onWheel={e => e.currentTarget.blur()}/>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" className="btn btn-primary">CONTINUE</button>
                            </form>
                        </div>
                    </div>
                    <div className='col-lg-5'>
                        <div className='register_details'>
                            <img src='assets/images/client_img.png' className='img-fluid'></img>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default ClientAdmin;