import React, { useRef } from 'react';
import Select from 'react-select';
import { useFormik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import './dashboardclientstyle.css';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { APIRequest, LIST_CLIENT_UNIT, ASSIGNMENT_EDIT, LIST_SITEUSER } from '../../../src/api';
import { useState } from 'react';
import * as Yup from "yup";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import { logout } from '../../redux/action';
import * as Constants from '../common/Global/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";

const Updateassignment = ({ editassignment, handleOnClose, editAssignmentData, user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [unitdata, SetUnitdata] = useState([])
    const [member, Setmember] = useState([]);
    const [teamleader, SetTeamleader] = useState(false);
    const [reviewerList1, setReviewerList1] = useState([]);
    const [reviewerList2, setReviewerList2] = useState([]);
    const [teammemberList, setTeammemberList] = useState([]);
    const [teamleaderList, setTeamleaderList] = useState([]);
    const [startdate, setStartDate] = useState();
    const newMemberList = member?.map((e) => { return { 'value': e?.userId, 'label': e?.firstName + " " + e?.lastName } });
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();

    useEffect(() => {
        const teamMemberList = editAssignmentData?.teamMembers?.map(e => e.User?.userId) || [];
        setTeammemberList(teamMemberList);
        const teamLeadList = editAssignmentData?.reviewers?.filter(e => e.assignmentReviewLevel === "L0").map(e => e.User?.userId) || [];
        setTeamleaderList(teamLeadList);
        const ReviewerList1 = editAssignmentData?.reviewers?.filter(e => e.assignmentReviewLevel === "L1").map(e => e.User?.userId) || [];
        setReviewerList1(ReviewerList1);
        const ReviewerList2 = editAssignmentData?.reviewers?.filter(e => e.assignmentReviewLevel === "L2").map(e => e.User?.userId) || [];
        setReviewerList2(ReviewerList2);
    }, [editassignment]);

    const resourceDropdownOnChange = (v, resourceType) => {
        if (resourceType === "TM") {
            const memberTeamList = v.map((item) => {
                return item?.value;
            })
            setTeammemberList(memberTeamList);
        }
        else if (resourceType === "TL") {
            const memberTeamList = v?.value;
            setTeamleaderList(memberTeamList);
        }
        else if (resourceType === "RL1") {
            const memberTeamList = v?.value;
            setReviewerList1(memberTeamList);
        }
        else if (resourceType === "RL2") {
            const memberTeamList = v?.value;
            setReviewerList2(memberTeamList);
        }
    }

    const filterResourceDisplayArray = () => {
        return newMemberList.filter(item => !teammemberList.includes(item?.value))
            .filter(item => teamleaderList != item?.value)
            .filter(item => reviewerList1 != item?.value)
            .filter(item => reviewerList2 != item?.value)
    }

    useEffect(() => {
        editAssignmentData?.clientId && new APIRequest.Builder()
            .post()
            .setReqId(LIST_CLIENT_UNIT)
            .reqURL(`clientUnit/getClientUnit/${editAssignmentData?.Client?.clientId}`)
            .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        new APIRequest.Builder()
            .post()
            .setReqId(LIST_SITEUSER)
            .reqURL(`user/userList/${user.userId}?organisationId=${user?.organisationId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editassignment])

    const editassignmentform = useFormik({
        enableReinitialize: true,
        initialValues: {
            clientId: editAssignmentData?.clientId ?? '',
            clientUnitId: editAssignmentData?.clientUnitId ?? '',
            referenceNumber: editAssignmentData?.referenceNumber ?? '',
            storeId: editAssignmentData?.AssignmentStoreMapping?.map(item => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } }) ?? "",
            teamMemberId: teammemberList,
            teamLeadId: teamleaderList?.length > 0 ? teamleaderList[0] : teamleaderList,
            reviewer1Id: reviewerList1?.length > 0 ? reviewerList1[0] : reviewerList1,
            reviewer2Id: reviewerList2?.length > 0 ? reviewerList2[0] : reviewerList2,
            assignmentStartDate: moment(editAssignmentData?.assignmentStartDate).format('YYYY-MM-DD') ?? "",
            assignmentEndDate: moment(editAssignmentData?.assignmentEndDate).format('YYYY-MM-DD') ?? ""
        },
        validationSchema: Yup.object().shape({
            clientUnitId: Yup.string().required("Select Unit Type"),
            // storeId: Yup.array().required("Select Store").min(1 ,"Select Store"),
            // teamMemberId: Yup.array().required("Select Team Member").nullable(true),
            // teamLeadID: Yup.object().required("Select Team Leader").nullable(true),
            // reviewer1ID: Yup.object().required("Select Reviewer 1").nullable(true),
            // reviewer2ID: Yup.object().required("Select Reviewer 2").nullable(true),
            assignmentStartDate: Yup.string().required('Select Start Date'),
            assignmentEndDate: Yup.string().required('Select End Date')
        }),
        onSubmit: values => {
            setLoading(true);
            Object.keys(values).forEach(key => values[key] === "" && delete values[key])
            let _step1Value = {
                ...values,
                organisationId: user.organisationId,
                userId: user.userId,
                storeId: values.storeId.map((e) => { return e?.value; }),
            }
            const finalValue = { ..._step1Value }
            Object.keys(finalValue).forEach(key => finalValue[key] === undefined && delete finalValue[key])
            Object.keys(finalValue).forEach(key => finalValue[key] === "" && delete finalValue[key])
            Object.keys(finalValue).forEach(key => finalValue[key].length === 0 && delete finalValue[key])
            if(editAssignmentData?.status == "Started" && filterResourceDisplayArray().length == newMemberList.length){
                setLoading(false);
                toast.warning("Atleast 1 resource must be assigned to the assignment.");
            }else{ 
                new APIRequest.Builder()
                    .post()
                    .setReqId(ASSIGNMENT_EDIT)
                    .jsonParams(finalValue)
                    .reqURL(`assignments/updateAssignments/${editAssignmentData.assignmentId}`)
                    .response(onResponse)
                    .error(onError)
                    .build()
                    .doRequest();
            }
        }
    });

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case LIST_CLIENT_UNIT:
                SetUnitdata(response.data?.data?.records);
                break;
            case LIST_SITEUSER:
                Setmember(response.data?.data?.records);
                break;
            case ASSIGNMENT_EDIT:
                setLoading(false);
                handleOnClose(true)
                toast.success(response?.data.message)
                editassignmentform.resetForm();
                setReviewerList1([]);
                setReviewerList2([]);
                setTeammemberList([]);
                setTeamleaderList([]);
                setStartDate("");
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case LIST_CLIENT_UNIT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data.message}`);
                }
                break;
            case ASSIGNMENT_EDIT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response.data.message}`);
                    setReviewerList1([]);
                    setReviewerList2([]);
                    setTeammemberList([]);
                    setTeamleaderList([]);
                    setStartDate("");
                }
                break;
            default:
                break;
        }
    }

    const storeOptions = unitdata?.find((item) => item.clientUnitId === editassignmentform.values.clientUnitId)?.ClientUnitStoreMap?.map((e) => { return { 'value': e.StoreType?.storeTypeId, 'label': e.StoreType?.storeTypeName } })
    const unitOptions = unitdata?.map((e) => { return { 'value': e.clientUnitId, 'label': e.clientUnitName } })
    useEffect(() => {
        if (editassignmentform.values.teamMemberId && editassignmentform.values.teamMemberId.length >= "2" && editassignmentform.values.teamLeadId === "") {
            SetTeamleader(true)
        } else { SetTeamleader(false) }
    }, [editassignmentform.values.teamMemberId, editassignmentform.values.teamLeadId]);
    const handleChangedateChange = (e => {
        let startDate = e.target.value;
        let endDate = inputRef.current.value;
        if(endDate < startDate && endDate != '' && endDate != null){
            editassignmentform.setFieldValue("assignmentEndDate", "")
            inputRef.current.value = ''
        }
        editassignmentform.setFieldValue("assignmentStartDate", e.target.value)
        setStartDate(e.target.value);
        
    })

    return (
        <>
            <Modal show={editassignment}>
                <form onSubmit={editassignmentform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Edit Ongoing Assignments</Modal.Title>
                        <div
                            onClick={() => {
                                handleOnClose()
                                editassignmentform.resetForm();
                                setReviewerList1([]);
                                setReviewerList2([]);
                                setTeammemberList([]);
                                setTeamleaderList([]);
                                setStartDate("");
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row dropdown_box'>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput2" className="form-label">Unit</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={unitOptions}
                                        onChange={value => editassignmentform.setFieldValue('clientUnitId', value.value)}
                                        defaultValue={{ label: editAssignmentData?.ClientUnit?.clientUnitName }}
                                    />
                                    {editassignmentform.touched.clientUnitId && editassignmentform.errors.clientUnitId ? (
                                        <span className="error">{editassignmentform.errors.clientUnitId}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput3" className="form-label">Store Type</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={storeOptions}
                                        onChange={value => editassignmentform.setFieldValue('storeId', value)}
                                        defaultValue={editAssignmentData?.AssignmentStoreMapping?.map((item) => {
                                            return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName }
                                        })}
                                        isMulti
                                    />
                                    {/* {editassignmentform.touched.storeId && editassignmentform.errors.storeId ? (
                                    <span className="error">{editassignmentform.errors.storeId}</span>
                                ) : null} */}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="referenceNumber" className="form-label">Reference Number</label>
                                    <input className='form-control' id='referenceNumber' type='text'
                                        onChange={editassignmentform.handleChange}
                                        defaultValue={editAssignmentData?.referenceNumber}

                                    ></input>
                                    {/* {editassignmentform.touched.assignmentStartDate && editassignmentform.errors.assignmentStartDate ? (
                                        <span className="error">{editassignmentform.errors.assignmentStartDate}</span>
                                    ) : null} */}
                                </div>
                            </div>
                            <div><p className='text-center mb-0 mt-2 pb-0 ' style={{ fontSize: "13px" }}>Audit Timeline</p></div>
                            <Dropdown.Divider />
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="assignmentStartDate" className="form-label">Start Date</label>
                                    <input className='form-control' id='assignmentStartDate' type='date'
                                        onChange={handleChangedateChange}
                                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                        defaultValue={moment(editAssignmentData?.assignmentStartDate).format('YYYY-MM-DD')}
                                        // min={startdate}
                                        // value={moment(editAssignmentData?.assignmentStartDate).format('YYYY-MM-DD')}
                                         ></input>
                                    {editassignmentform.touched.assignmentStartDate && editassignmentform.errors.assignmentStartDate ? (
                                        <span className="error">{editassignmentform.errors.assignmentStartDate}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="assignmentEndDate" className="form-label">End Date</label>
                                    <input className='form-control' id='assignmentEndDate' type='date' ref={inputRef}
                                        onChange={editassignmentform.handleChange}
                                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                        min={editassignmentform.values.assignmentStartDate}
                                        defaultValue={moment(editAssignmentData?.assignmentEndDate).format('YYYY-MM-DD')}></input>
                                    {editassignmentform.touched.assignmentEndDate && editassignmentform.errors.assignmentEndDate ? (
                                        <span className="error">{editassignmentform.errors.assignmentEndDate}</span>
                                    ) : null}
                                </div>
                            </div>
                           <div><p className='text-center mb-0 mt-2 pb-0 ' style={{ fontSize: "13px" }}>Assign Resources</p></div> 
                            <Dropdown.Divider />
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput6" className="form-label">Team Member</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isMulti
                                        onChange={v => {
                                            resourceDropdownOnChange(v, "TM");
                                            editassignmentform.setFieldValue('teamMemberId', v.map((e) => { return e?.value; }));
                                        }}
                                        defaultValue={editAssignmentData?.teamMembers?.map((e) => {
                                            return { 'value': e.User.userId, 'label': e.User.firstName + " " + e.User.lastName }
                                        })
                                        }
                                    />
                                    {editassignmentform.touched.teamMemberId && editassignmentform.errors.teamMemberId ? (
                                        <span className="error">{editassignmentform.errors.teamMemberId}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput7" className="form-label">Team lead</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isClearable
                                        onChange={v => {
                                            resourceDropdownOnChange(v, "TL");
                                            editassignmentform.setFieldValue('teamLeadId', v?.value)
                                        }}
                                        defaultValue={editAssignmentData?.reviewers?.map((e) => {
                                            return e.assignmentReviewLevel == "L0" ?
                                                { 'value': e.User?.userId, 'label': e.User?.firstName + " " + e.User?.lastName } : ''
                                        })}
                                    />
                                    {editassignmentform.touched.teamLeadId && editassignmentform.errors.teamLeadId ? (
                                        <span className="error">{editassignmentform.errors.teamLeadId}</span>
                                    ) : null}
                                    {teamleader && <span className="error">Team Member Is More Than 2 Member teamleader Must Required</span>}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput8" className="form-label">Reviewer 1</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isClearable
                                        onChange={v => {                                 
                                            resourceDropdownOnChange(v, "RL1");
                                            editassignmentform.setFieldValue('reviewer1Id', v?.value)
                                        }}
                                        defaultValue={editAssignmentData?.reviewers?.map((e) => {
                                            return e.assignmentReviewLevel == "L1" ?
                                                { 'value': e.User?.userId, 'label': e.User?.firstName + " " + e.User?.lastName } : ''
                                        })}

                                    />
                                    {editassignmentform.touched.reviewer1Id && editassignmentform.errors.reviewer1Id ? (
                                        <span className="error">{editassignmentform.errors.reviewer1Id}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label htmlFor="exampleInput9" className="form-label">Reviewer 2</label>
                                <Select
                                    placeholder="Select"
                                    className='reduceFonts'
                                    options={filterResourceDisplayArray()}
                                    isClearable
                                    onChange={v => {
                                        resourceDropdownOnChange(v, "RL2");
                                        editassignmentform.setFieldValue('reviewer2Id', v?.value)
                                    }}
                                    defaultValue={editAssignmentData?.reviewers?.map((e) => {
                                        return e.assignmentReviewLevel == "L2" ?
                                            { 'value': e.User.userId, 'label': e.User.firstName + " " + e.User.lastName } : ''
                                    })}

                                />
                                {editassignmentform.touched.reviewer2Id && editassignmentform.errors.reviewer2Id ? (
                                    <span className="error">{editassignmentform.errors.reviewer2Id}</span>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            handleOnClose()
                            editassignmentform.resetForm();
                            setReviewerList1([]);
                            setReviewerList2([]);
                            setTeammemberList([]);
                            setTeamleaderList([]);
                            setStartDate("");
                        }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                        {loading ? <Spinner animation="border"  variant="light" className='spinner-border-sm' /> : "Save"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Updateassignment;