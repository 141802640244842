import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import ObservationsTableEdit from '../../Component/ObservationsTable/observationTableEdit';
import ObservationsTable from '../../Component/ObservationsTable/observationsTable';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Table } from '../Table/Table';
import { toast } from 'react-toastify';
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';
import Observationdetails from './Detailspage/observationdetails';
import Select from 'react-select';
import ReusableModal from '../Modal/reusablemodel';
import { isEmptyArray, isEmptyVariable, localDateExtractor, localTimeExtractor } from '../../Component/common/Global/commonFunctions';
import ObservationsOrder from '../../Component/ObservationsTable/observationOrder';
import AssignmentListDetails from '../../Component/common/assignmentListDetails/assignmentListDetails';
import CommonButton from '../../commonComponent/button/commonButton';
import { PlusCircleFilled, DownloadOutlined, FilterFilled } from '@ant-design/icons';
import CommonLoader from '../../commonComponent/loader/commonLoader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ActualStockModal from './actualStockModal';
import CommonModal from '../../commonComponent/modal/commonModal';
import CommonAlert from '../../commonComponent/alert/commonAlert';

function OngoingAssignment() {
    const { assignmentId } = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const location = useLocation();
    const formatter = new Intl.NumberFormat('en-IN');
    const assignment = location?.state?.assignmentListDetails;
    const [stockData, setStockData] = useState([]);
    const [addNewObservationsModalShow, setAddNewObservationsModalShow] = useState(false);
    const [orderObservationsModalShow, setOrderObservationsModalShow] = useState(false);
    const [observationsEditModalShow, setObservationsEditModalShow] = useState(false);
    const [observationsList, setObservationsList] = useState();
    const [searchBookStockByOne, setSearchBookStockByOne] = useState([]);
    const [actualStockModalShow, setActualStockModalShow] = useState(false);
    const [searchBookStockItemData, setSearchBookStockItemData] = useState();
    const [observationsDetails, setObservationsDetails] = useState();
    const [editObservationsData, setEditObservationsData] = useState();
    const [observationsDetailsShow, setObservationsDetailsShow] = useState(false);
    const [requestChangeModalShow, setRequestChangeModalShow] = useState(false);
    const [requestChangeReviewList, setRequestChangeReviewList] = useState();
    const [requestChangeData, setRequestChangeData] = useState();
    const [deleteObservationConfirmDialog, setDeleteObservationConfirmDialog] = useState(false);
    const [freezeAssignmentConfrimDialog, setFreezeAssignmentConfrimDialog] = useState(false);
    const [freezeAssignmentReConfrimDialog, setFreezeAssignmentReConfrimDialog] = useState(false);
    const [validateBeforeFreezeConfirmDialog, setValidateBeforeFreezeConfirmDialog] = useState(false);
    const [validateBeforeFreezeErrorDialog, setValidateBeforeFreezeErrorDialog] = useState(false);
    const [validateBeforeFreezeErrorMessage, setValidateBeforeFreezeErrorMessage] = useState("");
    const [validateBeforeFreezeMessage, setValidateBeforeFreezeMessage] = useState("");
    const [assignmentObservationsId, setAssignmentObservationsId] = useState();
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [wordDownloadReportsLoading, setWordDownloadReportsLoading] = useState(false);
    const [downloadReportButtonDisable, setDownloadReportButtonDisable] = useState(false);
    const [assignmentRelatedMasterCounts, setAssignmentRelatedMasterCounts] = useState({});
    const [reportData, setReportData] = useState({});
    const [requestUserId, setRequestUserId] = useState();
    const [sortType, setSortType] = useState(null)
    const [loading, setLoading] = useState(false);
    const [suggestion, setSuggestion] = useState(false);
    const [alertShow, isAlertShow] = useState(false);
    const [message, setMessage] = useState('');
    const [ongoingReportLoading, setOngoingReportLoading] = useState(false);
    const [downloadReportModalShow, setDownloadReportModalShow] = useState(false);
    const [deleteObservationLoading, setDeleteObservationLoading] = useState(false);
    const [freezeAssignmentLoading, setFreezeAssignmentLoading] = useState(false);
    let menuRef = useRef();
    const buttonColor = {
        color: "#552B84",
        cursor: "pointer"
    }

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event?.target)) {
                setSuggestion(false)
            }
        }
        document.addEventListener("mousedown", handler);
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        getInitData();
    }, [])

    // Handle download word reports
    const wordDownloadReports = () => {
        setWordDownloadReportsLoading(true);
        setDownloadReportButtonDisable(true);
        fetch(Constants.API_URL.wordDownloadReports, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                "assignmentId": parseInt(assignmentId),
                "userId": user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                var link = document.createElement('a');
                link.download = "Report.zip";
                link.href = data.fileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setWordDownloadReportsLoading(false);
                setDownloadReportButtonDisable(false);
            }else{
                setWordDownloadReportsLoading(false);
                setDownloadReportButtonDisable(false);
                toast.error(`${data?.message}`)
            }
        });
    }

    // const maskingform = useFormik({
    //     initialValues: {
    //         sortname: '',
    //         stockname: '',
    //         fullname: ''
    //     },
    //     onSubmit: values => {
    //     },
    // });
    // const [flex2, setFlex2] = useState("none");
    // const [subflex, setSubflex] = useState("none");

    // const maskingmanage = (e) => {
    //     if (e.target.id === "flexRadioDefault2") {
    //         setFlex2("block")
    //     } else if (e.target.id === "flexRadioDefault1") {
    //         setFlex2("none")
    //     }

    //     if (e.target.id === "flexRadioDefault5") {
    //         setSubflex("block")
    //     } else if (e.target.id === "flexRadioDefault3" || e.target.id === "flexRadioDefault4") {
    //         setSubflex("none")
    //     }
    // }
    // const [complateDetails, setcomplateDetailsShow] = useState(false);
    // const complateDetailsform = useFormik({
    //     initialValues: {

    //     },
    //     onSubmit: values => {
    //     },
    // });
    
    const requestChange = (e) => {
        setRequestChangeData(e);
        fetch(Constants.API_URL.getFinalizeReviewerList, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                currentUser: e?.ObservationsReviewerCycle?.currentLevel, 
                observationId: e.ObservationsReviewerCycle?.observationId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setRequestChangeReviewList(data?.users);
            }else{
                toast.error(`${data?.message}`)
            }
        });
        setRequestChangeModalShow(true);
    }

    const submitObservationReviewer = (id) => {
        fetch(Constants.API_URL.submitObservationReviwer, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                observationId: id,
                createdBy: user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(data?.message);
                getObservation();
            }else{
                toast.error(`${data?.message}`)
            }
        });
    }

    const getObservation = () => {
        fetch(Constants.API_URL.getObservation + `/${assignmentId}?page=1&limit=${Constants.pageSize}`, {
            method:"GET",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setObservationsList(data.data);
            }else{
                toast.error(`${data?.message}`)
            }
        });
    }

    const validateBeforeFreezeAssignment = () => {
        fetch(Constants.API_URL.validateBeforeFreezeAssignment, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId)
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setValidateBeforeFreezeMessage(data?.message)
                setValidateBeforeFreezeConfirmDialog(true);
            }else{
                setValidateBeforeFreezeErrorMessage(data?.message)
                setValidateBeforeFreezeErrorDialog(true);
            }
        });
    }

    const getInitData = () => {
        setLoading(true);
        Promise.all([
            fetch(Constants.API_URL.assignmentRelatedMasterCounts, {
                method:"POST",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    "assignmentId": assignmentId,
                    "userId": user.userId,
                })
            }),
            fetch(Constants.API_URL.getObservation + `/${assignmentId}?page=1&limit=15`, {
                method:"GET",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            }),
            fetch(Constants.BASE_URL_API + `bookstock/${assignmentId}/getFilterStoreStockList`, {
                method:"GET",
                mode:"cors",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`,
                }
            })
        ])
        .then(([assignmentRelatedMasterCountsRes,observationRes,filterStoreStockRes]) => {
            return Promise.all([assignmentRelatedMasterCountsRes.json(), observationRes.json(), filterStoreStockRes.json()]) 
        })
        .then(([assignmentRelatedMasterCountsRes,observationRes, filterStoreStockRes]) => {
            if(assignmentRelatedMasterCountsRes?.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(assignmentRelatedMasterCountsRes.status === Constants.status.success){
                setAssignmentRelatedMasterCounts(assignmentRelatedMasterCountsRes.data);
                let reportData = assignmentRelatedMasterCountsRes.data.reportData[0] ?? {};
                setReportData(reportData);
                if(reportData && (reportData.status == 'inQueue' || reportData.status == 'inProcess' || reportData.status == 'error')){
                    isAlertShow(true);
                    setMessage('The report is currently being processed. Please refresh the page after some time.');
                    setDownloadReportButtonDisable(true);
                }else if(reportData && reportData.status == 'failed'){
                    isAlertShow(true);
                    setMessage('We are currently experiencing difficulties generating the assignment report. Please reach out to us at namste@takestock.in for assistance. Thank you.');
                    setDownloadReportButtonDisable(true);
                }
            }else{
                toast.error(`${assignmentRelatedMasterCountsRes?.message}`)
            }
            if(observationRes.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                window.location="/";
            }else if(observationRes.status === Constants.status.success){
                setObservationsList(observationRes.data);
            }else{
                setObservationsList([]);
            }
            if(filterStoreStockRes.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(filterStoreStockRes.status === Constants.status.success){
                setStockData(filterStoreStockRes?.storeType);
            }else{
                // toast.error(`${data?.message}`)
            }
            isComponentDidMountFlag(true);
            setLoading(false);
        });
    }

    const getAssignmentRelatedMasterCounts = () => {
        fetch(Constants.API_URL.assignmentRelatedMasterCounts, {
            method: "POST",
            mode:'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setAssignmentRelatedMasterCounts(data.data);
            }else{
                toast.error(`${data?.message}`)
            }
        });
    }

    const assignmentGenerateNewReport = () => {
        setWordDownloadReportsLoading(true);
        setDownloadReportButtonDisable(true);
        fetch(Constants.API_URL.assignmentGenerateNewReport, {
            method: "POST",
            mode:'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setWordDownloadReportsLoading(false);
                setDownloadReportModalShow(false);
                setDownloadReportButtonDisable(true);
                isAlertShow(true);
                setMessage('The report is currently being processed. Please refresh the page after some time.');
            }else{
                setWordDownloadReportsLoading(false);
                setDownloadReportButtonDisable(false);
                setDownloadReportModalShow(false);
                isAlertShow(false);
                toast.error(`${data?.message}`);
            }
        });
    }

    const getOngoingReportUrl = () => {
        setOngoingReportLoading(true);
        fetch(Constants.API_URL.getOngoingReportUrl, {
            method: "POST",
            mode:'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                if(data.data != Constants.status.error.toLowerCase()){
                    var link = document.createElement('a');
                    link.href = data.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadReportButtonDisable(false);
                }else{
                    isAlertShow(true);
                    setMessage('We are currently experiencing difficulties generating the assignment report. Please reach out to us at namste@takestock.in for assistance. Thank you.');
                    setDownloadReportButtonDisable(true);
                }
                setOngoingReportLoading(false);
                setDownloadReportModalShow(false);
            }else{
                toast.error(`${data?.message}`)
                setOngoingReportLoading(false);
            }
        });
    }

    const orderObservationsModalClose = (type) => {
        setOrderObservationsModalShow(false);
        if(type=="save"){
            getObservation();
        }
    }

    const searchBookStockItemOnClick = (e, data) => {
        setSearchBookStockItemData(data);
        fetch(Constants.API_URL.getActualBookStockResolve, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                createdBy: user?.userId,
                bookstockId: data?.bookStockId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setActualStockModalShow(true);
            }else{
                toast.error(data?.message);
            }
        });
    }

    const handleChange = (stocksearch) => {
        if (!assignment?.disableBookStock) {
            fetch(Constants.API_URL.getBookstockByOne + `?search=${encodeURIComponent(stocksearch)}&assignmentId=${assignmentId}`, {
                method:"GET",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.status === Constants.status.codeAccessTokenUnauthorized){
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                }else if(data.status === Constants.status.success){
                    setSearchBookStockByOne(data?.data?.records)
                }else{
                    toast.error(data?.message);
                }
            });
        } else {
            toast.error("Currently Actual Stock Add In Mobile")
        }
        if(stocksearch.length > 0){
            setSuggestion(true)
        }else{
            setSuggestion(false)
        }
    }

    // Function to clear the observations filter and fetching observations data
    const clearFilter = () => {
        setSortType(null);
        getObservation();
    }

    const handleRequestChange = () => {
        fetch(Constants.API_URL.submitObservationSelectedRequestChangeReviwer, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                observationId: requestChangeData?.ObservationsReviewerCycle?.observationId,
                currentLevel: requestChangeData?.ObservationsReviewerCycle?.currentLevel,
                requestUserId: requestUserId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(data?.message);
                setRequestChangeModalShow(false);
                getObservation();
            }else{
                toast.error(data?.message);
            }
        })
    }

    const handleTableChange = (type, newState) => {
        let url = `assignments/getObservatoin/${assignmentId}?type=list&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
        if (newState.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`;
        }
        if (!isEmptyVariable(newState?.searchText)) {
            url = url + `&search=${encodeURIComponent(newState.searchText)}`;
        }
        if (!isEmptyVariable(newState?.sortField)) {
            setSortType(newState?.sortField);
            if (newState.sortField === "observationDetails") {
                url = url + `&sortColumn=observationDetails`
            }
            if (newState.sortOrder) {
                url = url + `&sortType=${newState.sortOrder}`
            }
        }
        fetch(url, {
            method:"GET",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setObservationsList(data.data);
            }else{
                toast.error(`${data?.message}`)
            }
        });
    };

    const editObservationsModalData = (e) => {
        setEditObservationsData(e);
        setObservationsEditModalShow(true)
    }

    // Handle delete observations
    const deleteObservationsModalData = (e) => {
        setDeleteObservationConfirmDialog(true);
        setAssignmentObservationsId(e?.assignmentObservationsId)
    }

    const deleteObservation = () => {
        setDeleteObservationLoading(true);
        fetch(Constants.API_URL.deleteObservation + `/${assignmentObservationsId}`, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                updatedBy : user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(data?.message);
                setDeleteObservationConfirmDialog(false);
                getObservation();
                setDeleteObservationLoading(false);
            }else{
                toast.error(data?.message);
                setDeleteObservationLoading(false);
            }
        });
    }

    // Handles the close event of the Add New Observations modal
    const handleAddNewObservationsModalOnClose = (reload) => {
        setAddNewObservationsModalShow(false);
        if(reload){
            getObservation();
        }
    }

    // Handles the close event of the observations edit modal
    const handleEditObservationClose = (reload) => {
        setObservationsEditModalShow(false);
        if(reload){
            getObservation();
        }
    }

    // Handle the closing of the 'Actual Stock' modal
    const handleActualStockModalClose = (reload) => {
        setActualStockModalShow(false);
        if(reload){
            getAssignmentRelatedMasterCounts();
        }
    }

    // Function to map over the requestChangeReviewList array and return a new array of objects
    const requestChangeOptions = requestChangeReviewList?.map((e) => { return { 'value': e?.userId, 'label': e?.title + " " + e?.firstName + " " + e?.lastName } })
    
    // Function to remove HTML tags from a given string.
    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    // Handle finalize assignment confirmation modal
    const finalizeAssignment = () => {
        setFreezeAssignmentConfrimDialog(false)
        setFreezeAssignmentReConfrimDialog(true);
    }

    const freezeAssignment = () => {
        setFreezeAssignmentLoading(true);
        fetch(Constants.API_URL.freezeAssignment, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                createdBy: user.userId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                toast.success(data?.message);
                setFreezeAssignmentReConfrimDialog(false);
                setFreezeAssignmentLoading(false);
                assignment.teamMembers.forEach(reviewer => {
                    Object.assign(reviewer, reviewer.User);
                    delete reviewer.User;
                })
                assignment.reviewers.forEach(reviewer => {
                    Object.assign(reviewer, reviewer.User);
                    delete reviewer.User;
                })
                navigate(`/Client/completedassignment/${assignmentId}`,{ state: {
                    assignmentListDetails: {
                        storeStockTypeData: stockData,
                        status: 'completed', 
                        assignmentStartDate: assignment.assignmentStartDate,
                        clientName: assignment.clientName,
                        clientUnitName: assignment.clientUnitName,
                        city: assignment.city,
                        teamMembers: assignment.teamMembers,
                        reviewers: assignment.reviewers
                    }
                }})
            }else{
                setFreezeAssignmentReConfrimDialog(false);
                setFreezeAssignmentLoading(false);
                toast.error(data?.message);
            }
        });
    }

    // Function to handle the closing of an alert
    const handleAlertClose = () => {
        isAlertShow(false);
    };

    // Handle show or hide the review cycle action menu based on the current user and reviewer information.
    const actionMenuReviewCycle = (item) => {
        const lastIndex = assignment?.reviewers?.length - 1;
        const reviewer = assignment?.reviewers;
        const id = lastIndex ? reviewer[lastIndex]?.userId : null;
        if(item?.ObservationsReviewerCycle?.currentLevel === user?.userId){
            return true;
        }
        else if(reviewer?.length == 0 || item?.ObservationsReviewerCycle?.currentLevel === id ? "" : item?.ObservationsReviewerCycle?.currentLevel == user?.userId){
            return true;
        }else if(item?.ObservationsReviewerCycle?.createdBy != user.userId && item?.ObservationsReviewerCycle?.currentLevel == user.userId){
            return true;
        }
    }

    // Handle to display submit for review button
    const displaySubmitForReviewItem = (e) => {
        let currentUserLevel = 0;
        for(var i = 1; i < 5; i++){
            if(e.ObservationsReviewerCycle[`level${i}`] == e.ObservationsReviewerCycle.currentLevel){
                currentUserLevel = i;
            }
        }
        let submitForReviewUserIds = [];
        if(currentUserLevel < 4){
            for(var i = (currentUserLevel + 1); i <= 4; i++){
                if(e.ObservationsReviewerCycle[`level${i}`]){
                    submitForReviewUserIds.push(e.ObservationsReviewerCycle[`level${i}`])
                }
            }
            if(submitForReviewUserIds.length > 0  && e.ObservationsReviewerCycle?.currentLevel == user?.userId){
                return true;
            }else{ 
                return false;
            }
        }else{
            return false;
        }
    }

    // Handle to display Request Change button
    const displayRequestChangeItem = (e) => {
        let currentUserLevel = 0;
        for(var i = 1; i < 5; i++){
            if(e.ObservationsReviewerCycle[`level${i}`] == e.ObservationsReviewerCycle.currentLevel){
                currentUserLevel = i;
            }
        }
        let requestChangeUserIds = [];
        for(var i = 1; i < currentUserLevel; i++){
            if(e.ObservationsReviewerCycle[`level${i}`]){
                requestChangeUserIds.push(e.ObservationsReviewerCycle[`level${i}`])
            }
        }
        if(requestChangeUserIds.length > 0 && e.ObservationsReviewerCycle?.currentLevel == user?.userId && e.ObservationsReviewerCycle?.createdBy != user?.userId){
            return true;
        }else {
            return false;
        }
    }

    const observationsListColumn = [
        {
            dataField: "index",
            text: "No",
            headerClasses: "sr_no",
            sort: true,
            style: { cursor: "pointer" },
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        },
        {
            dataField: "observationDetails",
            text: "Observations Details",
            sort: true,
            style: { cursor: "pointer" },
            headerClasses: 'custom-observation-header-class',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        },
        {
            dataField: "reviewer",
            text: "Reviewer",
            sort: true,
            style: { cursor: "pointer" },
            classes:'custom-reviewer-header-class',
            headerClasses:'custom-reviewer-header-class',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        },
        {
            dataField: "action",
            text: "Action",
            style: { cursor: "pointer" },
            headerClasses: "action",
            formatter: (row, e, index) => {
                const lastIndex = assignment?.reviewers?.length - 1;
                const reviewer = assignment?.reviewers;
                const id = lastIndex ? reviewer[lastIndex]?.userId : null;
                return e.hidden ? <div className="m-4"></div> : (
                    <div className="edit_icons">
                        <div className="dropdown">
                            <button 
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false">
                                <i 
                                    className="fa fa-ellipsis-v"
                                    aria-hidden="true"
                                ></i>
                            </button>
                            <ul
                                className="dropdown-menu p-1"
                                aria-labelledby="dropdownMenuButton1"
                            >
                                {e?.ObservationsReviewerCycle?.currentLevel === user?.userId &&
                                    <>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => editObservationsModalData(e)}
                                            >
                                                <i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                                Edit
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                className="dropdown-item"
                                                onClick={() => deleteObservationsModalData(e)}
                                                ><i className="fa fa-trash me-1" aria-hidden="true"></i>
                                                Delete
                                            </a>
                                        </li>
                                    </>
                                }
                                {displaySubmitForReviewItem(e) && 
                                
                                        <li>
                                        <a
                                            className="dropdown-item"
                                            onClick={() => submitObservationReviewer(e.assignmentObservationsId)}
                                        >
                                            <i className="fa fa-arrow-right me-1" aria-hidden="true"></i>
                                            Submit for Review
                                        </a>
                                    </li>
                                }
                               {displayRequestChangeItem(e) && 
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            onClick={() => requestChange(e)}
                                        >
                                            <i className="fa fa-arrow-left me-1" aria-hidden="true"></i>
                                            Request Change
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                )
            },
        }
    ]

    return (
        <>
            {loading && 
                <CommonLoader loading={loading} />
            }
            {!loading && componentDidMountFlag && (
                <>
                    {assignment && 
                        <AssignmentListDetails 
                            assignment={assignment}
                            finalizeButton={'Finalize'}
                            handleOnClick={validateBeforeFreezeAssignment}
                        />
                    }
                    <div className='row'>
                        <div className='col-md-12'>
                            {alertShow &&
                                <CommonAlert
                                    message={reportData.status == 'failed' ? 
                                    (<span>
                                        We are currently experiencing difficulties generating the assignment report. 
                                        Please reach out to us at{' '}
                                        <a href={`mailto:namste@takestock.in`} style={{ color: 'blue', textDecoration: 'underline' }}>namste@takestock.in</a>
                                        {' '}for assistance. Thank you.
                                    </span>) : message}
                                    type={reportData.status == 'failed' ? 'error' : "info"}
                                    closable={reportData.status == 'failed' ? true : false}
                                    onClose={handleAlertClose}
                                />
                            }
                        </div>
                        <div className='col-md-6'>
                            <div className='sample-box mt-3 d-flex align-items-center'>
                                <h6 className='mb-1 searchContainer'>Enter Actual Stock :</h6>
                                <div ref={menuRef} className="ms-3 p-0 mb-0 w-100">
                                    <div className="d-flex justify-content-between">
                                        <input type="text" className='searchbar mr-2' placeholder='Enter Material to Search' onChange={e => { handleChange(e.target.value) }} />
                                    </div>
                                    <div className="suggestions" style={{ display: !suggestion ? 'none' : 'block' }}>
                                        {searchBookStockByOne.length > 0 ? searchBookStockByOne?.map((data, key) =>
                                            <button key={key} onClick={event => searchBookStockItemOnClick(event, data)} className='mb-1 singleSearchSuggestion'>
                                                {data?.partNo1}/{data?.materialName}</button>) : <button className='singleSearchSuggestion'>No Records</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="ongoingbutton add_btn mt-3">
                                <CommonButton
                                    style={{marginRight: 10}}
                                    label={'Upload Book Stock'}
                                    onClick={() => {
                                        if (!assignment?.disableBookStock) { 
                                            navigate("bookstock", { state: { assignmentListDetails: assignment } }) 
                                        }else { 
                                            toast.error("Currantly Actual Stock Add In Mobile") 
                                        }
                                    }}
                                    icon={<PlusCircleFilled
                                        style={{
                                            verticalAlign: 'middle',
                                            marginBottom: '2px'
                                        }}
                                    />}
                                />
                                {/* <CommonButton
                                    style={{marginRight: 10}}
                                    label={'Resolve Multiple Entry'}
                                    onClick={() => navigate("resolvemultipleentry")}
                                /> */}
                                <CommonButton
                                    style={{marginRight: 10, opacity: downloadReportButtonDisable ? 0.7 : 1}}
                                    disabled={downloadReportButtonDisable}
                                    loading={wordDownloadReportsLoading}
                                    label={wordDownloadReportsLoading ? 'Downloading...' : 'Download Report'}
                                    onClick={() => {
                                        if(isEmptyArray(assignmentRelatedMasterCounts.reportData)){
                                            assignmentGenerateNewReport();
                                        }else if(reportData.status == 'completed'){
                                            setDownloadReportModalShow(true);
                                        }
                                    }}
                                    icon={<DownloadOutlined
                                        style={{
                                            verticalAlign: 'middle',
                                            marginBottom: '2px'
                                        }}
                                    />}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <div className='bookstock-box'>
                                <div className='obser-tittle'>
                                    <h6 className='mb-2'>Book Stock</h6>
                                </div>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Total Items</th>
                                                <th scope="col">Total Value</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.bookStockDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.bookStockDetails?.totalItem)}</th>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.bookStockDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.bookStockDetails?.totalValue)}</td>
                                                <td onClick={() => { navigate('bookstockdetails', {state: {assignmentListDetails: assignment}}) }}><a style={buttonColor}>Details</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='bookstock-box'>
                                <div className='obser-tittle'>
                                    <h6 className='mb-2'>Verified</h6>
                                </div>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Total Items</th>
                                                <th scope="col">Total Value</th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.verificationDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.verificationDetails?.totalItem)}</th>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.verificationDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.verificationDetails?.totalValue)}</td>
                                                <td onClick={() => { navigate('verification', {state: {assignmentListDetails: assignment}}) }}><a style={buttonColor}>Details</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='deviation-box'>
                                <div className='obser-tittle'>
                                    <h6 className='mb-2'>Deviations</h6>
                                </div>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Total Items</th>
                                                <th scope='col'>Shortages</th>
                                                <th scope='col'>Total Items</th>
                                                <th scope="col">Excess</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.shortCount) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.shortCount)}</td>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.shortTotal) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.shortTotal)}</td>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.excessCount) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.excessCount)}</td>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.excessTotal) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.excessTotal)}</td>
                                                <td onClick={() => { navigate('deviation', {state: {assignmentListDetails: assignment}}) }}><a style={buttonColor}>Details</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='sample-box '>
                                <div className='obser-tittle'>
                                    <h6 className='mb-2'>Sampling</h6>
                                </div>
                                <div className='table-responsive'>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Total Items</th>
                                                <th scope="col">Total Value</th>
                                                <th scope="col"></th>
                                                <th scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.samplingDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.samplingDetails?.totalItem)}</th>
                                                <td>{isEmptyVariable(assignmentRelatedMasterCounts?.samplingDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.samplingDetails?.totalValue)}</td>
                                                <td onClick={() => { navigate('sampling', {state: {assignmentListDetails: assignment}}) }}> <a style={buttonColor}>Create Sample</a></td>
                                                <td onClick={() => { navigate('sampledetails', {state: {assignmentListDetails: assignment}}) }}><a style={buttonColor}>Details</a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* <Sampling sampling={sampling} /> */}
                            </div>
                        </div>
                    </div>
                    <div className='verification-box' style={{textAlign:'center',justifyContent:'center', marginTop: 10}}>
                        <CommonButton
                            style={{marginRight: 10}}
                            label={'Movement Analysis'}
                            onClick={() => navigate('materialmovement', {state: {assignmentListDetails: assignment}})}
                        />
                        <CommonButton
                            style={{marginRight: 10}}
                            label={'Not Used Analysis'}
                            onClick={() => navigate('materailnotused', {state: {assignmentListDetails: assignment}})}
                        />
                        <CommonButton
                            label={'Expiry Analysis'}
                            onClick={() => navigate('materialexpiery', {state: {assignmentListDetails: assignment}})}
                        />
                    </div>
                    <div className='observation-box'>
                        <div className='obser-tittle'>
                            <h6>Observations</h6>
                        </div>
                        <div style={{textAlign: 'right', marginBottom: 10}}>
                            {sortType != null && 
                                <CommonButton
                                    style={{marginRight: 10}}
                                        label={'Clear'}
                                        onClick={() => clearFilter()}
                                        icon={<FilterFilled
                                            style={{
                                                verticalAlign: 'middle',
                                                marginBottom: '2px'
                                            }}
                                    />}
                                />
                            }
                            {!isEmptyArray(observationsList?.records) && 
                                <CommonButton
                                    style={{marginRight: 10}}
                                        label={'Order Observations'}
                                    onClick={() => setOrderObservationsModalShow(true)}
                                />
                            }
                            <CommonButton
                                label={'Add New'}
                                onClick={() => setAddNewObservationsModalShow(true)}
                                icon={<PlusCircleFilled
                                    style={{
                                        verticalAlign: 'middle',
                                        marginBottom: '2px'
                                    }}
                                />}
                            />
                        </div>
                        {componentDidMountFlag && observationsList?.records && (
                            <Table
                                // key={assignment?.assignmentId ?? "bs-table"}
                                data={observationsList?.records?.map((item, index) => {
                                    return {
                                        ...item,
                                        key: index,
                                        index: (observationsList?.page - 1) * observationsList.limit + index + 1,
                                        id: item?.id,
                                        reviewer: item?.currentObservation?.map(item => item?.title + "" + item?.firstName + " " + item.lastName) ?? "",
                                        observationDetails: stripHtmlTags(item?.observationDetails),
                                        observationDetailswihtoutremovehtml: item?.observationDetails,
                                        status: item?.isActive,
                                        hidden: !actionMenuReviewCycle(item)
                                    };
                                })}
                                columns={observationsListColumn}
                                handleTableChange={handleTableChange}
                                totalSize={observationsList?.total ?? 0}
                                currentPage={observationsList?.page ?? 1}
                                sizePerPage={observationsList?.limit ?? 15}
                            />
                        )}
                    </div>
                    {actualStockModalShow &&
                        <ActualStockModal
                            handleActualStockModalClose={handleActualStockModalClose} 
                            showActualStockModal={actualStockModalShow} 
                            bookStockDetails={searchBookStockItemData} 
                            clientName={assignment?.clientName}
                        />
                    }
                    {observationsEditModalShow &&
                        <ObservationsTableEdit
                            setObservationsDetailsShow={setObservationsDetailsShow}
                            observationsEditModalShow={observationsEditModalShow}
                            editObservationsData={editObservationsData}
                            handleEditObservationClose={handleEditObservationClose}
                        />
                    }
                    {addNewObservationsModalShow &&
                        <ObservationsTable
                            addNewObservationsModalShow={addNewObservationsModalShow}
                            handleAddNewObservationsModalOnClose={handleAddNewObservationsModalOnClose} 
                        />
                    }
                    {orderObservationsModalShow && 
                        <ObservationsOrder
                            orderObservationsModalShow={orderObservationsModalShow}
                            orderObservationsModalClose={orderObservationsModalClose}
                        />
                    }
                    {observationsDetailsShow &&
                        <Observationdetails
                            status={assignment?.status}
                            observationsDetails={observationsDetails}
                            setObservationsDetailsShow={setObservationsDetailsShow}
                            observationsDetailsShow={observationsDetailsShow}
                            handleEditObservationClose={handleEditObservationClose}
                        />
                    }
                    <ReusableModal
                        show={deleteObservationConfirmDialog}
                        onClose={() => setDeleteObservationConfirmDialog(false)}
                        loading={deleteObservationLoading}
                        onConfirm={deleteObservation}
                        message={"The observation will get deleted. Are you sure, you want to delete the observation?"}
                    />
                    {requestChangeModalShow && 
                        <Modal show={requestChangeModalShow}>
                            <Modal.Header>
                                <Modal.Title>Request Change</Modal.Title>
                                <div
                                    onClick={() => {
                                        setRequestChangeModalShow(false);
                                    }}
                                    data-dismiss="modal">
                                    <i className="fa fa-close" aria-hidden="true"></i>
                                </div>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="exampleInput7" className="form-label">Send To</label>
                                <Select
                                    placeholder="Select"
                                    className='reduceFonts'
                                    id="requestUserId"
                                    name="requestUserId"
                                    options={requestChangeOptions}
                                    onChange={v => {
                                        setRequestUserId(v.value)}
                                    }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <CommonButton
                                    style={{marginRight: 10}}
                                    label={'Cancel'}
                                    onClick={() => setRequestChangeModalShow(false)}
                                />
                                <CommonButton
                                    label={'Save'}
                                    onClick={() => handleRequestChange()}
                                />
                            </Modal.Footer>
                        </Modal>
                    }
                    {freezeAssignmentConfrimDialog &&
                        <ReusableModal
                            show={freezeAssignmentConfrimDialog}
                            onClose={() => setFreezeAssignmentConfrimDialog(false)}
                            buttonOk={"OK"}
                            buttonCancel={"Cancel"}
                            onConfirm={finalizeAssignment}
                            message={'you will not be able to edit the assignment any more.'}
                        />
                    }
                    {validateBeforeFreezeConfirmDialog &&
                        <ReusableModal
                            show={validateBeforeFreezeConfirmDialog}
                            onClose={() => setValidateBeforeFreezeConfirmDialog(false)}
                            onConfirm={() => {
                                setFreezeAssignmentConfrimDialog(true); 
                                setValidateBeforeFreezeConfirmDialog(false);
                            }}
                            message={validateBeforeFreezeMessage}
                            buttonOk={"Next"}
                        />
                    }
                    {validateBeforeFreezeErrorDialog &&
                        <ReusableModal
                            show={validateBeforeFreezeErrorDialog}
                            onClose={() => setValidateBeforeFreezeErrorDialog(false)}
                            onConfirm={() => setValidateBeforeFreezeErrorDialog(false)}
                            message={validateBeforeFreezeErrorMessage}
                            confirmButton={true}
                        />
                    }
                    {freezeAssignmentReConfrimDialog &&
                        <ReusableModal
                            show={freezeAssignmentReConfrimDialog}
                            onClose={() => setFreezeAssignmentReConfrimDialog(false)}
                            loading={freezeAssignmentLoading}
                            onConfirm={freezeAssignment}
                            message={'Are you sure, you want to finalise the assignment?'}
                        />
                    }
                    {downloadReportModalShow &&
                        <CommonModal
                            onOpen={downloadReportModalShow}
                            handleRightButton={getOngoingReportUrl}
                            onClose={() => setDownloadReportModalShow(false)}
                            handleLeftButton={assignmentGenerateNewReport}
                            leftButtonLoading={wordDownloadReportsLoading}
                            rightButtonLoading={ongoingReportLoading}
                            className={"custom-common-modal"}
                            content={`The most recent report was generated by ${reportData.title} ${reportData.firstName} ${reportData.lastName} on ${localDateExtractor(reportData.createdAt)} ${localTimeExtractor(reportData.createdAt)}.`}
                            leftButtonName={"Generate Report"}
                            rightButtonName={"Download Report"}
                            closable={true}
                        />
                    }
                </>
            )}
        </>
    );
}

export default OngoingAssignment;