import React, { useState, useEffect } from "react";
import "../ClientDashboard/clientdashboardstyle.css";
import { useParams ,useNavigate} from "react-router-dom";
import { APIRequest, LIST_EMPLOYEE, LIST_CLIENT_UNIT, EDIT_EMPLOYEE, DELETE_EMPLOYEE } from "../../api";
import { Table } from "../Table/Table";
import { toast } from "react-toastify";
import "../EmployeeMaster/employee.css";
import CreateEmployee from "./createEmployee";
import EditEmployee from "./editEmployee";
import Employeedetails from "./employeedetails";
import { Form } from 'react-bootstrap';
import { logout } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import RegularDropDown from "../../Component/common/DropdownMenus/RegularDropdown";
import * as Constants from "../../Component/common/Global/constants";
import { isEmptyArray } from "../../Component/common/Global/commonFunctions";
import ReusableModal from "../Modal/reusablemodel";

const EmployeeMaster = ({ showEmployee }) => {

  const [addemployees, setaddemployeesShow] = useState(false);
  const [updateemployees, setUpdateemployeesShow] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [store, setStore] = useState([]);
  const [editData, seteditData] = useState([]);
  const [updateid, setUpdateid] = useState();
  const [employeedetails, Setemployeedetails] = useState();
  const [employeedetailsshow, Setemployeedetailsshow] = useState(false);
  const [empactive ,Setempactive] =useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [Id, setId] = useState();
  const { clientId, employeeId } = useParams();
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [deleteEmployeeLoading, setDeleteEmployeeLoading] = useState(false);
  const user = useSelector((state) => state?.user);

  const showEditModel = (e) => {
    setUpdateemployeesShow(true);
    seteditData(e);
    setUpdateid(e.employeeId );
  }

  const fetchEmployee = () => {
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_EMPLOYEE)
      .reqURL(empactive ?`employee/getEmployeeList/${employeeId}` : `employee/getEmployeeList/${employeeId}?isActive=false`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };
useEffect(()=>{
  fetchEmployee();
},[empactive]);
  useEffect(() => {
    fetchEmployee();
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_CLIENT_UNIT)
      .reqURL(`clientUnit/getClientUnit/${clientId}`)
      .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addemployees, updateemployees, showEmployee]);


  const handleTableChange = (type, newState) => {
    let url = empactive == true ? `employee/getEmployeeList/${employeeId}?isActive=true&page=${type === "search" || type === "sort" ? newState.page : newState.page
      }&`: `employee/getEmployeeList/${employeeId}?isActive=false&page=${type === "search" || type === "sort" ? newState.page : newState.page
      }&`;
    if (type === "search" && newState.searchText) {
      url = url + `search=${encodeURIComponent(newState.searchText)}&`;
    }
    if (type === "sort") {
      if (newState.sortField === "name") {
        url = url + `sortColumn=employeeName&`
    }
    if (newState.sortField === "unit") {
        url = url + `sortColumn=clientUnitName&`
    }
      if (newState.sortOrder) {
        url = url + `sortType=${newState.sortOrder}&`
      }
    }
    if (newState.sizePerPage) {
      url = url + `limit=${newState.sizePerPage}&`;
    }

    // setIsLoading(true);
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_EMPLOYEE)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();

  };

  const changeStatus = (id, status) => {
    setId(id)
    setConfrimDialog(true);
    // new APIRequest.Builder()
    //   .post()
    //   .setReqId(DELETE_EMPLOYEE)
    //   .jsonParams({ isActive: status, updateBy: user.userId })
    //   .reqURL(`employee/chageEmployeeIsActiveStatus/${id}`)
    //   .response(onResponse)
    //   .error(onError)
    //   .build()
    //   .doRequest();
  };

  const handleDeactivateEmployee = () =>{
    setDeleteEmployeeLoading(true);
    new APIRequest.Builder()
    .post()
    .setReqId(DELETE_EMPLOYEE)
    .jsonParams({ isActive: "false", updateBy: user.userId })
    .reqURL(`employee/chageEmployeeIsActiveStatus/${Id}`)
    .response(onResponse)
    .error(onError)
    .build()
    .doRequest();
  }

  const handleReactiveteEmployee = () =>{
    setDeleteEmployeeLoading(true);
    new APIRequest.Builder()
    .post()
    .setReqId(DELETE_EMPLOYEE)
    .jsonParams({ isActive: "true", updateBy: user.userId })
    .reqURL(`employee/chageEmployeeIsActiveStatus/${Id}`)
    .response(onResponse)
    .error(onError)
    .build()
    .doRequest();
  }

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_EMPLOYEE:      
        setEmployeeData(response.data?.data);
        isComponentDidMountFlag(true);
        break;
      case LIST_CLIENT_UNIT:
        setStore(response?.data.data.records);
        break;
      case EDIT_EMPLOYEE:
        toast.success(response.data?.message);
        fetchEmployee();
        break;
      case DELETE_EMPLOYEE:
        toast.success(response.data?.message);
        setConfrimDialog(false);
        setDeleteEmployeeLoading(false);
        fetchEmployee();
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_EMPLOYEE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
        break;
      case LIST_CLIENT_UNIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
        break;
      case DELETE_EMPLOYEE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
          setDeleteEmployeeLoading(false);
      }
        break;
      default:
        break;
    }
  };

  const handleActiveInactiveChange = (selected) => {
    selected.status === "Inactive" ? Setempactive(false) : Setempactive(true);
    setSelectedOption(selected.status)
    showDropdownItem(false)
  }

  return (
    <>

      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <div className="master_box">
                  <div className="master_boxinfo">
                    <div className="clients_menu">
                      <a className="text-primary tab" href="/Client/client">Clients</a>
                      <a className="text-primary tab" href={`/Client/Unitmaster/${clientId}`}>
                        Unit
                      </a>
                      <a className="btn btn-primary" href={`/Client/Unitmaster/${clientId}/EmployeeMaster/${employeeId}`}>
                        Employee
                      </a>
                    </div>
                    <div className="table-responsive pt-2 pb-5">
                    <div className='deactivelist'>
                          <RegularDropDown 
                            placeholder={selectedOption}
                            dropdownArr={Constants.activeInactiveStatus}
                            labelParam="status"
                            onDropDownItemClick={handleActiveInactiveChange}
                            isFocusRequired={true}
                            show={isShowDropDownItem}
                            onToggle={(isOpen) => showDropdownItem(isOpen)}
                            defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                          />
                        </div>
                      <div className="add_btns">
                        <button
                          type="button"
                          className="btn btn-primary"
                          variant="primary"
                          onClick={() => { setaddemployeesShow(true); seteditData([]); }}
                        >
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>{" "}
                          Add New
                        </button>
                      </div>

                      {componentDidMountFlag && employeeData?.records && (
                        <Table
                          data={employeeData?.records?.map((item, index) => {
                            return {
                              ...item,
                              key: index,
                              index: (employeeData?.page - 1) * employeeData?.limit + index + 1,
                              id: item?.employeeId,
                              name: item?.employeeName  ,
                              email: item?.email,
                              mobileNumber: item?.mobileNumber,
                              store: item?.ClientUnitEmployeeStoreMap?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item?.StoreType?.storeTypeName
                            }</span> }),
                            };
                          })}
                          columns={[
                            {
                              dataField: "index",
                              text: "No",
                              headerClasses: "sr_no",
                              style: { cursor: "pointer" },
                              events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                  Setemployeedetails(row); Setemployeedetailsshow(true)
                                },
                              }
                            },
                            {
                              dataField: "name",
                              text: "Name",
                              sort: true,
                              style: { cursor: "pointer" },
                              events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                  Setemployeedetails(row); Setemployeedetailsshow(true)
                                },
                              }
                            },
                            {
                              dataField: "email",
                              text: "Email",
                              sort: false,
                              style: { cursor: "pointer" },
                              events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                  Setemployeedetails(row); Setemployeedetailsshow(true)
                                },
                              }
                            },
                            {
                              dataField: "mobileNumber",
                              text: "Mobile Number",
                              sort: false,
                              style: { cursor: "pointer" },
                              events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                  Setemployeedetails(row); Setemployeedetailsshow(true)
                                },
                              }
                            },
                            {
                              dataField: "store",
                              text: "Store",
                              sort: false,
                              style: { cursor: "pointer" },
                              events: {
                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                  Setemployeedetails(row); Setemployeedetailsshow(true)
                                },
                              }
                            },
                            {
                              dataField: "action",
                              text: "Action",
                              style: { cursor: "pointer" },
                              headerClasses: "action",
                              formatter: (cell, e) => {
                                return (
                                  e?.isActive == "true" ?
                                  <div className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu p-1"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            data-bs-toggle="#modal"
                                            data-bs-target="#exampleModal3"
                                            onClick={() => showEditModel(e)}
                                          ><i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                           Edit
                                          </a>

                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            onClick={() => changeStatus(e.id, "false")}
                                          ><i className="fa fa-trash me-1" aria-hidden="true"></i>
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                  :
                                  <div className='edit_icons'>
                                    <div className="dropdown">
                                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                      </button>
                                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" onClick={() => {
                                          changeStatus(e.id, "true")
                                        }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                                      </ul>
                                    </div>
                                  </div>
                                );
                              },
                            },
                          ]}
                          handleTableChange={handleTableChange}
                          totalSize={employeeData?.total ?? 0}
                          currentPage={employeeData?.page ?? 1}
                          sizePerPage={employeeData?.limit ?? 15}
                        />
                      )}
                      {componentDidMountFlag && isEmptyArray(employeeData?.records) && 
                          <div className="no-items-layout">
                            <div className="no-items-card">
                              <h6>
                                {
                                  Constants.message.noRecordsWarning
                                }
                              </h6>
                            </div>
                          </div>
                        }
                    </div>
                    <CreateEmployee addemployees={addemployees} setaddemployeesShow={setaddemployeesShow} store={store} />
                    <EditEmployee updateemployees={updateemployees} store={store} updateid={updateid} setUpdateemployeesShow={setUpdateemployeesShow} editData={editData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReusableModal
        show={confrimDialog}
        onClose={() => setConfrimDialog(false)}
        loading={deleteEmployeeLoading}
        onConfirm={empactive ? handleDeactivateEmployee : handleReactiveteEmployee}
        message={empactive ? "Are you sure, you want to delete this employee?" : "Are you sure, you want to reactivate this employee?"}
      />
      <Employeedetails employeedetails={employeedetails} Setemployeedetailsshow={Setemployeedetailsshow} employeedetailsshow={employeedetailsshow} />
    </>
  );
}

export default EmployeeMaster;
