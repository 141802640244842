import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import '../../Pages/OngoingAssignment/assignmentstyle.css';
import '../ObservationsTable/observationTable.css';
import './htab.css';


export const Instances = ({ table, SetTable}) => {
    const [tabledisble, Settabledisble] = useState(false)

    let item = {
        fieldValue: [["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"],
        ["", "", "", "", "", "Action"]]
    }

    const addNewColumn = (e) => {
        let newData = table?.map((elem, index) => {
         if (index % 2) {
            return ([" ", ...elem])
         }  else{
            return ([" ", ...elem])
         } 
        })
        SetTable(newData)

    }


    const addNewRow = (index) => {
        SetTable(oldArray => [...oldArray, oldArray[0].map((item, id) => {
            if (item === "Action") { return "Action" } else { return " " }
        })]);
    }

    const deleteColumn = (colIndex, index) => {
        SetTable(previous =>
            previous.map((elem, index) => {
                let item = elem.filter((item, id) => id !== colIndex);
                return (item)
            }))
    }


    const handleTableInputBlur = (rowIndex, colIndex, index) => {
        table[rowIndex].splice(colIndex, 1, index.target.innerText)
        SetTable(table);

    }

    const handleDeleteTableClickAlert = (e) => {
        SetTable(item.fieldValue);

    }

    const deleteRow = (rowIndex, index) => {
        SetTable(current =>
            current.filter((item, index) => {
                return index !== rowIndex;
            }),
        );

    }
    
    return (
        <>   <div className='editor3 mb-1'>
            <div className='observations_tittle'>
                <label>Instances</label>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                        onClick={(e) => { if (e.target.checked) { Settabledisble(true) } else { Settabledisble(false) }; }} />
                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                        Not Required
                    </label>
                </div>
            </div>
            <div style={{ width: "100%", marginTop: 10, boxShadow: "none" }} className="editor-layout position-relative editor-disabled common-table">
                {tabledisble && <div className='blank_div'></div>}
                <div>
                    <div className="flex-center-layout m-b-10 add-row-button" style={{ textAlign: "right" }}>
                        <Button className="flex-center-layout instances-new-row" onClick={(index) => addNewColumn(index)}>
                            <i className="fa fa-plus" aria-hidden="true" />
                            &nbsp;Add New Column
                        </Button>
                        <Button className="flex-center-layout instances-new-row" onClick={(index) => addNewRow(index)}>
                            <i className="fa fa-plus" aria-hidden="true" />
                            &nbsp; Add New Row
                        </Button>
                    </div>
                    <table data-bind="foreach: my-array" className="table instances-table1 border">                  
                       { table?.map((rowItem, rowIndex) => {
                            if (rowIndex === 0) {
                                return <thead className="instances-thead" key={rowIndex}>
                                    <tr >
                                        {rowItem?.map((colItem, colIndex) => {
                                            return <th key={colIndex} width={colItem === "Action" ? "75" : ""} className={colItem === "Action" ? "text-right action-btn" : ""}>
                                                <div className="instances-table-header">
                                                    <p suppressContentEditableWarning={true}
                                                        tabIndex={colIndex}
                                                        contentEditable={colItem === "Action" ? "false" : "true"}
                                                        onBlur={(index) => handleTableInputBlur(rowIndex, colIndex, index)}
                                                    >
                                                        {colItem}
                                                    </p>
                                                    {colItem === "Action" ? null : (
                                                        <div className="instances-table-del-column" style={{ textAlign: 'right', fontSize: "15px" }}>
                                                            <i onClick={() => deleteColumn(colIndex)}
                                                                className="fa fa-trash fa-2px" aria-hidden="true"
                                                                style={{ cursor: 'pointer' }}>
                                                            </i>
                                                        </div>
                                                    )}
                                                </div>
                                            </th>
                                        })
                                        }
                                    </tr>
                                </thead>
                            } else {
                                return <tbody key={rowIndex} className="instances-thead">
                                    <tr>
                                        {
                                            rowItem?.map((colItem, colIndex) => {
                                                return <td key={colIndex}
                                                    // suppressContentEditableWarning={true}
                                                    className={colItem === "Action" ? "text-center" : ""}
                                                //  contentEditable={colItem === "Action" ? "false" : "true"}
                                                >
                                                    {colItem === "Action" ? (<div className="instances-table-del-cell" style={{ textAlign: 'right', fontSize: "15px" }}>
                                                        <i onClick={(index) => deleteRow(rowIndex, index)}
                                                            className="fa fa-trash"
                                                            style={{ cursor: 'pointer' }}>
                                                        </i>
                                                    </div>) : (<div className="instances-table-del-cell"><p suppressContentEditableWarning={true} tabIndex={colIndex}
                                                        contentEditable={colItem === "Action" ? "false" : "true"}
                                                        onBlur={(index) => handleTableInputBlur(rowIndex, colIndex, index)}>{colItem}</p> </div>)} </td>
                                            })}
                                    </tr>
                                </tbody>
                            }
                        })
                        }
                    </table>
                    <div className="addnew-row-wrapper" style={{ textAlign: "right" }}>
                        <Button className="flex-center-layout instances-new-row" onClick={(index) => handleDeleteTableClickAlert(item.fieldName, index)}>
                            <i className="fa fa-trash" style={{ fontSize: "15px" }} />
                            &nbsp; &nbsp;Delete Table
                        </Button>
                    </div>
                </div>

            </div>
        </div>
        </>

    )
}
