import React from 'react';
import '../UploadBookStock/bookstyle.css'
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CommonButton from '../../commonComponent/button/commonButton';
import CommonTabs from '../../commonComponent/tabs/commonTabs';
import Sampling from './sampling';

function CreateSample() {
    const navigate = useNavigate();
    const location = useLocation();
    const { assignmentId } = useParams();
    const [selectedTab, setSelectedTab] = useState(1);
    const assignmentListDetails = location?.state?.assignmentListDetails;

    // An array of objects representing the tabs
    const tabItems = [
        {
            key: '1',
            label: 'Sampling',
        },
    ];

    // Handles the change of the selected tab.
    const handleTabChange = (selectedTabKey) => {
        setSelectedTab(selectedTabKey);
    }

    return (
        <>
            <div className='main-container'>
                <div className="d-flex">
                    <CommonButton 
                        style={{
                            marginRight: 10
                        }}
                        onClick={() => {
                            navigate(`/Client/ongoingassignment/${assignmentId}`, {
                                state: {
                                    assignmentListDetails: assignmentListDetails
                                }
                            })
                        }}
                        icon={<ArrowLeftOutlined 
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 10,
                                paddingRight: 10,
                                marginBottom: '2px'
                            }}
                        />}
                    />
                    <CommonTabs
                        items={tabItems}
                        style={{marginLeft: 10}}
                        onChange={handleTabChange}
                    />
                </div>
               {selectedTab == 1 && 
                    <Sampling
                        assignmentId={assignmentId}
                        assignment={assignmentListDetails}
                    />
                }
            </div>
        </>
    );
}

export default CreateSample;