import React, { useEffect } from "react";
import "../Login/loginstyle.css";
import { APIRequest, API_LOGIN_SUBMIT, OFFLINE_SET } from "../../api";
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux'
import { setToken, setUser, logout } from "../../redux/action";
import { toast } from "react-toastify";
import { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import CommonButton from "../../commonComponent/button/commonButton";

function Login() {

  const [message, Setmessage] = useState(false);
  const [message2, Setmessage2] = useState(false);
  const [logindata, Setlogindata] = useState()
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const token = useSelector((state) => state.token)
  const user = useSelector((state) => state.user)
  const [errors, setErrors] = useState({});


  const dispatch = useDispatch()
  const onResponse = (response, reqId) => {
    switch (reqId) {
      case API_LOGIN_SUBMIT:
        if (response.data.user.isOffline === "false") {
          if (response.data?.token) {
            dispatch(setToken(response.data?.token))
            dispatch(setUser(response.data?.user))
            toast.success(response.data.message)
            if (response.data.user.role === "SITE_ADMIN") {
              navigate("/Dashboard");
            } else if (response.data?.user?.role === "SUBSCRIBER_ADMIN") {
              navigate("/Client");
            } else if (response.data?.user?.role === "SUBSCRIBER_USER") {
              navigate("/Client");
            } else if (response.data?.user?.role === "SITE_USER") {
              navigate("/Dashboard");
            }
          }
          setLoading(false);
        }
        else {
          Setmessage(true)
          Setlogindata(response);
          setLoading(false);
        }

        break;
      case OFFLINE_SET:
        toast.success(response.data?.message);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case API_LOGIN_SUBMIT:
        let errors = {};
        if(response.data.length > 0){
          response.data.forEach(error => {
            if(error.param == 'emailormobile'){
              errors[error.param] = error.msg;
            }else if(error.param == 'password'){
              errors[error.param] = error.msg;
            }
          });
          setErrors(errors);

          setTimeout(() => {
            setErrors({})
          },3000)
        }
        setLoading(false);
        toast.error(response.data?.message);
        break;
      case OFFLINE_SET:
        toast.error(response.data?.message);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (token && user) {
      if (user.role === "SITE_ADMIN") {
        navigate("/Dashboard");
      } else if (user.role === "SUBSCRIBER_ADMIN") {
        navigate("/Client");
      }
      else if (user.role === 'SUBSCRIBER_USER') {
        navigate("/Client");
      } else if (user.role === 'SITE_USER') {
        navigate("/Dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user])

  const formik = useFormik({
    initialValues: {
      emailormobile: '',
      password: '',
      loginFrom: "web"
    },
    onSubmit: values => {
      setLoading(true);
      new APIRequest.Builder()
        .post()
        .setReqId(API_LOGIN_SUBMIT)
        .jsonParams(values)
        .reqURL("auth/login")
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
      dispatch(logout())
    },
  });

  const offlinechange = (data) => {
    new APIRequest.Builder()
      .post()
      .setReqId(OFFLINE_SET)
      .reqURL(`auth/updateOfflineStatus?userId=${data}&isOffline=false`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }


  const offlinefunction = (data) => {
    if (data.data.token) {
      dispatch(setToken(data.data.token))
      dispatch(setUser(data.data.user))
      offlinechange(data.data.user.userId)
      toast.success(data.data.message)
      if (data.data.user.role === "SITE_ADMIN") {
        navigate("/Dashboard");
      } else if (data.data.user.role === "SUBSCRIBER_ADMIN") {
        navigate("/Client");
      } else if (data.data.user.role === "SUBSCRIBER_USER") {
        navigate("/Client");
      } else if (data.data.user.role === "SITE_USER") {
        navigate("/Dashboard");
      }

      Setmessage2(false);
    }
  }


  return (
    <>
      <div className="container">
        <div className="login_box">
          <div className="login_details">
            <div className="text-center login_logo">
              <img
                src="assets/images/logoTakeStock.png"
                className="img-fluid mb-4" alt=""
              ></img>
            </div>
            <h1>Login</h1>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">
                  Email address*
                </label>
                <input
                  className="form-control"
                  id="emailormobile"
                  name="emailormobile"
                  type="emailormobile"
                  aria-describedby="emailHelp"
                  onChange={formik.handleChange}
                  value={formik.values.emailormobile}
                />
                {errors.emailormobile ? (
                  <span className="error">{errors.emailormobile}</span>
                ) : null}

              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">
                  Password*
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {errors.password ? (
                  <span className="error">{errors.password}</span>
                ) : null}
              </div>
              <div className="Mandotary">
                {/* <p>* Fields Are Mandatory Reqired</p> */}
              </div>
              <div className="mb-3 form-check">
                <div>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    Remember me
                  </label>
                </div>
                <a href="/Forgot">Forgot password?</a>
              </div>
              <div className="text-center">
                <CommonButton
                    label={"Log in"}
                    style={{width: 130, height: 40, marginLeft:10}}
                    loading={loading}
                    onClick={() => formik.handleSubmit()}
                />
              </div>

            </form>
            <div className="text-center mt-3">
              <a className="cursor-pointer" href="/Register">New User Register?</a>
            </div>
          </div>
        </div>
      </div>

      <Modal show={message}>
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
          <div
            onClick={() => {
              Setmessage(false);
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>
            You have made an assignment offline. Sync your data and log in, else offline data will be lost. Do you still want to login?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => Setmessage(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => { Setmessage(false); Setmessage2(true); }} >
            Ok
          </Button>
        </Modal.Footer>

      </Modal >


      <Modal show={message2}>
        <Modal.Header>
          <Modal.Title>Message</Modal.Title>
          <div
            onClick={() => {
              Setmessage2(false);
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>
            If you login, your offline data will be lost. Do you want to login?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => Setmessage2(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => { offlinefunction(logindata) }} >
            Ok
          </Button>
        </Modal.Footer>

      </Modal >
    </>
  );
}

export default Login;
