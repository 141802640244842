import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { toast } from "react-toastify";
import { APIRequest, ADD_CONFIG } from '../../../../api'
import { logout } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RegularDropDown from '../../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../../Component/common/Global/constants";
import { Spinner } from 'react-bootstrap';

export const Addconfig = ({ addconfig, setaddConfig }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state?.user);
    const creditform = useFormik({
        enableReinitialize: true,
        initialValues: {
            rateType: "",
            rateAmount: '',
            userId: user.userId
        },
        validationSchema: Yup.object().shape({
            rateType: Yup.string().required('Rate Type Is Required'),
            rateAmount: Yup.string().required('Rate Amount Is Required')
        }),
        onSubmit: values => {
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(ADD_CONFIG)
                .reqURL("subscriptionRates/addSubscriptionRates")
                .jsonParams(values)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        },
    });

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ADD_CONFIG:
                toast.success(`${response?.data?.message}`);
                setaddConfig(false);
                creditform.resetForm()
                setSelectedOption('')
                setLoading(false);
                break;
            default:
                break;
        }
    }


    const onError = (response, reqId) => {
        switch (reqId) {
            case ADD_CONFIG:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                    setLoading(false);
                }
                break;
            default:
                break;
        }
    }

    const handleRateChange = (selected) => {
        setSelectedOption(selected.rateType)
        creditform.setFieldValue("rateType",selected.value)
        showDropdownItem(false)
    }

    return (
        <>
            <Modal size="sm" show={addconfig}>
                <form onSubmit={creditform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Set Rate</Modal.Title>
                        <div
                            onClick={() => {
                                setaddConfig(false);
                                creditform.resetForm();
                                setSelectedOption('')
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='addclients_details dropdown_width'>
                            <div className="form-group ">
                                {/* <label htmlFor="numberofcredits">Number of Credits </label>
                                <input
                                    className="form-control"
                                    id="credits"
                                    name="credits"
                                    type="text"
                                    onChange={creditform.handleChange}
                                    value={creditform.values.credits}
                                /> */}
                                <RegularDropDown 
                                    placeholder={selectedOption==''?Constants.placeholder.select:selectedOption}
                                    dropdownArr={Constants.rateType}
                                    labelParam="rateType"
                                    onDropDownItemClick={handleRateChange}
                                    isFocusRequired={true}
                                    show={isShowDropDownItem}
                                    onToggle={(isOpen) => showDropdownItem(isOpen)}
                                    defaultPlaceholderDropDown={Constants.placeholder.select}
                                />
                                {creditform.touched.rateType && creditform.errors.rateType ? (
                                    <span className="error">{creditform.errors.rateType}</span>
                                ) : null}

                            </div>
                            <div className="form-group ">
                                <label htmlFor="rate">Value</label>
                                <input
                                    className="form-control"
                                    id="rateAmount"
                                    name="rateAmount"
                                    type="text"
                                    onChange={creditform.handleChange}
                                    value={creditform.values.rateAmount}
                                />
                                {creditform.touched.rateAmount && creditform.errors.rateAmount ? (
                                    <span className="error">{creditform.errors.rateAmount}</span>
                                ) : null}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setaddConfig(false)
                            creditform.resetForm()
                            setSelectedOption('')
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Add"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    )
}
