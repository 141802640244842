import axios from "axios";
import AppStore from "../redux/store";
import * as Constants from "../Component/common/Global/constants";

let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "http://localhost:5500/api/",
  // baseURL: "http://192.168.0.180:3000/api/",
  timeout: 20000,
});


const listener = () => {
  if (AppStore.store.getState()) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${AppStore.store.getState().token}`;
  }
}
AppStore.store.subscribe(listener);
  
axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    let dataResponse = {
      status: response.status,
      data: response?.data ?? null,
    };
    return Promise.resolve(dataResponse);
  },
  (error) => {
    let errorResponse = {
      status: error.response !== undefined ? error.response.status : 'error',
      data:
        error.response.data ?? undefined,
    };

    switch (errorResponse.status) {
      case 404:
        break;
      case 500:
        break;
      case 503:
        break;
      case 400:
        break;
      default:
        break;
    }
    return Promise.reject(errorResponse);
  }
);

export {
  axiosInstance
};
