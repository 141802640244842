import React from 'react'
import { Button, Modal } from "react-bootstrap";
import moment from 'moment';

export const Clientdetails = ({ clientmodal, Setclientmodal, details }) => {
  return (
    <>
      <Modal show={clientmodal}>
        <Modal.Header>
          <Modal.Title>Client Details</Modal.Title>
          <div
            onClick={() => {
              Setclientmodal(false);
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className="row">
            <div className="col-md-12">
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label htmlFor="name" className="form-label w-25">
                  Name{" "}
                </label>:
                <label htmlFor="name" className="form-label ms-3">
                  {details?.organisation}
                </label>

              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group ">
                <label htmlFor="shortName" className="form-label w-25">
                  Short Name{" "}
                </label>:
                <label htmlFor="shortName" className="form-label ms-3">
                  {details?.organisationShortName}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="address1" className="form-label w-25">
                Address Line 1
              </label>:
              <label htmlFor="address1" className="form-label ms-3">
                {details?.address1}
              </label>
            </div>
            <div className="form-group">
              <label htmlFor="address2" className="form-label w-25">
                Address Line 2
              </label>:
              <label htmlFor="address2" className="form-label ms-3">
                {details?.address2}
              </label>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="city" className="form-label w-25">
                  City{" "}
                </label>:
                <label htmlFor="city" className="form-label ms-3">
                  {details?.City?.cityName}
                </label>

              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="state" className="form-label w-25">
                  State{" "}
                </label>:
                <label htmlFor="state" className="form-label ms-3">
                  {details?.City?.State?.stateName}
                </label>

              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="country" className="form-label w-25">
                  Country{" "}
                </label>:
                <label htmlFor="country" className="form-label ms-3">
                  {details?.City?.State?.Country?.countryName}
                </label>

              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="gst" className="form-label w-25">
                  GST{" "}
                </label>:
                <label htmlFor="gst" className="form-label ms-3">
                  {details?.gst}
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => Setclientmodal(false)}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
