
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
    let navigate = useNavigate();

    const backBtnHandle = () => {
        window.history.replaceState(null, null, '/');
        navigate(-2);
    }

    useEffect(() => {
        // Listen for the 'popstate' event (back/forward button press)
        window.addEventListener('beforeunload', backBtnHandle);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', backBtnHandle);
        };
    },[]);

    return (
        <div className="container">
            <div className='login_box'>
                <div className='shadow-lg bg-body rounded login_details text-center pb-5'>
                     <h1 className='text-longshadow'>404</h1>
                    <div className='contain'></div>
                    <h5 className='fw-bold'>Page not found</h5>
                    <p className='mt-3 fw-normal fs-6'>We can't find the page you're looking for.</p>
                    <button 
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                            backBtnHandle()
                        }}
                    ><i className='fa fa-arrow-left me-1'></i> GO BACK</button> 
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;