import React, { Component } from "react";
import { Spin } from "antd";
import '../loader/commonLoader.css';

class CommonLoader extends Component {

    render(){
        const { loading, loadingMessage } = this.props;
        
        return(
            <div className='loader'>
                <Spin tip={loadingMessage ? loadingMessage : "Loading..."} spinning={loading} />
            </div>
        )
    }
}
export default CommonLoader;