import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIRequest, GENERATE_FILE_URL, LIST_CITY, NAME_INFO, REMOVE_ORGANISATION_LOGO, UPDATE_ORGANISATION } from "../../api";
import { Navigate, useNavigate } from "react-router-dom";
import * as Constants from '../../Component/common/Global/constants';
import * as Yup from "yup";
import { logout } from "../../redux/action";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import Select from 'react-select';
import { Button, Spinner } from "react-bootstrap";
import { isEmptyVariable } from "../../Component/common/Global/commonFunctions";
import CommonLoader from "../../commonComponent/loader/commonLoader";

const Organisation = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const [organisation, setOrganisation] = useState({});  
    const [logoUrl, setLogoUrl] = useState("");  
    const [oldData, setOldData] = useState({});  
    const [City, setCityList] = useState([]);
    const [edit, isEdit] = useState(false);
    const [file, setFile] = useState(null);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [organisationDataLoading, setOrganisationDataLoading] = useState(false);

    useEffect(() => {
        getOrganisationDetails();
        if (!token) {
            navigate("/Login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const loadMasterList = (searchText) => {
        if(searchText.length >= 3){
          new APIRequest.Builder()
            .post()
            .setReqId(LIST_CITY)
            .reqURL("city/getCityList")
            .jsonParams({ search: searchText})
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        }
      }

    const getOrganisationDetails = () => {
        setOrganisationDataLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(NAME_INFO)
            .reqURL("auth/getOrganisationName")
            .jsonParams({ organisationId: `${user?.organisationId}` })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    };

    const clearFileInput = () => {
        const fileInput = document.getElementById('organisationLogo');
        if (fileInput) {
            fileInput.value = '';
        }
    };

    const generateFileUrl = (filename) => {
        new APIRequest.Builder()
            .post()
            .setReqId(GENERATE_FILE_URL)
            .jsonParams({
                fileName:filename,
                type:Constants.fileType.logo
            })
            .reqURL("generateFileUrl")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const removeLogo = () => {
        if(!isEmptyVariable(organisation.organisationLogo)){
            new APIRequest.Builder()
                .post()
                .setReqId(REMOVE_ORGANISATION_LOGO)
                .reqURL("auth/removeOrganisationLogo")
                .jsonParams({ organisationId: `${user?.organisationId}` })
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        }else{
            clearFileInput();
            setFile(null);
            let newObj = {...organisationeditform};
            newObj.values.organisationLogo = "";
            organisationeditform = {...newObj};
        }
    }
    
    const organisationeditform = useFormik({
        enableReinitialize: true,
        initialValues: {
            organisationId: user?.organisationId,
            organisation: organisation?.organisation ?? "",
            organisationShortName: organisation?.organisationShortName ?? "",
            address1: organisation.address1 ?? "",
            address2: organisation.address2 ?? "",
            cityId: { 'value': organisation?.cityId ?? "", 'label': organisation?.cityName ?? "" },
            gst: organisation?.gst ?? "",
            createdBy: organisation?.createdBy ?? "",
            updatedBy:user?.userId,
            organisationLogo: organisation?.organisationLogo ?? "",
            stateId:{ 'value': organisation?.stateId ?? "", 'label': organisation?.stateName ?? "" },
            countryId:{ 'value': organisation?.countryId ?? "", 'label': organisation?.countryName ?? "" },
        },
        validationSchema: Yup.object().shape({
            organisation: Yup.string().required("Please Enter Organisation Name"),
            address1: Yup.string().required("Please enter Address"),
            cityId: Yup.object().required("Please select City").nullable(true),
        }),
        onSubmit: (values) => {
            setLoading(true);
            delete values.stateId
            delete values.countryId
            let _step1Value = {
                ...values,
                cityId: values.cityId.value
            }
            const finalValue = { ..._step1Value }
            var bodyFormData = new FormData();
            bodyFormData.append('organisationId', finalValue.organisationId);
            bodyFormData.append('organisation', finalValue.organisation);
            bodyFormData.append('organisationShortName', finalValue.organisationShortName);
            bodyFormData.append('address1', finalValue.address1);
            bodyFormData.append('address2', finalValue.address2);
            bodyFormData.append('cityId', finalValue.cityId);
            bodyFormData.append('gst', finalValue.gst);
            bodyFormData.append('createdBy', finalValue.createdBy);
            bodyFormData.append('updatedBy', finalValue.updatedBy);
            bodyFormData.append('Logo', finalValue.organisationLogo);
            new APIRequest.Builder()
                .post()
                .setReqId(UPDATE_ORGANISATION)
                .jsonParams(bodyFormData)
                .reqURL(`auth/updateOrganisation`)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        },
    });
  
    const onResponse = (response, reqId) => {
        switch (reqId) {
            case NAME_INFO:
                setOrganisation(response.data.name);
                if(response.data.name.organisationLogo != ""){
                    generateFileUrl(response.data.name.organisationLogo)
                }
                setOldData(response.data.name)
                isComponentDidMountFlag(true);
                setOrganisationDataLoading(false);
                break;
            case UPDATE_ORGANISATION:
                setLoading(false);
                toast.success(response?.data.message);
                organisationeditform.resetForm();
                getOrganisationDetails();
                isEdit(false);
                break;
            case LIST_CITY:
                setCityList(response?.data.data.records);
                break;
            case REMOVE_ORGANISATION_LOGO:
                setFile(null);
                getOrganisationDetails();
                toast.success(response?.data.message);
            case GENERATE_FILE_URL:
                setLogoUrl(response.data.data);
            break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case NAME_INFO:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    Navigate("/Login");
                    dispatch(logout());
                } else {
                    setOrganisationDataLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case UPDATE_ORGANISATION:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    Navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case LIST_CITY:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    Navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case REMOVE_ORGANISATION_LOGO:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    Navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case GENERATE_FILE_URL:
                 if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    Navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            default:
                break;
        }
    };

    const cityOption = City.map((e) => { return { 'value': e.cityId, 'label': e.cityName } });

    const stateOption = City?.filter(item => Number(item.cityId) === Number(organisationeditform.values.cityId?.value != undefined ? organisationeditform.values.cityId?.value : organisationeditform.values.cityId))?.map((e) => { return { 'value': e.State.stateId, 'label': e.State.stateName } });

    const countryOption = City?.filter(item => Number(item.cityId) === Number(organisationeditform.values.cityId?.value  != undefined ? organisationeditform.values.cityId?.value : organisationeditform.values.cityId))?.map((e) => { return { 'value': e.State.Country.countryId, 'label': e.State.Country.countryName } });

    useEffect(() => {
        if (countryOption[0] && stateOption[0]) {
            organisationeditform.setFieldValue('countryId', countryOption[0])
            organisationeditform.setFieldValue('stateId', stateOption[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [organisationeditform.values.cityId]);

    const handleChangeUpload = (value) => {
        setFile(URL.createObjectURL(value.target.files[0]))
        organisationeditform.setFieldValue('organisationLogo', value.target.files[0])
    }
    
    return(
        <div className="main_tabs">
            <div className="master_box">
                <div className="clients_menu">
                    <a className='text-primary tab' href="admin">Users</a>
                    {user.role === "SUBSCRIBER_ADMIN" && 
                    <a className='btn btn-primary'>Organisation</a>}
                </div>
                {organisationDataLoading &&
                    <CommonLoader loading={organisationDataLoading} />
                }
                {componentDidMountFlag &&
                <div className="card" style={{marginTop:"1%", width:"auto"}}>
                    <form onSubmit={organisationeditform.handleSubmit}>
                            <div className="card-body">
                                {!edit &&
                                    <div className="add_btns">
                                        <button
                                            style={{marginRight: "12px", minWidth:"80px"}}
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => isEdit(true)}>
                                            <i className="fa fa-pencil mx-1" aria-hidden="true"></i> Edit
                                        </button>
                                    </div>
                                }
                                <p>Organisation Details</p>
                                <div className="row">
                                    <div className="col-md-5 col-lg-6 col-xl-8">
                                        <div className="row mt-3">
                                            <label className='org-logo-label'>Organisation Logo*:</label>
                                            <div className="main-logo-div me-2">
                                                {!isEmptyVariable(organisation.organisationLogo) ? <img className="logo" src={file != null ? file : logoUrl}/> : 
                                                <img src={file != null ? file : '/assets/images/client-logo.png'} className='logo main-logo-div' style={{ border:!file ? "1px solid #552B84" : "", borderRadius:!file ? 5 : ""}}></img>}
                                            </div>
                                            {edit &&
                                            <div className="col-md-3 col-lg-3 mt-1 ">
                                                <label htmlFor="organisationLogo" style={{width:110}}>
                                                <span className='btn btn-primary'>Change Logo</span>
                                                </label>
                                                <input
                                                    type="file"
                                                    id="organisationLogo"
                                                    style={{ display: "none" }}
                                                    onChange={handleChangeUpload}
                                                />
                                                <button className='btn btn-outline-primary d-flex mt-3 px-5' disabled={!edit} style={{width:110, justifyContent:'center'}} type="button"
                                                 onClick={() => removeLogo()}>Remove
                                                </button>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row mt-3">
                                            <div className="col-md-5 col-lg-6 col-xxl-5 org_main_div">
                                                <div className="row">
                                                    <label className='org-label'>Organisation Name*:</label>
                                                    <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                        <input
                                                            className="form-control"
                                                            id="organisation"
                                                            name="organisation"
                                                            type="text"
                                                            readOnly={!edit}
                                                            onChange={organisationeditform.handleChange}
                                                            value={organisationeditform.values.organisation}
                                                        />
                                                        {organisationeditform.touched.organisation && organisationeditform.errors.organisation ? (
                                                            <span className="error">{organisationeditform.errors.organisation}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5 col-lg-6 col-xxl-5 org_main_div">
                                                <div className="row">
                                                    <label className='org-label'>Short Name:</label>
                                                    <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                        <input
                                                            className="form-control"
                                                            id="organisationShortName"
                                                            name="organisationShortName"
                                                            type="text"
                                                            readOnly={!edit}
                                                            onChange={organisationeditform.handleChange}
                                                            value={organisationeditform.values.organisationShortName}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-6 col-xxl-5 org_main_div">
                                        <div className="row mt-3">
                                            <label className='org-label'>GST:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <input
                                                    className="form-control"
                                                    id="gst"
                                                    name="gst"
                                                    type="text"
                                                    readOnly={!edit}
                                                    onChange={organisationeditform.handleChange}
                                                    value={organisationeditform.values.gst}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr style={{borderColor: "#00000020", marginTop: 25}}/>
                                <p className="mt-2">Address</p>
                                <div className="row">
                                    <div className="col-md-5 col-lg-6 col-xxl-5 org_main_div">
                                        <div className="row mt-3">
                                            <label className='org-label'>Address 1*:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <input
                                                    className="form-control"
                                                    id="address1"
                                                    name="address1"
                                                    type="text"
                                                    readOnly={!edit}
                                                    onChange={organisationeditform.handleChange}
                                                    value={organisationeditform.values.address1}
                                                />
                                                {organisationeditform.touched.address1 && organisationeditform.errors.address1 ? (
                                                    <span className="error">{organisationeditform.errors.address1}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <label className='org-label'>Address 2:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <input
                                                    className="form-control"
                                                    id="address2"
                                                    name="address2"
                                                    type="text"
                                                    readOnly={!edit}
                                                    onChange={organisationeditform.handleChange}
                                                    value={organisationeditform.values.address2}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3 dropdown_box dropdown">
                                            <label className='org-label'>City*:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <Select
                                                    placeholder="Select"
                                                    className='reduceFonts'
                                                    id="cityId"
                                                    name="cityId"
                                                    isDisabled={!edit}
                                                    options={cityOption}
                                                    onInputChange={loadMasterList}
                                                    onChange={value => organisationeditform.setFieldValue('cityId', value)}
                                                    value={organisationeditform?.values?.cityId}
                                                />
                                                {organisationeditform.touched.cityId && organisationeditform.errors.cityId ? (
                                                    <span className="error">{organisationeditform.errors.cityId}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-6 col-xxl-5 org_main_div dropdown">
                                        <div className="row mt-3 dropdown_box">
                                            <label className='org-label'>State*:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <Select
                                                    placeholder="Select"
                                                    className='reduceFonts'
                                                    id="stateId"
                                                    name="stateId"
                                                    isDisabled={!edit}
                                                    options={stateOption}
                                                    onChange={value => organisationeditform.setFieldValue('stateId', value)}
                                                    value={organisationeditform?.values?.stateId}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3 dropdown_box">
                                            <label className='org-label'>Country*:</label>
                                            <div className='col-md-6 col-lg-7 col-xxl-8 org_input_div'>
                                                <Select
                                                    placeholder="Select"
                                                    className='reduceFonts'
                                                    id="countryId"
                                                    name="countryId"
                                                    isDisabled={!edit}
                                                    options={countryOption}
                                                    onChange={value => organisationeditform.setFieldValue('countryId', value)}
                                                    value={organisationeditform?.values?.countryId}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {edit &&
                            <div className="d-flex card-footer bg-transparent justify-content-center mt-3 dropdown_box">
                                <button className='btn btn-primary mx-3 my-2' type="button" onClick={() => {
                                    isEdit(false)
                                    setOrganisation(oldData)
                                    setFile(null)
                                    organisationeditform.resetForm()}}>Cancel</button>
                                <button className='btn btn-primary my-2' type="submit">{loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Submit"}</button>
                            </div>
                        }
                    </form>
                </div>
                }
            </div>
        </div>
    )
}
export default Organisation;