import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './billstyle.css';
import { ACTUALSTOCK_FILE_DELETE, APIRequest, BILLSANDPAYMENTS_LIST, GENERATE_FILE_URL, UPLOAD_FILES, UPLOAD_FILES_DATA } from '../../api'
import Credit from './credit';
import { Table } from "../../Pages/Table/Table";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';
import Spinner from "react-bootstrap/Spinner";
import { Button, Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import ReusableModal from '../../Pages/Modal/reusablemodel';
import RegularDropDown from '../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../common/Global/constants";
import { isEmptyArray, isEmptyVariable } from '../common/Global/commonFunctions';
import CreditInstruction from './instruction';
import CryptoJS from 'crypto-js';
import CommonLoader from '../../commonComponent/loader/commonLoader';

function BillPayment() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    let locat = location.pathname.slice(0, 10);
    const orgId = location?.state == null ? {} : location?.state?.organisationId;
    const [startdate, setstartdate] = useState("");
    const [todate, settodate] = useState("");
    const [date1, setdate] = useState("currentMonth");
    const [dateFilter, setdateFilter] = useState(false);
    const [UploadFileModal, setUplodFileModal] = useState(false);
    const { clientId } = useParams();
    var date = new Date();
    const today = new Date();
    var currentDate = new Date();
    const quarter = Math.floor((today.getMonth() / 3));
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    //currant month
    const currantmonthstart = moment().startOf('month').format('YYYY-MM-DD');
    const currantmonthend = moment().endOf('month').format('YYYY-MM-DD');
    //prev month
    var prevmonthstart = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var prevmonthend = new Date(date.getFullYear(), date.getMonth(), 0)
    //currant quater
    const Quarterstart = new Date(today.getFullYear(), quarter * 3, 1);
    const Quarterend = new Date(Quarterstart.getFullYear(), Quarterstart.getMonth() + 3, 0);
    // prev quater
    const prevQuarterstart = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const prevQuarterend = new Date(prevQuarterstart.getFullYear(), prevQuarterstart.getMonth() + 3, 0)
    //currant year
    var currantyearFirst = new Date(currentDate.getFullYear(), 0, 1);
    var currantyearlast = new Date(currentDate.getFullYear(), 11, 31);
    // prev year
    var lastyearfirst = (new Date(lastyear.getFullYear(), 0, 1));
    var lastyearlast = (new Date(lastyear.getFullYear(), 11, 31));
    const user = useSelector((state) => state.user);
    let userId = user.id
    const [buycreditmodal, setbuycreditmodalShow] = useState(false);
    const [instructionModal, setInstructionModalShow] = useState(false);
    const [filesDataModal, setFilesDataModal] = useState(false);
    const [billsAndPayments, SetbillsAndPaymensts] = useState();
    const [loading, setLoading] = useState(false);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [addDescriptionArray, setaddDescriptionArray] = useState([]);
    const [editDescriptionArray, seteditDescriptionArray] = useState([]);
    const [id, setId] = useState();
    const [transactionId, setTransactionId] = useState();
    const [uploadFilesData, setUploadFilesData] = useState([]);
    const filesdata = uploadFilesData?.uploadedFiles;
    const [fileId, setFileId] = useState(false);
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [selectedOption, setSelectedOption] = useState(Constants.placeholder.monthPlaceholder);
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [paymentMessageDialog, setPaymentMessageDialog] = useState(false);
    const [paymentMessage, setPaymentMessage] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [billAndPaymentLoading, setBillAndPaymentLoading] = useState(false);
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);
    const startDate = useRef(null);
    const toDate = useRef(null);
    //decrypt the payment message
    useEffect(() => {
        const queryString = location.search.substring(1);
        const queryParams = queryString.split('&');
        let valParam = '';

        queryParams.forEach(param => {
            const [key, value] = param.split('=');
            if (key === 'val') {
                valParam = value;
            }
        });
        if(valParam){
            const ciphertext = valParam;
            const decryptedData = CryptoJS.AES.decrypt(ciphertext, process.env.REACT_APP_RESPONSE_DEC_KEY);
            const plainText = JSON.parse(decryptedData.toString(CryptoJS.enc.Utf8));
            setPaymentStatus(plainText.status);
            if(plainText.status == Constants.status.codeAccessTokenUnauthorized){
                setPaymentMessageDialog(true);
                setPaymentMessage("Payment is unsuccessful. In case, the amount is debited from your account, it will be credited back. Kindly repurchase the credits to proceed with work.")
            }else{
                setPaymentMessageDialog(true);
                setPaymentMessage("Payment is successful. Credits will be issued soon.");
            }
        }
    },[location.search]);

    function removeValueAfterHash(filename) {
        const cleanedFilename = filename.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    useEffect(() => {
        // if (id) {
        //     getUploadFilesData(id);
        // }
        seteditDescriptionArray([]);
        setaddDescriptionArray([]);
        setUploadFilesData([]);
        setSelectedFiles([]);
    }, [UploadFileModal])

    useEffect(() => {
        getBillAndPaymentList();
    }, []);

    const getBillAndPaymentList = () => {
        setBillAndPaymentLoading(true);
        new APIRequest.Builder()
            .get()
            .setReqId(BILLSANDPAYMENTS_LIST)
            .reqURL(`billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=currentMonth&page=1&limit=15`)
            .jsonParams(userId)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const generateFileUrl = (filename) => {
        new APIRequest.Builder()
            .post()
            .setReqId(GENERATE_FILE_URL)
            .jsonParams({
                fileName:filename,
                type: Constants.fileType.billsAndPayments   
            })
            .reqURL("generateFileUrl")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case BILLSANDPAYMENTS_LIST:
                SetbillsAndPaymensts(response.data?.data);
                setLoading(false);
                isComponentDidMountFlag(true);
                setBillAndPaymentLoading(false);
                break;
            case UPLOAD_FILES_DATA:
                setUploadFilesData(response.data?.data)
                break;
            case UPLOAD_FILES:
                toast.success(`${response.data?.message}`);
                uplodedFileForm.resetForm();
                setUplodFileModal(false);
                setSelectedFiles([]);
                setDescriptionArray([]);
                setaddDescriptionArray([]);
                seteditDescriptionArray([]);
                getBillAndPaymentList();
                break;
            case ACTUALSTOCK_FILE_DELETE:
                toast.success(`${response.data?.data}`);
                setConfrimDialog(false)
                getUploadFilesData(id);
                setDeleteFileLoading(false);
                break;
            case GENERATE_FILE_URL:
                const link = document.createElement('a');
                link.href = response.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_FILE_DELETE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data.data}`);
                    setConfrimDialog(false);
                    setDeleteFileLoading(false);
                }
                break;
            case UPLOAD_FILES_DATA:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);

                }
                break;
            case BILLSANDPAYMENTS_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    setBillAndPaymentLoading(false);

                }
                break;
            case UPLOAD_FILES:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                }
                break;
            case GENERATE_FILE_URL:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                }
                break;
            default:
                break;
        }
    }

    const displayModal = (e) => {
        setFilesDataModal(true);
        setTransactionId(e?.transactionId)
        getUploadFilesData(e?.transactionId);
    }

    const handleTableChange = (transactionType, newState) => {
        let url = dateFilter ? `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?fromDate=${startdate}&toDate=${todate}&page=${transactionType === "search" || transactionType === "sort" ? "1" : newState.page}`
            : `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date1}&page=${transactionType === "search" || transactionType === "sort" ? "1" : newState.page
            }`;
        if (newState.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`;
        }
        if (!isEmptyVariable(newState?.searchText)) {
            setSearch(newState.searchText);
            url = url + `&search=${encodeURIComponent(newState.searchText)}`;
        }else{
            setSearch('');
        }
        if (!isEmptyVariable(newState?.sortField)) {
            setSort(newState?.sortField);
            if (newState.sortField === "transactionType") {
                url = url + `&sortColumn=transactionType`
            }
            if (newState.sortField === "credits") {
                url = url + `&sortColumn=credits`
            }
            if (newState.sortField === "balance") {
                url = url + `&sortColumn=balance`
            }
            if (newState.sortField === "remarks") {
                url = url + `&sortColumn=adminRemarks`
            }
            if (newState.sortOrder) {
                setSortOrder(newState?.sortOrder);
                url = url + `&sortType=${newState.sortOrder}`
            }
        }else{
            setSort('');
            setSortOrder('');
        }

        new APIRequest.Builder()
            .get()
            .setReqId(BILLSANDPAYMENTS_LIST)
            .reqURL(url)
            .jsonParams(userId)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    };

    const dateTableHandler = (date) => {
        setdateFilter(false);
        setdate(date);
        setstartdate('');
        settodate('');
        startDate.current.value = '';
        toDate.current.value = '';
        let url = "";
        if (date === "currentMonth") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`

        } if (date === "previousMonth") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`
        }
        if (date === "currentQuarter") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "previousQuarter") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`

        } if (date === "currentYear") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`

        } if (date === "previousYear") {
            url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?filterTimePeriod=${date}&page=1&limit=15`
        }
        if (search) {
            url = url + `&search=${encodeURIComponent(search)}`;
        }
        if(!isEmptyVariable(sort)){
            url = url + `&sortColumn=${sort}`
        }
        if(!isEmptyVariable(sortOrder)) {
            url = url + `&sortType=${sortOrder}`
        }
        new APIRequest.Builder()
            .get()
            .setReqId(BILLSANDPAYMENTS_LIST)
            .reqURL(url)
            .jsonParams(userId)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const handleSubmit = () => {
        let url = `billsAndPayments/getBillAndPaymentList/${clientId != undefined ? clientId : user.organisationId}?fromDate=${startdate}&toDate=${todate}&page=1&limit=15`;
        setLoading(true);
        setdateFilter(true);
        setSelectedOption(Constants.placeholder.monthPlaceholder)
        if (search) {
            url = url + `&search=${encodeURIComponent(search)}`;
        }
        if(!isEmptyVariable(sort)){
            url = url + `&sortColumn=${sort}`
        }
        if(!isEmptyVariable(sortOrder)) {
            url = url + `&sortType=${sortOrder}`
        }
        new APIRequest.Builder()
            .get()
            .setReqId(BILLSANDPAYMENTS_LIST)
            .reqURL(url)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const deleteFiles = (uplodedfileId) => {
        setFileId(uplodedfileId);
        setConfrimDialog(true);
    }

    const deleteUplodedFiles = () => {
        setDeleteFileLoading(true);
        new APIRequest.Builder()
            .delete()
            .setReqId(ACTUALSTOCK_FILE_DELETE)
            .reqURL(`bookstock/uploadedFiles/${fileId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const UploadFileClick = (e) => {
        setUplodFileModal(true);
        setId(e?.transactionId)
        getUploadFilesData(e?.transactionId);
    }

    const deleteFilesTamp = (i) => {
        const row = [...selectedFiles];
        row.splice(i, 1);
        const darray = [...addDescriptionArray];
        darray.splice(i, 1);
        setaddDescriptionArray(darray);
        setSelectedFiles(row);
    }

    const handleFileChange = (event) => {
        for (let i = 0; i < event.target.files.length; i++) addDescriptionArray.push({ description: "", uploadedFileId: "" });
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    }

    const handleChangeInput = (e, index) => {
        const updatedArray = [...addDescriptionArray];
        updatedArray[index].description = e.target.value;
        descriptionArray[index].uploadedFileId = null;
        setaddDescriptionArray([...updatedArray]);
    }

    const handleChangeInput1 = (e, index, data) => {
        const updatedArray = [...editDescriptionArray];
        updatedArray[index].description = e.target.value;
        updatedArray[index].uploadedFileId = data.uploadedFileId;
        seteditDescriptionArray([...updatedArray]);
    }

    useEffect(() => {
        for (let i = 0; i < uploadFilesData?.uploadedFiles?.length; i++) editDescriptionArray.push({ description: "", uploadedFileId: "" });
    }, [uploadFilesData?.uploadedFiles])

    const getUploadFilesData = (id) => {
        new APIRequest.Builder()
            .get()
            .setReqId(UPLOAD_FILES_DATA)
            .reqURL(`billsAndPayments/getBillAndPaymentDetail/${id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const uplodedFileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            actualStockFile: "",
            transactionId: id
        },
        onSubmit: values => {
            const filteredDescriptions = editDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
            // setLoading(true);
            const mergedArray = [...addDescriptionArray, ...filteredDescriptions];
            var uploadFilesData = new FormData();
            uploadFilesData.append('transactionId', values.transactionId);
            selectedFiles.forEach((file) => {
                uploadFilesData.append('transactionDocument', file);
            });
            uploadFilesData.append("descriptions", JSON.stringify(mergedArray));

            new APIRequest.Builder()
                .post()
                .setReqId(UPLOAD_FILES)
                .jsonParams(uploadFilesData)
                .reqURL("billsAndPayments/uploadBillAndPaymentFile")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    });

    const paymentHandle = () => {
        const queryString = location.search.substring(1);
        const queryParams = new URLSearchParams(queryString);
        queryParams.delete('val');
        const newSearch = queryParams.toString();
        const newURL = `${window.location.origin}${window.location.pathname}${newSearch ? `?${newSearch}` : ''}`;
        // Replace the current URL without reloading the page
        window.location.replace(newURL);
    }

    const capsFirstLatter = (type) => {
        if (type == "reduced") {
            return type = "Reduced"
        } else if (type == "issued") {
            return type = "Issued"
        } else if (type == "utilised") {
            return type = "Utilised"
        } else if (type == "purchase") {
            return type = "Purchase"
        }
    }

    const handleMonthChange = (selected) => {
        let value = selected.value;
        setSelectedOption(value)
        if(value == "Current Month"){
            dateTableHandler("currentMonth")
        }else if(value == "Previous Month"){
            dateTableHandler("previousMonth")
        }else if(value == "Current Quarter"){
            dateTableHandler("currentQuarter")
        }else if(value == "Previous Quarter"){
            dateTableHandler("previousQuarter")
        }else if(value == "Current Year"){
            dateTableHandler("currentYear")
        }else if(value == "Previous Year"){
            dateTableHandler("previousYear")
        }
        showDropdownItem(false)
    }
    const clearFilter = () => {
        setSearch('');
        setSort('');
        setSortOrder('');
        isComponentDidMountFlag(false);
        getBillAndPaymentList();
    }
    return (
        <>
            <div className=''>
                <div className='main_tabs'>
                    <div className='master_box '>
                        <div className='master_boxinfo'>
                            <div className="clients_menu">
                                <a className='btn btn-primary' href={location?.state?.organisationId ? `/Dashboard/billandpayment/${orgId}` : `/Client/billandpayment`}> Bills & Payments </a>
                            </div>
                            {billAndPaymentLoading &&
                                <CommonLoader loading={billAndPaymentLoading} />
                            }
                            <div className='table-responsive pt-2 pb-5 mt-2 table-height'>
                                {componentDidMountFlag && 
                                    <>
                                        <div className='add_btns d-flex'>
                                            {!isEmptyArray(billsAndPayments?.records) && (search != "" || sort != "") && 
                                                <button
                                                type="button"
                                                className="btn btn-primary"
                                                variant="primary"
                                                onClick={() => clearFilter()}
                                                >
                                                <i
                                                    className="fa fa-filter"
                                                    aria-hidden="true"
                                                ></i>{" "}
                                                Clear
                                                </button>
                                            }
                                            <span className='mx-2' style={{ fontSize: "14px" }} >From</span> <input className='form-control border-style' id='assignmentStartDate' type='date'
                                                onChange={(e) => { setstartdate(e.target.value); }}
                                                ref={startDate}
                                            // onChange={editassignmentform.handleChange}
                                            // defaultValue={moment(editAssignmentData?.assignmentStartDate).utc().format('YYYY-MM-DD')} 
                                            ></input>
                                            {/* <label htmlFor="assignmentEndDate" className="form-label">To</label> */}
                                            <span className='mx-2' style={{ fontSize: "14px" }} >To</span><input className='form-control border-style' id='assignmentEndDate' type='date'
                                                onChange={(e) => { settodate(e.target.value); }}
                                                ref={toDate}
                                            // onChange={editassignmentform.handleChange}
                                            // defaultValue={moment(editAssignmentData?.assignmentEndDate).utc().format('YYYY-MM-DD')}
                                            ></input>

                                            <button type="button" className='searchButton' style={{ width: "20px" }} onClick={handleSubmit}>
                                                {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : <i className="fa fa-search" aria-hidden="true"></i>}
                                            </button>
                                            <div className='month-dropdown'>
                                                <RegularDropDown 
                                                    placeholder={selectedOption}
                                                    dropdownArr={Constants.month}
                                                    labelParam="value"
                                                    onDropDownItemClick={handleMonthChange}
                                                    isFocusRequired={true}
                                                    show={isShowDropDownItem}
                                                    onToggle={(isOpen) => showDropdownItem(isOpen)}
                                                    defaultPlaceholderDropDown={Constants.placeholder.monthPlaceholder}
                                                />
                                            </div>
                                            {locat === '/Dashboard' ? "" : <button transactionType='button' className='btn btn-primary w-auto' variant="primary" onClick={setInstructionModalShow}><i className="fa fa-plus-circle" aria-hidden="true"></i> BUY CREDITS</button>}
                                        </div>
                                        <i className="fa fa-search search_icon" aria-hidden="true"></i>
                                    </>
                                }
                                {componentDidMountFlag && billsAndPayments?.records && (
                                    <Table
                                        data={billsAndPayments?.records?.map((item, index) => {
                                            return {
                                                ...item,
                                                key: index,
                                                index: (billsAndPayments?.page - 1) * billsAndPayments?.limit + index + 1,
                                                id: item?.id,
                                                date: moment(item?.updatedAt).utc().format('DD-MM-YYYY') ?? "-",
                                                transactionType: capsFirstLatter(item?.transactionType) ?? "-",
                                                credits: item?.transactionType === "utilized" || item?.transactionType === "reduced" ? `-${item?.credits}` : item?.credits ?? "-",
                                                balance: item?.balance ?? "-",
                                                remarks: item?.transactionType === "utilised" ? item?.userName + ", " + item?.Assignments?.Client?.clientName : item?.adminRemarks ?? "-",
                                                attachment: item?.uploadedFiles ?? "-",

                                            };
                                        })}
                                        columns={[
                                            {
                                                dataField: "index",
                                                text: "No",
                                                headerClasses: "sr_no",
                                                style: { cursor: "pointer" }
                                            },
                                            {
                                                dataField: "date",
                                                text: "Date",
                                                sort: false,
                                                style: { cursor: "pointer" }
                                            },
                                            {
                                                dataField: "transactionType",
                                                text: "Particulars",
                                                sort: true,
                                                style: { cursor: "pointer" }
                                            },
                                            {
                                                dataField: "credits",
                                                text: "Transactions",
                                                sort: true,
                                                style: { cursor: "pointer" }
                                            },
                                            {
                                                dataField: "balance",
                                                text: "Balance",
                                                sort: true,
                                                style: { cursor: "pointer"}
                                            },
                                            {
                                                dataField: "remarks",
                                                text: "Remarks",
                                                sort: true,
                                                headerClasses: 'custom-header-class'  
                                            },
                                            {
                                                dataField: "attachment",
                                                text: "Attachment",
                                                sort: false,
                                                style: { cursor: "pointer",textAlign: 'center', },
                                                headerStyle: (column, colIndex) => {
                                                    return {textAlign: 'center' };
                                                  },
                                                formatter: (cell, e) => {
                                                    return (
                                                        clientId !== undefined ? <i
                                                            className={`fa fa-paperclip h5 ${e?.uploadedFiles.length > 0 ? 'text-primary' : 'grey-icon'}`}
                                                            onClick={() => UploadFileClick(e)}
                                                        ></i>
                                                            :
                                                            e?.uploadedFiles.length > 0 ? <i className="fa fa-paperclip text-dark h5 ms-4" onClick={() => displayModal(e)} aria-hidden="true"></i> : ""

                                                    );
                                                }
                                            },
                                            {
                                                dataField: "action",
                                                text: "",
                                                headerClasses: "action",
                                                style: { cursor: "pointer",  },
                                                formatter: (cell, e) => {
                                                   return(
                                                     <div className="edit_icons">
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle"
                                                                type="button"
                                                                id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                            >
                                                                <i
                                                                    className="fa fa-ellipsis-v"
                                                                    aria-hidden="true"
                                                                    style={{color:'#fff'}}
                                                                ></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                   )
                                                }
                                            }
                                        ]}
                                        handleTableChange={handleTableChange}
                                        totalSize={billsAndPayments?.total ?? 0}
                                        currentPage={billsAndPayments?.page ?? 1}
                                        sizePerPage={billsAndPayments?.limit ?? 15}
                                    />
                                )}
                                {componentDidMountFlag && isEmptyArray(billsAndPayments?.records) && 
                                    <div className="no-items-layout">
                                        <div className="no-items-card">
                                            <h6>
                                                {
                                                Constants.message.noRecordsWarning
                                                }
                                            </h6>
                                        </div>
                                    </div>
                                }
                            </div>
                            {instructionModal &&
                                <CreditInstruction instructionModal={instructionModal} setInstructionModalShow={setInstructionModalShow} setbuycreditmodalShow={setbuycreditmodalShow}/>
                            }
                            {buycreditmodal &&
                                <Credit buycreditmodal={buycreditmodal} setbuycreditmodalShow={setbuycreditmodalShow} userId={userId} />
                            }
                            {UploadFileModal &&
                                <Modal size="md" show={UploadFileModal}>
                                    <form onSubmit={uplodedFileForm.handleSubmit} >
                                        <Modal.Header>
                                            <Modal.Title>Upload File</Modal.Title>
                                            <div
                                                onClick={() => {
                                                    setUplodFileModal(false);
                                                    setSelectedFiles([]);
                                                    setDescriptionArray([]);
                                                    setaddDescriptionArray([]);
                                                    seteditDescriptionArray([]);
                                                }}
                                                data-dismiss="modal">
                                                <i className="fa fa-close" aria-hidden="true"></i>
                                            </div>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <form className='actual-upload-btn '>

                                                <div className='actual-upload-btn text-center'>
                                                    <label htmlFor="inputGroupFile01" className='text-dark'>Upload Photo/Document</label>
                                                    <input
                                                        type="file"
                                                        className="form-control w-50 mb-2 m-auto"
                                                        id="logofile"
                                                        multiple
                                                        onChange={handleFileChange} />
                                                </div>
                                                <div>

                                                    {selectedFiles?.map((file, index) => (
                                                        <>
                                                            <div className='row d-flex'>
                                                                <div className='col-md-4'>
                                                                    <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>

                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <input
                                                                        type="text"
                                                                        name={`descriptions.${index}.description`}
                                                                        // value={stockform.values.descriptions[index]?.description ?? ""}
                                                                        onChange={(e) => handleChangeInput(e, index)}
                                                                        className="form-control mb-2  m-auto"
                                                                    />
                                                                </div>
                                                                <div className='col-md-2 '>
                                                                    <span id="list" onClick={() => deleteFilesTamp(index)}>
                                                                        <i className="fa fa-trash ms-1"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                                {filesdata?.length > 0 && <>
                                                    {/* <Dropdown.Divider /> */}

                                                    <div>
                                                        <table className='table'>
                                                            <thead>
                                                                <tr>
                                                                    <th>File Name</th>
                                                                    <th>Description</th>
                                                                    <th className='text-end' >Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filesdata.map((e, index) =>

                                                                (<>
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>{removeValueAfterHash(e.uploadedFilePath)}</a>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="text"
                                                                                name={e.description}
                                                                                defaultValue={e.description}
                                                                                onChange={(event) => handleChangeInput1(event, index, e)}
                                                                                className="form-control input-lg w-100"
                                                                            />
                                                                        </td>
                                                                        <td className='text-end'>
                                                                            <span onClick={() => deleteFiles(e.uploadedFileId)}>
                                                                                <i className="fa fa-trash ms-1"></i>
                                                                            </span>
                                                                        </td>
                                                                    </tr>

                                                                </>)


                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div></>}
                                            </form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={() => {
                                                setUplodFileModal(false);
                                                setSelectedFiles([]);
                                                setDescriptionArray([]);
                                                setaddDescriptionArray([]);
                                                seteditDescriptionArray([]);
                                            }}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" type="submit">
                                                {/* {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Save"} */}
                                                save
                                            </Button>
                                        </Modal.Footer>
                                    </form>
                                </Modal>
                            }
                            {filesDataModal &&
                                <Modal size="md" show={filesDataModal}>

                                    <Modal.Header>
                                        <Modal.Title>Upload Files</Modal.Title>
                                        <div
                                            onClick={() => {
                                                setFilesDataModal(false);

                                            }}
                                            data-dismiss="modal">
                                            <i className="fa fa-close" aria-hidden="true"></i>
                                        </div>
                                    </Modal.Header>
                                    <Modal.Body>

                                        {filesdata?.length && <>
                                            {/* <Dropdown.Divider /> */}

                                            <div>
                                                <table className='table'>
                                                    <thead>
                                                        <tr>
                                                            <th>File Name</th>

                                                            <th className='text-end'>
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {filesdata.map((e, index) =>

                                                        (<>
                                                            <tr key={index}>

                                                                <td>
                                                                    {removeValueAfterHash(e.uploadedFilePath)}
                                                                </td>
                                                                <td className='text-end'>
                                                                    <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>
                                                                        <i className="fa fa-download ms-5"></i>
                                                                    </a>
                                                                </td>
                                                            </tr>

                                                        </>)


                                                        )}
                                                    </tbody>
                                                </table>
                                            </div></>}

                                    </Modal.Body>

                                </Modal>
                            }
                            {paymentMessageDialog &&
                                <Modal show={paymentMessageDialog}>
                                    <Modal.Body>
                                        <div className="dialog-layout modal-click">
                                        
                                        {paymentStatus == Constants.status.codeAccessTokenUnauthorized ? 
                                            <>
                                                <i className={"fa fa-times-circle fa-4x icon_error"}></i>
                                                <h4 className='icon_error mt-3'><b>Payment Failed!!</b></h4>
                                            </>
                                            :
                                            <>
                                                <i className={"fa fa-check-circle fa-4x icon_success"}></i>
                                                <h4 className='icon_success mt-3'><b>Payment Successful!</b></h4>
                                            </>
                                        }
                                        <p style={{marginTop:0, fontSize:16}}>{paymentMessage}</p>
                                        <div className="dialog-btn-layout">
                                            <a
                                            className="alert-dialog-warning"
                                            href="javascript:void(0);"
                                            onClick={paymentHandle}
                                            style={{
                                                marginLeft:"10px",
                                            }}
                                            >
                                            Ok
                                            </a>
                                        </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {confrimDialog &&
                <ReusableModal
                    show={confrimDialog}
                    onClose={() => setConfrimDialog(false)}
                    loading={deleteFileLoading}
                    onConfirm={deleteUplodedFiles}
                    message={"Are you sure, you want to delete this file?"}
                />
            }
        </>

    );
}

export default BillPayment;