import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { APIRequest, BOOKSTOCK_MAP_FIELDS, CREATE_NEW_SINGLE_BOOKSTOCK, UPDATE_BOOKSTOCK } from "../../../api";
import * as Constants from '../../../Component/common/Global/constants';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/action";
import { isEmptyVariable } from "../../../Component/common/Global/commonFunctions";
import { toast } from "react-toastify";
import moment from "moment";

const EditMapFieldModal = ({ mapFieldModal, setMapFieldModal, singleBookStockData }) => {
    const [loading, setLoading] = useState(false);
    const [mapField, setMapField] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState({});
    const user = useSelector((state) => state?.user);
    const [validation, setValidation] = useState({});
    const selectedColumnsRef = useRef();
    selectedColumnsRef.current = selectedColumns;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        getBookStockMapFields();
    },[]);
    
    const getBookStockMapFields = () => {
        new APIRequest.Builder()
            .post()
            .setReqId(BOOKSTOCK_MAP_FIELDS)
            .reqURL(`bookstock/getBookStockMapFields`)
            .jsonParams({
                storeTypeId: singleBookStockData.storeType,
                stockTypeId: singleBookStockData.stockType,
                assignmentId: singleBookStockData.assignmentId,
            })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const updateBookStock = () => {
        const selectedColumnsValue = selectedColumnsRef.current;
        let finalValue = {
            assignmentId: singleBookStockData.assignmentId,
            userId: user.userId,
            bookStockMapData: selectedColumnsValue,
            bookStockId: singleBookStockData.bookStockId
        }
        let errormessage = false;
        setLoading(true);
        let errors = {};

        if (isEmptyVariable(selectedColumnsValue.uom) && isEmptyVariable(selectedColumnsValue.quantity)) {
            errormessage = true;
            errors['Uom'] = "UOM is required";
            errors['Qty'] = "Quantity is required";
        }
        
        Object.keys(finalValue.bookStockMapData).forEach(key => key === "partNo1" && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => key === "materialName" && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key] === undefined && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key] === "" && delete finalValue.bookStockMapData[key])
        Object.keys(finalValue.bookStockMapData).forEach(key => finalValue.bookStockMapData[key]?.length === 0  && delete finalValue.bookStockMapData[key])

        if (errormessage) {
            setValidation(errors);
            setLoading(false);
            setTimeout(()=>{
                setValidation({})
            },4000);
        } else {
            new APIRequest.Builder()
                .post()
                .setReqId(UPDATE_BOOKSTOCK)
                .jsonParams(finalValue)
                .reqURL("bookstock/updateBookStock")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        }
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case BOOKSTOCK_MAP_FIELDS:
                setLoading(false);
                setMapField(response.data?.data);
                let columns = {};
                if (response.data?.data) {
                    response.data.data.forEach(h => {
                        Object.keys(singleBookStockData).filter(function(key) {
                            if(h.originalName == key){
                                if(key == "quantity"){
                                    columns[h.originalName] = parseFloat(singleBookStockData[key]);
                                }else if(key == "value" || key == "rate"){
                                    var stringWithoutCommas = (singleBookStockData[key]).replace(/,/g, '');
                                    columns[h.originalName] = parseInt(stringWithoutCommas);
                                }else if(key == "lastReceiptDate" || key == "lastIssueDate"){
                                    columns[h.originalName] = moment(singleBookStockData[key]).format('YYYY-MM-DD')
                                }else if((singleBookStockData[key] == null) || (singleBookStockData[key] == "-")){
                                    columns[h.originalName] = "";
                                }else{
                                    columns[h.originalName] = singleBookStockData[key];
                                }
                            }
                        });
                    });
                }
                setSelectedColumns({ ...columns });
                break;
            case UPDATE_BOOKSTOCK:
                setMapFieldModal(true);
                toast.success(response.data.message);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
        case BOOKSTOCK_MAP_FIELDS:
            if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            } else {
            }
            break;
        case UPDATE_BOOKSTOCK:
            if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            } else {
                setLoading(false);
                setMapFieldModal();
            }
            break;
        default:
            break;
        }
    }

    
    return(
        <>
            <Modal show={mapFieldModal}>
                <Modal.Header>
                    <Modal.Title className="m-auto h2">Edit Single Bookstock</Modal.Title>
                    <div
                        onClick={() => {
                            setMapFieldModal();
                            setLoading(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className='row mb-2'>
                            {mapField?.map((item, key) =>
                            <>
                                <div className="col-md-6 mb-3">
                                    <label htmlFor={item.originalName} key={key} className='d-block mx-auto'>{item.displayName}</label>
                                    <input
                                        className="form-control mx-auto"
                                        id={item.originalName}
                                        name={item.originalName}
                                        max={new Date().toISOString().split('T')[0]}
                                        readOnly={((item.originalName == 'partNo1')  || (item.originalName == 'materialName')) || singleBookStockData?.isActive == "false"}
                                        onKeyDown={(e) => {
                                            if(item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate"){
                                                e.preventDefault()
                                            }
                                        }} // Prevent manual typing
                                        onPaste={(e) => {
                                            if(item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate"){
                                                e.preventDefault()
                                            }
                                        }} // Prevent pasting
                                        onWheel={e => e.currentTarget.blur()}
                                        type={(item.originalName == "value" || item.originalName == "rate" || item.originalName == "quantity") ? "number" : item.originalName == "lastReceiptDate" || item.originalName == "lastIssueDate" ? "date" : "text"}
                                        onChange={(e) => {
                                            let data = e?.target?.value;
                                            if(item.originalName == "quantity"){
                                                selectedColumns[item.originalName] = isNaN(parseFloat(data)) ? null : parseFloat(data);
                                            }else if(item.originalName == "value" || item.originalName == "rate"){
                                                selectedColumns[item.originalName] = isNaN(parseInt(data))  ? null : parseInt(data);
                                            }else{
                                                selectedColumns[item.originalName] = data;
                                            }
                                            setSelectedColumns({ ...selectedColumns });
                                        }}
                                        value={selectedColumns[item.originalName]}
                                    />
                                    {item.originalName == "uom"  && validation['Uom'] ?
                                        <span className="error">{validation?.Uom}</span> : 
                                    null}
                                    {item.originalName == "quantity" && validation['Qty'] ?
                                        <span className="error">{validation?.Qty}</span> : 
                                    null}
                                </div>
                                </>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                {singleBookStockData?.isActive == "true" && 
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setMapFieldModal();
                                setLoading(false);
                            }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" onClick={updateBookStock}>
                            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Update"}
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </>
    )
}
export default EditMapFieldModal;