import styled from 'styled-components';

export const CustomTableRow = styled.div`
    display:flex;
    align-items:center;
    outline:0;
    border-bottom: 1px solid #dee2e6;

    :last-child{
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    :last-child p:first-child{
        border-bottom-left-radius: 4px;
    }
    :last-child p:last-child{
        border-bottom-right-radius: 4px;
    }
    .table-custom-body{
        width:100%;
    }
    p{
        border-bottom: "0px solid #dee2e6";
        border-top: 0px solid #dee2e6;
        padding: 0px 10px;
        vertical-align: middle;
        min-height: 32px;
        vertical-align: middle;
        font-size: 12.5px;
        color: #000000;
        position: relative;
        background-clip: padding-box;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .table-cell{
        // border-bottom: 1px solid #dee2e6;
        display: flex;
        align-items: center;
        height: 32px;
    }

    .guidance-layout{
        display:flex;
        align-items:center;

        span{
            font-size:18px;
            color:#666;
            flex-shrink:0;
            cursor:pointer;
        }
        span:hover{
            color:#282828;
        }

        p{
            margin-bottom:0px;
            margin-right:10px;
            padding:0;
        }
    }
`;