import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import '../Table/tablestyle.css'
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
const { SearchBar } = Search;
export const Table = (object) => {
  // {
  //   data, columns,
  //   totalSize,
  //   handleTableChange,
  //   sizePerPage,
  //   currentPage,
  //   rowEvents
  // }
  const paginationOption = {
    custom: true,
    page: object.currentPage,
    sizePerPage: object.sizePerPage,
    totalSize: object.totalSize ?? 0,
    firstPageText: 'First',
    prePageText: 'Previous',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next',
    lastPageTitle: 'Last page',
    onSizePerPageChange: (sizePerPage, page) => {
    },
    paginationTotalRenderer:(from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing rows { from } to { to } of { size }
      </span>
    )
  };

  return (
    <PaginationProvider
      pagination={paginationFactory(paginationOption)}
    >
      {
        ({
          paginationProps,
          paginationTableProps
        }) => (
          <div>
            <SizePerPageDropdownStandalone
              {...paginationProps}
            />

            <ToolkitProvider
              keyField="id"
              columns={object.columns}
              data={object.data}
              search
            >
              {
                toolkitprops => (
                  <div>
                    <SearchBar {...toolkitprops.searchProps} className="search-width"/>
                    <BootstrapTable
                      classes='dataTable no-footer'
                      bordered={false}
                      remote={{
                        filter: false,
                        pagination: true,
                        search: true,
                        sort: true,
                      }}
                      onTableChange={object.handleTableChange}
                      rowEvents={object.rowEvents}
                      {...toolkitprops.baseProps}
                      {...paginationTableProps}
                    />
                  </div>
                )
              }

            </ToolkitProvider>
            <PaginationListStandalone
              {...paginationProps}
            />
            <PaginationTotalStandalone
              {...paginationProps}
            />
          </div>
        )
      }
    </PaginationProvider>
  );
}