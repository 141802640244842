import React, { useRef } from 'react'
import Select from 'react-select';
import { useFormik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import '../../clientdashboardstyle.css'
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { APIRequest, LIST_USER, LIST_CLIENT_UNIT, ASSIGNMENT_CREATE, LIST_SITEUSER, LIST_CLIENT } from '../../../../api';
import { useState } from 'react';
import * as Yup from "yup";
import { useDispatch,useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { logout } from '../../../../redux/action';
import * as Constants from '../../../../Component/common/Global/constants';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";

const Schedulednewassignment = ({ newscheduled, setnewscheduledShow }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [startdate,setStartDate]=useState();
    const [unitdata, SetUnitdata] = useState([])
    const [member, Setmember] = useState([]);
    const [client, setClient] = useState([]);
    const [teamleader, SetTeamleader] = useState(false);
    const[clientUser,SetClientUser] =useState([]);
    const [reviewerList1,setReviewerList1] = useState([]);
    const [reviewerList2,setReviewerList2] = useState([]);
    const [teammemberList,setTeammemberList] = useState([]);
    const [teamleaderList,setTeamleaderList] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const newMemberList = member?.map((e) => { return { 'value': e.userId, 'label': e.firstName + " " + e.lastName } });
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const resourceDropdownOnChange = (v,resourceType) => {
        if(resourceType== "TM"){
            const memberTeamList = v.map((item)=>{ 
                 return item.value;
             })
             setTeammemberList(memberTeamList);
        }
        else if(resourceType== "TL"){
            const memberTeamList = v?.value; 
             setTeamleaderList(memberTeamList);
        }
        else if(resourceType== "RL1"){
            const memberTeamList = v?.value;        
            setReviewerList1(memberTeamList);
        }
        else if(resourceType== "RL2"){
            const memberTeamList = v?.value;   
            setReviewerList2(memberTeamList);
        }
   }
   
   const filterResourceDisplayArray = () =>{
        return newMemberList.filter(item => !teammemberList.includes(item.value))
            .filter(item => teamleaderList != item.value )
            .filter(item => reviewerList1 != item.value)
            .filter(item => reviewerList2 != item.value)
   }

    const newscheduledform = useFormik({
        initialValues: {
            clientId: '',
            clientUnitId: '',
            referenceNumber :'',
            storeId: '',
            teamMemberId: '',
            teamLeadId: null,
            reviewer1Id: null,
            reviewer2Id: null,
            organisationId:'',
            userId:'',
            assignmentStartDate: "",
            assignmentEndDate: ""
        },
        validationSchema: Yup.object().shape({
            clientId: Yup.string().required("Select Client").nullable(true),
            clientUnitId: Yup.string().required("Select Unit Type").nullable(true),
        }),
    
        onSubmit: values => {
            setLoading(true);

            Object.keys(values).forEach(key => values[key] === "" && delete values[key])
            let _step1Value = {
                ...values,
                organisationId:user.organisationId,
                userId:user.userId,
                storeId:values?.storeId?.map((e) => { return e.value; }) ,
                teamMemberId: values?.teamMemberId?.map((e) => { return e.value; }) ?? [],
            }
            const finalValue = { ..._step1Value }
            Object.keys(finalValue).forEach(key => finalValue[key] === undefined && delete finalValue[key])
            Object.keys(finalValue).forEach(key => finalValue[key] === "" && delete finalValue[key])
            Object.keys(finalValue).forEach(key => finalValue[key]?.length === 0  && delete finalValue[key])

            new APIRequest.Builder()
                .post()
                .setReqId(ASSIGNMENT_CREATE)
                .jsonParams(finalValue)
                .reqURL('assignments/addAssignments')
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
                newscheduledform.resetForm();
        },
    });
    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(LIST_CLIENT)
            .reqURL(`client/getClientList/${user.organisationId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        new APIRequest.Builder()
            .post()
            .setReqId(LIST_SITEUSER)
            .reqURL(`user/userList/${user.userId}?organisationId=${user.organisationId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
         newscheduledform?.values?.clientId &&  new APIRequest.Builder()
            .post()
            .setReqId(LIST_CLIENT_UNIT)
            .reqURL(`clientUnit/getClientUnit/${newscheduledform?.values?.clientId}`)
            .jsonParams({ organisationId: user?.organisationId, userId: user?.userId })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        // new APIRequest.Builder()
        //     .post()
        //     .setReqId(LIST_USER)
        //     .jsonParams({ "role": "THIRD_PARTY_ADMIN" })
        //     .reqURL(`user/client/${user.id}`)
        //     //.reqURL(`user/client/${newscheduledform.values.clientId}`)
        //     .response(onResponse)
        //     .error(onError)
        //     .build()
        //     .doRequest();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newscheduledform?.values?.clientId])

    // useEffect(() => {
    //     if (newscheduledform.values.teamMemberId && newscheduledform.values.teamMemberId.length >= "2" && newscheduledform.values.teamLeadId === "") {
    //         SetTeamleader(true)
    //     } else { SetTeamleader(false) }
    // }, [newscheduledform.values.teamMemberId, newscheduledform.values.teamLeadId])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case LIST_CLIENT_UNIT:
                SetUnitdata(response.data?.data?.records);
                break;
            case LIST_USER:
                SetClientUser(response.data?.data?.records);
                break;
            case LIST_SITEUSER:
                Setmember(response.data?.data?.records);
                break;
            case LIST_CLIENT:
                setClient(response.data?.data);
                break;
            case ASSIGNMENT_CREATE:
                 setLoading(false);
                toast.success(response?.data?.message);
                setnewscheduledShow(true);
                newscheduledform.resetForm();
                setReviewerList1("");
                setReviewerList2("");
                setTeammemberList("");
                setTeamleaderList("");
                break;
            default:
                break;
        }
    }
    const onError = (response, reqId) => {
        switch (reqId) {
            case LIST_CLIENT_UNIT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                toast.error(response?.data?.message);
                }
                break;  
                case LIST_CLIENT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                toast.error(response?.data?.message);
                }
                break;
            case ASSIGNMENT_CREATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.status}`);
                    setnewscheduledShow(true);
                    newscheduledform.resetForm();
                    setReviewerList1("");
                    setReviewerList2("");
                    setTeammemberList("");
                    setTeamleaderList("");
                    setLoading(false);                   
                }
                break;
            default:
                break;
        }
    }
    const storeOptions = unitdata?.find((item) => item.clientUnitId === newscheduledform.values.clientUnitId )?.ClientUnitStoreMap?.map((e) => { return { 'value': e.StoreType?.storeTypeId, 'label': e.StoreType?.storeTypeName } })
    const unitOptions = unitdata?.map((e) => { return { 'value': e.clientUnitId, 'label': e.clientUnitName } })
    const clientOptions = client?.records?.map((e) => { return { 'value': e.clientId, 'label': e.clientName } })
    const handleChangedateChange= (e => {
        let startDate = e.target.value;
        let endDate = inputRef.current.value;
        if(endDate < startDate && endDate != '' && endDate != null){
            newscheduledform.setFieldValue("assignmentEndDate", "")
            inputRef.current.value = ''
        }
        newscheduledform.setFieldValue("assignmentStartDate", e.target.value)
        setStartDate(e.target.value);
    })
    return (
        <>
            <Modal show={newscheduled}>
                <form onSubmit={newscheduledform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Schedule New Assignments</Modal.Title>
                        <div 
                            onClick={() => {
                                setnewscheduledShow(); 
                                newscheduledform.resetForm(); 
                                setReviewerList1("");
                                setReviewerList2("");
                                setTeammemberList("");
                                setTeamleaderList("");
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row dropdown_box'>
                            <div className='col-md-12'>
                                <div className="">
                                    <label htmlFor="exampleInput1" className="form-label">Client Name</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={clientOptions}
                                        onChange={value => {
                                            if(newscheduledform.values.clientId != value.value){
                                                setSelectedUnit(null);
                                                setSelectedStore(null);
                                                newscheduledform.setFieldValue('clientUnitId', "");
                                                newscheduledform.setFieldValue('storeId', "");
                                            }
                                            newscheduledform.setFieldValue('clientId', value.value);
                                        }}
                                        defaultValue={newscheduledform.values.clientId}
                                    />
                                    {newscheduledform.touched.clientId && newscheduledform.errors.clientId ? (
                                        <span className="error">{newscheduledform.errors.clientId}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput2" className="form-label">Unit</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={unitOptions}
                                        onChange={value => {
                                            if(selectedUnit?.value != value.value){
                                                setSelectedStore(null);
                                                newscheduledform.setFieldValue('storeId', "");
                                            }
                                            setSelectedUnit(value);
                                            newscheduledform.setFieldValue('clientUnitId', value.value)
                                        }}
                                        value={selectedUnit}
                                    />
                                    {newscheduledform.touched.clientUnitId && newscheduledform.errors.clientUnitId ? (
                                        <span className="error">{newscheduledform.errors.clientUnitId}</span>
                                    ) : null}
                                </div>
                            </div>
                           
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput3" className="form-label">Store Type</label>
                                    <Select
                                        placeholder="Select"
                                        isClearable
                                        className='reduceFonts'
                                        options={storeOptions}
                                        onChange={value => {
                                            setSelectedStore(value);
                                            newscheduledform.setFieldValue('storeId', value)
                                        }}
                                        value={selectedStore}
                                        isMulti
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="assignmentStartDate" className="form-label">Reference Number</label>
                                    <input className='form-control' id='referenceNumber' type='text'
                                       onChange={newscheduledform.handleChange}
                                    ></input>
                                </div>
                            </div>
                            <div><p className='text-center mb-0 mt-2 pb-0 '  style={{ fontSize: "13px" }}>Audit Timeline</p>
                             </div>
                            <Dropdown.Divider  />
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="assignmentStartDate" className="form-label">Start Date</label>
                                    <input className='form-control' id='assignmentStartDate' type='date'
                                        onChange={handleChangedateChange}
                                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                        min={new Date().toISOString().split('T')[0]}
                                        ></input>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="assignmentEndDate" className="form-label">End Date</label>
                                    <input className='form-control' id='assignmentEndDate' type='date' ref={inputRef}
                                        onChange={newscheduledform.handleChange}
                                        onKeyDown={(e) => e.preventDefault()} // Prevent manual typing
                                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                                        min={startdate}
                                        ></input>
                                </div>
                            </div>
                            <div><p className='text-center mb-0 mt-2 pb-0 '  style={{ fontSize: "13px" }}>Assign Resources</p>
                                </div>
                            <Dropdown.Divider  /> 
                             
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput6" className="form-label">Team Member</label>
                                    <Select
                                        placeholder="Select"
                                        isClearable
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isMulti
                                        onChange={v => {
                                           resourceDropdownOnChange(v,"TM");
                                             newscheduledform.setFieldValue('teamMemberId', v);
                                        }}
                                        defaultValue={newscheduledform.values.teamMemberId}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput7" className="form-label">Team lead</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isClearable
                                        onChange={v => {
                                            newscheduledform.setFieldValue('teamLeadId',v?.value)
                                            resourceDropdownOnChange(v,"TL");
                                         }}
                                        defaultValue={newscheduledform.values.teamLeadId}
                                    />
                                    {/* {teamleader && <span className="error">Team Member Is More Than 2 Member teamleader Must Required</span>} */}
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput8" className="form-label">Reviewer 1</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isClearable
                                        onChange={v => {
                                            resourceDropdownOnChange(v,"RL1");
                                            newscheduledform.setFieldValue('reviewer1Id', v?.value)
                                         }}
                                        defaultValue={newscheduledform.values.reviewer1Id}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="">
                                    <label htmlFor="exampleInput9" className="form-label">Reviewer 2</label>
                                    <Select
                                        placeholder="Select"
                                        className='reduceFonts'
                                        options={filterResourceDisplayArray()}
                                        isClearable
                                        onChange={v => {
                                            resourceDropdownOnChange(v,"RL2");
                                            newscheduledform.setFieldValue('reviewer2Id', v?.value)
                                         }}
                                        defaultValue={newscheduledform.values.reviewer2Id}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setnewscheduledShow();
                            newscheduledform.resetForm();
                            setReviewerList1("");
                            setReviewerList2("");
                            setTeammemberList("");
                            setTeamleaderList("");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                             {loading ? <Spinner animation="border"  variant="light" className='spinner-border-sm' /> : "Save"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Schedulednewassignment