import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'antd';
import { logout } from '../../../../../../redux/action';
import '../../../../../OngoingAssignment/assignmentstyle.css';
import CommonTable from '../../../../../../commonComponent/table/commonTable';
import * as Constants from "../../../../../../Component/common/Global/constants";
import { randomStringGenerator } from '../../../../../../Component/common/Global/commonFunctions';
import CommonLoader from '../../../../../../commonComponent/loader/commonLoader';
import AssignmentListDetails from '../../../../../../Component/common/assignmentListDetails/assignmentListDetails';

const Sampling = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const assignment = location?.state?.assignmentListDetails;
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const [bookStockTypeSampling, setBookStockTypeSampling] = useState([]);
    const [bookStockTypeSamplingLoading, setBookStockTypeSamplingLoading] = useState(false);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        getCompletedSamplings();
    }, [])

    // Function to fetch completed sampling data
    const getCompletedSamplings = (page) => {
        setBookStockTypeSamplingLoading(true);
        fetch(Constants.API_URL.getCompletedSamplings, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
                page: page ? page : 1,
                limit: 15
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setBookStockTypeSampling(data?.data);
                isComponentDidMountFlag(true);
                setBookStockTypeSamplingLoading(false);
                setTotal(data?.data?.length)
            }else if(data?.errorStatus === Constants.status.codeNotAccess){
                setBookStockTypeSamplingLoading(false);
                navigate(`/Client/completedassignment/${assignmentId}/sampling/${randomStringGenerator(30)}`);
            }else{
                setBookStockTypeSamplingLoading(false);
                setBookStockTypeSampling([]);
                isComponentDidMountFlag(true);
            }
        });
    }

    // Handles the pagination change event for completed samplings
    const handlePaginationChange = (currentPage, pageSize) => {
        getCompletedSamplings(currentPage);
    };

    // Array of column objects for the bookStockTypeSampling table
    const bookStockTypeSamplingColumn = [
        {
            dataIndex: "index",
            title: "No",
            width: '5%'
        },
        {
            dataIndex: "storetype",
            title: "Store Type",
        },
        {
            dataIndex: "stocktype",
            title: "Stock Type",
        },
        {
            dataIndex: "sampleRule",
            title: "Sample Rule",
        }
    ]

    return (
        <>
            {assignment && 
                <AssignmentListDetails
                    assignment={assignment}
                />
            }
            <Row style={{marginTop: 10}}>
                <Col span={24}>
                    {componentDidMountFlag &&
                        <CommonTable
                            columns={bookStockTypeSamplingColumn}
                            dataSource={bookStockTypeSampling.map((item, index) => {
                                const sampleRule = JSON.parse(item?.sampleRule)
                                return {
                                    ...item,
                                    key: index,
                                    index: index + 1,
                                    storetype: item.storeTypeName,
                                    stocktype: item.stockTypeName,
                                    sampleRule: sampleRule?.samplingArray?.map((item, id) => {
                                        return (<div className='col-md-12'>
                                            <div className=''>
                                                <span>{item?.criteria}</span>&nbsp;<span>{item?.actualvalue}</span>&nbsp;<span>{item?.operation}by</span>&nbsp;
                                                <span>{item?.sortingValue}</span>&nbsp;
                                                <span>{item?.isBetween ? "between" : null}</span>&nbsp;
                                                <span>{item?.min}</span>&nbsp;
                                                <span>{item?.isBetween ? "to" : null}</span>&nbsp;
                                                <span>{item?.max}</span>
                                            </div>
                                        </div>)
                                    })
                                };
                            })}
                            pagination={{
                                total: total,
                                pageSize: Constants.pageSize,
                                onChange: handlePaginationChange,
                            }}
                        />
                    }
                    {bookStockTypeSamplingLoading && 
                        <CommonLoader loading={bookStockTypeSamplingLoading} />
                    }
                </Col>
            </Row>
        </>
    );
}

export default Sampling;