import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import '../OngoingAssignment/assignmentstyle.css'
import {
    APIRequest, 
    SAMPLING,
    BOOK_STOCK_SAMPLING_REMAINS_STORE_STOCK_LIST,
    BOOK_STORE_STOCK_TYPE_SAMPLING_NUMBER_OF_ITEM, 
    BOOK_STOCK_TYPE_SAMPLING, 
    RESET_BOOK_STOCK_SAMPLING
} from '../../api';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';
import ReusableModal from '../Modal/reusablemodel';
import * as Constants from "../../Component/common/Global/constants";
import { isEmptyVariable, randomStringGenerator } from '../../Component/common/Global/commonFunctions';
import { Col, Divider, Row } from 'antd';
import CommonSelect from '../../commonComponent/selectDropdown/commonSelect';
import CommonButton from '../../commonComponent/button/commonButton';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import CommonTable from '../../commonComponent/table/commonTable';
import AssignmentListDetails from '../../Component/common/assignmentListDetails/assignmentListDetails';

const Sampling = ({assignmentId, assignment}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const formatter = new Intl.NumberFormat('en-IN');
    const [samplingNumberOfItem, setSamplingNumberOfItem] = useState([]);
    const [bookStockTypeSampling, setBookStockTypeSampling] = useState([]);
    const [bookStockSamplingRemainsStoreStockList, SetBookStockSamplingRemainsStoreStockList] = useState([]);
    const [sampleRules, setSampleRules] = useState([]);
    const [stockName, setStockName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [storeId, setStoreId] = useState([]);
    const [stockId, setStockId] = useState([]);
    const store = storeId?.map(item => { return item.value });
    const [sampleRulesDetailsShow, setSampleRulesDetailsShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sampleRuleId, setSampleRuleId] = useState();
    const [deleteSampleRuleConfrimDialog, setDeleteSampleRuleConfrimDialog] = useState(false);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [isExcludeInNextSampling, setIsExcludeInNextSampling] = useState(false);
    const [isExcludeZeroQuantity, setIsExcludeZeroQuantity] = useState(false);
    const [errors, setErrors] = useState({});
    const [tempAddRule, setTempAddRule] = useState([]);
    const [samplingRule, setSamplingRule] = useState([]);
    const [deleteSampleRuleLoading, setDeleteSampleRuleLoading] = useState(false);
    
    useEffect(() => {
        if (storeId?.length == 0) {
            setSamplingNumberOfItem([]);
        } else {
            getMinMax();
        }
    }, [storeId, stockId]);

    // Delete a sampling rule by its index in the 'samplingRule' array
    const deleteRule = (index) => {
        setSamplingRule(samplingRule?.filter((element, id) => id !== index));
    }
    
    // Filter out undefined elements from the samplingRule array
    var samplingAddedRules = samplingRule?.filter(function (element) {
        return element !== undefined;
    });
    // Filter out empty rule elements from the filtered array
    samplingAddedRules.filter(function (element) {
        return element?.actualvalue !== "" || element?.sortingValue !== "";
    });

    //Creates a new sample
    const handleCreateSample = () => {
        setLoading(true);
        samplingAddedRules.map(obj => delete obj.key)
        let finalValue = {
            storeType: storeId.length != 0 && storeId?.map(item => { return item.value }),
            stockType: stockId.length != 0 ? stockId?.map(item => { return item.value }) : [1],
            assignmentId: assignmentId,
            isExcludeInNextSampling: isExcludeInNextSampling,
            isExcludeZeroQuentity: isExcludeZeroQuantity,
            samplingArray: samplingAddedRules
        }
        let isError = false;
        let errorMessageTemp = {};
        // Check if the storeId field is empty
        if (storeId.length === 0) {
            isError = true;
            errorMessageTemp['store'] = "Store is required";
        }
        if (stockId.length === 0) {
            isError = true;
            errorMessageTemp['stock'] = "Stock is required";
        }
        // Check if any of the rule fields have a truthy actualvalue property
        if(samplingAddedRules.length == 0){
            isError = true;
            errorMessageTemp['rule'] = "Rule is required";
        }
        // If there's an error, show it and stop the process
        if (isError) {
            setLoading(false);
            setErrors(errorMessageTemp);
            setTimeout(() => {
                setErrors("");
            },Constants.timeOut.errorMsgTimeout);
        }
        // Otherwise, create a final value with all the required data
        else {
            new APIRequest.Builder()
                .post()
                .setReqId(SAMPLING)
                .reqURL("bookstock/bookStockSampling")
                .jsonParams(finalValue)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        }
    }

    //Retrieves the min and max no. of items for each store and stock type combination for sampling.
    const getMinMax = () => {
        const _step1Value = {
            assignmentId: assignmentId,
            createdBy: user.userId,
            storeType: storeId?.map(item => { return item.value }),
            stockType: stockId?.map(item => { return item.value }) ?? []
        }
        new APIRequest.Builder()
            .post()
            .setReqId(BOOK_STORE_STOCK_TYPE_SAMPLING_NUMBER_OF_ITEM)
            .jsonParams(_step1Value)
            .reqURL(`bookstock/getBookStoreStockTypeSamplingNumberOfItem`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    // This function clear newly created samples rules
    const clearSampleRules = () => {
        setSamplingRule([]);
        setTempAddRule([]);
        setIsExcludeInNextSampling(false);
        setIsExcludeZeroQuantity(false);
    }

    // Function to fetch book stock type sampling data based on the status
    const getBookStockTypeSamplingData = () => {
        new APIRequest.Builder()
            .post()
            .setReqId(BOOK_STOCK_TYPE_SAMPLING)
            .jsonParams({ assignmentId: assignmentId, userId: user.userId, status: "Started" })
            .reqURL("bookstock/getBookStockTypeSampling")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    // Handle the deletion of a sample rule and displaying the confirmation dialog
    const handleDeleteSampleRule = (sampleRuleId) => {
        setSampleRuleId(sampleRuleId);
        setDeleteSampleRuleConfrimDialog(true);
    }

    // Resets the book stock sampling for the selected sample rule.
    const resetBookStockSampling = () => {
        setDeleteSampleRuleLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(RESET_BOOK_STOCK_SAMPLING)
            .jsonParams({
                "sampleRuleId": sampleRuleId
            })
            .reqURL(`bookstock/resetBookStockSampling`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    useEffect(() => {
        getRemainsStoreStockList();
        getBookStockTypeSamplingData();
    }, [])

    /* Retrieves the list of stores and their corresponding stock 
    items that remain unsampled for a specific assignment. */
    const getRemainsStoreStockList = () => {
        new APIRequest.Builder()
            .post()
            .setReqId(BOOK_STOCK_SAMPLING_REMAINS_STORE_STOCK_LIST)
            .jsonParams({
                "assignmentId": assignmentId,
            })
            .reqURL(`bookstock/getBookStockSamplingRemainsStoreStockList`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case SAMPLING:
                setLoading(false);
                getBookStockTypeSamplingData();
                toast.success(response?.data?.message);
                getRemainsStoreStockList();
                clearSampleRules();
                setStockId([]);
                setStoreId([]);
                break;
            case BOOK_STOCK_SAMPLING_REMAINS_STORE_STOCK_LIST:
                SetBookStockSamplingRemainsStoreStockList(response.data?.data)
                break;

            case BOOK_STOCK_TYPE_SAMPLING:
                setBookStockTypeSampling(response.data?.data);
                isComponentDidMountFlag(true);
                break;
            case BOOK_STORE_STOCK_TYPE_SAMPLING_NUMBER_OF_ITEM:
                setSamplingNumberOfItem(response?.data?.data[0]);
                break;
            case RESET_BOOK_STOCK_SAMPLING:
                setDeleteSampleRuleConfrimDialog(false);
                getBookStockTypeSamplingData();
                getRemainsStoreStockList();
                toast.success(response.data.message);
                setDeleteSampleRuleLoading(false);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case SAMPLING:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`)
                }
                break;
            case BOOK_STOCK_TYPE_SAMPLING:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
                    navigate(`/Client/ongoingassignment/${assignmentId}/sampling/${randomStringGenerator(30)}`);
                } else {
                    setBookStockTypeSampling([]);
                    isComponentDidMountFlag(true);
                }
                break;
            case BOOK_STORE_STOCK_TYPE_SAMPLING_NUMBER_OF_ITEM:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                }
                break;
            case RESET_BOOK_STOCK_SAMPLING:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`);
                    setDeleteSampleRuleConfrimDialog(false);
                    setDeleteSampleRuleLoading(false);
                }
                break;
            case BOOK_STOCK_SAMPLING_REMAINS_STORE_STOCK_LIST:
                SetBookStockSamplingRemainsStoreStockList([]);
                break;
            default:
                break;
        }
    }

    const stockOptions = [];
    const storeOptions = bookStockSamplingRemainsStoreStockList?.map((item, i) => { return { "value": item?.storeId, "label": item?.storeTypeName } }) ?? [];
    const listItems = bookStockSamplingRemainsStoreStockList?.filter((e) => { return store?.includes(e?.storeId) }).map(item => {
        return item?.stockType.forEach((item) => {
            stockOptions.push({ "value": item?.stockId, "label": item?.stockTypeName });
            return stockOptions;
        })
    })

    // Handles store change event
    const handleStoreChange = (e) => {
        setStoreId(e);
        setStockId([]);
        clearSampleRules();
    };

    // Function to handle changes in input fields for the rule object
    const handleInputChange = (event, ruleObj) => {
        const { name, value } = event.target;
        const { key } = ruleObj;
    
        setTempAddRule(prevState => {
            let updatedState = [...prevState];
    
            // Find the index of the object with the same key
            const index = updatedState.findIndex(item => item.key === key);
    
            // If an object with the same key exists, update its values
            if (index !== -1) {
                const updatedItem = { ...updatedState[index], [name]: value };
                updatedState[index] = updatedItem;
            } else {
                // If no object with the same key exists, create a new object
                updatedState.push({ ...ruleObj, [name]: value });
            }

            // After updating the state, check if any input field value is empty for the key
            if (value === "") {
                const allInputsEmpty = updatedState
                .filter(item => item.key === key)
                .every(item => item.actualvalue === "" && item.min === "" && item.max === "");

                // Check if sortingValue is available for the key
                const hasSortingValue = updatedState.some(item => item.key === key && item.sortingValue !== "");

                if (allInputsEmpty && !hasSortingValue) {
                    updatedState = updatedState.filter(item => item.key !== key);
                }
            }
            return updatedState;
        });
    };

    // Function to handle changes in the selected option for a rule object
    const handleSelectChange = (selected, ruleObj) => {
        setTempAddRule(prevState => {
            const index = prevState.findIndex(item => item.key === ruleObj.key);
            if (index !== -1) {
                return prevState.map(item => item.key === ruleObj.key ? { ...item, sortingValue: selected.value.toLowerCase() } : item);
            } else {
                return [...prevState, { ...ruleObj, sortingValue: selected.value.toLowerCase() }];
            }
        });
    };

    // Function to create a dropdown array based on the provided samplingNumberOfItem object
    const handleDropdownArr = () => {
        const { MinRate, MinValue, MinQuantity } = samplingNumberOfItem || {};
        let samplingArray = [];
        
        if(!isEmptyVariable(MinQuantity)){
            samplingArray.push({ value: 'Quantity', label: 'Quantity' });
        }
        if(!isEmptyVariable(MinValue)){
            samplingArray.push({ value: 'Value', label: 'Value' });
        }
        if(!isEmptyVariable(MinRate)){
            samplingArray.push({ value: 'Rate', label: 'Rate' });
        }
        if(!isEmptyVariable(MinRate) && !isEmptyVariable(MinValue) && !isEmptyVariable(MinQuantity)){
            return Constants.samplingOption;
        }
        return samplingArray;
    }

    // Function to check if a field should be disabled or not based on the provided key
    const handleDisableField = (key) => {
        const rules = tempAddRule.find(item => item.key !== key);
        if(samplingAddedRules.length == 2){
            return true;
        }else if(tempAddRule.length > 0 && rules){
            return true;
        }else{
            return false;
        }
    }

    // Function to create a dropdown array based on the provided samplingNumberOfItem object
    const handleSamplingRandomPercentageOption = () => {
        const { MinValue, MinQuantity } = samplingNumberOfItem || {};
        let samplingArray = [];
        
        if(!isEmptyVariable(MinValue)){
            samplingArray.push({ value: 'Value', label: 'Value' });
        }
        if(!isEmptyVariable(MinQuantity)){
            samplingArray.push({ value: 'Quantity', label: 'Quantity' });
        }
        if(!isEmptyVariable(MinValue) && !isEmptyVariable(MinQuantity)){
            return Constants.samplingRandomPercentageOption;
        }
        return samplingArray;
    }

    // Function to handle changes to the 'Exclude from next sampling' checkbox value
    const handleIsExcludeInNextSamplingChange = (event) => {
        setIsExcludeInNextSampling(event.target.checked);
    }
    
    // Function to handle changes to the 'Exclude zero quantity' checkbox value
    const handleIsExcludeZeroQuantityChange = (event) => {
        setIsExcludeZeroQuantity(event.target.checked);
    }

    // Function to handle the display of sample rules details
    const handleViewSampleRulesDetails = (record) => {
        const sampleRules = JSON.parse(record.sampleRule);
        setSampleRules(sampleRules?.samplingArray);
        setStockName(record.stockName);
        setStoreName(record.storeName);
        setSampleRulesDetailsShow(true);
    }

    // Function to add rules to the sampling rule array based on the provided tempAddRule array
    const handleAddRules = () => {
        if(tempAddRule.length == 0 || tempAddRule.filter(item => item.sortingValue === '' || item.actualvalue === '').length > 0){
            toast.warning("Enter a number or percentage and add the rule");
        }else{
            setSamplingRule([...samplingRule, ...tempAddRule]);
            setTempAddRule([]);
        }
    }

    // Array of column objects for the samlingAddedRules table
    const samlingAddedRulesColumn = [
        {
            dataIndex: 'criteria',
            title: "",
            key: 'criteria'
        },
        {
            title: "",
            key: 'delete',
            width: '4%',
            render: (record) => {
                return (
                    <CommonButton
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                        }}
                        onClick={() => deleteRule(record.index)}
                        icon={<DeleteOutlined
                            style={{
                                verticalAlign: 'middle',
                                color: 'rgba(0, 0, 0, 0.85)',
                                paddingLeft: 10,
                                paddingRight: 10,
                            }}
                        />}
                    />
                );
            }
        },
    ]

    // Array of column objects for the sampleRules table
    const sampleRulesColumn = [
        {
            dataIndex: 'store',
            title: "Store",
            key: 'store',
            width: '25%',
            onCell: (_, index) => ({
                rowSpan: index === 0 ? sampleRules.length : 0,
            }),
        },
        {
            dataIndex: 'stock',
            title: "Stock",
            key: 'stock',
            width: '25%',
            onCell: (_, index) => ({
                rowSpan: index === 0 ? sampleRules.length : 0,
            })
        },
        {
            dataIndex: 'criteria',
            title: "Criteria",
            key: 'criteria'
        }
    ]

    const components = {
        header: {
          row: () => null,
        },
    };

    // Array of column objects for the samplesCreatedData table
    const samplesCreatedDataColumn = [
        {
            dataIndex: 'storeName',
            title: 'Store Type',
            key: 'storeName'
        },
        {
            dataIndex: 'stockName',
            title: 'Stock Type',
            key: 'storeName'
        },
        {
            title: 'View',
            key: 'view',
            width: '2%',
            render: (record) => {
                return (
                    <CommonButton
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                        }}
                        onClick={() => handleViewSampleRulesDetails(record)}
                        icon={<EyeOutlined
                            style={{
                                verticalAlign: 'middle',
                                color: 'rgba(0, 0, 0, 0.85)',
                                paddingLeft: 10,
                                paddingRight: 10
                            }}
                        />}
                    />
                );
            }
        },
        {
            title: 'Action',
            key: 'action',
            width: '2%',
            render: (record) => {
                return (
                    <CommonButton 
                        style={{
                            backgroundColor: 'transparent',
                            boxShadow: '0 0 0 rgba(0, 0, 0, 0.045)'
                        }}
                        onClick={() => handleDeleteSampleRule(record?.sampleRuleId)}
                        icon={<DeleteOutlined
                            style={{
                                verticalAlign: 'middle',
                                color: 'rgba(0, 0, 0, 0.85)',
                                paddingLeft: 10,
                                paddingRight: 10
                            }}
                        />}
                    />
                );
            }
        },
    ]

    // Array of sampleMinMaxData objects that displays minimum and maximum values
    const sampleMinMaxData = [
        {
            title: 'Store Type',
            min: storeId?.map((item, index) => { return (index ? ", " : "") + item.label }),
            max: "",
        },
        {
            title: 'Stock Type',
            min: stockId?.map((item, index) => { return (index ? ", " : "") + item.label }),
            max: "",
        },
        {
            title: 'Number Of Items',
            min: isEmptyVariable(samplingNumberOfItem?.numberOfItem) ? '-' : formatter.format(samplingNumberOfItem?.numberOfItem),
            max: "",
        },
        {
            title: 'Qty',
            min: isEmptyVariable(samplingNumberOfItem?.MinQuantity) ? '-' : formatter.format(samplingNumberOfItem.MinQuantity),
            max: isEmptyVariable(samplingNumberOfItem?.MaxQuantity) ? '-' : formatter.format(samplingNumberOfItem.MaxQuantity),
        },
        {
            title: 'Rate',
            min: isEmptyVariable(samplingNumberOfItem?.MinRate) ? '-' : formatter.format(samplingNumberOfItem.MinRate),
            max: isEmptyVariable(samplingNumberOfItem?.MaxRate) ? '-' : formatter.format(samplingNumberOfItem.MaxRate),
        },
        {
            title: 'Value',
            min: isEmptyVariable(samplingNumberOfItem?.MinValue) ? '-' : formatter.format(samplingNumberOfItem.MinValue),
            max: isEmptyVariable(samplingNumberOfItem?.MaxValue) ? '-' : formatter.format(samplingNumberOfItem.MaxValue),
        }
    ]

    // Array of column objects for the sampleDataMinMax table
    const sampleDataMinMaxColumn = [
        {
            dataIndex: 'title',
            title: "",
            key: 'title',
            width: '8%'
        },
        {
            dataIndex: 'min',
            title: 'Min',
            key: 'Min',
            width: '8%'
        },
        {
            dataIndex: 'max',
            title: 'Max',
            key: 'title',
            width: '8%'
        },
    ]

    // Array of column objects for the bookStockTypeSampling table
    const bookStockTypeSamplingColumn = [
        {
            dataIndex: "index",
            title: "No",
            width: '5%'
        },
        {
            dataIndex: "storetype",
            title: "Store Type",
        },
        {
            dataIndex: "stocktype",
            title: "Stock Type",
        },
        {
            dataIndex: "samplingarray",
            title: "Sample Rule",
        }
    ]

    /* Function Returns a new array with the item that has the same sortingValue
    as the item in the tempAddRule array with the provided key */
    const selectedValue = (key, dropdownArr) => {
        return dropdownArr.find(item => item.value.toLowerCase() == tempAddRule.find(item => item.key === key)?.sortingValue)
    }

    return (
        <>
            {assignment && 
                <AssignmentListDetails
                    assignment={assignment}
                />
            }
            <div className='sampling_box'>
                <Row>
                    <Col span={15}>
                        <Row style={{marginTop: 8, alignItems: 'center'}}>
                            <Col span={1}>
                                <label className='form-label dropdown-lable'>Store</label>
                            </Col>
                            <Col span={8} className='select_box' style={{marginLeft:10}}>
                                <CommonSelect
                                    placeholder="Select"
                                    isMulti={true}
                                    name='storeId'
                                    className='reduceFonts'
                                    options={storeOptions}
                                    onChange={handleStoreChange}
                                    value={storeId}
                                />
                                {!isEmptyVariable(errors['store']) &&
                                <p className="error">{errors['store']}</p>}
                            </Col>
                            <Col span={4} style={{textAlign: 'end'}}>
                                <label className='form-label dropdown-lable'>Stock Type</label>
                            </Col>
                            <Col span={8} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    isMulti={true}
                                    isClearable={true}
                                    className='reduceFonts'
                                    name='stockId'
                                    options={stockOptions}
                                    onChange={e => {
                                        setStockId(e)                 
                                        clearSampleRules();
                                    }}
                                    value={stockId}
                                />
                                {!isEmptyVariable(errors['stock']) &&
                                    <p className="error">{errors['stock']}</p>}
                            </Col>
                        </Row>
                        <Row style={{alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Top</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="topnumber"
                                    style={{cursor: handleDisableField('top1') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('top1')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder='Enter No.'
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "top",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: false,
                                        sortingValue: "",
                                        key:'top1'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'top1')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={8}>
                                <label className='form-label dropdown-lable'>stock(s) by</label>
                            </Col>
                            <Col span={5} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    className='reduceFonts select_border'
                                    name='selectedTopStocksByOption'
                                    isDisabled={handleDisableField('top1')}
                                    options={handleDropdownArr()}
                                    onChange={(selected) => {
                                        handleSelectChange(selected, { 
                                            criteria: "top",
                                            actualvalue: "",
                                            min: "",
                                            max: "",
                                            operation: "stock(s)",
                                            isBetween: false,
                                            sortingValue: "",
                                            key:'top1'
                                        });
                                    }}
                                    value={selectedValue('top1', handleDropdownArr())}
                                />
                            </Col>
                        </Row>
                        <Row style={{alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Top</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="topnumber2"
                                    style={{cursor: handleDisableField('top2') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('top2')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "top",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "percentage of stock(s)",
                                        isBetween: false,
                                        sortingValue: "",
                                        key:'top2'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'top2')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={8}>
                                <label className='form-label dropdown-lable'>percentage of stock(s) by</label>
                            </Col>
                            <Col span={5} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    className='reduceFonts select_border'
                                    name='selectedTopPercentageByOption'
                                    isDisabled={handleDisableField('top2')}
                                    options={handleSamplingRandomPercentageOption()}
                                    onChange={(selected) => {
                                        handleSelectChange(selected, {
                                            criteria: "top",
                                            actualvalue: "",
                                            min: "",
                                            max: "",
                                            operation: "percentage of stock(s)",
                                            isBetween: false,
                                            sortingValue: "",
                                            key:'top2'
                                        })
                                    }}
                                    value={selectedValue('top2', handleSamplingRandomPercentageOption())}
                                />
                            </Col>
                        </Row>
                        <Row style={{alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Random</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="randomnumber2"
                                    style={{cursor: handleDisableField('random1') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('random1')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "random",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "percentage of stock(s)",
                                        isBetween: false,
                                        sortingValue: "",
                                        key:'random1'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'random1')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={8}>
                                <label className='form-label dropdown-lable'>percentage of stock(s) by</label>
                            </Col>
                            <Col span={5} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    className='reduceFonts select_border'
                                    name='selectedRandomPercentageByOption'
                                    isDisabled={handleDisableField('random1')}
                                    options={handleSamplingRandomPercentageOption()}
                                    onChange={(selected) => {
                                        handleSelectChange(selected, {
                                            criteria: "random",
                                            actualvalue: "",
                                            min: "",
                                            max: "",
                                            operation: "percentage of stock(s)",
                                            isBetween: false,
                                            sortingValue: "",
                                            key:'random1'
                                        })
                                    }}
                                    value={selectedValue('random1', handleSamplingRandomPercentageOption())}
                                />
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Random</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="randomnumber"
                                    style={{cursor: handleDisableField('random2') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('random2')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "random",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: false,
                                        sortingValue: "bookStockId",
                                        key:'random2'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'random2')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={8}>
                                <label className='form-label dropdown-lable'>stock(s)</label>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Top</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="topnumberbetween"
                                    style={{cursor: handleDisableField('top3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('top3')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "top",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'top3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'top3')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={3.5}>
                                <label className='form-label dropdown-lable'>stock(s) by</label>
                            </Col>
                            <Col span={5} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    className='reduceFonts select_border'
                                    name='selectedTopStocksByNumberOption'
                                    isDisabled={handleDisableField('top3')}
                                    options={handleDropdownArr()}
                                    onChange={(selected) => {
                                        handleSelectChange(selected, {
                                            criteria: "top",
                                            actualvalue: "",
                                            min: "",
                                            max: "",
                                            operation: "stock(s)",
                                            isBetween: true,
                                            sortingValue: "",
                                            key:'top3'
                                        })
                                    }}
                                    value={selectedValue('top3', handleDropdownArr())}
                                />
                            </Col>
                            <Col span={2.5}>
                                <label className='form-label dropdown-lable ms-2'>between</label>
                            </Col>
                            <Col span={2.5}>
                                <input
                                    className="form-control"
                                    id="topnumberbetweenfrom"
                                    style={{cursor: handleDisableField('top3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('top3')}
                                    name="min"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "top",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'top3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'top3')?.min || ''}
                                />
                            </Col>
                            <Col span={1.5}>
                                <label className='form-label dropdown-lable ms-2'>to</label>
                            </Col>
                            <Col span={2}>
                                <input
                                    className="form-control"
                                    id="topnumberbetweento"
                                    style={{cursor: handleDisableField('top3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('top3')}
                                    name="max"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "top",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'top3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'top3')?.max || ''}
                                />
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center'}} className='dropdown_box'>
                            <Col span={2}>
                                <label className='form-label dropdown-lable'>Random</label>
                            </Col>
                            <Col span={3}>
                                <input
                                    className="form-control"
                                    id="randomnumberbetween"
                                    style={{cursor: handleDisableField('random3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('random3')}
                                    name="actualvalue"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "random",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'random3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'random3')?.actualvalue || ''}
                                />
                            </Col>
                            <Col span={3.5}>
                                <label className='form-label dropdown-lable'>stock(s) by</label>
                            </Col>
                            <Col span={5} className='select_box'>
                                <CommonSelect
                                    placeholder="Select"
                                    className='reduceFonts select_border'
                                    name='selectedRandomStocksByNumberOption'
                                    isDisabled={handleDisableField('random3')}
                                    options={handleDropdownArr()}
                                    onChange={(selected) => {
                                        handleSelectChange(selected, {
                                            criteria: "random",
                                            actualvalue: "",
                                            min: "",
                                            max: "",
                                            operation: "stock(s)",
                                            isBetween: true,
                                            sortingValue: "",
                                            key:'random3'
                                        })
                                    }}
                                    value={selectedValue('random3', handleDropdownArr())}
                                />
                            </Col>
                            <Col span={2.5}>
                                <label className='form-label dropdown-lable ms-2'>between</label>
                            </Col>
                            <Col span={2.5}>
                                <input
                                    className="form-control"
                                    id="randomnumberbetweenfrom"
                                    style={{cursor: handleDisableField('random3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('random3')}
                                    name="min"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "random",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'random3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'random3')?.min || ''}
                                />
                            </Col>
                            <Col span={1.5}>
                                <label className='form-label dropdown-lable ms-2'>to</label>
                            </Col>
                            <Col span={2}>
                                <input
                                    className="form-control"
                                    id="randomnumberbetweento"
                                    style={{cursor: handleDisableField('random3') ? 'not-allowed' : 'text'}}
                                    readOnly={handleDisableField('random3')}
                                    name="max"
                                    type="number"
                                    placeholder="Enter No."
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={e => handleInputChange(e, {
                                        criteria: "random",
                                        actualvalue: "",
                                        min: "",
                                        max: "",
                                        operation: "stock(s)",
                                        isBetween: true,
                                        sortingValue: "",
                                        key:'random3'
                                    })}
                                    value={tempAddRule.find(item => item.key === 'random3')?.max || ''}
                                />
                            </Col>
                        </Row>
                        <Row style={{marginTop: 5}}>
                            <Col>
                                <input 
                                    className="form-check-input" 
                                    type="checkbox"
                                    id="isExcludeInNextSampling"
                                    name='isExcludeInNextSampling'
                                    checked={isExcludeInNextSampling}
                                    onChange={handleIsExcludeInNextSamplingChange} 
                                />
                                <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                    Exclude already selected item in next sampling criteria.
                                </label>
                            </Col>
                            <Col>
                                <input
                                    className="form-check-input ms-2"
                                    type="checkbox"
                                    id="isExcludeZeroQuantity"
                                    name="isExcludeZeroQuantity"
                                    checked={isExcludeZeroQuantity}
                                    onChange={handleIsExcludeZeroQuantityChange}
                                />
                                <label className="form-check-label ms-2" htmlFor="flexCheckChecked">
                                    Exclude 0 quantities
                                </label>
                            </Col>
                        </Row>
                        <Row>
                            {!isEmptyVariable(errors['rule']) &&
                            <p className="error">{errors['rule']}</p>}
                        </Row>
                        {samplingAddedRules.length > 0 &&
                            <Divider type="horizontal" />
                        }
                        <Row style={{justifyContent:'center'}}>
                            <Col>
                                {samplingAddedRules.length > 0 &&
                                    <div className='table-top-border'>
                                        <p style={{fontSize: 13, marginBottom: 0, textAlign: 'center'}}><b>Added Rules</b></p>
                                        <CommonTable
                                            columns={samlingAddedRulesColumn}
                                            dataSource={samplingAddedRules?.map((item, index) => {
                                                return {
                                                    ...item,
                                                    key: (index + 1),
                                                    index: index,
                                                    criteria: item.criteria.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + item.actualvalue + ' ' + item.operation 
                                                    + (item.sortingValue == 'bookStockId' ? "" : (' by ' + item.sortingValue)) + ' ' + (item.isBetween ? 'between ' : '') 
                                                    + item.min + ' ' + (item.isBetween ? 'to ' : '') + item.max,
                                                };
                                            })}
                                            pagination={false}
                                            components={components}
                                        />
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row style={{justifyContent:'center', marginTop: 10}}>
                            <CommonButton
                                label='Clear Rules'
                                onClick={() => {
                                    clearSampleRules();
                                    setStoreId([]);
                                    setStockId([]);
                                }}
                            />
                            <CommonButton
                                style={{marginLeft: 10}}
                                label='Create Sample'
                                loading={loading}
                                onClick={() => {
                                    handleCreateSample()
                                }}
                            />
                            <CommonButton
                                style={{marginLeft: 10, opacity: samplingAddedRules.length == 2 ? 0.7 : 1}}
                                label='Add Rules'
                                disabled={samplingAddedRules.length == 2}
                                onClick={() => {
                                    handleAddRules()
                                }}
                            />
                        </Row>
                    </Col>
                    <Divider type="vertical" orientation='center'/>
                    <Col span={8}>
                        <p style={{fontSize: 13, marginBottom: 0, marginTop: 10, textAlign: 'center'}}><b>Samples created</b></p>
                        <Divider type="horizontal" className='sample-created-divider'/>
                        <Col style={{marginTop: 10}}>
                            {componentDidMountFlag &&
                                <CommonTable
                                    columns={samplesCreatedDataColumn}
                                    dataSource={bookStockTypeSampling?.map((item, index) => {
                                        return {
                                            ...item,
                                            key: (index + 1),
                                            storeName: item.storeName,
                                            stockName: item.stockName,
                                            sampleRule: item.sampleRule,
                                            sampleRuleId: item.sampleRuleId
                                        };
                                    })}
                                    pagination={false}
                                />
                            }
                        </Col>
                        <Divider type="horizontal"/>
                        <Col>
                            <CommonTable
                                columns={sampleDataMinMaxColumn}
                                dataSource={sampleMinMaxData.map((item, index) => {
                                    return {
                                        ...item,
                                        key: (index + 1),
                                        title: item.title,
                                        min: item.min,
                                        max: item.max,
                                    };
                                })}
                                pagination={false}
                            />
                        </Col>
                    </Col>
                </Row>
            </div>
            {sampleRulesDetailsShow &&
                <Modal show={sampleRulesDetailsShow}>
                    <Modal.Header className='bookstockheader'>
                        <Modal.Title>Sample Rules Details</Modal.Title>
                        <div
                            onClick={() => {
                                setSampleRulesDetailsShow(false);
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{marginTop: 10}}>
                            <Col span={24}>
                            <CommonTable
                                columns={sampleRulesColumn}
                                dataSource={sampleRules?.map((item, index) => {
                                    return {
                                        ...item,
                                        key: (index + 1),
                                        index: index,
                                        store: storeName,
                                        stock: stockName,
                                        criteria: item.criteria.replace(/^\w/, (c) => c.toUpperCase()) + ' ' + item.actualvalue + ' ' + item.operation 
                                        + (item.sortingValue == 'bookStockId' ? "" : (' by ' + item.sortingValue)) + ' ' + (item.isBetween ? 'between ' : '') 
                                        + item.min + ' ' + (item.isBetween ? 'to ' : '') + item.max,
                                    };
                                })}
                                pagination={false}
                                bordered
                                // className="custom-table"
                                // components={components}
                            />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
            {deleteSampleRuleConfrimDialog &&
                <ReusableModal
                    show={deleteSampleRuleConfrimDialog}
                    onClose={() => setDeleteSampleRuleConfrimDialog(false)}
                    loading={deleteSampleRuleLoading}
                    onConfirm={resetBookStockSampling}
                    message={"Are you sure, you want to delete this Rule?"}
                />
            }
        </>
    );
}

export default Sampling