import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { DownloadOutlined } from '@ant-design/icons';
import { Table } from '../../../../Table/Table';
import { logout } from '../../../../../redux/action';
import Observationdetails from '../../../../OngoingAssignment/Detailspage/observationdetails';
import * as Constants from '../../../../../Component/common/Global/constants';
import { isEmptyArray, isEmptyVariable } from '../../../../../Component/common/Global/commonFunctions';
import AssignmentListDetails from '../../../../../Component/common/assignmentListDetails/assignmentListDetails';
import CommonButton from '../../../../../commonComponent/button/commonButton';
import CommonLoader from '../../../../../commonComponent/loader/commonLoader';
import { Alert } from 'antd';
import CommonAlert from '../../../../../commonComponent/alert/commonAlert';

function CompletedAssignmentDetails() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const assignment = location?.state?.assignmentListDetails;
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const formatter = new Intl.NumberFormat('en-IN');
    const [observationsList, setObservationsList] = useState();
    const [observationsDetails, setObservationsDetails] = useState();
    const [observationsDetailsShow, setObservationsDetailsShow] = useState(false);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [wordDownloadReportsLoading, setWordDownloadReportsLoading] = useState(false);
    const [downloadReportButtonDisable, setDownloadReportButtonDisable] = useState(false);
    const [assignmentRelatedMasterCounts, setAssignmentRelatedMasterCounts] = useState({});
    const [reportData, setReportData] = useState({});
    const [alertShow, isAlertShow] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const buttonColor = {
        color: "#552B84",
        cursor: "pointer"
    }

    useEffect(() => {
        getInitData();
    }, [])

    // Fetch data from the `completedAssignmentRelatedMasterCounts` and `getObservation` APIs.
    const getInitData = () => {
        setLoading(true);
        Promise.all([
            fetch(Constants.API_URL.completedAssignmentRelatedMasterCounts, {
                method:"POST",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    assignmentId: parseInt(assignmentId),
                    userId: user.userId,
                })
            }),
            fetch(Constants.API_URL.getObservation + `/${assignmentId}?page=1&limit=15`, {
                method:"GET",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }
            }),
        ])
        .then(([assignmentRelatedMasterCountsRes,observationRes]) => {
            return Promise.all([assignmentRelatedMasterCountsRes.json(), observationRes.json()]) 
        })
        .then(([assignmentRelatedMasterCountsRes,observationRes]) => {
            if(assignmentRelatedMasterCountsRes?.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(assignmentRelatedMasterCountsRes.status === Constants.status.success){
                setAssignmentRelatedMasterCounts(assignmentRelatedMasterCountsRes.data);
                let reportData = assignmentRelatedMasterCountsRes.data.reportData[0] ?? [];
                setReportData(reportData);
                if(reportData && (reportData.status == 'inQueue' || reportData.status == 'inProcess' || reportData.status == 'error')){
                    isAlertShow(true);
                    setMessage('The report is currently being processed. Please refresh the page after some time.');
                    setDownloadReportButtonDisable(true);
                }else if(reportData && reportData.status == 'failed'){
                    isAlertShow(true);
                    setMessage('We are currently experiencing difficulties generating the assignment report. Please reach out to us at namste@takestock.in for assistance. Thank you.');
                    setDownloadReportButtonDisable(true);
                }
            }else{
                toast.error(`${assignmentRelatedMasterCountsRes?.message}`)
            }
            if(observationRes.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                window.location="/";
            }else if(observationRes.status === Constants.status.success){
                setObservationsList(observationRes.data);
            }else{
                setObservationsList([]);
            }
            isComponentDidMountFlag(true);
            setLoading(false);
        });
    }

    // Function to fetch data from the `getFinalizeReportUrl` API and initiate a word download.
    const getFinalizeReportUrl = () => {
        setWordDownloadReportsLoading(true);
        setDownloadReportButtonDisable(true);
        fetch(Constants.API_URL.getFinalizeReportUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                if(data.data != Constants.status.error.toLowerCase()){
                    var link = document.createElement('a');
                    link.href = data.data;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    setDownloadReportButtonDisable(false);
                }else{
                    isAlertShow(true);
                    setMessage('We are currently experiencing difficulties generating the assignment report. Please reach out to us at namste@takestock.in for assistance. Thank you.');
                    setDownloadReportButtonDisable(true);
                }
                setWordDownloadReportsLoading(false);
            }else{
                setWordDownloadReportsLoading(false);
                setDownloadReportButtonDisable(false);
                toast.error(`${data?.message}`);
            }
        });
    }

    // Function to fetch data from the `getObservation` API.
    const getObservation = () => {
        fetch(Constants.API_URL.getObservation + `/${assignmentId}?page=1&limit=${Constants.pageSize}`, {
            method:"GET",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setObservationsList(data.data);
            }else{
                toast.error(`${data?.message}`)
            }
        });
    }

    // Function to handle changes in the table, such as searching, sorting, or changing the page size
    const handleTableChange = (type, newState) => {
        let url = `assignments/getObservatoin/${assignmentId}?type=list&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
        if (newState.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`;
        }
        if (type === "search" && !isEmptyVariable(newState.searchText)) {
            url = url + `&search=${encodeURIComponent(newState.searchText)}`;
        }
        if (!isEmptyVariable(newState?.sortField)) {
            if (newState.sortField === "observationDetails") {
                url = url + `&sortColumn=observationDetails`
            }
            if (newState.sortOrder) {
                url = url + `&sortType=${newState.sortOrder}`
            }
        }

        fetch(url, {
            method:"GET",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setObservationsList(data.data);
            }else{
                toast.error(`${data?.message}`)
            }
        });
    };

    // Function to handle closing the edit observation modal
    const handleEditObservationClose = (reload) => {
        if(reload){
            getObservation();
        }
    }

    // Function to handle the closing of an alert
    const handleAlertClose = () => {
        isAlertShow(false);
    };

    // Function to strip HTML tags from a string
    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const observationListColumn = [
        {
            dataField: "index",
            text: "No",
            headerClasses: "sr_no",
            sort: true,
            style: { cursor: "pointer" },

            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        },
        {
            dataField: "observationDetails",
            text: "Observations Details",
            sort: true,
            style: { cursor: "pointer" },
            headerClasses: 'custom-observation-header-class',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        },
        {
            dataField: "reviewer",
            text: "Reviewer",
            sort: true,
            style: { cursor: "pointer" },
            classes:'custom-reviewer-header-class',
            headerClasses:'custom-reviewer-header-class',
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    setObservationsDetails(row); setObservationsDetailsShow(true)
                },
            },
        }
    ]

    return (
        <>
        {loading && 
            <CommonLoader loading={loading} />
        }
        {!loading && componentDidMountFlag && (
        <>
            {assignment && 
                <AssignmentListDetails
                    assignment={assignment}
                />
            }
            <div className='row'>
                <div className='col-md-12'>
                    {alertShow &&
                        <CommonAlert
                            message={reportData.status == 'failed' ? 
                            (<span>
                                We are currently experiencing difficulties generating the assignment report. 
                                Please reach out to us at{' '}
                                <a href={`mailto:namste@takestock.in`} style={{ color: 'blue', textDecoration: 'underline' }}>namste@takestock.in</a>
                                {' '}for assistance. Thank you.
                            </span>) : message}
                            type={reportData.status == 'failed' ? 'error' : "info"}
                            closable={reportData.status == 'failed' ? true : false}
                            onClose={handleAlertClose}
                        />
                    }
                </div>
                <div className='col-md-6'>
                    <div className='bookstock-box mt-3'>
                        <div className='obser-tittle'>
                            <h6 className='mb-2'>Book Stock</h6>
                        </div>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Items</th>
                                        <th scope="col">Total Value</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.bookStockDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.bookStockDetails?.totalItem)}</th>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.bookStockDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.bookStockDetails?.totalValue)}</td>
                                        <td onClick={() => { navigate('bookstockdetails', { state: { assignmentListDetails: assignment } }) }}><a style={buttonColor}>Details</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='bookstock-box'>
                        <div className='obser-tittle'>
                            <h6 className='mb-2'>Verified</h6>
                        </div>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Items</th>
                                        <th scope="col">Total Value</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.verificationDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.verificationDetails?.totalItem)}</th>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.verificationDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.verificationDetails?.totalValue)}</td>
                                        <td onClick={() => { navigate('verification', { state: { assignmentListDetails: assignment } }) }}><a style={buttonColor}>Details</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='deviation-box mt-3'>
                        <div className='obser-tittle'>
                            <h6 className='mb-2'>Deviations</h6>
                        </div>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Items</th>
                                        <th scope='col'>Shortages</th>
                                        <th scope='col'>Total Items</th>
                                        <th scope="col">Excess</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.shortCount) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.shortCount)}</th>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.shortTotal) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.shortTotal)}</td>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.excessCount) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.excessCount)}</td>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.deviationDetails?.excessTotal) ? "-" : formatter.format(assignmentRelatedMasterCounts?.deviationDetails?.excessTotal)}</td>
                                        <td onClick={() => { navigate('deviation', { state: { assignmentListDetails: assignment } }) }}><a style={buttonColor}>Details</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='sample-box'>
                        <div className='obser-tittle'>
                            <h6 className='mb-2'>Sampling</h6>
                        </div>
                        <div className='table-responsive'>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Total Items</th>
                                        <th scope="col">Total Value</th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{isEmptyVariable(assignmentRelatedMasterCounts?.samplingDetails?.totalItem) ? "-" : formatter.format(assignmentRelatedMasterCounts?.samplingDetails?.totalItem)}</th>
                                        <td>{isEmptyVariable(assignmentRelatedMasterCounts?.samplingDetails?.totalValue) ? "-" : formatter.format(assignmentRelatedMasterCounts?.samplingDetails?.totalValue)}</td>
                                        <td onClick={() => { navigate('sampling', { state: { assignmentListDetails: assignment } }) }}> <a style={buttonColor}>Sample</a></td>
                                        <td onClick={() => { navigate('sampledetails', { state: { assignmentListDetails: assignment } }) }}><a style={buttonColor}>Details</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='verification-box'>
                <div className='row'>
                    <div className="col-7 d-flex justify-content-end">
                        <CommonButton
                            style={{marginRight: 10}}
                            label={'Movement Analysis'}
                            onClick={() => navigate('materialmovement', {state: {assignmentListDetails: assignment}})}
                        />
                        <CommonButton
                            style={{marginRight: 10}}
                            label={'Not Used Analysis'}
                            onClick={() => navigate('materailnotused', {state: {assignmentListDetails: assignment}})}
                        />
                        <CommonButton
                            label={'Expiry Analysis'}
                            onClick={() => navigate('materialexpiery', {state: {assignmentListDetails: assignment}})}
                        />
                    </div>
                    <div className="col-5 d-flex justify-content-end">
                        <CommonButton
                            style={{opacity: downloadReportButtonDisable ? 0.7 : 1}}
                            disabled={downloadReportButtonDisable}
                            loading={wordDownloadReportsLoading}
                            label={wordDownloadReportsLoading ? 'Downloading...' : 'Download Report'}
                            onClick={() => {
                                if(reportData.status == 'completed'){
                                    getFinalizeReportUrl()
                                }
                            }}
                            icon={<DownloadOutlined
                                style={{
                                    verticalAlign: 'middle',
                                    marginBottom: '2px'
                                }}
                            />}
                        />
                    </div>
                </div>
            </div>
            <div className='observation-box'>
                <div className='obser-tittle'>
                    <h6 className='mb-2'>Observations</h6>
                </div>
                <div className='observation-table'>
                    {componentDidMountFlag && observationsList?.records && (
                        <Table
                            data={observationsList?.records?.map((item, index) => {
                                const mergedArray = assignment?.teamMembers?.concat(assignment?.reviewers);
                                return {
                                    ...item,
                                    key: index,
                                    index: (observationsList?.page - 1) * observationsList.limit + index + 1,
                                    id: item?.id,
                                    reviewer: mergedArray?.map((e,i) =>
                                        item?.currentObservation?.find(item => e?.userId === item?.userId)
                                            ? `${e?.title} ${e?.firstName} ${e?.lastName}`
                                            : ""
                                    ),
                                    observationDetails: stripHtmlTags(item?.observationDetails),
                                    status: item?.isActive,
                                };
                            })}
                            columns={observationListColumn}
                            handleTableChange={handleTableChange}
                            totalSize={observationsList?.total ?? 0}
                            currentPage={observationsList?.page ?? 1}
                            sizePerPage={observationsList?.limit ?? 15}
                        />
                    )}
                    {componentDidMountFlag && isEmptyArray(observationsList?.records) && 
                        <div className="no-items-layout">
                            <div className="no-items-card">
                                <h6>
                                    {
                                    Constants.message.noRecordsWarning
                                    }
                                </h6>
                            </div>
                        </div>
                    }
                </div>
                {observationsDetailsShow &&
                    <Observationdetails 
                        status={assignment?.status} 
                        observationsDetails={observationsDetails} 
                        setObservationsDetailsShow={setObservationsDetailsShow} 
                        observationsDetailsShow={observationsDetailsShow} 
                        handleEditObservationClose={handleEditObservationClose}
                    />
                }
            </div>
        </>
        )}
        </>
    );
}
export default CompletedAssignmentDetails;