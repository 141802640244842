import { APIRequest } from "./api-request";
export const API_REGISTER_SUBMIT = 1;
export const API_LOGIN_SUBMIT = 2;
export const API_FORGOT_PASSWORD = 3;
export const API_RESET_PASSWORD = 4;

export const CREATE_COUNTRY = 5;
export const CREATE_STATE = 6;
export const CREATE_CITY = 7;
export const LIST_COUNTRY = 8;
export const LIST_STATE = 9;
export const LIST_CITY = 10;
export const DELETE_CITY = 11;

export const COMMON_LIST = 12;
export const COMMON_CREATE = 13;
export const COMMON_EDIT = 14;
export const COMMON_DELETE = 15;

export const LIST_CLIENT = 16;
export const CREATE_CLIENT = 17;
export const EDIT_CLIENT = 18;
export const DELETE_CLIENT = 18;

export const LIST_CLIENT_UNIT = 20;
export const CREATE_CLIENT_UNIT = 21;
export const EDIT_CLIENT_UNIT = 22;
export const DELETE_CLIENT_UNIT = 23;

export const LIST_UNIT = 24;
export const LIST_STORE = 25;

export const LIST_EMPLOYEE = 26;
export const CREATE_EMPLOYEE = 27;
export const EDIT_EMPLOYEE = 28;
export const DELETE_EMPLOYEE = 29;

export const LIST_USER = 30;
export const CREATE_USER = 31;


export const BILLSANDPAYMENTS_LIST = 32;
export const CREDIT_PURCHASE = 33;
export const CREDIT_UTILIZED = 34;
export const TOTAL_CREDIT = 35;

export const ASSIGNMENT_LIST = 36;
export const ASSIGNMENT_CREATE = 37;
export const ASSIGNMENT_EDIT = 38;

export const LIST_SITEUSER = 39;

export const UPLOAD_FILES = 40;

export const STOCK_STORE = 41;

export const UPDATE_USER = 42;

export const UPDATE_CITY = 43;


export const ADD_BOOKSTOCK_FILE_IN_QUEUE = 44;

export const OBSERVATIONS_LIST = 45;

export const SAMPLING = 46;

export const OBSERVATIONS_CREATE = 47;

export const CONFIGS_LIST = 48;

export const ADD_CONFIG = 49;

export const ASSIGNMENT_STATUS = 50;

export const ASSIGNMENT_COMPLETED = 51;
export const ASSIGNMENT_BOOKSTOCK = 52;
export const ASSIGNMENT_DETAILS = 53;
export const LIST_SAMPLING = 54;
export const SEARCH_BOOK_STOCK_BY_ONE = 55;
export const ACTUALSTOCK_MAPBOOKSTOCK = 56;

export const RECONCILATION_LIST = 57;
export const RECONCILATION_STORE = 58;
export const RECONCILATION_UPDATE = 59;

export const LIST_VARIFACTION = 60;
export const LIST_DEVIATION = 61;
export const RECONCILATION_CREATE = 62;
export const MATERIAL_MOVEMENT_ANALYSIS = 63;
export const MATERIAL_EXPIRY_ANALYSIS = 64;
export const MATERIAL_NOTUSED_ANALYSIS = 65;
export const LIST_BOOKSTOCK = 66;
export const LIST_SAMPLE = 67;
export const CHANGE_RECONCILATION_ACTIVE_STATUS = 68;

export const LIST_STOCK = 69;
export const BOOKSTOCK_FILE_HISTORY = 70;
export const MATERIAL_MOVEMENT_ANALYSIS_SAVE = 71;
export const MATERIAL_EXPIRY_ANALYSIS_SAVE = 72;
export const MATERIAL_NOTUSED_ANALYSIS_SAVE = 73;

export const DELETE_USER = 74;
export const EDIT_BOOKSTOCK = 75;
export const BOOKSTOCK_STOCKTYPE = 76;
export const ACTUALSTOCK_USERWISE_LIST = 77;
export const MANNUAL_RESOLUTION = 78;
export const NAME_CHECK = 79;
export const NAME_INFO = 80;
export const AUTH_FORGOT = 81;
export const GST_CHECK = 82;
export const TERMS_CHECK = 83;
export const TERMS_CHECK2 = 84;
export const OFFLINE_SET = 85;
export const ORGANISATION_NAME = 86;
export const SUBSCRIBER_ADMIN_LIST = 87;
export const UNIT_TYPE = 89;
export const BOOK_STOCK_CRITERIA = 90;
export const CANCEL_BOOK_STOCK = 91;
export const UPLOAD_BOOK_STOCK = 92;
export const CANCEL_BOOK_STOCK_BACK = 93;
export const CANCEL_BOOK_STOCK_UPLOAD = 94;
export const ADD_ANNEXURE = 95;
export const ACTUALSTOCK_RESOLVE = 96;
export const BOOK_STORE_STOCK_TYPE_SAMPLING_NUMBER_OF_ITEM = 97;
export const ACCOUNTLIST = 98;
export const BOOK_STOCK_TYPE_SAMPLING = 99;
export const BOOK_STOCK_SAMPLING_REMAINS_STORE_STOCK_LIST = 100;
export const RESET_BOOK_STOCK_SAMPLING = 101;
export const BOOK_STOCK_MATERIAL_MOVEMENT_ANALYSIS = 102;
export const MATERIAL_EXPIRY = 103;
export const MATERIAL_NOTUSED = 104;
export const MATERIAL_EXPIRY_DELETE = 105;
export const CLEAR_MATERIAL_MOVEMENT_ANALYSIS = 106;
export const MATERIAL_NOTUSED_DELETE = 107;
export const CLIENT_EXIST_OR_NOT = 108;
export const UPLOAD_FILES_DATA = 109;
export const CLIENT_UNIT_EXIST_OR_NOT = 110;
export const UNIT_EXIST_OR_NOT = 111;
export const STORE_EXIST_OR_NOT = 112;
export const STOCK_EXIST_OR_NOT = 113;
export const DELETE_OBSERVATION = 114;
export const RECONCILATION_EXIST_OR_NOT = 115;
export const OBSERVATIONS_CHANGE_STATUS = 116;
export const FINALIZE_REVIEW_LIST = 117;
export const CITY_EXIST_OR_NOT = 118;
export const SELECTED_OBSERVATION_REQUEST_CHANGE = 119;
export const ACTUALSTOCK_CREDIT = 120;
export const ACTUALSTOCK_UTILIZE = 121;
export const ACTUALSTOCK_DOCLIST = 122;
export const DOWNLOAD_REPORT = 123;
export const DOWNLOAD_WORD_REPORT = 124;
export const ACTUALSTOCK_FILE_DELETE = 125;
export const ACTUALSTOCK_DATA = 126;
export const DELETE_COMMENT = 127;
export const UPLODED_DOCUMENTS_DATA = 128;
export const FINALIZE_ASSIGNMENT = 129;
export const GET_DATA_MONTH_WISE = 130;
export const GET_DATA_DAY_WISE = 131;
export const DELETE_BOOKSTOCK = 132;
export const DELETE_UPLOAD_BOOKSTOCK = 133;
export const DELETE_BOOKSTOCKS = 134;
export const ADD_STORE = 135;
export const UPDATE_ORGANISATION = 136;
export const ORGANISATION_CREDIT_CHECK = 137;
export const FINALIZE_WORD_DOWNLOAD_REPORTS = 138;
export const REMOVE_ORGANISATION_LOGO = 139;
export const GENERATE_FILE_URL = 140;
export const BOOKSTOCK_MAP_FIELDS = 141;
export const CHECK_BOOKSTOCK_EXISTENCE = 142;
export const CREATE_NEW_SINGLE_BOOKSTOCK = 143;
export const SEND_FEEDBACK = 144;
export const UPDATE_OBSERVATION_ORDER = 145;
export const REACTIVATE_BOOKSTOCK = 146;
export const DELETE_ACTUAL_STOCK = 147;
export const DELETE_RECONCILATION = 148;
export const VALIDATE_BEFORE_FREEZE_ASSIGNMENT = 149;
export const UPDATE_BOOKSTOCK = 150;
export const DOWNLOAD_SAMPLE_REPORT = 151;
export const ASSIGNMENT_STORE_LIST = 152;
export const STOCK_TYPE_LIST = 153;
export const CHECK_FILE_PROCESS_STATUS = 154;
export const REMOVE_UPLOADED_BOOK_STOCK = 155;
export const ADD_BOOK_STOCK_MAPPING = 156;
export const ASSIGNMENTS_LIST_BY_ORG_USER = 157;
export const BOOK_STOCK_SAMPLING_STORE_STOCK_LIST = 158;
export { APIRequest };