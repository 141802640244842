import React, { Component } from "react";
import { Empty } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import * as Constants from "../../Component/common/Global/constants";

class NoRecords extends Component {
    render(){
        const { message } = this.props;
      
        return(
            <Empty
                image={<InboxOutlined  style={{ fontSize: '60px', color: '#552B844d' }}/>} // Use the NoData icon as the image
                imageStyle={{
                    height: 60, // Adjust the height of the icon as per your preference
                }}
                description={
                <span>
                    {message ? message : Constants.message.noRecordsWarning}
                </span>
                }
            />
        )
    }
}
export default NoRecords;