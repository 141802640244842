import React from 'react'
import Modal from "react-bootstrap/Modal";


const Clientdetails = ({ clientshow, Setclientshow, clientdetails }) => {
    return (
        <Modal show={clientshow}>
            <Modal.Header className='bookstockheader'>
                <Modal.Title>Client Details</Modal.Title>
                <div
                    onClick={() => {
                        Setclientshow(false);
                    }}
                    data-dismiss="modal">
                    <i className="fa fa-close" aria-hidden="true"></i>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Full Name</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.company_name}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Short Name</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.clientShortName}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Unit Type</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.UnitType?.unitTypeName}</p>

                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Address Line 1</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.address1}</p>
                    </div>
                </div>
                {clientdetails?.address2 !== "" &&
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Address Line 2</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.address2}</p>
                        </div>
                    </div>}
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>City</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.city}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>State</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.state}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Country</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.City?.State?.Country?.countryName}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <p className='detailslable'>Pin code</p>
                    </div>
                    <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{clientdetails?.pincode}</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Clientdetails