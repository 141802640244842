import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { APIRequest, EDIT_CLIENT_UNIT, COMMON_CREATE, COMMON_EDIT } from "../../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";

const EditUnit = ({
  addunitype,
  setaddunitypeShow,
  fetchClientUnit,
  clientId,
  editData,
  unit,
  store,
  city,
  fetchStoreList,
  loadMasterList
}) => {
  const User = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [storeloading, setStoreLoading] = useState(false);

  const addunitform = useFormik({
    enableReinitialize: true,
    initialValues: {
      unit: editData?.UnitType?.unitTypeId ?? "",
      store: editData?.ClientUnitStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } }) ?? "",
      clientUnitName: editData?.clientUnitName ?? "",
      address1: editData?.address1 ?? "",
      address2: editData?.address2 ?? "",
      country: { 'value': editData?.City?.State?.Country?.countryId ?? '', 'label': editData?.City?.State?.Country?.countryName ?? '' },
      city: { 'value': editData?.City?.cityId ?? '', 'label': editData?.City?.cityName ?? '' },
      state: { 'value': editData?.City?.State?.stateId ?? '', 'label': editData?.City?.State?.stateName ?? '' },
      pincode: editData?.pincode ?? "",
      updatedBy: User.userId
    },
    validationSchema: Yup.object().shape({
      unit: Yup.string().required("Select Unit"),
      // store: Yup.array().required("Select Store").min(1 ,"Select Store"),
      clientUnitName: Yup.string().required("Enter Name"),
      address1: Yup.string().required("Enter Address"),
      country: Yup.object().required("Select Country").nullable(true),
      state: Yup.object().required('select state').nullable(true),
      city: Yup.object().required('select city').nullable(true),

    }),
    onSubmit: (values) => {
      setLoading(true);
      let _step1Value = {
        ...values,
        country: values.country.value,
        state: values.state.value,
        city: values.city.value,
        store: values.store?.map((item, id) => { return item.value })
      }
      const finalValue = { ..._step1Value }
      new APIRequest.Builder()
        .post()
        .setReqId(EDIT_CLIENT_UNIT)
        .reqURL(`clientUnit/updateClientUnit/${clientId}/${editData.clientUnitId}`)
        .jsonParams(finalValue)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    },
  });
  const generalForm = useFormik({
    initialValues: {
      storeTypeShortName: '',
      storeTypeName: '',
      organisationId: User.organisationId,
      clientId: clientId,
      createdBy: User.userId,
      updatedBy: User.userId
    },
    validationSchema: Yup.object().shape({
      storeTypeShortName: Yup.string().required("Enter storeTypeShortName"),
      storeTypeName: Yup.string().required("Enter Name"),

    }),
    onSubmit: values => {
      setStoreLoading(true);
      let pageInfo = {};
      pageInfo.title = 'Store';
      pageInfo.createApi = 'storeType/addStoreType';
      pageInfo.editApi = 'storeType/updateStoreType/:id';
      pageInfo.listApi = 'storeType/getStoreTypeList';
      pageInfo.deleteApi = 'storeType/changeStoreTypeIsActiveStatus/:id';
      const editUrl = false ? pageInfo.editApi.replace(":id", values.id) : '';
      new APIRequest.Builder()
        .post()
        .setReqId(true ? COMMON_CREATE : COMMON_EDIT)
        .jsonParams(values)
        .reqURL(true ? pageInfo.createApi : editUrl)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    },
  });

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case EDIT_CLIENT_UNIT:
        setLoading(false);
        toast.success(response?.data?.message);
        setaddunitypeShow(false);
        fetchClientUnit();
        addunitform.resetForm();
        break;
      case COMMON_CREATE:
        setStoreLoading(false);
        toast.success(response?.data?.message);
        setShow(false);
        fetchStoreList();
        generalForm.resetForm();
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case EDIT_CLIENT_UNIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
          setLoading(false);
        }
        break;
      case COMMON_CREATE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          response?.data?.message && toast.error(`${response?.data?.message}`);
          setStoreLoading(false);
          // setShow(false);
        }
        break;

      default:
        break;
    }
  };


  const stateOption = city?.filter(item => Number(item.cityId) === Number(addunitform.values.city.value))?.map((e) => { return { 'value': e.State.stateId, 'label': e.State.stateName } })

  const cityOption = city.map((e) => { return { 'value': e.cityId, 'label': e.cityName } })

  const countryOption = city?.filter(item => Number(item.cityId) === Number(addunitform.values.city.value))?.map((e) => {
    return {
      'value': e.State.Country.countryId
      , 'label': e.State.Country.countryName
    }
  })

  const storeoption = store?.map((e) => { return { 'value': e.storeTypeId, 'label': e.storeTypeName } })

  const unitOption = unit?.map((e) => { return { 'value': e.unitTypeId, 'label': e.unitTypeName } })

  useEffect(() => {
    if (countryOption[0] && stateOption[0]) {
      addunitform.setFieldValue('country', countryOption[0])
      addunitform.setFieldValue('state', stateOption[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [addunitform.values.city])

  const handleClose = () => {
    setShow(false);
    generalForm.resetForm();
  };

  return (
    <Modal show={addunitype}>
      <form onSubmit={addunitform.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Update Unit Detail</Modal.Title>
          <div
            onClick={() => {
              setaddunitypeShow(false);
              addunitform.resetForm();
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="addclients_details">
            <div className="row dropdown_box">
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="unit" className="form-label">
                    Unit Type*{" "}
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="unit"
                    name="unit"
                    options={unitOption}
                    onChange={e => { addunitform.setFieldValue('unit', e.value) }}
                    defaultValue={{ label: editData?.UnitType?.unitTypeName }}
                  />
                  {addunitform.touched.state && addunitform.errors.state ? (
                    <span className="error">{addunitform.errors.state}</span>
                  ) : null}


                  {addunitform.touched.unit && addunitform.errors.unit ? (
                    <span className="error">{addunitform.errors.unit}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="name" className="form-label">
                    Name*{" "}
                  </label>
                  <input
                    className="form-control"
                    id="clientUnitName"
                    name="clientUnitName"
                    type="text"
                    onChange={addunitform.handleChange}
                    value={addunitform.values.clientUnitName}
                  />
                  {addunitform.touched.clientUnitName && addunitform.errors.clientUnitName ? (
                    <span className="error">{addunitform.errors.clientUnitName}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="address1" className="form-label">
                    Address Line 1*{" "}
                  </label>
                  <input
                    className="form-control"
                    id="address1"
                    name="address1"
                    type="text"
                    onChange={addunitform.handleChange}
                    value={addunitform.values.address1}
                  />
                  {addunitform.touched.address1 && addunitform.errors.address1 ? (
                    <span className="error">{addunitform.errors.address1}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="address2" className="form-label">
                    Address Line 2{" "}
                  </label>
                  <input
                    className="form-control"
                    id="address2"
                    name="address2"
                    type="text"
                    onChange={addunitform.handleChange}
                    value={addunitform.values.address2}
                  />
                  {addunitform.touched.address2 && addunitform.errors.address2 ? (
                    <span className="error">{addunitform.errors.address2}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="city" className="form-label">
                    City*{" "}
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="city"
                    name="city"
                    options={cityOption}
                    onInputChange={loadMasterList}
                    onChange={value => addunitform.setFieldValue('city', value)}
                    defaultValue={addunitform.values.city}
                  />
                  {addunitform.touched.city && addunitform.errors.city ? (
                    <span className="error">{addunitform.errors.city}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="state" className="form-label">
                    State*
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="state"
                    name="state"
                    options={stateOption}
                    onChange={value => addunitform.setFieldValue('state', value)}
                    value={addunitform.values.state}
                  />
                  {addunitform.touched.state && addunitform.errors.state ? (
                    <span className="error">{addunitform.errors.state}</span>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="country" className="form-label">
                    Country*
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="country"
                    name="country"
                    options={countryOption}
                    onChange={value => addunitform.setFieldValue('country', value)}
                    value={addunitform.values.country}
                  />
                  {addunitform.touched.country && addunitform.errors.country ? (
                    <span className="error">{addunitform.errors.country}</span>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="pincode" className="form-label">
                    Pin Code{" "}
                  </label>
                  <input
                    className="form-control"
                    id="pincode"
                    name="pincode"
                    type="text"
                    onChange={addunitform.handleChange}
                    value={addunitform.values.pincode}
                  />
                  {addunitform.touched.pincode && addunitform.errors.pincode ? (
                    <span className="error">{addunitform.errors.pincode}</span>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="store" className="form-label">
                    Store Type{" "}
                  </label>
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    options={storeoption}
                    defaultValue={editData?.ClientUnitStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } })}
                    isMulti
                    onChange={e => { addunitform.setFieldValue("store", e) }} />
                  {/* {addunitform.touched.store && addunitform.errors.store ? (
                  <span className="error">{addunitform.errors.store}</span>
                ) : null} */}
                  <button className='btn btn-primary mt-2' type="button" onClick={() => {
                    setShow(true);
                  }}>Add</button>
                </div>
              </div>
            </div>
          </div>
          <div className="Mandotary">
            {/* <p>* Fields Are Mandatory Reqired</p> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setaddunitypeShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Update"}
          </Button>
        </Modal.Footer>
      </form>
      <Modal show={show}>
        <form onSubmit={generalForm.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Store Type</Modal.Title>
            <div
              onClick={() => {
                setShow(false);
                generalForm.resetForm();
              }}
              data-dismiss="modal">
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
          </Modal.Header>
          <Modal.Body className='pb-1'>
            <div className="">
              <label htmlFor="storeTypeShortName" className="form-label">Short Name*</label>
              <input
                className="form-control"
                id="storeTypeShortName"
                name="storeTypeShortName"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.storeTypeShortName}
              />
              {generalForm.touched.storeTypeShortName && generalForm.errors.storeTypeShortName ? (
                <span className="error">{generalForm.errors.storeTypeShortName}</span>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="storeTypeName" className="form-label">Name*</label>
              <input
                className="form-control"
                id="storeTypeName"
                name="storeTypeName"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.storeTypeName}
              />
              {generalForm.touched.storeTypeName && generalForm.errors.storeTypeName ? (
                <span className="error">{generalForm.errors.storeTypeName}</span>
              ) : null}
            </div>
            <div className="Mandotary">
              {/* <p>* Fields Are Mandatory Reqired</p> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {storeloading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Save"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </Modal >
  );
};

export default EditUnit;
