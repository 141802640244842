import { useState, useEffect } from "react";
import { useFormik } from "formik";
import "../ClientDashboard/clientdashboardstyle.css";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/action";
import { Outlet, useNavigate } from "react-router-dom";
import * as Yup from "yup";



function ThirdPartydashboard() {
   const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user); 
  const logOut = () => {
    dispatch(logout());
    navigate("/Login");
  };
  return (
    <>
      <nav className="navbar navbar-light bg-white">
        <div className="container-fluid">
          <a className="navbar-brand">
            <img src="assets/images/logo1.svg" type="image/svg+xml" className="img-fluid"></img>
            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" width="52px" height="50px" viewBox="113.5 0 52 50"  >
    <g>
    	<path fill="#EA2227" d="M164.633,5.742c0,2.612-2.115,4.73-4.729,4.73c-2.612,0-4.729-2.118-4.729-4.73   c0-2.611,2.116-4.73,4.729-4.73C162.518,1.011,164.633,3.13,164.633,5.742"/>
    	<g>
    		<g>
    			<defs>
    				<path id="SVGID_1_" d="M126.15,1.018c-0.133,0.005-1.526,0.053-3.068,0.301c-1.143,0.19-2.015,1.184-2.015,2.376      c0,1.332,1.078,2.417,2.408,2.417c0.314,0,0.611-0.064,0.884-0.169c0.008-0.004,0.008,0,0.008,0.004      c2.177-0.791,7.653-2.139,10.203,3.067c0.246,0.503,0.825,2.598,0.695,3.968c-0.287,2.981-1.124,6.05-5.231,7.7      c-0.57,0.228-2.524,0.783-4.597,0.228c-1.436-0.38-3.133-1.299-5.188-3.522c-0.353-0.386-1.255-1.219-2.471-0.998      c-2.405,0.433-6.274,3.055-0.036,10.948c0,0,19.042,17.334,27.118,21.458c0.127,0.058,0.271,0.093,0.42,0.093      c0.548,0,0.994-0.445,0.994-0.994c0-0.293-0.127-0.556-0.33-0.738c-4.42-3.383-15.844-12.227-19.334-15.896      c0,0,10.566-1.197,15.495-9.382c0,0,4.207,8.325,14.618,6.707V44.97L164.18,49V19.001v-2.09v-0.589c0-2.099-1.701-3.8-3.801-3.8      s-3.797,1.701-3.797,3.8v0.767v0.215v6.172c0,0-7.352,2.292-13.837-5.183c0,0,0.138-2.604-0.625-5.877      C140.018,2.335,131.12,1,126.999,1C126.685,1,126.402,1.004,126.15,1.018"/>
    			</defs>
    			<clipPath id="SVGID_2_">
    				<use xlink:href="#SVGID_1_" overflow="visible"/>
    			</clipPath>
    			
    				<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-925.4844" y1="67.0313" x2="-924.9766" y2="67.0313" gradientTransform="matrix(-88.0474 -88.0474 88.0474 -88.0474 -87225.4531 -75534.4063)">
    				<stop offset="0" style="stop-color:#FCEE43"/>
    				<stop offset="0.015" style="stop-color:#FCE234"/>
    				<stop offset="0.0406" style="stop-color:#FCD221"/>
    				<stop offset="0.0668" style="stop-color:#FCC916"/>
    				<stop offset="0.0947" style="stop-color:#FCC612"/>
    				<stop offset="0.396" style="stop-color:#ED683B"/>
    				<stop offset="0.6322" style="stop-color:#ED653B"/>
    				<stop offset="0.7828" style="stop-color:#EC5D3A"/>
    				<stop offset="0.909" style="stop-color:#EA4E3A"/>
    				<stop offset="1" style="stop-color:#E83E39"/>
    			</linearGradient>
    			<polygon clip-path="url(#SVGID_2_)" fill="url(#SVGID_3_)" points="137.838,75.342 87.5,24.996 137.838,-25.341 188.18,24.996         "/>
    		</g>
    	</g>
    </g>
           </svg> */}
          </a>
          <div className="main_user_nav">
            {/* <a>
              {" "}
              <img
                src="assets/images/bell.png"
                className="img-fluid me-3 ms-3"
              ></img>{" "}
            </a> */}
            <a href="">
              <i className="fa fa-question-circle" aria-hidden="true"></i>
            </a>
            <div className="user_box">
              <img src="assets/images/user.png" className="img-fluid"></img>
              <h5 className="mb-0 ms-2">
                {user.firstName + " " + user.lastName}
              </h5>
              <div
                className="px-2"
                style={{ cursor: "pointer" }}
                onClick={logOut}
              >
                <i className="fa fa-sign-out" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="main_tabs">
          <div className="row">
            <div className="col-md-2">
              <div
                className="nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link"
                  onClick={()=>navigate('/ThirdPartydashboard')}
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <i className="fa fa-tachometer me-3" aria-hidden="true"></i>
                  <span>Dashboard</span>
                </button>
                <button
                  className="nav-link"
                  id="v-pills-profile-tab"
                  onClick={()=>navigate('/ThirdPartydashboard/assignment')}
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  <i className="fa fa-list me-3" aria-hidden="true"></i>
                  <span>Assignments</span>
                </button>
                <button
                  className="nav-link"
                  id="v-pills-messages-tab"
                  data-bs-toggle="pill"
                  onClick={()=>navigate('/ThirdPartydashboard/user')}
                  data-bs-target="#v-pills-messages"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-messages"
                  aria-selected="false"
                >
                  <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                  <span>User </span>
                </button>
              </div>
            </div>
            <div className="col-md-10">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-home"
                  role="tabpanel"
                  aria-labelledby="v-pills-home-tab"
                >
                  {/* <div className="thirdparty_box">
                    <div className="main_tabs">
                      <div className="master_box">
                        <div className="master_boxinfo">
                          <div className="tittle_box p-2 border mb-4">
                            <a>Dashboard</a>
                          </div>
                          <h6>Ongoing Assignments</h6>
                          <div className="table-responsive pt-2 pb-5">
                            <div className="add_btns d-none">
                              <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal5"
                              >
                                <i
                                  className="fa fa-plus-circle"
                                  aria-hidden="true"
                                 ></i>{" "}
                                BUY CREDITS
                              </button>
                            </div>
                            <i
                              className="fa fa-search search_icon"
                              aria-hidden="true"
                            ></i>
                            <table id="datatable13" className="table">
                              <thead>
                                <tr>
                                  <th>Sl. No</th>
                                  <th>Store Type</th>
                                  <th>Auditor’s Firm</th>
                                  <th>Auditors</th>
                                  <th>Start Date</th>
                                  <th>End Date</th>
                                  <th>Book Stock Uploaded</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>001</td>
                                  <td>John</td>
                                  <td>12assa</td>
                                  <td>01/04/2022</td>
                                  <td>01/04/2022</td>
                                  <td>Chicago</td>
                                  <td>
                                    <i
                                      className="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item">
                                            Assign user
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="/BookStock"
                                          >
                                            Upload Book Stock
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>002</td>
                                  <td>John</td>
                                  <td>12assa</td>
                                  <td>01/04/2022</td>
                                  <td>01/04/2022</td>
                                  <td>Chicago</td>
                                  <td>
                                    <i
                                      className="fa fa-check-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item">
                                            Assign user
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="/BookStock"
                                          >
                                            Upload Book Stock
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>003</td>
                                  <td>John</td>
                                  <td>12assa</td>
                                  <td>01/04/2022</td>
                                  <td>01/04/2022</td>
                                  <td>Chicago</td>
                                  <td>
                                    <i
                                      className="fa fa-window-close-o"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item">
                                            Assign user
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="/BookStock"
                                          >
                                            Upload Book Stock
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>004</td>
                                  <td>John</td>
                                  <td>12assa</td>
                                  <td>01/04/2022</td>
                                  <td>01/04/2022</td>
                                  <td>Chicago</td>
                                  <td>
                                    <i
                                      className="fa fa-window-close-o"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item">
                                            Assign user
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="/BookStock"
                                          >
                                            Upload Book Stock
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td>005</td>
                                  <td>John</td>
                                  <td>12assa</td>
                                  <td>01/04/2022</td>
                                  <td>01/04/2022</td>
                                  <td>Chicago</td>
                                  <td>
                                    <i
                                      className="fa fa-window-close-o"
                                      aria-hidden="true"
                                    ></i>
                                  </td>
                                  <td className="edit_icons">
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-secondary dropdown-toggle"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <i
                                          className="fa fa-ellipsis-v"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a className="dropdown-item">
                                            Assign user
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item"
                                            href="/BookStock"
                                          >
                                            Upload Book Stock
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-profile-tab"
                >
                  {/* <Thirdpartyassignment/> */}
                  {/* <div className="master_boxinfo mt-3">
                    <Tabs
                      defaultActiveKey="seven"
                      className="thirdpartyassignment"
                    >
                      <Tab eventKey="seven" title="Scheduled Assignment">
                        <div className="table-responsive pt-2 pb-5">
                          <i
                            className="fa fa-search search_icon"
                            aria-hidden="true"x
                          ></i>
                          <table id="datatable14" className="table">
                            <thead>
                              <tr>
                                <th>Sl. No</th>
                                <th>Store Type</th>
                                <th>Auditor’s Firm</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>001</td>
                                <td>John</td>
                                <td>12assa</td>
                                <td>01/04/2022</td>
                                <td>Chicago</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </Tab>
                      <Tab eventKey="eight" title="Completed Assignment">
                        <div className="table-responsive pt-2 pb-5">
                          <div className="add_btns d-none">
                            <button
                              type="button"
                              className="btn btn-primary w-auto"
                              data-bs-toggle="modal"
                              data-bs-target="#newscheduled"
                            >
                              <i
                                className="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              Scheduled New Assignments
                            </button>
                          </div>
                          <i
                            className="fa fa-search search_icon"
                            aria-hidden="true"
                          ></i>
                          <table id="datatable16" className="table">
                            <thead>
                              <tr>
                                <th>Sl. No</th>
                                <th>Form Name</th>
                                <th>Unit</th>
                                <th>Store Type</th>
                                <th>Completion Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  variant="primary"
                                  onClick={setcomplateDetailsShow}
                                >
                                  1
                                </td>
                                <td
                                  variant="primary"
                                  onClick={setcomplateDetailsShow}
                                >
                                  John
                                </td>
                                <td
                                  variant="primary"
                                  onClick={setcomplateDetailsShow}
                                >
                                  12assa
                                </td>
                                <td
                                  variant="primary"
                                  onClick={setcomplateDetailsShow}
                                >
                                  01/04/2022
                                </td>
                                <td
                                  variant="primary"
                                  onClick={setcomplateDetailsShow}
                                >
                                  Chicago
                                </td>
                                <td className="edit_icons">
                                  <div className="dropdown">
                                    <button
                                      className="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i
                                        className="fa fa-ellipsis-v"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a className="dropdown-item">
                                          Export Documents
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="">
                                          Download Report
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <Modal
                          show={complateDetails}
                        >
                          <form onSubmit={complateDetailsform.handleSubmit}>
                            <Modal.Header>
                              <Modal.Title>Complete Details</Modal.Title>
                               <div 
                            onClick={() => {
                                setcomplateDetailsShow(false); 
                                complateDetailsform.resetForm();                                
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Client Name
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">Unit</div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Unit Type
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">Store </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Auditors
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Storekeeper
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">John</div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Start Date
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">
                                    17/05/2022
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    End Date
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">
                                    25/06/2022
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="regisdetails p-2">
                                    Completion Date
                                  </div>
                                </div>
                                <div className="col-md-1">
                                  <div className="regisdetails p-2">:</div>
                                </div>
                                <div className="col-md-8">
                                  <div className="regisdetails p-2">
                                    26/07/2022
                                  </div>
                                </div>
                              </div>
                            </Modal.Body>
                            <Modal.Footer className="d-none">
                              <Button
                                variant="secondary"
                                onClick={() => setcomplateDetailsShow(false)}
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" type="submit">
                                Add
                              </Button>
                            </Modal.Footer>
                          </form>
                        </Modal>
                      </Tab>
                    </Tabs>
                  </div> */}
                </div>
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                {/* <User isThirdParty={true} />                   */}
                </div>
              </div>
            <Outlet/></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ThirdPartydashboard;
