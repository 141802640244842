import React, { useEffect, useState } from 'react';
import '../Dashboard/dashboardstyle.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { logout } from "../../redux/action"
import { Outlet } from "react-router-dom";
import Datebox from '../../Component/Datebox/datebox'
import Monthbox from '../../Component/Monthbox/monthbox'
import { APIRequest, TOTAL_CREDIT } from '../../api';
import * as Constants from '../../Component/common/Global/constants';

function Dashboard() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.token);
    const user = useSelector((state) => state.user);
    const [totalBalance, setTotalBalance] = useState("0")

    useEffect(() => {
        if (!token) {
            navigate("/Login");  
        }
          new APIRequest.Builder()
            .get()
            .setReqId(TOTAL_CREDIT)
            .reqURL(`billsAndPayments/totalBalance`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
 const onResponse = (response, reqId) => {
        switch (reqId) {
            case TOTAL_CREDIT:
                setTotalBalance(response?.data?.data?.totalBalance);
                break;
            default:
                break;
        }
    }


    const onError = (response, reqId) => {
        switch (reqId) {
            case TOTAL_CREDIT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else { }
                break;
            default:
                break;
        }
    }
    const logOut = () => {
        dispatch(logout())
        navigate("/Login");
    }
   
    const location =useLocation();

    return (
        <>
            <nav className="navbar navbar-light bg-white fixed-top">
                <div className="container-fluid ">
                    <a href='/Dashboard' className="navbar-brand takelogo">
                    <img src='/assets/images/talke-logo.jpg' className=' img-fluid me-3 ms-3' alt=''></img>
                        
                        </a>
                    <div className="main_user_nav">
                    <h5 className='mb-0 me-2'>Credit <b>{totalBalance}</b></h5>

                         {/* <a> <img src='assets/images/bell.png' className='img-fluid me-1 ms-3' alt=''></img></a> | */}
                        {/* <a><i className="fa fa-question-circle" aria-hidden="true"></i></a> */}
                        <div className='user_box'>
                            {/* <img src='assets/images/user.png' className='img-fluid'></img> */}
                            <h5 className='mb-0 ms-2'>{user?.firstName + ' ' + user?.lastName}</h5>
                            <div className='px-2' onClick={logOut}><i className="fa fa-sign-out" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='container-fluid ps-0'>
                <div className='main_tabs'>
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className={location.pathname==="/Dashboard"?"nav-link active":" nav-link"} id="v-pills-home-tab" onClick ={()=>navigate("/Dashboard")} data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <i className="fa fa-tachometer me-3" aria-hidden="true"></i>
                                    <span>Dashboard</span>
                                </button>
                                <button className={location?.pathname==="/Dashboard/client" ?"nav-link active":" nav-link"} id="v-pills-profile-tab" onClick ={()=>navigate("/Dashboard/client")}  data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                    <i className="fa fa-users me-3" aria-hidden="true"></i>
                                    <span>Clients</span>
                                </button>
                               {user.role !== "SITE_USER" && <button className={location.pathname==="/Dashboard/accounts" ?"nav-link active":" nav-link"} id="v-pills-messages-tab" onClick ={()=>navigate("/Dashboard/accounts")}  data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <i className="fa fa-folder-open-o me-3" aria-hidden="true"></i>
                                    <span>Accounts</span>
                                </button>}
                                <button className={location.pathname==="/Dashboard/master" ?"nav-link active":" nav-link"} id="v-pills-settings-tab" onClick ={()=>navigate("/Dashboard/master")}  data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                    <i className="fa fa-bar-chart me-3" aria-hidden="true"></i>
                                    <span>Masters</span>
                                </button>
                                <button className={location.pathname=== "/Dashboard/admin" ?"nav-link active":" nav-link"} id="v-pills-settings-tab2" onClick ={()=>navigate("/Dashboard/admin")}  data-bs-toggle="pill" data-bs-target="#v-pills-settings2" type="button" role="tab" aria-controls="v-pills-settings2" aria-selected="false">
                                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                    <span>Admin </span>
                                </button>
                            </div>
                        </div>
                        <div className='col-md-10 new_conetent-col'>
                            
                            <Outlet />
                        </div>

                    </div>
                </div>
            </div>

        </>

    );
}

export default Dashboard;