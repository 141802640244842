import React, { Component } from "react";
import { Table } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import * as Constants from "../../Component/common/Global/constants";
import '../table/commonTable.css';
import NoRecords from "../noRecords/noRecords";

class CommonTable extends Component {
    constructor(props) {
      super(props);
      // Define the default style properties for the tabs
      this.tableStyle = {
          
      };
    }

    // Merges the given style properties with the existing style properties of the tabs.
    addStylePropertiesToTable(styleProperties) {
      Object.entries(this.tableStyle).map(([key, value]) => {
        if(!styleProperties[key]){
          styleProperties[key] = value
        }
      });
      return styleProperties;
    }

    getTableCustomStyle() {
      // If the tabs has a prop 'style' then check if it exists
      if(this.props.style){
          return this.addStylePropertiesToTable(this.props.style);
      }else{
          return this.tableStyle;
      }
    }

    // displaySortingIcon is a function that takes dataIndex as an argument
    // It is responsible for displaying the appropriate sorting icon based on the current sorting state
    displaySortingIcon(dataIndex) {
        // Destructuring assignment to get sortColumnName and sortType from props
        const { sortColumnName, sortType } = this.props;

        // If sortColumnName exists and is equal to the current dataIndex
        if (sortColumnName && sortColumnName === dataIndex) {
            // Return the CaretUpOutlined icon if sortType is ascending
            // Otherwise, return the CaretDownOutlined icon
            return sortType === Constants.sortingType.asc ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
        }
        // If sortColumnName is not equal to dataIndex, return null
        return null;
    }

    render() {
        const {
            columns,
            dataSource,
            size,
            bordered,
            loading,
            pagination,
            showHeader,
            showSorterTooltip,
            onRowClick,
            scroll,
            components,
            className,
            rowClassName,
            message
        } = this.props;

    return (
      <>
        <Table
          columns={columns.map(column => ({
            ...column,
            title: (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>{column.title}</div>
                {this.displaySortingIcon(column.dataIndex)}
              </div>
            ),
          }))}
          dataSource={dataSource}
          size={size ?? size} // small | middle | large
          bordered={bordered}
          loading={loading}
          className={className}
          pagination={pagination}
          showHeader={showHeader ?? showHeader}
          showSorterTooltip={showSorterTooltip}
          onRow={onRowClick}
          scroll={scroll}
          components={components}
          rowClassName={rowClassName}
          style={this.getTableCustomStyle()}
          locale={{ emptyText: <NoRecords message={message}/> }}
        />
      </>
    );
  }
}

export default CommonTable;