import React, { useEffect, useState } from 'react';
import '../UploadBookStock/bookstocktablestyle.css'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APIRequest, BOOK_STOCK_CRITERIA, ADD_BOOK_STOCK_MAPPING, REMOVE_UPLOADED_BOOK_STOCK } from "../../api";
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { logout } from '../../redux/action';
import * as Constants from '../../Component/common/Global/constants';
import { Checkbox, Radio, Table } from 'antd';
import CommonButton from '../../commonComponent/button/commonButton';
import NoRecords from '../../commonComponent/noRecords/noRecords';
import CommonLoader from '../../commonComponent/loader/commonLoader';
import { randomStringGenerator } from '../../Component/common/Global/commonFunctions';
import CommonAlert from '../../commonComponent/alert/commonAlert';

function Bookstocktable({checkFileProcessStatusData, assignment}) {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { assignmentId } = useParams();
    const [bookStockCriteriaData, setBookStockCriteriaData] = useState([]);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const user = useSelector((state) => state.user);
    const [newRecordZeroQty, setnewRecordZeroQty] = useState('');
    const [existingRecord, setExistingRecord] = useState('');
    const [existingRecordQtyDiff, setexistingRecordQtyDiff] = useState('');
    const [existingRecordActualQty, setexistingRecordActualQty] = useState('');
    const [existingRecordQtyDiffActualQty, setexistingRecordQtyDiffActualQty] = useState('');
    const [existingRecordInactive, setexistingRecordInactive] = useState('');
    const [existingRecordDiffStockType, setExistingRecordDiffStockType] = useState('');
    const [existingRecordDiffActualStockType, setExistingRecordDiffActualStockType] = useState('');
    const [existingRecordDiffStockTypeInactive, setExistingRecordDiffStockTypeInactive] = useState('');
    const [loading, setLoading] = useState(false);
    const [clearLoading, setClearLoading] = useState(false);
    const [errors, setErrors] = useState("");
    const [bookStockCriteriaLoading, setBookStockCriteriaLoading] = useState(false);
    const [errorAlertShow, isErrorAlertShow] = useState(true);
    const [existingRecordDiffActualStockTypeChecked, setExistingRecordDiffActualStockTypeChecked] = useState(false);
    const [existingRecordActualQtyChecked, setExistingRecordActualQtyChecked] = useState(false);
    const [existingRecordQtyDiffActualQtyChecked, setExistingRecordQtyDiffActualQtyChecked] = useState(false);
    let dataSource = [];

    // Handles the change event for radio buttons in the table.
    const handleRadioOnChange = (event) => {
        // Check if the selected radio button is match with the respective criteria.
        if (event.target.id === "newRecordZeroQty") {
            setnewRecordZeroQty(event.target.value);
        } else if (event.target.id === "existingRecord") {
            setExistingRecord(event.target.value)
        } else if (event.target.id === "existingRecordQtyDiff") {
            setexistingRecordQtyDiff(event.target.value)
        } else if (event.target.id === "existingRecordActualQty") {
            setexistingRecordActualQty(event.target.value)
            // If the selected value is "discard", uncheck the corresponding checkbox
            if (event.target.value == "discard") {
                setExistingRecordActualQtyChecked(false);
            }
        } else if (event.target.id === "existingRecordQtyDiffActualQty") {
            setexistingRecordQtyDiffActualQty(event.target.value)
            // If the selected value is "discard", uncheck the corresponding checkbox
            if (event.target.value == "discard") {
                setExistingRecordQtyDiffActualQtyChecked(false);
            }
        } else if (event.target.id === "existingRecordInactive") {
            setexistingRecordInactive(event.target.value)
        } else if (event.target.id === "existingRecordDiffStockType") {
            setExistingRecordDiffStockType(event.target.value)
        } else if (event.target.id === "existingRecordDiffStockTypeInactive") {
            setExistingRecordDiffStockTypeInactive(event.target.value)
        } else if (event.target.id === "existingRecordDiffActualStockType") {
            setExistingRecordDiffActualStockType(event.target.value)
            if (event.target.value == "create" || event.target.value == "discard") {
                setExistingRecordDiffActualStockTypeChecked(false);
            }
        }
    };

    // Handles the change event for checkboxes in the table.
    const handleCheckboxOnChange = (e) => {
        // Check if the changed checkbox is match with the respective criteria.
        if(e.target.id == 'existingRecordActualQty'){
            setExistingRecordActualQtyChecked(e.target.checked);
        }else if(e.target.id == 'existingRecordQtyDiffActualQty'){
            setExistingRecordQtyDiffActualQtyChecked(e.target.checked)
        }else if(e.target.id == 'existingRecordDiffActualStockType'){
            setExistingRecordDiffActualStockTypeChecked(e.target.checked);
        }
    }

    // Makes an API request to get the book stock criteria for a specific assignment and user.
    const getBookStockCriteria = () => {
        setBookStockCriteriaLoading(true);
        new APIRequest.Builder()
            .get()
            .setReqId(BOOK_STOCK_CRITERIA)
            .reqURL(`bookstock/getBookStockCriteria/${assignmentId}/${user.userId}`)
            .response(onResponse)
            .error(onError)
            .timeout(180000)
            .build()
            .doRequest();
    }
    
    // Makes an API request to get the book stock criteria when the component mounts.
    useEffect(() => {
        getBookStockCriteria();
    }, []);

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case BOOK_STOCK_CRITERIA:
                setBookStockCriteriaData(response?.data?.data);
                isComponentDidMountFlag(true);
                setBookStockCriteriaLoading(false);
                break;
            case ADD_BOOK_STOCK_MAPPING:
                setLoading(false);
                setExistingRecord('');
                setexistingRecordActualQty('');
                setexistingRecordInactive('');
                setexistingRecordQtyDiff('');
                setexistingRecordQtyDiffActualQty('');
                setnewRecordZeroQty('');
                setExistingRecordDiffStockType('');
                setExistingRecordDiffActualStockType('');
                setExistingRecordDiffStockTypeInactive('');
                toast.success(response.data.message);
                navigate(`/Client/ongoingassignment/${assignmentId}`,{
                    state: {
                        assignmentListDetails: assignment
                    }
                });
                break;
            case REMOVE_UPLOADED_BOOK_STOCK:
                toast.success(response.data.message);
                navigate(`/Client/ongoingassignment/${assignmentId}`,{
                    state: {
                        assignmentListDetails: assignment
                    }
                });
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case BOOK_STOCK_CRITERIA:
                if (response.data.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else if(response.data.errorStatus === Constants.status.codeNotAccess){
                    navigate(`/Client/ongoingassignment/${assignmentId}/${randomStringGenerator(30)}`);
                    setBookStockCriteriaLoading(false);
                }else {
                    setBookStockCriteriaLoading(false);
                    toast.error(response.data.message);
                }
                break;
            case ADD_BOOK_STOCK_MAPPING:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    setExistingRecord('');
                    setexistingRecordActualQty('');
                    setexistingRecordInactive('');
                    setexistingRecordQtyDiff('');
                    setexistingRecordQtyDiffActualQty('');
                    setnewRecordZeroQty('');
                    setExistingRecordDiffStockType('');
                    setExistingRecordDiffActualStockType('');
                    setExistingRecordDiffStockTypeInactive('');
                    toast.error(response.data.message);
                }
                break;
            case REMOVE_UPLOADED_BOOK_STOCK:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response.data.message);
                }
                break;
            default:
                break;
        }
    };
    
    // Makes an API request to remove the uploaded book stock
    const clearData = () => {
        new APIRequest.Builder()
            .post()
            .setReqId(REMOVE_UPLOADED_BOOK_STOCK)
            .reqURL(`bookstock/removeUploadedBookStock`)
            .jsonParams({
                assignmentId: parseInt(assignmentId),
                createdBy: checkFileProcessStatusData?.createdBy,
                bookStockFileHistoryId: checkFileProcessStatusData?.bookStockFileHistoryId
            })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }
    
    // Function to add a book stock mapping
    const addBookStockMapping = () => {
        let isError = false;
        let errorMessage = "";

        if (bookStockCriteriaData[0]?.totalNewRecordZeroQty != 0 && newRecordZeroQty === "") {
            isError = true;
            errorMessage = "New Record Zero Qty Required";
        }else if (bookStockCriteriaData[0]?.totalgetExistingRecord != 0 && existingRecord === "") {
            isError = true;
            errorMessage = "Existing Record Required";
        }else if(bookStockCriteriaData[0]?.totalExistingRecordQtyDiff != 0 && existingRecordQtyDiff === ""){
            isError = true;
            errorMessage = "Existing Record QtyDiff Required";
        }else if(bookStockCriteriaData[0]?.totalExistingRecordInactive != 0 && existingRecordInactive === ""){
            isError = true;
            errorMessage = "Existing Record Inactive Required";
        }else if(bookStockCriteriaData[0]?.totalexistingRecordActualQty != 0 && existingRecordActualQty === ""){
            isError = true;
            errorMessage = "Existing Record ActualQty Required";
        }else if(bookStockCriteriaData[0]?.totalexistingRecordDiffActualQty != 0 && existingRecordQtyDiffActualQty === ""){
            isError = true;
            errorMessage = "Existing Record QtyDiff ActualQty Required";
        }else if(bookStockCriteriaData[0]?.totalExistingRecordDiffStockType != 0 && existingRecordDiffStockType === ""){
            isError = true;
            errorMessage = "Existing Record Diff StockType Required";
        }else if(bookStockCriteriaData[0]?.totalExistingRecordDiffActualStockType != 0 && existingRecordDiffActualStockType === ""){
            isError = true;
            errorMessage = "Existing Record Diff Stocktype With Actual Quantities Required";
        }else if(bookStockCriteriaData[0]?.totalExistingRecordDiffStockTypeInactive != 0 && existingRecordDiffStockTypeInactive === ""){
            isError = true;
            errorMessage = "Existing Record Diff Stocktype Inactive Required";
        }
    
        setLoading(true);
        // If there's an error, show it and stop the process
         if (isError) {
            setErrors(errorMessage);
            setTimeout(() => {
                setErrors('');
            },Constants.timeOut.errorMsgTimeout);
            handleShowAlertAgain()
            setLoading(false);
        }
        // Otherwise, create a final value with all the required data
        else {
            const { assignmentId, createdBy, stockTypeId, storeTypeId, fileName, bookStockFileHistoryId } = checkFileProcessStatusData;
            let finalValue = {
                assignmentId: assignmentId,
                createdBy: createdBy,
                stockTypeId: stockTypeId,
                storeTypeId: storeTypeId,
                fileName: fileName,
                bookStockFileHistoryId: bookStockFileHistoryId,
                newRecordZeroQty: newRecordZeroQty,
                existingRecord: existingRecord,
                existingRecordQtyDiff: existingRecordQtyDiff,
                existingRecordInactive: existingRecordInactive,
                existingRecordActualQty: existingRecordActualQtyChecked ? "replaceAndDeleteActual" : existingRecordActualQty,
                existingRecordQtyDiffActualQty: existingRecordQtyDiffActualQtyChecked ? "replaceAndDeleteActual" : existingRecordQtyDiffActualQty,
                existingRecordDiffStockType: existingRecordDiffStockType,
                existingRecordDiffActualStockType: existingRecordDiffActualStockTypeChecked ? "replaceAndDeleteActual" : existingRecordDiffActualStockType,
                existingRecordDiffStockTypeInactive: existingRecordDiffStockTypeInactive
            }
            new APIRequest.Builder()
                .post()
                .setReqId(ADD_BOOK_STOCK_MAPPING)
                .reqURL(`bookstock/addBookStockMapping`)
                .response(onResponse)
                .jsonParams(finalValue)
                .error(onError)
                .timeout(180000)
                .build()
                .doRequest();
        }
    }

    // Function to handle the closing of an alert
    const handleAlertClose = () => {
        isErrorAlertShow(false);
    };

    // This function handles the display of an alert again.
    const handleShowAlertAgain = () => {
        isErrorAlertShow(true);
    };

    // Handle the criteria data for book stock based on the `bookStockCriteriaData` array.
    const handleCriteria = () => {
        dataSource = [];
        if(bookStockCriteriaData.length > 0){
            if(bookStockCriteriaData[0]?.totalRecords != 0){
                dataSource.push({
                    id: 1,
                    key: 'Total Records',
                    total: bookStockCriteriaData[0]?.totalRecords,
                    options: []
                })
            }
            if(bookStockCriteriaData[0]?.totalNewRecords != 0){
                dataSource.push({
                    id: 2,
                    key: 'New Records',
                    total: bookStockCriteriaData[0]?.totalNewRecords,
                    options: []
                })
            }
            if(bookStockCriteriaData[0]?.totalNewRecordZeroQty != 0){
                dataSource.push({
                    id: 3,
                    key: 'New Records with Zero quantity',
                    total: bookStockCriteriaData[0]?.totalNewRecordZeroQty,
                    options: [
                        {
                            id: 'newRecordZeroQty',
                            checked: newRecordZeroQty === "upload",
                            action: 'Upload',
                            value: 'upload',
                            type: 'radio'
                        },
                        {
                            id: 'newRecordZeroQty',
                            checked: newRecordZeroQty === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalgetExistingRecord != 0){
                dataSource.push({
                    id: 4,
                    key: 'Existing Records',
                    total: bookStockCriteriaData[0]?.totalgetExistingRecord,
                    options: [
                        {
                            id: 'existingRecord',
                            checked: existingRecord === "replace",
                            action: 'Replace',
                            value: 'replace',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecord',
                            checked: existingRecord === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalExistingRecordQtyDiff != 0){
                dataSource.push({
                    id: 5,
                    key: 'Existing Records (Book quantity does not match with uploaded file.)',
                    total: bookStockCriteriaData[0]?.totalExistingRecordQtyDiff,
                    options: [
                        {
                            id: 'existingRecordQtyDiff',
                            checked: existingRecordQtyDiff === "replace",
                            action: 'Replace',
                            value: 'replace',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordQtyDiff',
                            checked: existingRecordQtyDiff === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalexistingRecordActualQty != 0){
                let optionData = [];
                optionData.push({
                    id: 'existingRecordActualQty',
                    checked: existingRecordActualQty === "replaceKeepActual",
                    action: 'Replace Existing',
                    value: 'replaceKeepActual',
                    type: 'radio'
                })
                if(existingRecordActualQty === "replaceKeepActual"){
                    optionData.push({
                        id: 'existingRecordActualQty',
                        checked: false,
                        action: 'Delete Actual',
                        value: 'replaceAndDeleteActual',
                        type: 'checkbox'
                    })
                }
                optionData.push({
                    id: 'existingRecordActualQty',
                    checked: existingRecordActualQty === "discard",
                    action: 'Discard',
                    value: 'discard',
                    type: 'radio'
                })
                dataSource.push({
                    id: 6,
                    key: 'Existing Records & Actual Stock recorded.',
                    total: bookStockCriteriaData[0]?.totalexistingRecordActualQty,
                    options: optionData
                })
            }
            if(bookStockCriteriaData[0]?.totalexistingRecordDiffActualQty != 0){
                let optionData = [];
                optionData.push({
                    id: 'existingRecordQtyDiffActualQty',
                    checked: existingRecordQtyDiffActualQty === "replaceKeepActual",
                    action: 'Replace Existing',
                    value: 'replaceKeepActual',
                    type: 'radio'
                })
                if(existingRecordQtyDiffActualQty === "replaceKeepActual"){
                    optionData.push({
                        id: 'existingRecordQtyDiffActualQty',
                        checked: false,
                        action: 'Delete Actual',
                        value: 'replaceAndDeleteActual',
                        type: 'checkbox'
                    })
                }
                optionData.push({
                    id: 'existingRecordQtyDiffActualQty',
                    checked: existingRecordQtyDiffActualQty === "discard",
                    action: 'Discard',
                    value: 'discard',
                    type: 'radio'
                })
                dataSource.push({
                    id: 7,
                    key: 'Existing records and actual stock recorded (Book quantity does not match with uploaded file.)',
                    total: bookStockCriteriaData[0]?.totalexistingRecordDiffActualQty,
                    options: optionData
                })
            }
            if(bookStockCriteriaData[0]?.totalExistingRecordInactive != 0){
                dataSource.push({
                    id: 8,
                    key: 'Inactive Existing Records',
                    total: bookStockCriteriaData[0]?.totalExistingRecordInactive,
                    options: [
                        {
                            id: 'existingRecordInactive',
                            checked: existingRecordInactive === "replace",
                            action: 'Replace and Make Active',
                            value: 'replace',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordInactive',
                            checked: existingRecordInactive === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalExistingRecordDiffStockTypeInactive != 0){
                dataSource.push({
                    id: 9,
                    key: 'Inactive Existing Record (Different Stock Type)',
                    total: bookStockCriteriaData[0]?.totalExistingRecordDiffStockTypeInactive,
                    options: [
                        {
                            id: 'existingRecordDiffStockTypeInactive',
                            checked: existingRecordDiffStockTypeInactive === "replace",
                            action: 'Replace and Make Active',
                            value: 'replace',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordDiffStockTypeInactive',
                            checked: existingRecordDiffStockTypeInactive === "create",
                            action: 'Create New',
                            value: 'create',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordDiffStockTypeInactive',
                            checked: existingRecordDiffStockTypeInactive === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalExistingRecordDiffStockType != 0){
                dataSource.push({
                    id: 10,
                    key: 'Existing Record (Different Stock Type)',
                    total: bookStockCriteriaData[0]?.totalExistingRecordDiffStockType,
                    options: [
                        {
                            id: 'existingRecordDiffStockType',
                            checked: existingRecordDiffStockType === "replace",
                            action: 'Replace Existing',
                            value: 'replace',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordDiffStockType',
                            checked: existingRecordDiffStockType === "create",
                            action: 'Create New',
                            value: 'create',
                            type: 'radio'
                        },
                        {
                            id: 'existingRecordDiffStockType',
                            checked: existingRecordDiffStockType === "discard",
                            action: 'Discard',
                            value: 'discard',
                            type: 'radio'
                        }
                    ]
                })
            }
            if(bookStockCriteriaData[0]?.totalExistingRecordDiffActualStockType != 0){
                let optionData = [];
                optionData.push(
                    {
                        id: 'existingRecordDiffActualStockType',
                        checked: existingRecordDiffActualStockType === "replaceKeepActual",
                        action: 'Replace Existing',
                        value: 'replaceKeepActual',
                        type: 'radio'
                    },
                    {
                        id: 'existingRecordDiffActualStockType',
                        checked: existingRecordDiffActualStockType === "create",
                        action: 'Create New',
                        value: 'create',
                        type: 'radio'
                    },
                )
                if(existingRecordDiffActualStockType == "replaceKeepActual"){
                    optionData.push({
                        id: 'existingRecordDiffActualStockType',
                        checked: false,
                        action: 'Delete Actual',
                        value: 'replaceAndDeleteActual',
                        type: 'checkbox'
                    })
                }
                optionData.push({
                    id: 'existingRecordDiffActualStockType',
                    checked: existingRecordDiffActualStockType === "discard",
                    action: 'Discard',
                    value: 'discard',
                    type: 'radio'
                })
                dataSource.push({
                    id: 11,
                    key: 'Existing Record (Different stock type with actual quantities)',
                    total: bookStockCriteriaData[0]?.totalExistingRecordDiffActualStockType,
                    options: optionData
                })
            }
        }
        return dataSource;
    }

    // Defines the schema of the table used to display the book stock criteria.
    const bookstockTable = [
        {
            title: `Types of Records`,
            dataIndex: 'key',
            key: 'typesOfRecords',
            width: '30%'
        },
        {
            title: `Records`,
            dataIndex: 'total',
            key: 'total',
            width: '15%'
        },
        {
            title: `Radio Buttons for following items.`,
            dataIndex: 'options',
            key: 'options',
            width: '35%',
            render: (text, record) => {
                return(
                    <>
                        {
                            record.options.length > 0 && record.options.filter(item => item.type == 'checkbox').length > 0 &&
                            record.options.map((item,index)=>{
                                return item.type == 'checkbox' && <Checkbox value={item.value} defaultChecked={item.checked} id={item.id} onChange={handleCheckboxOnChange}>{item.action}</Checkbox>
                            })
                        }
                        {record.options.length > 0 && record.options.filter(item => item.type == 'radio').length > 0 &&
                            <Radio.Group onChange={handleRadioOnChange}>
                                {record.options.map((item,index)=>{
                                    return item.type == 'radio' && <Radio value={item.value} checked={item.checked} id={item.id}>{item.action}</Radio>
                                })}
                            </Radio.Group>
                        }
                    </>
                )
            },
        }
    ]
    
    return (
        <>
            {componentDidMountFlag && bookStockCriteriaData.length > 0 && 
                <>
                    <p className='text-center mb-3'>Please select the relevant type of records to be uploaded or updated.</p>
                    {errorAlertShow && errors && 
                        <CommonAlert 
                            message={errors}
                            type="error"
                            closable
                            onClose={handleAlertClose}
                        />
                    }
                    <Table
                        columns={bookstockTable}
                        dataSource={handleCriteria()}
                        pagination={false}
                        className="custom-table"
                        style={{marginTop: 10}}
                    />
                </>
            }
            {componentDidMountFlag && bookStockCriteriaData.length == 0 && 
                <div style={{alignItems:'center',justifyContent:'center',display:'flex',minHeight: '60vh'}}>
                    <NoRecords />
                </div>
            }
            {bookStockCriteriaLoading && 
                <CommonLoader loading={bookStockCriteriaLoading} />
            }
            
            {componentDidMountFlag && bookStockCriteriaData.length > 0 &&
                <div className='text-center'>
                    <CommonButton
                        label={"Cancel"}
                        onClick={() => navigate(`/Client/ongoingassignment/${assignmentId}`,{
                            state: {
                                assignmentListDetails: assignment
                            }
                        })}
                        style={{ marginRight: 10, borderRadius: 5, height: 32 }}
                    />
                    <CommonButton
                        label={"Clear Data"}
                        onClick={clearData}
                        style={{ marginRight: 10, borderRadius: 5, height: 32 }}
                        loading={clearLoading}
                    />
                    <CommonButton
                        label={"Upload"}
                        onClick={addBookStockMapping}
                        style={{ borderRadius: 5, height: 32 }}
                        loading={loading}
                    />
                </div>
            }
        </>
    );
}

export default Bookstocktable;