import {
    SET_TOKEN,
    SET_USER,
    UPDATE_CREDIT

} from "./action-types";

import {REHYDRATE} from 'redux-persist/lib/constants';
let initialState = {
    user: null,
    token: null,
    credit:false
};

export const setToken = (token) => ({type: SET_TOKEN, token});
export const setUser = (user) => ({type: SET_USER, user});

export const logout = () => ({type: REHYDRATE, payload: initialState});
export const credit = (credit) => ({type:UPDATE_CREDIT, credit});
