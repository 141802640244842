import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APIRequest, LIST_BOOKSTOCK, LIST_STOCK, DELETE_BOOKSTOCK, DELETE_BOOKSTOCKS, REACTIVATE_BOOKSTOCK, BOOKSTOCK_MAP_FIELDS } from '../../../api';
import { Table } from '../../Table/Table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Bookstockitem from './bookstockitem';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import ReusableModal from '../../Modal/reusablemodel';
import { toast } from 'react-toastify';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../Component/common/Global/commonFunctions';
import AddBookStock from './addBookStock';
import EditMapFieldModal from './editMapFieldModal';
import AssignmentListDetails from '../../../Component/common/assignmentListDetails/assignmentListDetails';
import ActualStockModal from '../actualStockModal';
import CommonLoader from '../../../commonComponent/loader/commonLoader';

const Bookstockdetails = () => {
  const location = useLocation();
  const assignment = location?.state?.assignmentListDetails;
  const status = location?.pathname?.split('/')[2] == "ongoingassignment" ? "Started" : "completed";
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [reConfrimDialog, setReConfrimDialog] = useState(false);
  const [reConfrimDialog1, setReConfrimDialog1] = useState(false);
  const [reActiavte, setReActivate] = useState(false);
  const [bookstock, Setbookstock] = useState({});
  const [download, Setdownload] = useState([]);
  const [detailsshow, Setdetailsshow] = useState(false);
  const [details, Setdetails] = useState([]);
  const { assignmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bookstockdetails, Setbookstockdetails] = useState();
  const user = useSelector((state) => state?.user);
  const formatter = new Intl.NumberFormat('en-IN');
  const [stockmodal, Setstockmodal] = useState(false);
  const [userdetails, setuserDetails] = useState([]);
  const [clientdetails, setclientDetails] = useState([]);
  const [storeStockDetails, setStoreStockDetails] = useState([]);
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [searchStock, setSearchStock] = useState('');
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const store = storeId;
  const stock = stockId;
  const [stockData, SetstockData] = useState([])
  const [bookstockId, setBookstockId] = useState()
  const [message, setMessage] = useState([])
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [bookStockDetailsLoading, setBookStockDetailsLoading] = useState(false);
  const [bookStockActive, setBookStockactive] = useState(true);
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [addBookstockDialog, setAddBookStockDialog] = useState(false);
  const [mapFieldModal, setMapFieldModal] = useState(false);
  const [singleBookStockData, setSingleBookStockData] = useState({});
  const [deleteBookStockLoading, setDeleteBookStockLoading] = useState(false);
  const columns = [
    {
      dataField: "index",
      text: "No",
      headerClasses: "sr_bookstock_no",
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "partNo1",
      text: "Part No.",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "materialName",
      text: "Name",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "uom",
      text: "UOM",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "bookstockquantity",
      text: "Book Qty",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "value",
      text: "Value",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          Setdetails(row); Setdetailsshow(true)
        },
      }
    },
    {
      dataField: "actual",
      text: "Actual",
      style: { cursor: "pointer" },
      formatter: (cell, e) => {
        return(
          e.ActualStockMap.length > 0 ?
          <h4 style={{padding:0, margin:0}}>&#10003;</h4>
          : ""
        )
      }
    },
    {
      dataField: "isSample",
      text: "Sample",
      style: { cursor: "pointer" },
      formatter: (cell, e) => {
        return(
          e.isSample == "true" ?
          <h4 style={{padding:0, margin:0}}>&#10003;</h4>
          : ""
        )
      }
    },
    {

      dataField: "action",
      text: "Action",
      headerClasses: "action",
      style: { cursor: "pointer" },
      formatter: (cell, e) => {
        return (
          <div className="edit_icons">
            <div className="dropdown">
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className="fa fa-ellipsis-v"
                  aria-hidden="true"
                ></i>
              </button>
              <ul
                className="dropdown-menu p-1"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      Setstockmodal(true);
                      Setbookstockdetails(e)
                    }}
                  >
                    <i className={e.isActive == "true" ?  "fa fa-pencil me-1" : "fa fa-eye me-1"} aria-hidden="true"></i>
                    Actual Stock
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setMapFieldModal(true);
                      setSingleBookStockData(e);
                    }}
                  >
                    <i className={e.isActive == "true" ?  "fa fa-pencil me-1" : "fa fa-eye me-1"} aria-hidden="true"></i>
                    Single BookStock
                  </a>
                </li>
                {e?.isActive === "true" ? <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setBookstockId(e?.bookStockId)
                      deleteBookStock(e?.bookStockId);
                    }}
                  >
                    <i className="fa fa-trash me-1" aria-hidden="true"></i>
                    Delete
                  </a>
                </li> : ""}
                {e?.isActive === "false" ? <li>
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      setReActivate(true);
                      setBookstockId(e?.bookStockId)
                    }}
                  >
                    <i className="fa fa-repeat me-1" aria-hidden="true"></i>
                    Reactivate
                  </a>
                </li> : ""}
              </ul>
            </div>
          </div>
        );
      },
    }
    ,
  ];
  const handleDeleteBookstockDialog = () => {
    setConfrimDialog(false);
    setReConfrimDialog1(true);
  }
  const deleteBookStock = (bookStockId) => {
    new APIRequest.Builder()
      .delete()
      .setReqId(DELETE_BOOKSTOCK)
      .reqURL(`bookstock/deleteBookStock/${bookStockId}?isDelete=false`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  const deleteBookstockConfrim = () => {
    setDeleteBookStockLoading(true);
    new APIRequest.Builder()
      .delete()
      .setReqId(DELETE_BOOKSTOCKS)
      .reqURL(`bookstock/deleteBookStock/${bookstockId}?isDelete=true`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  const reactivateBookStock = () => {
    setDeleteBookStockLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(REACTIVATE_BOOKSTOCK)
      .reqURL(`bookstock/reactivateBookStock/${bookstockId}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  const getListOfBookstock = () => {
    setBookStockDetailsLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_BOOKSTOCK)
      .reqURL(`bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=${page}&limit=15`)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  const getBookstockActiveInactiveList = (bookStockActive = true) => {
    let url = bookStockActive ? `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15` : `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15&isActive=false`;
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    if(storeId){
      url = url + `&storeType=${storeId}`
    }
    if(stockId){
      url = url + `&stockType=${stockId}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_BOOKSTOCK)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  useEffect(() => {
    getListOfBookstock();

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_STOCK)
      .reqURL(`bookstock/${assignmentId}/getFilterStoreStockList`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getStoreStockFilterList = (storeId, stockId) => {
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15&stockType=${stockId}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}&stockType=${stockId}`;
    }
    if(selectedOption == "Inactive"){
      url = url + `&isActive=false`;
    }else{
      url = url;
    }
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_BOOKSTOCK)
      .jsonParams({ userId: user?.userId, status: status })
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  useEffect(() => {
    Setdownload(bookstock?.records?.map((item, index) => {
      return {
        key: index,
        No: index + 1,
        PartNumer: item?.partNo1 ?? "",
        SubPartNo1: item?.partNo2 ?? "",
        SubPartNo2: item?.partNo3 ?? "",
        SubPartNo3: item?.partNo4 ?? "",
        SubPartNo4: item?.partNo5 ?? "",
        StockType: item?.stockType ?? "",
        Materialname: item?.materialName ?? "",
        MaterialSubname1: item?.materialName1 ?? "",
        MaterialSubname2: item?.materialName2 ?? "",
        MaterialSubname3: item?.materialName3 ?? "",
        MaterialSubname4: item?.materialName4 ?? "",
        Location: item?.location1 ?? "",
        Location1: item?.Location2 ?? "",
        Location2: item?.location3 ?? "",
        Location3: item?.location4 ?? "",
        Location4: item?.location5 ?? "",
        UOM: item?.uom ?? "",
        BookQuantity: item?.quantity ?? "-",
        Rate: item?.rate ?? "-",
        Value: item?.value ?? "-",
        ExpiryDate: item?.expiryDate ?? "",
        LastReceiptDate: item?.lastReceiptDate ?? "",
        LastIssueDate: item?.lastIssueDate ?? "",
        Remarks: item?.remarks ?? ""

      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookstock])

  const [fileName, setFileName] = useState("Bookstock Reports");
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(download);
    const wb = { Sheets: { 'BookStock': ws }, SheetNames: ['BookStock'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_BOOKSTOCK:
        Setbookstock(response.data?.data);
        isComponentDidMountFlag(true);
        setBookStockDetailsLoading(false);
        document.body.style = '';
        break;
      case LIST_STOCK:
        SetstockData(response.data?.storeType);
        break;
      case DELETE_BOOKSTOCK:
        break;
      case REACTIVATE_BOOKSTOCK:
        setReActivate(false);
        setBookstockId();
        toast.success(`${response?.data?.data}`);
        clearFilter();
        setDeleteBookStockLoading(false);
        break;
      case DELETE_BOOKSTOCKS:
        setReConfrimDialog1(false);
        setReConfrimDialog(false);
        setBookstockId();
        getListOfBookstock();
        toast.success(`${response?.data?.data}`);
        setDeleteBookStockLoading(false);
        break;
      default:
        break;
    }
  }

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_STOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      case LIST_BOOKSTOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
          setBookStockDetailsLoading(false);
          navigate(`/Client/ongoingassignment/${assignmentId}/bookstockdetails/${randomStringGenerator(30)}`);
        }else{
          setBookStockDetailsLoading(false);
        }
        break;
      case DELETE_BOOKSTOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setMessage(response?.data?.message);
          if (response?.data?.message == "") {
            setReConfrimDialog(true);
          } else {
            setConfrimDialog(true);
          }
        }
        break;
      case REACTIVATE_BOOKSTOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setMessage(response?.data?.message);
          setDeleteBookStockLoading(false);
        }
        break;
      case DELETE_BOOKSTOCKS:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setReConfrimDialog1(false);
          setReConfrimDialog(false);
          setDeleteBookStockLoading(false);
        }
        break;
      default:
        break;
    }
  }

  const handleTableChange = (type, newState) => {
    setPage(newState.page);
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&storeType=${storeId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getBookStockByAssignment/${assignmentId}?type=list&storeType=${storeId}&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    }
    if(selectedOption == "Inactive"){
      url = url + `&isActive=false`
    }
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if(!isEmptyVariable(newState?.searchText)){
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if(!isEmptyVariable(newState?.sortField)){
      setSort(newState?.sortField);
      if (newState?.sortField === "PartNo") {
        url = url + `&sortColumn=partNo1`
      }
      if (newState?.sortField === "Materialname") {
        url = url + `&sortColumn=materialName`
      }
      if (newState?.sortField === "uom") {
        url = url + `&sortColumn=uom`
      }
      if (newState?.sortField === "rate") {
        url = url + `&sortColumn=rate`
      }
      if (newState?.sortField === "value") {
        url = url + `&sortColumn=value`
      }
      if (newState?.sortField === "bookstockquantity") {
        url = url + `&sortColumn=quantity`
      }
      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }else{
      setSort('');
      setSortOrder('');
    }

    new APIRequest.Builder()
      .post()
      .setReqId(LIST_BOOKSTOCK)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  };

  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockId, "label": stockItem?.stockTypeName });
    });
  });

  // onchange event for store
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setSearchStock('search');
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "")
      setSelectedStoreType(selected.label)
    }
    showStoreTypeDropdownItem(false);
  };

  // onchange event for stock
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setSearchStock('search');
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value)
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };
  
  const handleActiveInactiveChange = (selected) => {
    selected.status === "Inactive" ? setBookStockactive(false) : setBookStockactive(true);
    getBookstockActiveInactiveList(selected.status === "Inactive" ? false : true)
    setSelectedOption(selected.status)
    showDropdownItem(false)
  }

  const handleOnClose = (reload) => {
    setAddBookStockDialog(false);
    if(reload){
      getListOfBookstock();
    }
  }

  const handleMapFieldModalOnClose = (reload) => {
    setMapFieldModal(false);
    setBookstockId();
    if(reload){
      getListOfBookstock();
    }
  }

  const clearFilter = () => {
    isComponentDidMountFlag(false);
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setBookStockactive(true);
    setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
    setSearch('');
    setSort('');
    setSortOrder('');
    Setbookstock({});
    getListOfBookstock();
  }

  const handleActualStockModalClose = (reload) => {
    Setstockmodal(false);
    if(reload){
      getListOfBookstock();
    }
  }

  return (
    <>
      {assignment && 
        <AssignmentListDetails
          assignment={assignment}
        />
      }
      {bookStockDetailsLoading &&
        <CommonLoader loading={bookStockDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
            <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/ongoingassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
            <a className='btn btn-primary' href="bookstockdetails">BookStock</a>
          </div>

          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
              {componentDidMountFlag &&
                <>
                  <div className='deactivelist bookstock_active'>
                    <RegularDropDown 
                      placeholder={selectedOption}
                      dropdownArr={Constants.activeInactiveStatus}
                      labelParam="status"
                      onDropDownItemClick={handleActiveInactiveChange}
                      isFocusRequired={true}
                      show={isShowDropDownItem}
                      onToggle={(isOpen) => showDropdownItem(isOpen)}
                      defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                    />
                  </div>
                  <div className="add_btns bookstock_dropdown_width bookstock_btn">
                    {!isEmptyArray(bookstock?.records) && (search != "" || sort != "" || storeId != "" || stockId != "" || selectedOption == "Inactive") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                    }
                    <RegularDropDown 
                      placeholder={selectedStoreType}
                      dropdownArr={storeOptions}
                      labelParam="label"
                      onDropDownItemClick={handleStoreChange}
                      isFocusRequired={true}
                      show={isShowStoreTypeDropDownItem}
                      onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                      defaultPlaceholderDropDown={"Select Store Type"}
                    />
                    <RegularDropDown 
                      placeholder={selectedStockType}
                      dropdownArr={stockOptions}
                      labelParam="label"
                      onDropDownItemClick={handleStockChange}
                      isFocusRequired={true}
                      show={isShowStockTypeDropDownItem}
                      onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                      defaultPlaceholderDropDown={"Select Stock Type"}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={(e) => exportToCSV()}
                    >
                      <i
                        className="fa fa-download"
                        aria-hidden="true"
                      ></i>{" "}
                      Download
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => setAddBookStockDialog(true)}
                    >
                      <i
                        className="fa fa-plus-circle"
                        aria-hidden="true"
                      ></i>{" "}
                      Add New
                    </button>
                  </div>
                  <i className="fa fa-search search_icon" aria-hidden="true"></i>
                </>
              }
              {componentDidMountFlag && bookstock?.records && (
                  <Table
                    // key={assignment?.assignmentId ?? "bs-table"}
                    data={bookstock?.records?.map((item, index) => {
                      return {
                        ...item,
                        key: index,
                        index: (bookstock?.page - 1) * bookstock?.limit + index + 1,
                        id: item.id,
                        partNo1: item?.partNo1 ?? "-",
                        materialName: item?.materialName ?? "-",
                        uom: item?.uom ?? "-",
                        bookstockquantity: isEmptyVariable(item?.quantity) ? "-" : item?.quantity,
                        rate: isEmptyVariable(item?.rate) ? "-" : formatter.format(item?.rate),
                        value: isEmptyVariable(item?.value) ? "-" : formatter.format(item?.value),
                      };
                    }) ?? []}
                    columns={columns.filter((column) => column !== null)}
                    handleTableChange={handleTableChange}
                    totalSize={bookstock?.total ?? 0}
                    currentPage={bookstock?.page ?? 1}
                    sizePerPage={bookstock?.limit ?? 15}
                  />
                )
              }
              {componentDidMountFlag && isEmptyArray(bookstock?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      <Bookstockitem bookStockDetailsShow={detailsshow} setBookStockDetailsShow={Setdetailsshow} details={details} />
      {stockmodal &&
        <ActualStockModal
          handleActualStockModalClose={handleActualStockModalClose} 
          showActualStockModal={stockmodal} 
          bookStockDetails={bookstockdetails} 
          clientName={assignment?.clientName}
        />
      }
      <AddBookStock handleOnClose={handleOnClose} addBookstockDialog={addBookstockDialog} assignmentId={assignmentId}/>
      {mapFieldModal && 
        <EditMapFieldModal mapFieldModal={mapFieldModal} setMapFieldModal={handleMapFieldModalOnClose} singleBookStockData={singleBookStockData} />
      }
      <ReusableModal
        show={confrimDialog}
        onClose={() => setConfrimDialog(false)}
        buttonOk={"OK"}
        buttonCancel={"Cancel"}
        onConfirm={handleDeleteBookstockDialog}
        message={message}
      />
      <ReusableModal
        show={reConfrimDialog1}
        onClose={() => setReConfrimDialog1(false)}
        loading={deleteBookStockLoading}
        onConfirm={deleteBookstockConfrim}
        message={"Are you sure, you want to delete book stock and actual stock?"}
      />
       <ReusableModal
        show={reConfrimDialog}
        onClose={() => setReConfrimDialog(false)}
        loading={deleteBookStockLoading}
        onConfirm={deleteBookstockConfrim}
        message={"Are you sure, you want to delete this material?"}
      />
      {reActiavte &&
        <ReusableModal
          show={reActiavte}
          onClose={() => setReActivate(false)}
          loading={deleteBookStockLoading}
          onConfirm={reactivateBookStock}
          message={"Are you sure, you want to reactivate this book stock?"}
        />
      }
    </>
  )
}

export default Bookstockdetails