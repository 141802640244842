import React, { useState, useEffect } from 'react';
import { APIRequest, CONFIGS_LIST, ACCOUNTLIST } from '../../../../api'
import moment from "moment";
import { Addconfig } from './addconfig';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Table } from '../../../Table/Table';
import "./account.css";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { logout } from '../../../../redux/action';
import RegularDropDown from '../../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../../Component/common/Global/constants";
import { isEmptyArray } from '../../../../Component/common/Global/commonFunctions';

const Accounts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addconfig, setaddConfig] = useState(false);
    const [config, SetConfig] = useState();
    const [account, SetAccount] = useState();
    const [startdate, setstartdate] = useState("");
    const [todate, settodate] = useState("");
    const { clientId } = useParams();
    var date = new Date();
    const today = new Date();
    var currentDate = new Date();
    const [date1, setdate] = useState("");
    const quarter = Math.floor((today.getMonth() / 3));
    var lastyear = new Date(new Date().getFullYear() - 1, 0, 1);
    //currant month
    const currantmonthstart = moment().startOf('month').format('YYYY-MM-DD');
    const currantmonthend = moment().endOf('month').format('YYYY-MM-DD');
    //prev month
    var prevmonthstart = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    var prevmonthend = new Date(date.getFullYear(), date.getMonth(), 0)
    //currant quater
    const Quarterstart = new Date(today.getFullYear(), quarter * 3, 1);
    const Quarterend = new Date(Quarterstart.getFullYear(), Quarterstart.getMonth() + 3, 0);
    // prev quater
    const prevQuarterstart = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const prevQuarterend = new Date(prevQuarterstart.getFullYear(), prevQuarterstart.getMonth() + 3, 0)
    //currant year
    var currantyearFirst = new Date(currentDate.getFullYear(), 0, 1);
    var currantyearlast = new Date(currentDate.getFullYear(), 11, 31);
    // prev year
    var lastyearfirst = (new Date(lastyear.getFullYear(), 0, 1));
    var lastyearlast = (new Date(lastyear.getFullYear(), 11, 31));
    const user = useSelector((state) => state.user);
    let userId = user.id
    const [buycreditmodal, setbuycreditmodalShow] = useState(false);
    const [billsAndPayments, SetbillsAndPaymensts] = useState();
    const [selectedOption, setSelectedOption] = useState(Constants.placeholder.monthPlaceholder);
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [componentDidMountConfigFlag, isComponentDidMountConfigFlag] = useState(false);
    const [componentDidMountAccountFlag, isComponentDidMountAccountFlag] = useState(false);

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(ACCOUNTLIST)
            .reqURL(`/billsAndPayments/getAccount/${user?.organisationId}?&filterTimePeriod=currentMonth&page=1&limit=15`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }, [])


    useEffect(() => {
        new APIRequest.Builder()
            .post()
            .setReqId(CONFIGS_LIST)
            .reqURL("/subscriptionRates/getSubscriptionRatesList?rateType=perDay&page=1&limit=15")
            .jsonParams({
                "rateType": "perDay"
            })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
        new APIRequest.Builder()
            .get()
            .setReqId(ACCOUNTLIST)
            .reqURL(`/billsAndPayments/getAccount/${user?.organisationId}?&filterTimePeriod=currentMonth&page=1&limit=15`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
        if (clientId === undefined) {
            new APIRequest.Builder()
                .get()
                .setReqId(ACCOUNTLIST)
                .reqURL(`billsAndPayments/getBillAndPaymentList/${user.organisationId}?filterTimePeriod=currentMonth`)
                .jsonParams(userId)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()

        } else {
            new APIRequest.Builder()
                .get()
                .setReqId(ACCOUNTLIST)
                .reqURL(`billsAndPayments/getBillAndPaymentList/${user?.organisationId}?filterTimePeriod=currentMonth`)
                .jsonParams(userId)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        }
    }
        , [addconfig])


    const onResponse = (response, reqId) => {
        switch (reqId) {
            case CONFIGS_LIST:
                SetConfig(response.data?.data)
                isComponentDidMountConfigFlag(true);
                break;
            case ACCOUNTLIST:
                SetAccount(response.data?.data)
                isComponentDidMountAccountFlag(true);
                break;
            // case ACCOUNTLIST:
            //     SetbillsAndPaymensts(response.data?.data);
            //     break;
            default:
                break;
        }
    }


    const onError = (response, reqId) => {
        switch (reqId) {
            case CONFIGS_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case ACCOUNTLIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            // case ACCOUNTLIST:
            // break;
            default:
                break;
        }
    }


    const handleTableChange = (type, newState) => {
        let url = `subscriptionRates/getSubscriptionRatesList?rateType=perDay&page=${type === "search" || type === "sort" ? "1" : newState.page
            }&`;
        if (type === "search" && newState.searchText) {
            url = url + `search=${encodeURIComponent(newState.searchText)}&`;
        }
        if (type === "sort") {
            if (newState.sortField === "value") {
                url = url + `sortColumn=rateAmount&`
            }
            if (newState.sortOrder) {
                url = url + `sortType=${newState.sortOrder}&`
            }
        }

        if (newState.sizePerPage) {
            url = url + `limit=${newState.sizePerPage}&`;
        }
        new APIRequest.Builder()
            .post()
            .setReqId(CONFIGS_LIST)
            .reqURL(url)
            .jsonParams({
                "rateType": "perDay"
            })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    };

    const handleTableChange1 = (transactionType, newState) => {
        let url = `billsAndPayments/getAccount/${user?.organisationId}?filterTimePeriod=${date1}&page=${transactionType === "search" || transactionType === "sort" ? "1" : newState.page
            }&`;
        if (transactionType === "search" && newState.searchText) {
            url = url + `search=${encodeURIComponent(newState.searchText)}&`;
        }
        if (transactionType === "sort") {
            if (newState.sortField === "transactionType") {
                url = url + `sortColumn=transactionType&`
            }
            if (newState.sortField === "credits") {
                url = url + `sortColumn=numberOfCredits&`
            }
            if (newState.sortField === "balance") {
                url = url + `sortColumn=numberOfBalance&`
            }
            if (newState.sortOrder) {
                url = url + `sortType=${newState.sortOrder}&`
            }
        }
        if (newState.sizePerPage) {
            url = url + `limit=${newState.sizePerPage}&`;
        }

        new APIRequest.Builder()
            .get()
            .setReqId(ACCOUNTLIST)
            .reqURL(url)
            .jsonParams(userId)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    };

    const dateTableHandler = (date) => {
        setdate(date);
        let url = "";
        if (date === "currentMonth") {
            url = `billsAndPayments/getAccount/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "previousMonth") {
            url = `billsAndPayments/getAccount/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "currentQuarter") {
            url = `billsAndPayments/getAccount/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "previousQuarter") {
            url = `billsAndPayments/getAccount/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "currentYear") {
            url = `billsAndPayments/getAccount/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        } if (date === "previousYear") {
            url = `billsAndPayments/getBillAndPaymentList/${user.organisationId}?&filterTimePeriod=${date}&page=1&limit=15`
        }
        new APIRequest.Builder()
            .get()
            .setReqId(ACCOUNTLIST)
            .reqURL(url)
            .jsonParams(userId)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()

    }
    const handleSubmit = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(ACCOUNTLIST)
            .reqURL(`billsAndPayments/getBillAndPaymentList/${user.organisationId}?fromDate=${startdate}&toDate=${todate}&page=1&limit=15`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()

    }
    const location = useLocation();
    let locat = location.pathname.slice(0, 10);

    const handleMonthChange = (selected) => {
        let value = selected.value;
        setSelectedOption(value)
        if(value == "Current Month"){
            dateTableHandler("currentMonth")
        }else if(value == "Previous Month"){
            dateTableHandler("previousMonth")
        }else if(value == "Current Quarter"){
            dateTableHandler("currentQuarter")
        }else if(value == "Previous Quarter"){
            dateTableHandler("previousQuarter")
        }else if(value == "Current Year"){
            dateTableHandler("currentYear")
        }else if(value == "Previous Year"){
            dateTableHandler("previousYear")
        }
        showDropdownItem(false)
    }

    return (
        <>
            <>

                {/* <div className=''> */}
                    <div className='main_tabs'>
                        <div className='master_box'>
                            <div className='master_boxinfo'>
                                <Tabs defaultActiveKey="first">
                                    <Tab eventKey="first" title="Rate">

                                        <div className='table-responsive pt-2 pb-5 mt-2 account-table'>
                                            <div className='add_btns'>
                                                <button type="button" variant="primary" className='btn btn-primary' data-bs-toggle="#modal" onClick={() => setaddConfig(true)}><i className="fa fa-plus-circle" aria-hidden="true"></i>&nbsp;Set Rate</button>
                                            </div>
                                            {componentDidMountConfigFlag && config?.records && (
                                                <Table
                                                    data={config?.records?.map((item, index) => {
                                                        return {
                                                            ...item,
                                                            key: index + 1,
                                                            index: (config?.page - 1) * config?.limit + index + 1,
                                                            id: item?.rateId,
                                                            value: item?.rateAmount,
                                                            createdate: moment(item?.createdAt).utc().format('DD-MM-YYYY') ?? "-",
                                                            enddate: item.rateEndDate ? moment(item?.rateEndDate).utc().format('DD-MM-YYYY') : "-",
                                                        };
                                                    })}
                                                    columns={[
                                                        {
                                                            dataField: "index",
                                                            text: "No",
                                                            headerClasses: "sr_no",
                                                        },
                                                        {
                                                            dataField: "value",
                                                            text: "Value",
                                                            sort: true,
                                                        },
                                                        {
                                                            dataField: "createdate",
                                                            text: "Create Date",
                                                            sort: false,
                                                        },
                                                        {
                                                            dataField: "enddate",
                                                            text: "End Date",
                                                            sort: false,
                                                        },
                                                        {
                                                            dataField: "action",
                                                            text: "",
                                                            headerClasses: "action",
                                                            style: { cursor: "pointer",  },
                                                            formatter: (cell, e) => {
                                                            return(
                                                                <div className="edit_icons">
                                                                    <div className="dropdown">
                                                                        <button
                                                                            className="btn btn-secondary dropdown-toggle"
                                                                            type="button"
                                                                            id="dropdownMenuButton1"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i
                                                                                className="fa fa-ellipsis-v"
                                                                                aria-hidden="true"
                                                                                style={{color:'#fff'}}
                                                                            ></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )
                                                            }
                                                        }
                                                    ]}
                                                    handleTableChange={handleTableChange}
                                                    totalSize={config?.total ?? 0}
                                                    currentPage={config?.page ?? 1}
                                                    sizePerPage={config?.limit ?? 15}

                                                />
                                            )}
                                            {componentDidMountConfigFlag && isEmptyArray(config?.records) && 
                                                <div className="no-items-layout">
                                                    <div className="no-items-card">
                                                        <h6>
                                                        {
                                                            Constants.message.noRecordsWarning
                                                        }
                                                        </h6>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Tab>
                                    <Tab eventKey="second" title="Accounts">
                                        <div className=''>
                                            <div className='main_tabs'>
                                                <div className='master_box '>
                                                    <div className='master_boxinfo'>
                                                        <div className="clients_menu">
                                                            {/* <a className='btn btn-primary' href="/Client/billandpayment"> Bill & Payment </a> */}
                                                        </div>



                                                        <div className='table-responsive pt-2 pb-5 mt-2 table-height'>

                                                            <div className='add_btns d-flex'>

                                                                <span className='mx-2' style={{ fontSize: "14px" }} >From</span> <input className='form-control' id='assignmentStartDate' type='date'
                                                                    onChange={(e) => { setstartdate(e.target.value); }}
                                                                // onChange={editassignmentform.handleChange}
                                                                // defaultValue={moment(editAssignmentData?.assignmentStartDate).utc().format('YYYY-MM-DD')} 
                                                                ></input>
                                                                {/* <label htmlFor="assignmentEndDate" className="form-label">To</label> */}
                                                                <span className='mx-2' style={{ fontSize: "14px" }} >To</span><input className='form-control' id='assignmentEndDate' type='date'
                                                                    onChange={(e) => { settodate(e.target.value); }}

                                                                // onChange={editassignmentform.handleChange}
                                                                // defaultValue={moment(editAssignmentData?.assignmentEndDate).utc().format('YYYY-MM-DD')}
                                                                ></input>

                                                                <button type="button" className='me-1 searchButton' style={{ width: "20px" }} onClick={handleSubmit}>
                                                                    <i className="fa fa-search" aria-hidden="true"></i>
                                                                </button>

                                                                <div className='month-dropdown me-2'>
                                                                    <RegularDropDown 
                                                                        placeholder={selectedOption}
                                                                        dropdownArr={Constants.month}
                                                                        labelParam="value"
                                                                        onDropDownItemClick={handleMonthChange}
                                                                        isFocusRequired={true}
                                                                        show={isShowDropDownItem}
                                                                        onToggle={(isOpen) => showDropdownItem(isOpen)}
                                                                        defaultPlaceholderDropDown={Constants.placeholder.monthPlaceholder}
                                                                    />
                                                                </div>
                                                                {/* {locat === '/Dashboard' ? "" : <button transactionType='button' className='btn btn-primary w-auto' variant="primary" onClick={setbuycreditmodalShow}><i className="fa fa-plus-circle" aria-hidden="true"></i> BUY CREDITS</button>} */}
                                                            </div>



                                                            <i className="fa fa-search search_icon" aria-hidden="true"></i>

                                                            {componentDidMountAccountFlag && account?.records && (
                                                                <Table
                                                                    data={account?.records?.map((item, index) => {
                                                                        return {
                                                                            ...item,
                                                                            key: index,
                                                                            index: (account?.page - 1) * account.limit + index + 1,
                                                                            id: item.id,
                                                                            date: moment(item.createdAt).utc().format('DD-MM-YYYY') ?? "-",
                                                                            transactionType: item.transactionType,
                                                                            credits: item.numberOfCredits,
                                                                            // balance: item.numberOfBalance,
                                                                        };
                                                                    })}
                                                                    columns={[
                                                                        {
                                                                            dataField: "index",
                                                                            text: "No",
                                                                            headerClasses: "sr_no",
                                                                        },
                                                                        {
                                                                            dataField: "date",
                                                                            text: "Date",
                                                                            sort: false,
                                                                        },
                                                                        {
                                                                            dataField: "transactionType",
                                                                            text: "Type",
                                                                            sort: true,
                                                                        },
                                                                        {
                                                                            dataField: "credits",
                                                                            text: "Credits",
                                                                            sort: true,
                                                                        },
                                                                        {
                                                                            dataField: "action",
                                                                            text: "",
                                                                            headerClasses: "action",
                                                                            style: { cursor: "pointer",  },
                                                                            formatter: (cell, e) => {
                                                                            return(
                                                                                <div className="edit_icons">
                                                                                    <div className="dropdown">
                                                                                        <button
                                                                                            className="btn btn-secondary dropdown-toggle"
                                                                                            type="button"
                                                                                            id="dropdownMenuButton1"
                                                                                            data-bs-toggle="dropdown"
                                                                                            aria-expanded="false"
                                                                                        >
                                                                                            <i
                                                                                                className="fa fa-ellipsis-v"
                                                                                                aria-hidden="true"
                                                                                                style={{color:'#fff'}}
                                                                                            ></i>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                            }
                                                                        }
                                                                        // {
                                                                        //     dataField: "balance",
                                                                        //     text: "Balance",
                                                                        //     sort: true,
                                                                        // }
                                                                    ]}
                                                                    handleTableChange={handleTableChange1}
                                                                    totalSize={account?.total ?? 0}
                                                                    currentPage={account?.page ?? 1}
                                                                    sizePerPage={account?.limit ?? 15}
                                                                />
                                                            )}
                                                            {componentDidMountAccountFlag && isEmptyArray(account?.records) && 
                                                                <div className="no-items-layout">
                                                                    <div className="no-items-card">
                                                                        <h6>
                                                                        {
                                                                            Constants.message.noRecordsWarning
                                                                        }
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                                <Addconfig addconfig={addconfig} setaddConfig={setaddConfig} />
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </>
        </>
    )
}

export default Accounts