import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import '../ClientBillingPayment/billstyle.css'
import { toast } from "react-toastify";
import { APIRequest, CREDIT_PURCHASE, BILLSANDPAYMENTS_LIST, CONFIGS_LIST } from '../../api'
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../common/Global/constants';

const CreditInstruction = ({ instructionModal, setInstructionModalShow, setbuycreditmodalShow }) => {
   
    return (
        <>
            <Modal size="md" show={instructionModal}>
                <Modal.Header>
                    <Modal.Title>Buy Credits</Modal.Title>
                    <div
                        onClick={() => {
                            setInstructionModalShow(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='addclients_details'>
                        <div className='rules-list'>
                            <ul>
                                <li>
                                    We charge you on the basis of per user per assignment per day.
                                </li>
                                <li>
                                    One Credit means a user can work on one assignment for any given day. If user works on two assignments on a day then 2 credits will be charged and so on.
                                </li>
                                <li>
                                    Credits will be charged only when a user enters or edits actual stock. All other options and features for the assignment can be accessed by any user at any given point of time.    
                                </li>
                                <li>
                                    Credit will get deducted once a user adds or updates first actual stock for the day for a particular assignment.
                                </li>
                                <li className="fontSize">
                                    The credit for the day will be valid for 10 hours from the time of first entry or edit of actual stock by an user for an assignment.
                                </li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setInstructionModalShow(false); }}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => {setInstructionModalShow(false); setbuycreditmodalShow(true)}}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreditInstruction;