import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APIRequest, LIST_SAMPLE, LIST_STOCK } from '../../../api';
import { Table } from '../../Table/Table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Sampleitem from './sampleitem';
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../Component/common/Global/commonFunctions';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import AssignmentListDetails from '../../../Component/common/assignmentListDetails/assignmentListDetails';
import CommonLoader from '../../../commonComponent/loader/commonLoader';

const Sampledetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignment = location?.state?.assignmentListDetails;
  const status = location?.pathname?.split('/')[2] == "ongoingassignment" ? "Started" : "completed";
  const [sample, Setsample] = useState([]);
  const [download, Setdownload] = useState([]);
  const [sampledetails, Setsampledetails] = useState()
  const [showdetails, Setshowdetails] = useState(false);
  const { assignmentId } = useParams();
  const user = useSelector((state) => state?.user);
  const formatter = new Intl.NumberFormat('en-IN');
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [searchStock, setSearchStock] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const store = storeId;
  const stock = stockId;
  const [stockData, SetstockData] = useState([])
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [sampleDetailsLoading, setSampleDetailsLoading] = useState(false);

  useEffect(() => {
    getSampling();

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_STOCK)
      .reqURL(`bookstock/${assignmentId}/getFilterStoreStockList`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSampling = () => {
    setSampleDetailsLoading(true);

    new APIRequest.Builder()
      .post()
      .setReqId(LIST_SAMPLE)
      .reqURL(`bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15`)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  useEffect(() => {
    Setdownload(sample?.records?.map((item, index) => {
      return {
        No: index + 1,
        key: index,
        PartNumer: item?.partNo1 ?? "",
        SubPartNo1: item?.partNo2 ?? "",
        SubPartNo2: item?.partNo3 ?? "",
        SubPartNo3: item?.partNo4 ?? "",
        SubPartNo4: item?.partNo5 ?? "",
        StockType: item?.stockType ?? "",
        Materialname: item?.materialName ?? "",
        MaterialSubname1: item?.materialName1 ?? "",
        MaterialSubname2: item?.materialName2 ?? "",
        MaterialSubname3: item?.materialName3 ?? "",
        MaterialSubname4: item?.materialName4 ?? "",
        Location: item?.location1 ?? "",
        Location1: item?.Location2 ?? "",
        Location2: item?.location3 ?? "",
        Location3: item?.location4 ?? "",
        Location4: item?.location5 ?? "",
        UOM: item?.uom ?? "",
        BookQuantity: item?.quantity ?? "-",
        Rate: item?.rate ?? "-",
        Value: item?.value ?? "",
        ExpiryDate: item?.expiryDate ?? "",
        LastReceiptDate: item?.lastReceiptDate ?? "",
        LastIssueDate: item?.lastIssueDate ?? "",
        Remarks: item?.remarks ?? ""
      }
    }))
  }, [sample])

  const [fileName, setFileName] = useState("Sample Reports");
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(download);
    const wb = { Sheets: { 'Sampledata': ws }, SheetNames: ['Sampledata'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_SAMPLE:
        Setsample(response?.data?.data)
        isComponentDidMountFlag(true);
        setSampleDetailsLoading(false);
        break;
      case LIST_STOCK:
        SetstockData(response.data?.storeType);
        break;
      default:
        break;
    }
  }

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_SAMPLE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
          setSampleDetailsLoading(false);
          navigate(`/Client/ongoingassignment/${assignmentId}/sampledetails/${randomStringGenerator(30)}`);
        }else{
          setSampleDetailsLoading(false);
        }
        break;
      case LIST_STOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      default:
        break;
    }
  }

  const getStoreStockFilterList = (storeId, stockId) => {
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15&stockType=${stockId}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}&stockType=${stockId}`;
    }
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_SAMPLE)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  };

  const getSample = () => {
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_SAMPLE)
      .reqURL(`bookstock/getSampling/${assignmentId}?type=list&page=1&limit=15`)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }
  // partNo1,partNo2,partNo3,materialName,materialName1,materialName2,location1,location2,location3,uom,quantity,rate,value,stockType
  const handleTableChange = (type, newState) => {
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&storeType=${storeId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getSampling/${assignmentId}?type=list&storeType=${storeId}&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    }
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if(newState.searchText == ""){
      setSearch("");
    }
    if (newState?.searchText) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }
    if (newState?.sortField) {
      setSort(newState?.sortField);
      if (newState?.sortField === "PartNo") {
        url = url + `&sortColumn=partNo1`
      }
      if (newState?.sortField === "Materialname") {
        url = url + `&sortColumn=materialName`
      }
      if (newState?.sortField === "rate") {
        url = url + `&sortColumn=rate`
      }
      if (newState?.sortField === "uom") {
        url = url + `&sortColumn=uom`
      } if (newState?.sortField === "value") {
        url = url + `&sortColumn=value`
      }if (newState?.sortField === "samplequantity") {
        url = url + `&sortColumn=quantity`
      }

      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }

    new APIRequest.Builder()
      .post()
      .setReqId(LIST_SAMPLE)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  };
  // onchange event for store
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setSearchStock('search');
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "");
      setSelectedStoreType(selected.label);
    }
    showStoreTypeDropdownItem(false);
  };
  
  // onchange event for stock
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setSearchStock('search');
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value);
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };

  const clearFilter = () => {
    isComponentDidMountFlag(false);
    getSample();
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setSearch('');
    setSort('');
    setSortOrder('');
  }

  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockId, "label": stockItem?.stockTypeName });
    });
  });
  return (
    <>
      {assignment && 
        <AssignmentListDetails
          assignment={assignment}
        />
      }
      {sampleDetailsLoading &&
        <CommonLoader loding={sampleDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
          <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/ongoingassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
            <a className='btn btn-primary' href="sampledetails"> Sample Details </a>
          </div>
          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
            {componentDidMountFlag &&
              <>
                <div className="add_btns bookstock_dropdown_width">
                  {!isEmptyArray(sample?.records) && (search != "" || sort != "" || storeId != "" || stockId != "") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                  <RegularDropDown 
                    placeholder={selectedStoreType}
                    dropdownArr={storeOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStoreChange}
                    isFocusRequired={true}
                    show={isShowStoreTypeDropDownItem}
                    onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Store Type"}
                  />
                  <RegularDropDown 
                    placeholder={selectedStockType}
                    dropdownArr={stockOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStockChange}
                    isFocusRequired={true}
                    show={isShowStockTypeDropDownItem}
                    onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Stock Type"}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={(e) => exportToCSV()}
                  >
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                    ></i>{" "}
                    Download
                  </button>
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
              }
              {componentDidMountFlag && sample?.records && (
                <Table
                  data={sample?.records?.map((item, index) => {

                    return {
                      ...item,
                      key: index,
                      index: (sample?.page - 1) * sample.limit + index + 1,
                      id: item.id,
                      PartNo: item?.partNo1 ?? "-",
                      Materialname: item?.materialName ?? "-",
                      uom: item?.uom ?? "-",
                      rate: isEmptyVariable(item?.rate) ? "-" : formatter.format(item?.rate),
                      samplequantity: isEmptyVariable(item?.quantity) ? "-" : item?.quantity,
                      value:isEmptyVariable(item?.value) ? "-" : formatter.format(item?.value),
                    };
                  }) ?? []}
                  columns={[
                    {
                      dataField: "index",
                      text: "No",
                      headerClasses: "sr_no",
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "PartNo",
                      text: "Part No.",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "Materialname",
                      text: "Name",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "uom",
                      text: "UOM",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "rate",
                      text: "Rate",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "value",
                      text: "Value",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                    {
                      dataField: "samplequantity",
                      text: "Book Qty",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setsampledetails(row); Setshowdetails(true)
                        },
                      }
                    },
                  ]}
                  handleTableChange={handleTableChange}
                  totalSize={sample?.total ?? 0}
                  currentPage={sample?.page ?? 1}
                  sizePerPage={sample?.limit ?? 15}
                />
              )}
              {componentDidMountFlag && isEmptyArray(sample?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Sampleitem sampledetails={sampledetails} Setshowdetails={Setshowdetails} showdetails={showdetails} />
    </>
  )
}

export default Sampledetails