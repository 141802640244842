import React, { useEffect, useState } from 'react';
import '../DashBoardClient/dashboardclientstyle.css'
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { isEmptyArray, useFormik } from 'formik';
import { APIRequest, ASSIGNMENT_LIST } from '../../api';
import { toast } from 'react-toastify';
import { Table } from '../../Pages/Table/Table';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';
import Updateassignment from './updateassignment';
import * as Constants from '../common/Global/constants';
import { isEmptyVariable, localDateExtractor } from '../common/Global/commonFunctions';
import CommonLoader from '../../commonComponent/loader/commonLoader';

const aquaticCreatures = [
    { label: 'Shark', value: 'Shark' },
    { label: 'Dolphin', value: 'Dolphin' },
    { label: 'Whale', value: 'Whale' },
    { label: 'Octopus', value: 'Octopus' },
    { label: 'Crab', value: 'Crab' },
    { label: 'Lobster', value: 'Lobster' },
];

function DashboardClient() {

    const user = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [flex2, setFlex2] = useState("none");
    const [subflex, setSubflex] = useState("none");
    const [assignment, Setassignment] = useState({});
    const [masking, setmaskingShow] = useState(false);
    const [addlevels, setaddlevelsShow] = useState(false);
    const [changeresource, setchangeresourceShow] = useState(false);
    const [editassignment, Seteditassignment] = useState(false);
    const [editAssignmentData, SeteditAssignmentData] = useState();
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
    const [ongoingAssignmentLoading, setOngoingAssignmentLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [openMenuIndex, setOpenMenuIndex] = useState(null);

    useEffect(() => {  
        getOngoingAssignmentsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOngoingAssignmentsList = () => {
        setOngoingAssignmentLoading(true);
        new APIRequest.Builder()
            .get()
            .setReqId(ASSIGNMENT_LIST)
            .reqURL(`assignments/getOngoingAssignmentsList/${user.organisationId}?page=1&limit=15&status=Started`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const Editassignment = (e, item) => {
        e.stopPropagation();
        Seteditassignment(true)
        SeteditAssignmentData(item);
    }
    const maskingmanage = (e) => {
        if (e.target.id === "flexRadioDefault2") {
            setFlex2("block")
        } else if (e.target.id === "flexRadioDefault1") {
            setFlex2("none")
        }

        if (e.target.id === "flexRadioDefault5") {
            setSubflex("block")
        } else if (e.target.id === "flexRadioDefault3" || e.target.id === "flexRadioDefault4") {
            setSubflex("none")
        }

    }

    const maskingform = useFormik({
        initialValues: {
            sortname: '',
            stockname: '',
            fullname: ''
        },
        onSubmit: values => {

        },
    });

    const changeresourceform = useFormik({
        initialValues: {
            sortname: '',
            stockname: '',
            fullname: ''
        },
        onSubmit: values => {

        },
    });

    const addlevelsform = useFormik({
        initialValues: {
            sortname: '',
            stockname: '',
            fullname: ''
        },
        onSubmit: values => {

        },
    });


    const onResponse = (response, reqId) => {
        switch (reqId) {
            case ASSIGNMENT_LIST:
                const data = response?.data?.data ?? {};
                Setassignment({ ...data });
                isComponentDidMountFlag(true);
                setOngoingAssignmentLoading(false);
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case ASSIGNMENT_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {  
                    setOngoingAssignmentLoading(false);
                    // toast.error(`${response?.data?.message}`)
                }
                break;
            default:
                break;
        }
    }
    const clearFilter = () => {
        isComponentDidMountFlag(false);
        setSearch('');
        setSort('');
        setSortOrder('');
        getOngoingAssignmentsList();
    }
    const handleTableChange = (type, newState) => {
        let url = `assignments/getOngoingAssignmentsList/${user.organisationId}?status=Started&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
        if (newState.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`;
        }
        if (!isEmptyVariable(newState?.searchText)) {
            setSearch(newState.searchText);
            url = url + `&search=${encodeURIComponent(newState.searchText)}`;
        }else{
            setSearch('');
        }
        if (!isEmptyVariable(newState?.sortField)) {
            setSort(newState?.sortField);
            if (newState.sortField === "clientName") {
                url = url + `&sortColumn=clientName`
            }
            if (newState.sortField === "clientUnitName") {
                url = url + `&sortColumn=clientUnitName`
            }
            if (newState.sortOrder) {
                setSortOrder(newState?.sortOrder);
                url = url + `&sortType=${newState.sortOrder}`
            }
        }else{
            setSort('');
            setSortOrder('');
        }

        new APIRequest.Builder()
            .get()
            .setReqId(ASSIGNMENT_LIST)
            .reqURL(url)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    };
    const handleOnClose = (reload) => {
        Seteditassignment(false);
        if(reload){
            getOngoingAssignmentsList();
        }
    }

    const handleOnClick = (row) => {
        if (row?.AssignmentStoreMapping.length) {
            navigate(`ongoingassignment/${row?.assignmentId}`,{
                state:{
                    assignmentListDetails : {
                        status: row.status, 
                        assignmentStartDate: localDateExtractor(row.assignmentStartDate), 
                        disableBookStock: row.disableBookStock,
                        clientName: row.Client.clientName,
                        clientUnitName: row.ClientUnit.clientUnitName,
                        city: row.ClientUnit.City.cityName,
                        teamMembers: row.teamMembers,
                        reviewers: row.reviewers,
                        clientId: row.clientId
                    }
                }
            })
        } else {
            toast.error("Store is not selected for stock take. Please select a store.");
        }
    }

    // Function to toggle the action menu
    const toggleMenu = (rowIndex) => {
        setOpenMenuIndex(openMenuIndex === rowIndex ? null : rowIndex);
    };
    return (
        <>
            {ongoingAssignmentLoading &&
                <CommonLoader loading={ongoingAssignmentLoading} />
            }
            <div className='main_tabs'>
                <div className='master_box'>
                    <div className='master_boxinfo'>
                        <div className='table-responsive pt-2 pb-5' style={{marginTop:-8}}>
                            {componentDidMountFlag && 
                                <>
                                    <div className='add_btns'>
                                        {!isEmptyArray(assignment?.records) && (search != "" || sort != "") && 
                                            <button
                                            type="button"
                                            className="btn btn-primary"
                                            variant="primary"
                                            onClick={() => clearFilter()}
                                            >
                                            <i
                                                className="fa fa-filter"
                                                aria-hidden="true"
                                            ></i>{" "}
                                            Clear
                                            </button>
                                        }
                                    </div>
                                    <i className="fa fa-search search_icon" aria-hidden="true"></i>
                                </>
                                }
                                {componentDidMountFlag && assignment?.records && (
                                <Table
                                    data={assignment?.records?.map((item, index) => {
                                        return {
                                            ...item,
                                            key: index,
                                            index: (assignment?.page - 1) * assignment?.limit + index + 1,
                                            id: item?.id,
                                            clientName: item?.Client?.clientName ?? '',
                                            referenceNumber: item?.referenceNumber ?? '',
                                            clientUnitName: item?.ClientUnit?.clientUnitName ?? '',
                                            date: moment(item?.assignmentStartDate).utc().format('DD-MM-YYYY') ?? "-",
                                            reviewer: item?.reviewers === null ? "" : item?.reviewers.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> }),
                                            teammember: item?.teamMembers === null ? "" : item?.teamMembers.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> })
                                        };
                                    }) ?? []} // items
                                    columns={[
                                        {
                                            dataField: "index",
                                            text: "No",
                                            headerClasses: "sr_no",
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "referenceNumber",
                                            text: "Ref. No.",
                                            sort: false,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "clientName",
                                            text: "Name",
                                            sort: true,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "clientUnitName",
                                            text: "Unit",
                                            sort: true,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "date",
                                            text: "Start Date",
                                            sort: false,
                                            onClick: true,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "teammember",
                                            text: "Team Member",
                                            sort: false,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "reviewer",
                                            text: "Reviewer",
                                            sort: false,
                                            style: { cursor: "pointer" },
                                            events: {
                                                onClick: (e, column, columnIndex, row, rowIndex) => {
                                                    handleOnClick(row);
                                                },
                                            }
                                        },
                                        {
                                            dataField: "action",
                                            text: "Action",
                                            headerClasses: "action",
                                            style: { cursor: "pointer" },
                                            formatter: (cell, row, rowIndex) => {
                                                return (
                                                    <div className="edit_icons">
                                                        <div className="dropdown">
                                                            <button
                                                                className="btn btn-secondary dropdown-toggle"
                                                                type="button"
                                                                id={`dropdownMenuButton1`}
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded='false'
                                                            >
                                                                <i
                                                                    className="fa fa-ellipsis-v"
                                                                    aria-hidden="true"
                                                                ></i>
                                                            </button>
                                                            <ul
                                                                className="dropdown-menu p-1"
                                                                aria-labelledby={`dropdownMenuButton1`}
                                                            >
                                                                <li>
                                                                    <a className="dropdown-item"
                                                                        data-bs-toggle="#modal"
                                                                        data-bs-target="#exampleModal3"
                                                                        onClick={(e) => Editassignment(e, row)}
                                                                    >
                                                                        <i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                                                        Edit
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    handleTableChange={handleTableChange} // onChangePage
                                    totalSize={assignment?.total ?? 0} // totalLength
                                    currentPage={assignment?.page ?? 1} // currentPageNo
                                    sizePerPage={assignment?.limit ?? 15} // pageSize
                                />
                                )}
                            </div>
                        {componentDidMountFlag && isEmptyArray(assignment?.records) && 
                            <div className="no-items-layout">
                                <div className="no-items-card">
                                    <h6>
                                        {
                                            Constants.message.noRecordsWarning
                                        }
                                    </h6>
                                </div>
                            </div>
                        }
                        {editassignment &&
                            <Updateassignment editassignment={editassignment} handleOnClose={handleOnClose} editAssignmentData={editAssignmentData} user={user} />
                        }
                        <Modal show={changeresource}>
                            <form onSubmit={changeresourceform.handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Assign Resource</Modal.Title>
                                    <div
                                        onClick={() => {
                                            setchangeresourceShow(false);
                                            changeresourceform.resetForm();
                                        }}
                                        data-dismiss="modal">
                                        <i className="fa fa-close" aria-hidden="true"></i>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='resource_box'>
                                        <h5>Resource</h5>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='team_member_box'>
                                                    <h6>Team Members</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Suresh IATM2(Consultant)</p>
                                                        <button type='button' className='btn btn-outline-primary'>Remove</button>
                                                    </div>
                                                    <div className='resource_details mb-2'>
                                                        <p>Usha IATM1(Senior Consultant)</p>
                                                        <button type='button' className='btn btn-outline-primary'>Remove</button>
                                                    </div>
                                                    <button type='button' className='btn btn-primary'>Add Team Member</button>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className='team_leader_box'>
                                                    <h6>Team Leader</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Ramesh IAL3(Manager)</p>
                                                        <button type='button' className='btn btn-outline-primary'>Remove</button>
                                                    </div>
                                                    <h6>Review Level 1</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Not Assigned</p>
                                                        <button type='button' className='btn btn-primary' variant="primary" onClick={setaddlevelsShow}>Add</button>
                                                    </div>
                                                    <h6>Review Level 2</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Not Assigned</p>
                                                        <button type='button' className='btn btn-primary' variant="primary" onClick={setaddlevelsShow}>Add</button>
                                                    </div>
                                                    <h6>Review Level 3</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Not Assigned</p>
                                                        <button type='button' className='btn btn-primary' variant="primary" onClick={setaddlevelsShow}>Add</button>
                                                    </div>
                                                    <h6>Review Level 4</h6>
                                                    <div className='resource_details mb-2'>
                                                        <p>Not Assigned</p>
                                                        <button type='button' className='btn btn-primary' variant="primary" onClick={setaddlevelsShow}>Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setchangeresourceShow(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                        <Modal show={addlevels}>
                            <form onSubmit={addlevelsform.handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Add Level</Modal.Title>
                                    <div
                                        onClick={() => {
                                            setaddlevelsShow(false);
                                            addlevelsform.resetForm();
                                        }}
                                        data-dismiss="modal">
                                        <i className="fa fa-close" aria-hidden="true"></i>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='row dropdown_box'>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <label htmlFor="exampleInput6" className="form-label">Team Member</label>
                                                <Select
                                                    options={aquaticCreatures}
                                                    isMulti
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <label htmlFor="exampleInput7" className="form-label">Team lead</label>
                                                <Select
                                                    options={aquaticCreatures}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <label htmlFor="exampleInput8" className="form-label">Review 1</label>
                                                <Select
                                                    options={aquaticCreatures}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <label htmlFor="exampleInput9" className="form-label">Reviewer 2</label>
                                                <Select
                                                    options={aquaticCreatures}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="">
                                                <label htmlFor="exampleInput10" className="form-label">Client User</label>
                                                <Select
                                                    options={aquaticCreatures}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setaddlevelsShow(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal>
                        {/* <Modal show={masking}>
                            <form onSubmit={maskingform.handleSubmit}>
                                <Modal.Header>
                                    <Modal.Title>Masking</Modal.Title>
                                    <div
                                        onClick={() => {
                                            setmaskingShow(false);
                                            maskingform.resetForm();
                                        }}
                                        data-dismiss="modal">
                                        <i className="fa fa-close" aria-hidden="true"></i>
                                    </div>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='masking_box'>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={(e) => maskingmanage(e)} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                No masking
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={(e) => maskingmanage(e)} />
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Masking
                                            </label>
                                        </div>
                                        <div className="sublist" style={{ "display": flex2 }}>
                                            <div className="form-check ps-5">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={(e) => maskingmanage(e)} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                    Open the Mask at Time (at time selection should be there)
                                                </label>
                                            </div>
                                            <div className="form-check ps-5">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" onClick={(e) => maskingmanage(e)} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                    Open the Mask at Time (at time selection should be there)
                                                </label>
                                            </div>
                                            <div className="form-check ps-5">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" onClick={(e) => maskingmanage(e)} />
                                                <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                    Open the Mask if the actual quantity matches
                                                </label>
                                            </div>
                                            <div className='sub_masking_item' style={{ "display": subflex }}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                        Open difference at time (at time selection should be there)
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                        Open unmasked by team leader. (All the unmasked will at once will get opened by team leader.)
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                                                    <label className="form-check-label" htmlFor="flexRadioDefault8">
                                                        Open difference after ___ punch in.
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-check ps-5">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault9">
                                                    Open the mask when first time actual quantity is punched.
                                                </label>
                                            </div>
                                            <div className="form-check ps-5">
                                                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" />
                                                <label className="form-check-label" htmlFor="flexRadioDefault10">
                                                    Team leader to open masked items at once.
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setmaskingShow(false)}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardClient;