import React, { Component } from "react";
import { Button } from "antd";
import '../button/commonButton.css';

class CommonButton extends Component {
    constructor(props) {
        super(props);
        // Define the default style properties for the button
        this.buttonStyle = {
            height: 29, 
            width: 'auto', 
            backgroundColor:'#552B84', 
            borderColor: '#552B84',
            borderRadius: 3,
            fontSize: 12
        };
    }

    // This function takes an object 'styleProperties' as an argument and returns the same object with additional properties.
    addStylePropertiesToButton(styleProperties) {
        // Check if the 'backgroundColor' property exists in the 'styleProperties' object.
        if(styleProperties?.backgroundColor){
            // If it exists, set the 'borderColor' property of 'styleProperties' object to the same value as 'backgroundColor'.
            styleProperties.borderColor = styleProperties.backgroundColor
        }
        // Iterate over the entries in the 'this.buttonStyle' object using 'Object.entries()' method.
        Object.entries(this.buttonStyle).map(([key, value]) => {
            // Check if the current key does not exist in the 'styleProperties' object.
            if(!styleProperties[key]){
                // If it doesn't exist, add the key-value pair from 'this.buttonStyle' object to 'styleProperties' object.
                styleProperties[key] = value
            }
        });
        // Finally, return the updated 'styleProperties' object.
        return styleProperties;
    }

    // Function getButtonCustomStyle() used to get the custom style for a button
    // If the button has a prop 'style' then the custom style will be applied to the button
    //  If the button does not have a propstyle' then the style will be used
    getButtonCustomStyle() {
        // If the button has a prop 'style' then check if it exists
        if(this.props.style){
            // If the prop 'style' exists then add the style properties to the button
            return this.addStylePropertiesToButton(this.props.style);
        }else{
            // If the prop 'style' does not exist then use the default style
            return this.buttonStyle;
        }
    }

    render() {
        return (
            <Button 
                loading={this.props.loading ?? this.props.loading}
                block={this.props.block ?? this.props.block}
                danger={this.props.danger ?? this.props.danger}
                disabled={this.props.disabled ?? this.props.disabled}
                ghost={this.props.ghost ?? this.props.ghost} //ghost property will make button's background transparent
                icon={this.props.icon ?? this.props.icon}
                shape={this.props.shape ?? this.props.shape} //default | circle | round
                size={this.props.size ?? this.props.size} //large | small | default
                type={this.props.type ? this.props.type : "primary"} //primary | dashed | text | link | default
                className={"commonButton"}
                onClick={this.props.onClick}
                style={this.getButtonCustomStyle()}
            >
                {this.props.label}
            </Button>
        );
    }
}

export default CommonButton;