import React, { Fragment } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ClientAdmin from '../src/Pages/Client_Admin/clientadmin'
import Register from '../src/Pages/Register/register'
import Login from '../src/Pages/Login/login'
import Forgot from '../src/Pages/Forgot/forgot'
import Dashboard from '../src/Pages/Dashboard/dashboard'
import ClientDashboard from '../src/Pages/ClientDashboard/clientdashboard'
import UnitMaster from '../src/Pages/Unitmaster/unit'
import EmployeeMaster from '../src/Pages/EmployeeMaster/employee'
import BillPayments from '../src/Component/ClientBillingPayment/billis'
import ThirdPartydashboard from '../src/Pages/ThirdPartyDashboard/thirdpartydashboard'
import OngoingAssignment from '../src/Pages/OngoingAssignment/ongoingassignment'
import BookStock from './Pages/UploadBookStock/tabMenu'
import ResetPassword from '../src/Pages/ResetPassword/resetpassword'
import { useSelector } from "react-redux";
import { Table } from "./Pages/Table/Table";
import EditUnit from "./Pages/Unitmaster/editUnit";
import DashboardClient from "./Component/DashBoardClient/clientdashboard";
import Assignment from "./Pages/ClientDashboard/Component/Assignments";
import Client from "./Pages/ClientDashboard/Component/Clients";
import User from './Pages/User/index'
import Accounts from "./Pages/Dashboard/Component/Accounts/Accounts";
import DashClint from './Pages/Dashboard/Component/Dashboardclient/DashClient'
import DashboardMaster from "./Pages/Dashboard/Component/DashboardMaster";
import Thirdpartyassignment from "./Pages/ThirdPartyDashboard/thirdpartyassignment";
import Thirdpartyongoingassignment from "./Pages/ThirdPartyDashboard/thirdpartyongoingassignment";
import Verification from "./Pages/OngoingAssignment/Detailspage/verification";
import Deviation from "./Pages/OngoingAssignment/Detailspage/deviationDetails";
import Materialanalysis from "./Pages/OngoingAssignment/Material/materialanalysis";
import Materialnotused from "./Pages/OngoingAssignment/Material/materialnotused";
import Materialexpiry from "./Pages/OngoingAssignment/Material/materialexpiry";
import Bookstockdetails from "./Pages/OngoingAssignment/Detailspage/bookstockdetails";
import Sampledetails from "./Pages/OngoingAssignment/Detailspage/sampledetails";
import Resolveentry from "./Pages/OngoingAssignment/Detailspage/resolveentry";
// import Finalreport from "./Pages/OngoingAssignment/finalreport";
import Maindashboard from "./Pages/Dashboard/maindashboard";
import Bookstocktable from "./Pages/UploadBookStock/bookstocktable";
import PrivateRoute from "./Pages/Routes/PrivateRoutes";
import PageNotFound from "./Pages/pageNotFound";
import CompletedAssignment from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/completedAssignmentDetails";
import Organisation from "./Pages/Organisation";
import CreateSample from "./Pages/OngoingAssignment/createSample";
import CompletedAssignmentDetails from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/completedAssignmentDetails";
import BookStockDetails from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/bookStock/bookStockDetails";
import VerifiedDetails from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/verification/verificationDetails";
import DeviationDetails from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/deviations/devationDetails";
import SampleDetails from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/sample/sampleDetails";
import Sample from "./Pages/ClientDashboard/Component/Assignments/completedAssignments/sample/tabMenu";

function App() {
  const user = useSelector((state) => state?.user);
  return (
    <>
              <BrowserRouter>
            <Fragment>
              <Routes>
                {/* <Route exact path="/FinalReport" element={<PrivateRoute><Finalreport /></PrivateRoute>} /> */}
                <Route exact path="/BookStock" element={<PrivateRoute><BookStock /></PrivateRoute>} />
                <Route exact path="/OngoingAssignment" element={<PrivateRoute><OngoingAssignment /></PrivateRoute>} />
                <Route exact path="/completedassignment" element={<PrivateRoute><CompletedAssignment /></PrivateRoute>} />
                <Route exact path="/BillPayments" element={<PrivateRoute><BillPayments /></PrivateRoute>} />
                <Route exact path="/UnitMaster/:clientId/EmployeeMaster/:employeeId" element={<PrivateRoute><EmployeeMaster /></PrivateRoute>} />
                <Route exact path="/UnitMaster/:clientId" element={<PrivateRoute><UnitMaster /></PrivateRoute>} />
                {(user?.role == "SUBSCRIBER_ADMIN" || user?.role =="SUBSCRIBER_USER") &&
                  <Route exact path="/Client" element={<PrivateRoute><ClientDashboard /></PrivateRoute>}>
                    <Route exact path="" element={<DashboardClient />} />
                    <Route exact path="ongoingassignment/:assignmentId" element={<OngoingAssignment />} />
                    <Route exact path="completedassignment/:assignmentId" element={<CompletedAssignmentDetails />} />
                    <Route exact path="ongoingassignment/:assignmentId/bookstock/" element={<BookStock />} />
                    <Route exact path="ongoingassignment/:assignmentId/bookstock/bookstocktable" element={<Bookstocktable />} />

                    <Route exact path="completedassignment/:assignmentId/verification" element={<VerifiedDetails />} />
                    <Route exact path="completedassignment/:assignmentId/deviation" element={<DeviationDetails />} />
                    <Route exact path="completedassignment/:assignmentId/materialmovement" element={<Materialanalysis />} />
                    <Route exact path="completedassignment/:assignmentId/materailnotused" element={<Materialnotused />} />
                    <Route exact path="completedassignment/:assignmentId/materialexpiery" element={<Materialexpiry />} />
                    <Route exact path="completedassignment/:assignmentId/bookstockdetails" element={<BookStockDetails />} />
                    <Route exact path="completedassignment/:assignmentId/sampledetails" element={<SampleDetails />} />
                    <Route exact path="completedassignment/:assignmentId/sampling" element={<Sample />} />

                    <Route exact path="ongoingassignment/:assignmentId/verification" element={<Verification />} />
                    <Route exact path="ongoingassignment/:assignmentId/deviation" element={<Deviation />} />
                    <Route exact path="ongoingassignment/:assignmentId/materialmovement" element={<Materialanalysis />} />
                    <Route exact path="ongoingassignment/:assignmentId/materailnotused" element={<Materialnotused />} />
                    <Route exact path="ongoingassignment/:assignmentId/materialexpiery" element={<Materialexpiry />} />
                    <Route exact path="ongoingassignment/:assignmentId/bookstockdetails" element={<Bookstockdetails />} />
                    <Route exact path="ongoingassignment/:assignmentId/sampledetails" element={<Sampledetails />} />
                    <Route exact path="ongoingassignment/:assignmentId/sampling" element={<CreateSample />} />
                    {/* <Route exact path="ongoingassignment/:assignmentId/resolvemultipleentry" element={<Resolveentry />} /> */}
                    <Route exact path="assignment" element={<Assignment />} />
                    <Route exact path="billandpayment" element={<BillPayments />} />
                    <Route exact path="client" element={<Client />} />
                    <Route exact path="Unitmaster/:clientId" element={<UnitMaster />} />
                    <Route exact path="UnitMaster/:clientId/EmployeeMaster/:employeeId" element={<EmployeeMaster />} />
                    <Route exact path="admin" element={<User />} />
                    <Route exact path="organisation" element={<Organisation />} />
                  </Route>}
                  {(user?.role == "SITE_ADMIN" || user?.role == "SITE_USER") &&
                  <Route exact path="/Dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>}>
                    <Route exact path="" element={<Maindashboard />} />
                    <Route exact path="accounts" element={<Accounts />} />
                    <Route exact path="client" element={<DashClint />} />
                    <Route exact path="billandpayment/:clientId" element={<BillPayments />} />
                    <Route exact path="master" element={<DashboardMaster />} />
                    <Route exact path="admin" element={<User />} />
                  </Route>}
                  <Route path="*" element={<PageNotFound to="/404" />} />
                {/* <Route exact path="*"element={PageNotFound}/> */}
                <Route exact path="/Login" element={<Login />} />
                <Route exact path="/Forgot" element={<Forgot />} />
                <Route exact path="/Register" element={<Register />} />
                <Route exact path="/ResetPassword" element={<ResetPassword />} />
                <Route exact path="" element={<Login />} />

                <Route exact path="/ClientAdmin" element={<PrivateRoute><ClientAdmin /></PrivateRoute>} />
                <Route exact path="/table" element={<PrivateRoute><Table /></PrivateRoute>} />
                <Route exact path="/createUnit" element={<PrivateRoute><EditUnit /></PrivateRoute>} />


                <Route exact path="/ThirdPartydashboard" element={<PrivateRoute><ThirdPartydashboard /></PrivateRoute>}>
                  <Route exact path="assignment" element={<Thirdpartyassignment />} />
                  <Route exact path="bookstock" element={<BookStock />} />

                  <Route exact path="" element={<Thirdpartyongoingassignment />} />
                  <Route exact path="user" element={<User isThirdParty={true} />} />
                </Route>

              </Routes>

            </Fragment>
          </BrowserRouter>
    </>
  );
}
export default App;
