import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import "../ClientDashboard/clientdashboardstyle.css";
import { useDispatch, useSelector } from "react-redux";
import { LIST_USER, CREATE_USER, APIRequest, DELETE_USER, UNIT_EXIST_OR_NOT } from "../../api";
import { toast } from "react-toastify";
import { Table } from "../Table/Table";
import * as Yup from "yup";
import EditUser from "./editUser";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/action";
import Userdetails from "./userdetails";
import { Form } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import ReusableModal from "../Modal/reusablemodel";
import RegularDropDown from "../../Component/common/DropdownMenus/RegularDropdown";
import * as Constants from "../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable } from "../../Component/common/Global/commonFunctions";
import CommonLoader from "../../commonComponent/loader/commonLoader";

const Index = ({ isThirdParty }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [useradd, setuseraddShow] = useState(false);
  const [userData, setUserData] = useState({});
  // const [unit, setUnit] = useState([]);
  // const [store, setStore] = useState([]);
  const [useredit, setUserEdit] = useState(false);
  const [usereditdata, setusereditdata] = useState();
  const [usedetails, Setuserdetails] = useState();
  const [userdetailsshow, Setuserdetailsshow] = useState(false);
  const [useractive, Setuseractive] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [Id, setId] = useState();
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [selectedTitleOption, setSelectedTitleOption] = useState('');
  const [isShowTitleDropDownItem, showTitleDropdownItem] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [isShowUserDropDown, showUserDropdown] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);

  const fetchUser = () => {
    setUserDataLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_USER)
      .reqURL(`user/userList/${user.userId}?organisationId=${user.organisationId}&page=1&limit=15`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();

    // isThirdParty && new APIRequest.Builder()
    //   .post()
    //   .jsonParams({ "role": "THIRD_PARTY_ADMIN" })
    //   .setReqId(LIST_USER)
    //   .reqURL(`user/client/${user.id}`)
    //   .response(onResponse)
    //   .error(onError)
    //   .build()
    //   .doRequest();
  };

  const getUserActiveInactiveList = (useractive = true) => {
    let url = useractive ? `user/userList/${user.userId}?organisationId=${user.organisationId}&page=1&limit=15` : `user/userList/${user.userId}?isActive=false&organisationId=${user.organisationId}&page=1&limit=15`;
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_USER)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  useEffect(() => {
    fetchUser();
    // new APIRequest.Builder()
    //   .get()
    //   .setReqId(LIST_UNIT)
    //   .reqURL(unit/getUnitList)
    //   .response(onResponse)
    //   .error(onError)
    //   .build()
    //   .doRequest();
    // new APIRequest.Builder()
    //   .get()
    //   .setReqId(LIST_STORE)
    //   .reqURL("store/list")
    //   .response(onResponse)
    //   .error(onError)
    //   .build()
    //   .doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const useraddform = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisationId: user?.organisationId,
      organisationName: user?.organisationName,
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      role: "",
      createdBy: user.userId
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Select Title"),
      firstName: Yup.string().required("Please enter First Name"),
      lastName: Yup.string().required("Please enter Last Name"),
      email: Yup.string().email("Invalid email address").required("Please enter Email"),
      mobileNumber: Yup.string().length(10, "enter 10 Digit Number").required("Please enter Mobile Number").matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      ),
      role: Yup.string().required("Select Role")
    }),
    onSubmit: (values) => {
      setLoading(true);
      new APIRequest.Builder()
        .post()
        .setReqId(CREATE_USER)
        .jsonParams(values)
        .reqURL(`auth/userRegister`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    },
  });

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_USER:
        setUserData(response.data?.data);
        isComponentDidMountFlag(true);
        setUserDataLoading(false);
        break;
      case CREATE_USER:
        setLoading(false);
        toast.success(response.data?.message);
        fetchUser();
        setuseraddShow(false);
        setSelectedTitleOption('')
        setSelectedUser('')
        useraddform.resetForm();
        break;
      case UNIT_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.userId);
        break;
      // case LIST_UNIT:
      //   setUnit(response?.data.data.records);
      //   break;
      // case LIST_STORE:
      //   setStore(response?.data.data.records);
      // break;
      case DELETE_USER:
        toast.success(response.data?.message);
        setConfrimDialog(false);
        Setuseractive(true);
        fetchUser(true);
        setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
        setDeleteUserLoading(false);
        break;
      default:
        break;
    }
  };
  const handleDeactivateUser = () => {
    setDeleteUserLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(DELETE_USER)
      .jsonParams({ isActive: "false", userId: user.userId })
      .reqURL(`auth/changeUserIsActiveStatus/${Id}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }
  const handleReactiveteUser = () => {
    setDeleteUserLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(DELETE_USER)
      .jsonParams({ isActive: "true", userId: user.userId })
      .reqURL(`auth/changeUserIsActiveStatus/${Id}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }
  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_USER:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setUserDataLoading(false);
          toast.error(`${response?.data?.message}`)
        }

        break;
      case CREATE_USER:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setLoading(false);
          response?.data?.message && toast.error(`${response?.data?.message}`);
        }
        break;
      // case LIST_UNIT:

      //   break;
      // case LIST_STORE:

      //   break;
      case DELETE_USER:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
          setDeleteUserLoading(false);
        }
        break;
      case UNIT_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      default:
        break;
    }
  };

  const handleTableChange = (type, newState) => {
    let url = useractive ? `user/userList/${user.userId}?organisationId=${user.organisationId}&page=${type === "search" || type === "sort" ? "1" : newState.page
    }` :
    `user/userList/${user.userId}?organisationId=${user.organisationId}&isActive=false&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }

    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === "name") {
        url = url + `&sortColumn=firstName`
      }
      if (newState.sortField === "email") {
        url = url + `&sortColumn=email`
      }
      if (newState.sortField === "mobileNumber") {
        url = url + `&sortColumn=mobileNumber`
      }
      if (newState.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }else{
      setSort('');
      setSortOrder('');
    }

    new APIRequest.Builder()
      .post()
      .setReqId(LIST_USER)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const DeleteClientUser = (e) => {
    new APIRequest.Builder()
      .post()
      .setReqId(UNIT_EXIST_OR_NOT)
      .jsonParams({
        userId: e.userId
      })
      .reqURL(`user/isUserExistingAssignments`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const editUser = (e) => {
    setUserEdit(true);
    setusereditdata(e);
  }

  const userDetail = (userRole) => {
    if (userRole === "SUBSCRIBER_USER") {
      return "User"
    } else if (userRole === "SUBSCRIBER_ADMIN") {
      return "Admin"

    } else if (userRole === "SITE_USER") {
      return "User"

    } else if (userRole === "SITE_ADMIN") {
      return "Admin"

    }
  }

  const handleActiveInactiveChange = (selected) => {
    selected.status === "Inactive" ? Setuseractive(false) : Setuseractive(true);
    getUserActiveInactiveList(selected.status === "Inactive" ? false : true)
    setSelectedOption(selected.status)
    showDropdownItem(false)
  }

  const handleTitleChange = (selected) => {
    setSelectedTitleOption(selected.title)
    useraddform.setFieldValue("title",selected.value)
    showTitleDropdownItem(false)
  }

  const handleUserChange = (selected) => {
    setSelectedUser(selected.type)
    useraddform.setFieldValue("role",selected.role)
    showUserDropdown(false)
  }

  const handleOnEditUser = (reload) => {
    setUserEdit(false);
    if(reload){
      fetchUser();
    }
  }

  const clearFilter = () => {
    Setuseractive(true);
    setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
    setSearch('');
    setSort('');
    setSortOrder('');
    isComponentDidMountFlag(false);
    fetchUser();
  }

  return (
    <div className="main_tabs">
      <div className="master_box">
        <div className="clients_menu mb-2">
          <a className='btn btn-primary' href="admin">Users</a>
          {user.role === "SUBSCRIBER_ADMIN" && 
          <a className='text-primary tab' href="organisation">Organisation</a>}
        </div>
        {userDataLoading &&
          <CommonLoader loading={userDataLoading} />
        }
        <div className="master_boxinfo">
          <div className="table-responsive pt-2 pb-5">
            {componentDidMountFlag &&
              <>
                {/* <div className='deactivelist'>
                  <RegularDropDown 
                    placeholder={selectedOption}
                    dropdownArr={Constants.activeInactiveStatus}
                    labelParam="status"
                    onDropDownItemClick={handleActiveInactiveChange}
                    isFocusRequired={true}
                    show={isShowDropDownItem}
                    onToggle={(isOpen) => showDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                  />
                </div> */}
                <div className="add_btns">
                {!isEmptyArray(userData?.records) && (search != "" || sort != "" || selectedOption == "Inactive") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                  {useractive && <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={setuseraddShow}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                    User
                  </button>}
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
            }
            {componentDidMountFlag && userData?.records && (
              <Table
                key={"table-user"}
                data={userData?.records?.map((item, index) => {
                  return {
                    ...item,
                    index: (userData.page - 1) * userData.limit + index + 1,
                    id: item?.userId,
                    name: item?.title + ' ' + item?.firstName + ' ' + item?.lastName,
                    email: item?.email,
                    mobileNumber: item?.mobileNumber,
                    unit: item?.unit ?? "-",
                    store: item?.store ?? "-",
                    role: userDetail(item?.role) ?? "-",
                    userrole: item?.role ?? "-",
                    key: index,
                  };
                }) ?? []}
                columns={[
                  {
                    dataField: "index",
                    text: "No",
                    headerClasses: "sr_no",
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setuserdetails(row); Setuserdetailsshow(true)
                      },
                    }
                  },
                  {
                    dataField: "name",
                    text: "Name",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setuserdetails(row); Setuserdetailsshow(true)
                      },
                    }
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setuserdetails(row); Setuserdetailsshow(true)
                      },
                    }
                  },
                  {
                    dataField: "mobileNumber",
                    text: "Mobile Number",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setuserdetails(row); Setuserdetailsshow(true)
                      },
                    }
                  },
                  {
                    dataField: "role",
                    text: "User Role",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setuserdetails(row); Setuserdetailsshow(true)
                      },
                    }
                  },
                  // isThirdParty && {
                  //   dataField: "unit",
                  //   text: "Unit",
                  //   sort: true,
                  // },
                  // isThirdParty &&{
                  //   dataField: "store",
                  //   text: "Store",
                  //   sort: true,
                  // }
                  {
                    dataField: "action",
                    text: "Action",
                    headerClasses: "action",
                    style: { cursor: "pointer" },
                    formatter: (cell, e) => {
                      return (
                        e?.isActive === "true" ?
                          <div className="edit_icons">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul
                                className="dropdown-menu p-1"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => editUser(e)}
                                  ><i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item"
                                    onClick={() => {
                                      DeleteClientUser(e)
                                      // if (e.role === "SUBSCRIBER_USER" || e.role === "SITE_USER") {
                                      //   DeleteClientUser(e.id)
                                      // } else {
                                      //   toast.error("Admin Cannot Delete")
                                      // }

                                    }}><i className="fa fa-trash me-1" aria-hidden="true"></i>
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> :
                          <div className='edit_icons'>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" onClick={() => {
                                  setId(e?.id)
                                  setConfrimDialog(true);
                                }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                              </ul>
                            </div>
                          </div>
                      );
                    },
                  },
                ]}
                handleTableChange={handleTableChange}
                totalSize={userData?.total ?? 0}
                currentPage={userData?.page ?? 1}
                sizePerPage={userData?.limit ?? 15}
              />
            )}
            {componentDidMountFlag && isEmptyArray(userData?.records) && 
              <div className="no-items-layout">
                <div className="no-items-card">
                  <h6>
                    {
                      Constants.message.noRecordsWarning
                    }
                  </h6>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      <ReusableModal
        show={confrimDialog}
        onClose={() => setConfrimDialog(false)}
        loading={deleteUserLoading}
        onConfirm={useractive ? handleDeactivateUser : handleReactiveteUser}
        message={useractive ? "Are you sure, you want to delete this user?" : "Are you sure, you want to reactivate this user?"}
      />
      {useradd &&
        <Modal show={useradd}>
          <form onSubmit={useraddform.handleSubmit}>
            <Modal.Header>
              <Modal.Title>Add User</Modal.Title>
              <div
                onClick={() => {
                  setuseraddShow(false);
                  useraddform.resetForm();
                  setSelectedTitleOption('')
                  setSelectedUser('')
                }}
                data-dismiss="modal">
                <i className="fa fa-close" aria-hidden="true"></i>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="row dropdown_width">
                <div className="col-md-6">
                  <label htmlFor="title" className="form-label">
                    Title*{" "}
                  </label>
                  <RegularDropDown 
                    placeholder={selectedTitleOption==''?Constants.placeholder.selectTitle:selectedTitleOption}
                    dropdownArr={Constants.title}
                    labelParam="title"
                    onDropDownItemClick={handleTitleChange}
                    isFocusRequired={true}
                    show={isShowTitleDropDownItem}
                    onToggle={(isOpen) => showTitleDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={Constants.placeholder.selectTitle}
                  />
                  {useraddform.touched.title && useraddform.errors.title ? (
                    <span className="error">{useraddform.errors.title}</span>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label htmlFor="firstName" className="form-label">
                    First Name*
                  </label>
                  <input
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={useraddform.handleChange}
                    value={useraddform.values.firstName}
                  />
                  {useraddform.touched.firstName && useraddform.errors.firstName ? (
                    <span className="error">{useraddform.errors.firstName}</span>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label htmlFor="middleName" className="form-label">
                    Middle Name
                  </label>
                  <input
                    className="form-control"
                    id="middleName"
                    name="middleName"
                    type="text"
                    onChange={useraddform.handleChange}
                    value={useraddform.values.middleName}
                  />
                  {useraddform.touched.middleName && useraddform.errors.middleName ? (
                    <span className="error">{useraddform.errors.middleName}</span>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label htmlFor="lastName" className="form-label">
                    Last Name*
                  </label>
                  <input
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={useraddform.handleChange}
                    value={useraddform.values.lastName}
                  />
                  {useraddform.touched.lastName && useraddform.errors.lastName ? (
                    <span className="error">{useraddform.errors.lastName}</span>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label htmlFor="email" className="form-label">
                    Email Id*
                  </label>
                  <input
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    onChange={useraddform.handleChange}
                    value={useraddform.values.email}
                  />
                  {useraddform.touched.email && useraddform.errors.email ? (
                    <span className="error">{useraddform.errors.email}</span>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label htmlFor="mobileNumber" className="form-label">
                    Mobile*
                  </label>
                  <input
                    className="form-control"
                    id="mobileNumber"
                    name="mobileNumber"
                    type="number"
                    onWheel={e => e.currentTarget.blur()}
                    onChange={useraddform.handleChange}
                    value={useraddform.values.mobileNumber}
                  />
                  {useraddform.touched.mobileNumber && useraddform.errors.mobileNumber ? (
                    <span className="error">{useraddform.errors.mobileNumber}</span>
                  ) : null}
                </div>
                {user.role === "SITE_ADMIN" &&
                  <div className="col-md-6">
                    <label htmlFor="userunit" className="form-label">
                      User Type*{" "}
                    </label>
                    <RegularDropDown 
                      placeholder={selectedUser==''?Constants.placeholder.userTypePlaceholder:selectedUser}
                      dropdownArr={Constants.userSiteType}
                      labelParam="type"
                      onDropDownItemClick={handleUserChange}
                      isFocusRequired={true}
                      show={isShowUserDropDown}
                      onToggle={(isOpen) => showUserDropdown(isOpen)}
                      defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                    />
                  </div>}
                {user.role === "SITE_USER" &&
                  <div className="col-md-6">
                    <label htmlFor="userunit" className="form-label">
                      User Type*{" "}
                    </label>
                    <RegularDropDown 
                      placeholder={selectedUser==''?Constants.placeholder.userTypePlaceholder:selectedUser}
                      dropdownArr={Constants.userSiteType}
                      labelParam="type"
                      onDropDownItemClick={handleUserChange}
                      isFocusRequired={true}
                      show={isShowUserDropDown}
                      onToggle={(isOpen) => showUserDropdown(isOpen)}
                      defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                    />
                  </div>}
                {user.role === "SUBSCRIBER_ADMIN" &&
                  <div className="col-md-6">
                    <label htmlFor="userunit" className="form-label">
                      User Type*{" "}
                    </label>
                    <RegularDropDown 
                      placeholder={selectedUser==''?Constants.placeholder.userTypePlaceholder:selectedUser}
                      dropdownArr={Constants.userSubscriberType}
                      labelParam="type"
                      onDropDownItemClick={handleUserChange}
                      isFocusRequired={true}
                      show={isShowUserDropDown}
                      onToggle={(isOpen) => showUserDropdown(isOpen)}
                      defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                    />
                  </div>}
                  {user.role === "SUBSCRIBER_USER" &&
                  <div className="col-md-6">
                    <label htmlFor="userunit" className="form-label">
                      User Type*{" "}
                    </label>
                    <RegularDropDown 
                      placeholder={selectedUser==''?Constants.placeholder.userTypePlaceholder:selectedUser}
                      dropdownArr={Constants.userSubscriberType}
                      labelParam="type"
                      onDropDownItemClick={handleUserChange}
                      isFocusRequired={true}
                      show={isShowUserDropDown}
                      onToggle={(isOpen) => showUserDropdown(isOpen)}
                      defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                    />
                  </div>}
                {useraddform.touched.role && useraddform.errors.role ? (
                  <span className="error">{useraddform.errors.role}</span>
                ) : null}



                {/* {isThirdParty && unit &&
                  <div className="col-md-6">
                    <label htmlFor="userunit" className="form-label">
                      Unit Name{" "}
                    </label>
                    <select
                      name="unitName"
                      className="form-control"
                      value={useraddform.values.userunit}
                      onChange={useraddform.handleChange}
                    >{unit.map(e =>
                      <option value={e.id}>{e.name}</option>
                    )}
                    </select>
                    {useraddform.touched.unitName && useraddform.errors.unitName ? (
                      <span className="error">{useraddform.errors.unitName}</span>
                    ) : null}
                  </div>}
                {store && isThirdParty && <div className="col-md-6">
                  <label htmlFor="userstore" className="form-label">
                    Store Name{" "}
                  </label>
                  <select
                    name="storeName"
                    className="form-control"
                    value={useraddform.values.userstore}
                    onChange={useraddform.handleChange}
                  >
                    {store.map(e =>
                      <option value={e.id}>{e.name}</option>
                    )}
                  </select>
                  {useraddform.touched.storeName && useraddform.errors.storeName ? (
                    <span className="error">{useraddform.errors.storeName}</span>
                  ) : null}
                </div>} */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => {
                setuseraddShow(false)
                useraddform.resetForm()
                setSelectedTitleOption('')
                setSelectedUser('')
              }}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Save"}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      }
      {useredit &&
        <EditUser usereditdata={usereditdata} isThirdParty={isThirdParty} useredit={useredit} setUserEdit={handleOnEditUser} user={user} />
      }
      {usedetails &&
        <Userdetails usedetails={usedetails} Setuserdetailsshow={Setuserdetailsshow} userdetailsshow={userdetailsshow} />
      }
    </div>
  );
};

export default Index;
