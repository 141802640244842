import React from 'react'
import Modal from "react-bootstrap/Modal";


const Userdetails = ({ userdetailsshow, Setuserdetailsshow ,usedetails }) => {

    return (
        <>
            <Modal show={userdetailsshow}>
                <Modal.Header className='bookstockheader'>
                  
                    <Modal.Title>User Details</Modal.Title>
                        <div 
                            onClick={() => {
                                Setuserdetailsshow(false);                               
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                </Modal.Header>               
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Name</p>
                        </div>
                        <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{usedetails?.name}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Email</p>
                        </div>
                        <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{usedetails?.email}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Phone number</p>
                        </div>
                        <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{usedetails?.mobileNumber}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Role</p>
                        </div>
                        <div className='col-md-8'>
                        <p className='detailslable '><span className='pe-4'>:</span>{usedetails?.role}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default Userdetails