import React from 'react'
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { APIRequest, LIST_VARIFACTION, LIST_STOCK } from '../../../api';
import Reconcilation from '../reconcilation';
import { Table } from '../../Table/Table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import { Form } from 'react-bootstrap';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../Component/common/Global/commonFunctions';
import Bookstockitem from './bookstockitem';
import AssignmentListDetails from '../../../Component/common/assignmentListDetails/assignmentListDetails';
import ActualStockModal from '../actualStockModal';
import CommonLoader from '../../../commonComponent/loader/commonLoader';

const Verification = () => {
  const location = useLocation();
  const status = location?.pathname?.split('/')[2] == "ongoingassignment" ? "Started" : "completed";
  const assignment = location?.state?.assignmentListDetails;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [varifactionlist, Setvarifactionlist] = useState([]);
  const [reconcilation, Setreconcilation] = useState(false);
  const [reconcilationitem, Setreconcilationitem] = useState([]);
  const [download, Setdownload] = useState([]);
  const [editactualstock, Seteditactualstock] = useState(false);
  const [editactualstockdetails, Setactualstockdetails] = useState();
  const [detailsShow, setDetailsShow] = useState(false);
  const [details, Setdetails] = useState([]);
  const { assignmentId } = useParams();
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [searchStock, setSearchStock] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const store = storeId;
  const [stockData, SetstockData] = useState([])
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [verifiedDetailsLoading, setVerifiedDetailsLoading] = useState(false);

  const [fileName, setFileName] = useState("Verification Reports");
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const user = useSelector((state) => state?.user);
  const formatter = new Intl.NumberFormat('en-IN');

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(download);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  const ReconcilationEvent = (e, item) => {
    Setreconcilationitem(item);
    Setreconcilation(true);
  }
  useEffect(() => {
    getVerification()
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_STOCK)
      .reqURL(`bookstock/${assignmentId}/getFilterStoreStockList`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }, [])


  useEffect(() => {
    Setdownload(varifactionlist?.records?.map((item, index) => {
      return {
        No: index + 1,
        key: index,
        PartNo: item?.BookStock?.partNo1 ?? "",
        SubPartNo1: item?.BookStock?.partNo2 ?? "",
        SubPartNo2: item?.BookStock?.partNo3 ?? "",
        SubPartNo3: item?.BookStock?.partNo4 ?? "",
        SubPartNo4: item?.BookStock?.partNo5 ?? "",
        StockType: item?.stockType ?? "",
        Materialname: item?.BookStock?.materialName ?? "",
        MaterialSubname1: item?.BookStock?.materialName1 ?? "",
        MaterialSubname2: item?.BookStock?.materialName2 ?? "",
        MaterialSubname3: item?.BookStock?.materialName3 ?? "",
        MaterialSubname4: item?.BookStock?.materialName4 ?? "",
        Location: item?.BookStock?.location1 ?? "",
        Location1: item?.BookStock?.Location2 ?? "",
        Location2: item?.BookStock?.location3 ?? "",
        Location3: item?.BookStock?.location4 ?? "",
        Location4: item?.BookStock?.location5 ?? "",
        UOM: item?.BookStock?.uom ?? "",
        Rate: item?.BookStock?.rate ?? "-",
        Value: item?.BookStock?.value ?? "",
        BookQuantity: item?.BookStock?.quantity ?? "-",
        ActualStockquantity: item?.actualStock ?? "",
        difference: item?.actualStock - item?.BookStock?.quantity ?? "",
        ExpiryDate: item?.BookStock?.expiryDate ?? "",
        LastReceiptDate: item?.BookStock?.lastReceiptDate ?? "",
        LastIssueDate: item?.BookStock?.lastIssueDate ?? "",
        ReconcilationObservations: item?.ReconcilationObservations ?? [],
        Remarks: item?.BookStock?.remarks ?? "",
        FilePath: item?.filepath ?? "",
        ActualStockRemarks: item?.remarks ?? ""

      }
    }) ?? [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varifactionlist])
  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_VARIFACTION:
        Setvarifactionlist(response.data?.data)
        isComponentDidMountFlag(true);
        setVerifiedDetailsLoading(false);
        break;
      case LIST_STOCK:
        SetstockData(response.data?.storeType);
        break;
      default:
        break;
    }
  }

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_VARIFACTION:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else if(response.data?.errorStatus === Constants.status.codeNotAccess){
          setVerifiedDetailsLoading(false);
          navigate(`/Client/ongoingassignment/${assignmentId}/verification/${randomStringGenerator(30)}`);
        }else{
          setVerifiedDetailsLoading(false);
        }
        break;
      case LIST_STOCK:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {

        }
        break;
      default:
        break;
    }
  }
  const getStoreStockFilterList = (storeId, stockId) => {
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&page=1&limit=15`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&page=1&limit=15&stockType=${stockId}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&page=1&limit=15&storeType=${storeId}&stockType=${stockId}`;
    }
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_VARIFACTION)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  const getVerification = () => {
    setVerifiedDetailsLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_VARIFACTION)
      .reqURL(`bookstock/getVerification/${assignmentId}?type=list&page=1&limit=15`)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  const handleTableChange = (type, newState) => {
    let url = "";
    if (storeId == "" && stockId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&storeType=${storeId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (stockId != "" && storeId == "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    } else if (storeId != "" && stockId != "") {
      url = `bookstock/getVerification/${assignmentId}?type=list&storeType=${storeId}&stockType=${stockId}&page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    }
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if(newState.searchText == ""){
      setSearch("");
    }
    if (newState?.searchText) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }

    if (newState?.sortField) {
      setSort(newState?.sortField)
      if (newState?.sortField === "Materialname") {
        url = url + `&sortColumn=materialName`
      } if (newState?.sortField === "PartNo") {
        url = url + `&sortColumn=partNo1`
      }
      if (newState?.sortField === "uom") {
        url = url + `&sortColumn=uom`
      }
      if (newState?.sortField === "rate") {
        url = url + `&sortColumn=rate`
      }
      if (newState.sortField === "value") {
        url = url + `&sortColumn=value`
      }
      if (newState?.sortField === "bookstockquantity") {
        url = url + `&sortColumn=quantity`
      }
      if (newState?.sortField === "actualquantity") {
        url = url + `&sortColumn=actualStockQuantity`
      }

      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }
    new APIRequest.Builder()
      .post()
      .setReqId(LIST_VARIFACTION)
      .reqURL(url)
      .jsonParams({ userId: user?.userId, status: status })
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  };
  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockId, "label": stockItem?.stockTypeName });
    });
  });

  // onchange event for store
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setSearchStock('search');
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "")
      setSelectedStoreType(selected.label)
    }
    showStoreTypeDropdownItem(false);
  };

  // onchange event for stock
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setSearchStock('search');
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value)
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };

  const clearFilter = () => {
    isComponentDidMountFlag(false);
    getVerification();
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setSearch('');
    setSort('');
    setSortOrder('');
    Setvarifactionlist([])
  }
  const handleOnClose = (reload) => {
    Setreconcilation(false);
    if(reload){
      getVerification();
    }
  }

  const handleActualStockModalClose = (reload) => {
    Seteditactualstock(false);
    if(reload){
      getVerification();
    }
  }
  return (
    <>
      {assignment && 
        <AssignmentListDetails
            assignment={assignment}
        />
      }
      {verifiedDetailsLoading &&
        <CommonLoader loading={verifiedDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
          <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/ongoingassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
            <a className='btn btn-primary' href="verification"> Verified </a>
          </div>
          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
              {componentDidMountFlag && 
              <>
                <div className="add_btns bookstock_dropdown_width">
                  {!isEmptyArray(varifactionlist?.records) && (search != ""  || sort != "" || storeId != "" || stockId != "") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                  <RegularDropDown 
                    placeholder={selectedStoreType}
                    dropdownArr={storeOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStoreChange}
                    isFocusRequired={true}
                    show={isShowStoreTypeDropDownItem}
                    onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Store Type"}
                  />
                  <RegularDropDown 
                    placeholder={selectedStockType}
                    dropdownArr={stockOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStockChange}
                    isFocusRequired={true}
                    show={isShowStockTypeDropDownItem}
                    onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Stock Type"}
                  />

                  <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={(e) => exportToCSV()}
                  >
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                    ></i>{" "}
                    Download
                  </button>
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
              }
              {componentDidMountFlag && (
                <Table
                  data={varifactionlist?.records?.map((item, index) => {
                    return {
                      ...item,
                      index: (varifactionlist?.page - 1) * varifactionlist.limit + index + 1,
                      id: item.id,
                      partNo1: item?.BookStock?.partNo1 ?? "-",
                      materialName: item?.BookStock?.materialName ?? "-",
                      uom: item?.BookStock?.uom ?? "-",
                      rate: isEmptyVariable(item?.BookStock?.rate) ? "-" : formatter.format(item?.BookStock?.rate),
                      bookstockquantity: isEmptyVariable(item?.BookStock?.quantity) ? "-" : item?.BookStock?.quantity,
                      actualquantity: isEmptyVariable(item?.actualStockQuantity) ? "-" : item?.actualStockQuantity,
                      reconciliation: isEmptyVariable(item?.reconcilationValue) ? "-" : item?.reconcilationValue,
                      status: item?.status,
                      difference: item?.diffvalue == null ? "multiple" : item?.diffvalue,
                      value: isEmptyVariable(item?.BookStock?.value) ? "-" : formatter.format(item?.BookStock?.value),
                      key: index,
                    };
                  }) ?? []}
                  columns={[
                    {
                      dataField: "index",
                      text: "No",
                      headerClasses: "sr_no",
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "partNo1",
                      text: "Part No.",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "materialName",
                      text: "Name",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "uom",
                      text: "UOM",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "rate",
                      text: "Rate",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "bookstockquantity",
                      text: "Book Qty",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      },
                    },
                    {
                      dataField: "actualquantity",
                      text: "Actual Qty",
                      sort: true,
                      style: { cursor: "pointer" },
                      formatter: (cell, item) => {
                        return (
                          item?.actualStockQuantity != "multiple" ? <div className='d-flex align-items-center'>
                          <a
                            onClick={() => {
                              Seteditactualstock(true);
                              Setactualstockdetails(item);
                            }}
                          >
                            <i className="fa fa-pencil me-2" aria-hidden="true"></i>
                          </a>
                            <span className=''>{item?.actualStockQuantity}</span>
                        </div> : "multiple"
                        )
                      },
                    },
                    {
                      dataField: "reconciliation",
                      text: "Reco.",
                      sort: true,
                      style: { cursor: "pointer" },
                      formatter: (cell, item) => {
                        return (
                          item?.actualStockQuantity != "multiple" ? <div className='d-flex align-items-center'>
                            <a
                                    onClick={(e) => ReconcilationEvent(e, item)}
                                  > <i className="fa fa-list me-2" aria-hidden="true"></i>
                                  </a>
                            <span className=''>{isEmptyVariable(item?.reconcilationValue) ? "-" : item?.reconcilationValue}</span>
                        </div>:"multiple"
                        )
                      },
                    },
                    {
                      dataField: "value",
                      text: "Value",
                      sort: true,
                      style: { cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setdetails(row); setDetailsShow(true)
                        },
                      }
                    },
                    {
                      dataField: "difference",
                      text: "Difference",
                      sort: true,
                      style: "difference" !== null ? { color: 'red', cursor: "pointer" } : { color: 'black', cursor: "pointer" },
                      events: {
                        onClick: (e, column, columnIndex, row, rowIndex) => {
                          Setreconcilationitem(row); Setreconcilation(true)
                        },
                      }
                    },
                  ]}
                  handleTableChange={handleTableChange}
                  totalSize={varifactionlist?.total ?? 0}
                  currentPage={varifactionlist?.page ?? 1}
                  sizePerPage={varifactionlist?.limit ?? 15}
                />
              )}
              {componentDidMountFlag && isEmptyArray(varifactionlist?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

      </div>
      {editactualstock &&
        <ActualStockModal
          handleActualStockModalClose={handleActualStockModalClose} 
          showActualStockModal={editactualstock} 
          bookStockDetails={editactualstockdetails} 
          clientName={assignment?.clientName}
        />
      }

      {/* {reconcilation &&  */}
        <Reconcilation assignment={assignment} reconcilation={reconcilation} handleOnClose={handleOnClose} reconcilationitem={reconcilationitem} assignmentId={assignmentId}/>
      {/* } */}
      {detailsShow &&
          <Bookstockitem bookStockDetailsShow={detailsShow} setBookStockDetailsShow={setDetailsShow} details={details.BookStock} actualquantity={details.actualquantity}/>
      }
      </>
  )
}

export default Verification;