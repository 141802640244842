import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import * as Yup from "yup";
import { useFormik } from 'formik';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import './textstyle.css';
import { APIRequest, OBSERVATIONS_CREATE, } from '../../api'
import { Instances } from '../Table/instances';
import { logout } from '../../redux/action';
import * as Constants from '../common/Global/constants';
import CommonButton from '../../commonComponent/button/commonButton';

function ObservationsTable({ addNewObservationsModalShow, handleAddNewObservationsModalOnClose }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { assignmentId } = useParams();
    const user = useSelector((state) => state?.user);
    const [loading, setLoading] = useState(false);
    const [executiveSummery, setExecutiveSummery] = useState(false);
    const [suggestion, setSuggestion] = useState(false);
    const [annexureSelectedFiles, setAnnexureSelectedFiles] = useState([]);
    const [supprotingSelectedFiles, setSupprotingSelectedFiles] = useState([]);
    const [annexureDescriptionArray, setAnnexureDescriptionArray] = useState([]);
    const [supprotingDescriptionArray, setSupprotingDescriptionArray] = useState([]);
    const [focusObj, setFocusObj] = useState({});

    let item = {
        fieldValue: [["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"], ["", "", "", "", "", "Action"]]
    }

    const [table, setTable] = useState(item.fieldValue);

    // Reset state variables when the addNewObservationsModalShow prop changes.
    useEffect(() => {
        setAnnexureDescriptionArray([]);
        setAnnexureSelectedFiles([]);
        setSupprotingSelectedFiles([]);
        setSupprotingDescriptionArray([]);
    }, [addNewObservationsModalShow])

    // Handle file uploads for annexure files.
    const annexureFileUpload = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.target.files.length; i++) annexureDescriptionArray.push({fileName: e.target.files[i].name, description: "", uploadedFileType: "annexure" });
        setAnnexureSelectedFiles([...annexureSelectedFiles, ...e.target.files]);
    }

    // Handle file uploads for supporting files.
    const supportingFileUpload = (e) => {
        e.preventDefault();
        for (let i = 0; i < e.target.files.length; i++) supprotingDescriptionArray.push({ fileName: e.target.files[i].name, description: "", uploadedFileType: "supporting" });
        setSupprotingSelectedFiles([...supprotingSelectedFiles, ...e.target.files]);
    }

    const observationsTableform = useFormik({
        initialValues: {
            assignmentId: assignmentId,
            observationHeading: '',
            observationDetails: '',
            executiveSummary: '',
            suggestion: '',
            riskImpact: '',
            instances: table,
            createdBy: user.userId,
        },
        validationSchema: Yup.object().shape({
            observationHeading: Yup.string().required('Observation Heading Must Require'),
            observationDetails: Yup.string().required('Observation Details Must Require')
        }),
        onSubmit: values => {
            const files = [...annexureSelectedFiles, ...supprotingSelectedFiles];
            const descriptions = [...annexureDescriptionArray, ...supprotingDescriptionArray];
            var observationData = new FormData();
            observationData.append('assignmentId', parseInt(assignmentId));
            observationData.append('observationHeading', values.observationHeading);
            observationData.append('observationDetails', values.observationDetails);
            observationData.append('executiveSummary', values.executiveSummary);
            observationData.append('suggestion', values.suggestion);
            observationData.append('riskImpact', values.riskImpact);
            observationData.append('createdBy', user.userId);
            observationData.append('instances',JSON.stringify(table.map(row => row.slice(0, -1))))
            observationData.append('descriptions', JSON.stringify(descriptions));
            files.forEach((file) => {
                observationData.append('obsFiles', file);
            });
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(OBSERVATIONS_CREATE)
                .reqURL("assignments/addObservatoin")
                .jsonParams(observationData)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        },
    });

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case OBSERVATIONS_CREATE:
                setLoading(false);
                setTable(item.fieldValue);
                toast.success(response?.data?.message);
                handleAddNewObservationsModalOnClose(true);
                observationsTableform.resetForm();
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case OBSERVATIONS_CREATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(response?.data?.message);
                }
                break;
            default:
                break;
        }
    }

    // Handle changes in annexure description input field
    const handleAnnexureOnChange = (e, index) => {
        const updatedArray = [...annexureDescriptionArray];
        updatedArray[index].description = e.target.value;
        setAnnexureDescriptionArray([...updatedArray]);
    }

    // Handle changes in supporting description input field
    const handleSupportingOnChange = (e, index) => {
        const updatedArray = [...supprotingDescriptionArray];
        updatedArray[index].description = e.target.value;
        setSupprotingDescriptionArray([...updatedArray]);
    }

    // Function to delete annexure files
    const deleteAnnexureFiles = (i) => {
        const row = [...annexureSelectedFiles];
        row.splice(i, 1);
        const darray = [...annexureDescriptionArray];
        darray.splice(i, 1);
        setAnnexureDescriptionArray(darray);
        setAnnexureSelectedFiles(row);
    }

    // Function to delete supporting files
    const deleteSupportingFiles = (i) => {
        const row = [...supprotingSelectedFiles];
        row.splice(i, 1);
        const darray = [...supprotingDescriptionArray];
        darray.splice(i, 1);
        setSupprotingDescriptionArray(darray);
        setSupprotingSelectedFiles(row);
    }

    // Configuration object for the Quill editor
    const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, false] }],
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }, { 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],
            ["blockquote", { 'font': [] }],
			["clean"],
		]
	};

    // Handle focus changes in the input fields
    const handleFocus = (focusedFieldName) => {
        // If no field is focused, return an object with all fields set to false
		if (focusedFieldName ==  "") {
			return {
				observationHeading_isFocused: false,
				detailedObservation_isFocused: false,
				executiveSummary_isFocused: false,
				riskImpact_isFocused: false,
				suggestion_isFocused: false,
			};
		} else {
            // Otherwise, return an object with all fields set to false except for the focused field
			return {
				observationHeading_isFocused: false,
				detailedObservation_isFocused: false,
				executiveSummary_isFocused: false,
				riskImpact_isFocused: false,
				suggestion_isFocused: false,

				[focusedFieldName]: true,
			};
		}
	};

    // Function to handle focus on observation heading input field
    const onObsHeadingFocus = () => {
        let focusObj = handleFocus("observationHeading_isFocused");
        setFocusObj({...focusObj});
    };

    // Function to handle focus on detailed observation input field
    const onDetailedObsFocus = () => {
		let focusObj = handleFocus("detailedObservation_isFocused");
		setFocusObj({...focusObj});
	};

    // Function to handle focus on executive summary input field
    const onExecutiveSummaryFocus = () => {
		let focusObj = handleFocus("executiveSummary_isFocused");
		setFocusObj({...focusObj});
	};

    // Function to handle focus on risk impact input field
    const onRiskImpactFocus = () => {
        let focusObj = handleFocus("riskImpact_isFocused");
		setFocusObj({...focusObj});
    };

    // Function to handle focus on suggestion input field
    const onSuggestionFocus = () => {
		let focusObj = handleFocus("suggestion_isFocused");
        setFocusObj({...focusObj});
	};

    return (
        <>
            <Modal size='lg' show={addNewObservationsModalShow} className="scrollable-modal">
                <Modal.Header>
                    <Modal.Title>Observations</Modal.Title>
                    <div
                        onClick={() => {
                            handleAddNewObservationsModalOnClose();
                            observationsTableform.resetForm();
                            setAnnexureDescriptionArray([]);
                            setAnnexureSelectedFiles([]);
                            setSupprotingSelectedFiles([]);
                            setSupprotingDescriptionArray([]);
                            setTable(item.fieldValue);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <form>
                        <div className='observations_tittle'>
                            <label>Observation heading*</label>
                        </div>
                        <div>
                            <ReactQuill
                                className={focusObj.observationHeading_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.observationHeading}
								onChange={(event) => {
                                    observationsTableform.setFieldValue('observationHeading', event)
                                }}
                                onFocus={onObsHeadingFocus}
								modules={modules}
							/>
                        </div>
                        {observationsTableform.touched.observationHeading && observationsTableform.errors.observationHeading ? (
                            <span className="error">{observationsTableform.errors.observationHeading}</span>
                        ) : null}
                        <div className='observations_tittle'>
                            <label>Observation Details*</label>
                        </div>
                        <div>
                            <ReactQuill
                                className={focusObj.detailedObservation_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.observationDetails}
								onChange={(event) => {
                                    observationsTableform.setFieldValue('observationDetails', event)
                                }}
                                onFocus={onDetailedObsFocus}
								modules={modules}
							/>
                        </div>
                        {observationsTableform.touched.observationDetails && observationsTableform.errors.observationDetails ? (
                            <span className="error">{observationsTableform.errors.observationDetails}</span>
                        ) : null}
                        <div className='observations_tittle'>
                            <label>Executive Summary</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                                    onClick={(e) => { if (e.target.checked) { setExecutiveSummery(true) } else { setExecutiveSummery(false) }; }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                    Not Required
                                </label>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.executiveSummary_isFocused?"two-lines-focus":"two-lines"}
                                readOnly={executiveSummery}
								value={observationsTableform.values.executiveSummary}
								onChange={(event) => {
                                    observationsTableform.setFieldValue('executiveSummary', event)
                                }}
                                onFocus={onExecutiveSummaryFocus}
								modules={modules}
							/>
                        </div>
                        <div className='observations_tittle'>
                            <label>Risk/Impact</label>
                        </div>
                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.riskImpact_isFocused?"two-lines-focus":"two-lines"}
								value={observationsTableform.values.riskImpact} 
								onChange={(event) => {
                                    observationsTableform.setFieldValue('riskImpact', event)
                                }}
                                onFocus={onRiskImpactFocus}
								modules={modules}
							/>
                        </div>
                        <Instances SetTable={setTable} table={table} />
                        <div className='observations_tittle'>
                            <label>Suggestion</label>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault2"
                                    onClick={(e) => { if (e.target.checked) { setSuggestion(true) } else { setSuggestion(false) }; }} />
                                <label className="form-check-label" htmlFor="flexCheckDefault2">
                                    Not Required
                                </label>
                            </div>
                        </div>
                        <div className='mt-1'>
                            <ReactQuill
                                className={focusObj.suggestion_isFocused?"two-lines-focus":"two-lines"}
                                readOnly={suggestion}
								value={observationsTableform.values.suggestion}
								onChange={(event) => {
                                    observationsTableform.setFieldValue('suggestion', event)
                                }}
                                onFocus={onSuggestionFocus}
								modules={modules}
							/>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <label>Annexure</label>
                                <input type="file"
                                    name="annexure"
                                    className='form-control mb-2 border'
                                    id="annexure"
                                    multiple
                                    onChange={annexureFileUpload}
                                />
                                {annexureSelectedFiles?.map((file, index) => (
                                    <>
                                        <div className='row d-flex'>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>

                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type="text"
                                                    name={`descriptions.${index}.description`}
                                                    onChange={(e) => handleAnnexureOnChange(e, index)}
                                                    className="form-control mb-2  m-auto"
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <span id="list" onClick={() => deleteAnnexureFiles(index)}>
                                                    <i className="fa fa-trash ms-1"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className='col-md-6'>
                                <label>Supporting</label>
                                <input type="file"
                                    className="form-control mb-2 border"
                                    id="supporting"
                                    name="supporting"
                                    multiple
                                    onChange={supportingFileUpload}
                                >
                                </input>
                                {supprotingSelectedFiles?.map((file, index) => (
                                    <>
                                        <div className='row d-flex'>
                                            <div className='col-md-4'>
                                                <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>

                                            </div>
                                            <div className='col-md-6'>
                                                <input
                                                    type="text"
                                                    name={`descriptions.${index}.description`}
                                                    onChange={(e) => handleSupportingOnChange(e, index)}
                                                    className="form-control mb-2  m-auto"
                                                />
                                            </div>
                                            <div className='col-md-2'>
                                                <span id="list" onClick={() => deleteSupportingFiles(index)}>
                                                    <i className="fa fa-trash ms-1"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton
                        style={{marginRight: 10}}
                        loading={loading}
                        label={'Save'}
                        onClick={() => observationsTableform.handleSubmit()}
                    />
                    <CommonButton
                        label={'Cancel'}
                        onClick={() => {
                            handleAddNewObservationsModalOnClose();
                            observationsTableform.resetForm();
                            setAnnexureSelectedFiles([]);
                            setAnnexureDescriptionArray([]);
                            setSupprotingSelectedFiles([]);
                            setSupprotingDescriptionArray([]);
                            setTable(item.fieldValue);
                        }}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ObservationsTable;