import { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { APIRequest, COMMON_LIST, COMMON_CREATE, COMMON_EDIT, COMMON_DELETE, UNIT_EXIST_OR_NOT, STORE_EXIST_OR_NOT, STOCK_EXIST_OR_NOT } from "../../../api";
import { Table } from '../../Table/Table';
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';
import ReusableModal from '../../Modal/reusablemodel';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable } from '../../../Component/common/Global/commonFunctions';

const MasterMain = (props) => {
  const { type } = props;
  const [show, setShow] = useState(false);
  const [Id, setId] = useState();
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [pageInfo, setPageInfo] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    generalForm.setFieldValue('shortName', '');
    generalForm.setFieldValue('name', '');
    generalForm.setFieldValue('id', '');
    generalForm.resetForm();
  };
  const [UnitId, SetUnitId] = useState()
  const handleShow = () => setShow(true);
  const [tableData, setTableData] = useState({
    total: 0,
    records: [],
    page: 1,
    limit: 15
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isActive, SetisActive] = useState(true);
  const user = useSelector(state => state.user);
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setPageInfo(addPageinfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const addPageinfo = () => {
    const info = {};
    switch (type) {
      case 'Stock':
        info.inactivelist = "stockType/allStockTypeList?isActive=false"
        info.table = 'stockType/allStockTypeList?';
        info.title = 'Stock';
        info.createApi = 'stockType/addStockType';
        info.editApi = 'stockType/updateStockType/:id';
        info.listApi = 'stockType/allStockTypeList?page=1&limit=15';
        info.deleteApi = 'stockType/changeStockTypeIsActiveStatus/:id'
        return info;
      case 'Unit':
        info.inactivelist = "unitType/getUnitTypeList?isActive=false"
        info.table = 'unitType/getUnitTypeList?';
        info.title = 'Unit';
        info.createApi = 'unitType/addUnitType';
        info.editApi = 'unitType/updateUnitType/:id ';
        info.listApi = 'unitType/getUnitTypeList?page=1&limit=15';
        info.deleteApi = 'unitType/changeUnitTypeIsActiveStatus/:id '
        return info;
      case 'Store':
        info.inactivelist = "storeType/getStoreTypeList?isActive=false"
        info.table = 'storeType/getStoreTypeList?';
        info.title = 'Store';
        info.createApi = 'storeType/addStoreType';
        info.editApi = 'storeType/updateStoreType/:id';
        info.listApi = 'storeType/getStoreTypeList?page=1&limit=15';
        info.deleteApi = 'storeType/changeStoreTypeIsActiveStatus/:id '
        return info;
      default:
        return 'foo';
    }
  }

  const masterListApi = () => {
    setIsLoading(true);
    if (pageInfo.listApi) {
      isActive && new APIRequest.Builder()
        .get()
        .setReqId(COMMON_LIST)
        .reqURL(pageInfo.listApi)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();

      !isActive && new APIRequest.Builder()
        .get()
        .setReqId(COMMON_LIST)
        .reqURL(pageInfo.inactivelist)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  useEffect(() => {
    masterListApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo, isActive])


  const generalForm = useFormik({
    initialValues: {
      shortName: '',
      name: '',
    },
    validationSchema: Yup.object().shape({
      shortName: Yup.string().required("Enter ShortName"),
      name: Yup.string().required("Enter Name"),

    }),
    onSubmit: values => {
      const editUrl = isEdit ? pageInfo.editApi.replace(":id", values.id) : '';
      if (pageInfo.title === "Store") {
        let finalValue = {
          storeTypeShortName: values.shortName,
          storeTypeName: values.name,
          organisationId: user.organisationId,
          createdBy: user?.userId,
          updatedBy: user?.userId,
        }
        new APIRequest.Builder()
          .post()
          .setReqId(!isEdit ? COMMON_CREATE : COMMON_EDIT)
          .jsonParams(finalValue)
          .reqURL(!isEdit ? pageInfo.createApi : editUrl)
          .response(onResponse)
          .error(onError)
          .build()
          .doRequest();

      }

      if (pageInfo.title === "Stock") {
        let finalValue = {
          stockTypeShortName: values.shortName,
          stockTypeName: values.name,
          organisationId: user.organisationId,
          createdBy: user?.userId,
          updatedBy: user?.userId,
        }
        new APIRequest.Builder()
          .post()
          .setReqId(!isEdit ? COMMON_CREATE : COMMON_EDIT)
          .jsonParams(finalValue)
          .reqURL(!isEdit ? pageInfo.createApi : editUrl)
          .response(onResponse)
          .error(onError)
          .build()
          .doRequest();

      }

      if (pageInfo.title === "Unit") {
        let finalValue = {
          unitTypeShortName: values.shortName,
          unitTypeName: values.name,
          organisationId: user.organisationId,
          createdBy: user?.userId,
          updatedBy: user?.userId,
        }
        new APIRequest.Builder()
          .post()
          .setReqId(!isEdit ? COMMON_CREATE : COMMON_EDIT)
          .jsonParams(finalValue)
          .reqURL(!isEdit ? pageInfo.createApi : editUrl)
          .response(onResponse)
          .error(onError)
          .build()
          .doRequest();

      }

    },
  });

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case COMMON_CREATE:
        masterListApi();
        handleClose();
        toast.success(response.data?.message);
        generalForm.resetForm();
        break;
      case COMMON_EDIT:
        masterListApi();
        handleClose();
        toast.success(response.data?.message);
        generalForm.resetForm();
        break;
      case COMMON_LIST:
        setIsLoading(false)
        setTableData(response?.data?.data)
        isComponentDidMountFlag(true);
        break;
      case UNIT_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.unitTypeId);
        break;
      case STOCK_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.stockTypeId);
        break;
      case STORE_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.storeTypeId);
        break;
      case COMMON_DELETE:
        masterListApi();
        setConfrimDialog(false);
        toast.success(response.data?.message);
        setDeleteLoading(false);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case COMMON_CREATE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setIsLoading(false)
          toast.error(`${response.data.message}`)
        }
        break;
      case UNIT_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      case STOCK_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      case STORE_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      case COMMON_LIST:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(response?.data?.message);
        }
        break;
      case COMMON_DELETE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(response?.data?.message);
          setDeleteLoading(false);
        }
        break;
      case COMMON_EDIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response.data.message}`)
        }
        break;
      default:
        break;
    }
  };

  const editRecord = (id, sName, name) => {
    setIsEdit(true);
    generalForm.setFieldValue('shortName', sName);
    generalForm.setFieldValue('name', name);
    generalForm.setFieldValue('id', id);
    handleShow()
  }

  const handleDelete = (id, data) => {
    if (pageInfo.title === "Unit") {
      SetUnitId(id)
      new APIRequest.Builder()
        .post()
        .setReqId(UNIT_EXIST_OR_NOT)
        .jsonParams({
          unitTypeId: id
        })
        .reqURL(`unitType/isUnitTypeExisting`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    } else if (pageInfo.title === "Stock") {
      new APIRequest.Builder()
        .post()
        .setReqId(STOCK_EXIST_OR_NOT)
        .jsonParams({
          stockTypeId: id
        })
        .reqURL(`stockType/isStockTypeExisting`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
    else if (pageInfo.title === "Store") {
      new APIRequest.Builder()
        .post()
        .setReqId(STORE_EXIST_OR_NOT)
        .jsonParams({
          storeTypeId: id
        })
        .reqURL(`storeType/isStoreTypeExisting`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const handleDeactivate = () => {
    setDeleteLoading(true);
    if (pageInfo.title === "Unit") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'false',
          userId: user?.userId
        })
        .reqURL(`unitType/changeUnitTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    } else if (pageInfo.title === "Stock") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'false',
          userId: user?.userId
        })
        .reqURL(`stockType/changeStockTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
    else if (pageInfo.title === "Store") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'false',
          userId: user?.userId
        })
        .reqURL(`storeType/changeStoreTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const handleReactivete = () => {
    setDeleteLoading(true);
    if (pageInfo.title === "Unit") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'true',
          userId: user?.userId
        })
        .reqURL(`unitType/changeUnitTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    } else if (pageInfo.title === "Stock") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'true',
          userId: user?.userId
        })
        .reqURL(`stockType/changeStockTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    } else if (pageInfo.title === "Store") {
      new APIRequest.Builder()
        .post()
        .setReqId(COMMON_DELETE)
        .jsonParams({
          isActive: 'true',
          userId: user?.userId
        })
        .reqURL(`storeType/changeStoreTypeIsActiveStatus/${Id}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const handleTableChangeCity = (type, newState) => {
    // useractive ? `user/userList/${user.userId}?organisationId=${user.organisationId}&page=${type === "search" || type === "sort" ? "1" : newState.page
    //   }&` :
    //   `user/userList/${user.userId}?organisationId=${user.organisationId}&isActive=false&page=${type === "search" || type === "sort" ? "1" : newState.page}&`;
    let url = isActive == true ? `${pageInfo.table}&isActive=true&page=${(type === "search" || type === "sort") ? "1" : newState.page}` :
      `${pageInfo.table}&isActive=false&page=${(type === "search" || type === "sort") ? "1" : newState.page}`;
      if (newState.sizePerPage) {
        url = url + `&limit=${newState.sizePerPage}`
      }
      if (!isEmptyVariable(newState?.searchText)) {
      url = url + `&search=${newState.searchText}`
    }
    if (!isEmptyVariable(newState?.sortField)) {
      if (pageInfo.title === "Unit") {
        if (newState.sortField === "name") {
          url = url + `&sortColumn=unitTypeName`
        } if (newState.sortField === "sort_name") {
          url = url + `&sortColumn=unitTypeShortName`
        }
      }
      if (pageInfo.title === "Stock") {
        if (newState.sortField === "name") {
          url = url + `&sortColumn=stockTypeName`
        } if (newState.sortField === "sort_name") {
          url = url + `&sortColumn=stockTypeShortName`
        }
      }
      if (pageInfo.title === "Store") {

        if (newState.sortField === "name") {
          url = url + `&sortColumn=storeTypeName`
        } if (newState.sortField === "sort_name") {
          url = url + `&sortColumn=storeTypeShortName`
        }
      }
      if (newState.sortOrder) {
        url = url + `&sortType=${newState.sortOrder}`
      }
    }

    

    setIsLoading(true);
    if (pageInfo.listApi) {
      new APIRequest.Builder()
        .get()
        .setReqId(COMMON_LIST)
        .reqURL(url)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const handleActiveInactiveChange = (selected) => {
      selected.status === "Inactive" ? SetisActive(false) : SetisActive(true);
      setSelectedOption(selected.status)
      showDropdownItem(false)
  }

  return (
    <div className='table-responsive pt-2 pb-5 mt-2'>
      <div className='deactivelist'>
        <RegularDropDown 
          placeholder={selectedOption}
          dropdownArr={Constants.activeInactiveStatus}
          labelParam="status"
          onDropDownItemClick={handleActiveInactiveChange}
          isFocusRequired={true}
          show={isShowDropDownItem}
          onToggle={(isOpen) => showDropdownItem(isOpen)}
          defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
        />
      </div>
      <div className='add_btns'>
        {isActive && <button type='button' className='btn btn-primary' variant="primary" onClick={handleShow}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add New</button>}
      </div>
      <i className="fa fa-search search_icon" aria-hidden="true"></i>
      {componentDidMountFlag && tableData?.records &&
        <Table data={tableData?.records?.map((item, index) => {
          return {
            ...item,
            key: index,
            index: (tableData?.page - 1) * tableData.limit + index + 1,
            id: item.stockTypeId ? item.stockTypeId : item.storeTypeId ? item.storeTypeId : item.unitTypeId,
            name: item.storeTypeName ? item.storeTypeName : item.stockTypeName ? item.stockTypeName : item.unitTypeName,
            sort_name: item.storeTypeShortName ? item.storeTypeShortName : item.stockTypeShortName ? item.stockTypeShortName : item.unitTypeShortName,
            organisation: (pageInfo.title === "Store") ? (item?.Organisation?.organisationId == 1 ? "Admin Created " : item?.Organisation?.organisation ?? "Admin Created") : item?.Organisation?.organisation ?? "Admin Created"
          }
        })}
        columns={[
          {
            dataField: 'index',
            text: 'No',
            headerClasses: 'sr_no'
          },
          {
            dataField: 'sort_name',
            text: 'Short Name',
            sort: true,
          },
          {
            dataField: 'name',
            text: 'Name',
            sort: true,
          },
          pageInfo.title !== "Unit" && {
            dataField: 'organisation',
            text: 'Organisation Name',
            sort: true,
          },
          {
            dataField: 'action',
            text: 'Action',
            headerClasses: 'action',
            formatter: (cell, e) => {
              return (
                e?.isActive === "true" ?
                  <div className='edit_icons'>
                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>
                      <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" data-bs-toggle="#modal" onClick={() => editRecord(e.id, e.sort_name, e.name)}><i className="fa fa-pencil me-1" aria-hidden="true"></i> Edit</a></li>
                        <li><a className="dropdown-item" onClick={() => handleDelete(e?.id)}><i className="fa fa-trash me-1" aria-hidden="true"></i> Delete</a></li>
                      </ul>
                    </div>
                  </div> :
                  <div className='edit_icons'>
                    <div className="dropdown">
                      <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>
                      <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" onClick={() => {
                          setId(e?.id)
                          setConfrimDialog(true);
                        }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                      </ul>
                    </div>
                  </div>
              )
            }
          }
        ]}
        handleTableChange={handleTableChangeCity}
        totalSize={tableData?.total ?? 0}
        currentPage={tableData?.page ?? 1}
        sizePerPage={tableData?.limit ?? 15}>
        </Table>
      }
      {componentDidMountFlag && isEmptyArray(tableData?.records) && 
        <div className="no-items-layout">
          <div className="no-items-card">
            <h6>
              {
                Constants.message.noRecordsWarning
              }
            </h6>
          </div>
        </div>
      }
      <Modal show={show}>
        <form onSubmit={generalForm.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{pageInfo.title} Type</Modal.Title>
            <div
              onClick={handleClose}
              data-dismiss="modal">
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
          </Modal.Header>
          <Modal.Body className='pb-1'>
            <div className="">
              <label htmlFor="shortName" className="form-label">Short Name*</label>
              <input
                className="form-control"
                id="shortName"
                name="shortName"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.shortName}
              />
              {generalForm.touched.shortName && generalForm.errors.shortName ? (
                <span className="error">{generalForm.errors.shortName}</span>
              ) : null}
            </div>
            <div className="">
              <label htmlFor="name" className="form-label">Name*</label>
              <input
                className="form-control"
                id="name"
                name="name"
                type="text"
                onChange={generalForm.handleChange}
                value={generalForm.values.name}
              />
              {generalForm.touched.name && generalForm.errors.name ? (
                <span className="error">{generalForm.errors.name}</span>
              ) : null}
            </div>
            <div className="Mandotary">
              {/* <p>* Fields Are Mandatory Reqired</p> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <ReusableModal
        show={confrimDialog}
        onClose={() => setConfrimDialog(false)}
        loading={deleteLoading}
        onConfirm={isActive ? handleDeactivate : handleReactivete}
        message={isActive ?  `Are you sure, you want to delete the ${(pageInfo?.title)?.toLowerCase()}?` : `Are you sure, you want to reactivate ${(pageInfo?.title)?.toLowerCase()}?` }
      />
    </div>
  );
}

export default MasterMain;
