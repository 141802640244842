import { useFormik } from 'formik'
import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { APIRequest, RECONCILATION_STORE } from '../../../../api';
import { useDispatch,useSelector } from 'react-redux';
import { logout } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import RegularDropDown from '../../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../../Component/common/Global/constants";

const Addreconcilation = ({ Setaddreconcilation, addreconcilation, refreshMasterList }) => {
    
    const [selectedOption, setSelectedOption] = useState("");
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const generalForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            operation: "",
            comment: "",
            createdBy: user?.userId,
            updatedBy: user?.userId,
        },
        validationSchema: Yup.object().shape({
            operation: Yup.string().required("Enter operation"),
            comment: Yup.string().required("Enter comment"),
        }),
        onSubmit: values => {
            new APIRequest.Builder()
                .post()
                .setReqId(RECONCILATION_STORE)
                .jsonParams(values)
                .reqURL("reconcilation/addReconcilationComments")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();

        }
    });

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case RECONCILATION_STORE:
                toast.success(`${response.data.message}`);
                Setaddreconcilation(false);
                refreshMasterList();
                generalForm.resetForm();
                setSelectedOption('')
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case RECONCILATION_STORE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                }
                break;
            default:
                break;
        }
    };

    const handleOperationChange = (selected) => {
        setSelectedOption(selected.operation)
        generalForm.setFieldValue("operation",selected.operation.toLowerCase())
        showDropdownItem(false)
    }

    return (
        <>
            <Modal
                show={addreconcilation}
            >
                <form onSubmit={generalForm.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Add Reconciliation Comment</Modal.Title>
                        <div
                            onClick={() => {
                                Setaddreconcilation(false);
                                generalForm.resetForm();
                                setSelectedOption('')
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='pb-1'>
                        <div className="form-group dropdown_width">
                            <label htmlFor="shortName" className="form-label">Operation Type*</label>
                            <RegularDropDown 
                                placeholder={selectedOption==''?Constants.placeholder.select:selectedOption}
                                dropdownArr={Constants.operation}
                                labelParam="operation"
                                onDropDownItemClick={handleOperationChange}
                                isFocusRequired={true}
                                show={isShowDropDownItem}
                                onToggle={(isOpen) => showDropdownItem(isOpen)}
                                defaultPlaceholderDropDown={Constants.placeholder.select}
                            />
                            {generalForm.touched.operation && generalForm.errors.operation ? (
                                <span className="error">{generalForm.errors.operation}</span>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="name" className="form-label"> Comment*</label>
                            <input
                                className="form-control"
                                id="comment"
                                name="comment"
                                type="text"
                                onChange={generalForm.handleChange}
                                value={generalForm.values.comment}
                            />
                            {generalForm.touched.comment && generalForm.errors.comment ? (
                                <span className="error">{generalForm.errors.comment}</span>
                            ) : null}
                        </div>
                        <div className="Mandotary">
                            {/* <p>* Fields Are Mandatory Reqired</p> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary"
                        onClick={() => {
                            Setaddreconcilation(false)
                            generalForm.resetForm();
                            setSelectedOption('')
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>

        </>
    )
}

export default Addreconcilation