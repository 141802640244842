import React from 'react';
import '../Forgot/forgotstyle.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import { APIRequest, AUTH_FORGOT } from '../../api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function Forgot() {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter valid email")
        }),
        onSubmit: (values) => {
            new APIRequest.Builder()
                .post()
                .setReqId(AUTH_FORGOT)
                .jsonParams(values)
                .reqURL("auth/forgotPassword")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();
        },

    });

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case AUTH_FORGOT:
                navigate("/Login")
                toast.success(`${response.data.message}`);
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case AUTH_FORGOT:
                toast.error(`${response.data.message}`);
                break;
            default:
                break;
        }
    };


    return (
        <>
            <div className='container'>
                <div className='login_box'>
                    <div className='login_details'>
                        <div className='text-center login_logo'><img src='assets/images/logoTakeStock.png' className='img-fluid mb-4' alt=''></img></div>
                        <h1 className='mb-4'>Forgot Password</h1>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleInputEmail1" className="form-label">Enter Your Email</label>
                                <input type="email" className="form-control" id="exampleInputEmail1"
                                    name="email"
                                    onChange={formik.handleChange}
                                    value={formik.values.email}
                                    placeholder='email@example.com' />
                                {formik.touched.email && formik.errors.email ? (
                                    <span className="error">{formik.errors.email}</span>
                                ) : null}
                            </div>
                            <div className='text-center forgot_btn mt-5'>
                                <button type="submit" className="btn btn-primary">SUBMIT</button>
                                <a href='/Login'>Click here to login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Forgot;