import React, { useState } from 'react';
import CommonTabs from '../../../../commonComponent/tabs/commonTabs';
import ScheduleAssignment from './scheduleAssignment';
import CompletedAssignment from './completedAssignments/completedAssignment';

const Index = () => {
    const [selectedTab, setSelectedTab] = useState(1);

    // An array of objects representing the tabs
    const tabItems = [
        {
            key: '1',
            label: 'Schedule',
        },
        {
            key: '2',
            label: 'Completed',
        },
    ];

    // Handles the change of the selected tab.
    const handleTabChange = (selectedTabKey) => {
        setSelectedTab(selectedTabKey);
    }

    return (
        <>
            <div className='main-container'>
                <div className="d-flex">
                    <CommonTabs
                        items={tabItems}
                        style={{ marginLeft: 10 }}
                        onChange={handleTabChange}
                    />
                </div>
                {selectedTab == 1 ? <ScheduleAssignment /> : <CompletedAssignment />}
            </div>
        </>
    );
}

export default Index;
