import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Table } from '../../../../Table/Table';
import { logout } from '../../../../../redux/action';
import * as Constants from "../../../../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable, localDateExtractor } from '../../../../../Component/common/Global/commonFunctions';
import CommonLoader from '../../../../../commonComponent/loader/commonLoader';

const CompletedAssignment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const [completeassignment, Setcompleteasssignment] = useState();
    const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
    const [completedAssignmentLoading, setCompletedAssignmentLoading] = useState(false);
    const [componentDidMountCompletedFlag, isComponentDidMountCompletedFlag] = useState(false);
    const [freezeAssignmentSort, setFreezeAssignmentSort] = useState('');
    const [freezeAssignmentSearch, setFreezeAssignmentSearch] = useState('');

    useEffect(() => {
        getCompletedAssignment();
    },[]);

    // Fetch completed assignments from the API
    const getCompletedAssignment = () => {
        setCompletedAssignmentLoading(true);
        fetch(Constants.API_URL.getCompletedAssignments, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                organisationId: user.organisationId,
                userId: user.userId,
                page: 1,
                limit: 15
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                Setcompleteasssignment(data?.data)
                isComponentDidMountCompletedFlag(true);
                setCompletedAssignmentLoading(false);
            }else{
                setCompletedAssignmentLoading(false);
                toast.error(`${data?.message}`)
            }
        });
    }

    // Handle table changes (search, sort, page, limit)
    const handleTableChange = (type, newState) => {
        let body = {
            organisationId: user.organisationId,
            userId: user.userId
        }
        // Set page and limit based on the new state
        if(type === 'search' || type === 'sort'){
            body.page = 1
        }else{
            body.page = newState.page;
        }
        if (newState.sizePerPage) {
            body.limit = newState.sizePerPage;
        }
        // Set search and sort fields based on the new state
        if (!isEmptyVariable(newState?.searchText)) {
            setFreezeAssignmentSearch(newState.searchText);
            body.search = encodeURIComponent(newState.searchText);
        }else{
            setFreezeAssignmentSearch('');
        }
        if (!isEmptyVariable(newState?.sortField)) {
            setFreezeAssignmentSort(newState?.sortField);
            if (newState.sortField === 'name') {
                body.sortColumn = 'name';
            }
            if (newState.sortField === 'unit') {
                body.sortColumn = 'unit';
            }
            if (newState?.sortOrder) {
                body.sortType = newState.sortOrder;
            }
        }else{
            setFreezeAssignmentSort('');
        }

        fetch(Constants.API_URL.getCompletedAssignments, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body)
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                Setcompleteasssignment(data?.data)
                isComponentDidMountCompletedFlag(true);
                setCompletedAssignmentLoading(false);
            }else{
                setCompletedAssignmentLoading(false);
                toast.error(`${data?.message}`)
            }
        });
    };

    // Define an object 'rowEvents' containing an onClick function for table rows
    var rowEvents = {
        onClick: (e, row, rowIndex) => {
            navigate(`/Client/completedassignment/${row?.assignmentId}`, {
                state:{
                    assignmentListDetails : {
                        storeStockTypeData: row.storeStockTypeData,
                        status: 'completed', 
                        assignmentStartDate: row.assignmentStartDate, 
                        clientName: row.clientName,
                        clientUnitName: row?.clientUnitName,
                        city: row.cityName,
                        teamMembers: row.teamMemberData,
                        reviewers: row.reviewerData
                    }
                }
            })
        }
    }

    // Define a 'clearFilter' function to reset the filters and settings for the assignment list
    const clearFilter = () => {
        setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
        setFreezeAssignmentSearch('');
        setFreezeAssignmentSort('');
        isComponentDidMountCompletedFlag(false);
        getCompletedAssignment(); 
    }

    return (
        <div className='master_boxinfo'>
            {completedAssignmentLoading &&
                <CommonLoader loading={completedAssignmentLoading} />
            }
            {(user.role === "SUBSCRIBER_ADMIN" || user.role === "SUBSCRIBER_USER") &&
                <div className='table-responsive pt-2 pb-5 mt-2'>
                    <div className='add_btns'>
                        {!isEmptyArray(completeassignment?.records) && (freezeAssignmentSearch != "" || freezeAssignmentSort != "" || selectedOption == "Inactive") && 
                            <button
                                type="button"
                                className="btn btn-primary"
                                variant="primary"
                                onClick={() => clearFilter(true)}
                            >
                                <i
                                    className="fa fa-filter"
                                    aria-hidden="true"
                                ></i>{" "}
                                Clear
                            </button>
                        }
                    </div>
                    {componentDidMountCompletedFlag && 
                        <i className="fa fa-search search_icon" aria-hidden="true"></i>
                    }
                    {componentDidMountCompletedFlag && completeassignment?.records && (
                        <Table
                            data={completeassignment?.records?.map((item, index) => {
                                return {
                                    ...item,
                                    key: index,
                                    index: (completeassignment?.page - 1) * completeassignment?.limit + index + 1,
                                    name: item?.clientName ?? '',
                                    referenceNumber: item?.referenceNumber ?? '',
                                    unit: item?.clientUnitName,
                                    assignmentStartDate: localDateExtractor(item?.assignmentStartDate) ?? "-",
                                    teammember: item.teamMemberData.length === 0 ? "" : item.teamMemberData?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item?.title + "" + item?.firstName + " " + item?.lastName}</span> }),
                                    reviewer: item.reviewerData.length === 0 ? "" : item.reviewerData?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ', ' : '') + item?.title + "" + item?.firstName + " " + item?.lastName}</span> }),
                                };
                            })}
                            columns={[
                                {
                                    dataField: "index",
                                    text: "No",
                                    headerClasses: "sr_no",
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "referenceNumber",
                                    text: "Ref. No.",
                                    sort: false,
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "name",
                                    text: "Name",
                                    sort: true,
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "unit",
                                    text: "Unit",
                                    sort: true,
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "assignmentStartDate",
                                    text: "Start Date",
                                    sort: false,
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "teammember",
                                    text: "Team Member",
                                    sort: false,
                                    style: { cursor: "pointer" },
                                },
                                {
                                    dataField: "reviewer",
                                    text: "Reviewer",
                                    sort: false,
                                    style: { cursor: "pointer" },
                                },
                            ]}
                            handleTableChange={handleTableChange}
                            totalSize={completeassignment?.totalRecords ?? 0}
                            currentPage={completeassignment?.page ?? 1}
                            sizePerPage={completeassignment?.limit ?? 15}
                            rowEvents={rowEvents}
                        />
                    )}
                    {componentDidMountCompletedFlag && isEmptyArray(completeassignment?.records) && 
                        <div className="no-items-layout">
                            <div className="no-items-card">
                                <h6>
                                    {
                                        Constants.message.noRecordsWarning
                                    }
                                </h6>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default CompletedAssignment;
