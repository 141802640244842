import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useFormik } from 'formik';
import * as Yup from "yup";
import '../ClientBillingPayment/billstyle.css'
import { toast } from "react-toastify";
import { APIRequest, CREDIT_PURCHASE, BILLSANDPAYMENTS_LIST, CONFIGS_LIST } from '../../api'
import { useDispatch, useSelector } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';
import * as Constants from '../common/Global/constants';

const Credit = ({ buycreditmodal, setbuycreditmodalShow, userId }) => {
    const [rate, Setrate] = useState("");
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const creditform = useFormik({
        enableReinitialize: true,
        initialValues: {
            userId: user.userId,
            credits: '',
            valuePrCredit: rate,
            gst: '',
            totalAmount: '',
            type: "purches",
            organisationId: user.organisationId
        },
        validationSchema: Yup.object().shape({
            credits: Yup.number().typeError('you must specify a number').required('Enter required credits'),
            valuePrCredit: Yup.number().typeError('you must specify a number').required('Something Went Wrong Login Again'),
            totalAmount: Yup.number().typeError('you must specify a number').required('Something Went Wrong Again'),    
        }),
        onSubmit: values => {
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(CREDIT_PURCHASE)
                .reqURL("billsAndPayments/addPurchaseBillsAndPayments")
                .jsonParams(values)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()

            new APIRequest.Builder()
                .get()
                .setReqId(BILLSANDPAYMENTS_LIST)
                .reqURL(`billsAndPayments/${user.userId}/list`)
                .jsonParams(userId)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()

        },
    });

    useEffect(() => {
        new APIRequest.Builder()
            .post()
            .setReqId(CONFIGS_LIST)
            .reqURL("subscriptionRates/getSubscriptionRates?rateType=perDay")
            .jsonParams({
                "rateType": "perDay"
            })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }, [])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case CREDIT_PURCHASE:
                setLoading(false);
                toast.success(response?.data?.message);
                const targetURL = response?.data?.data;
                window.location.replace(targetURL);
                // window.open(targetURL, "_blank", "noreferrer");
                creditform.resetForm();
                setbuycreditmodalShow(false)
                break;
            case CONFIGS_LIST:
                Setrate(response?.data?.data[0].rateAmount)
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case CREDIT_PURCHASE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    creditform.resetForm();
                    setLoading(false);
                    toast.error(response?.data?.message);
                }
                break;
            case CONFIGS_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                   
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        creditform.setFieldValue("gst", creditform.values.credits * creditform.values.valuePrCredit * 0.18)
        creditform.setFieldValue("totalAmount", creditform.values.credits * creditform.values.valuePrCredit * 1.18)
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [creditform.values.credits])

    return (
        <>
            <Modal size="sm" show={buycreditmodal}>
                <form onSubmit={creditform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Buy Credits</Modal.Title>
                        <div
                            onClick={() => {
                                setbuycreditmodalShow(false);
                                creditform.resetForm();
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='addclients_details'>
                            <div className="form-group ">
                                <label htmlFor="numberofcredits">Number of Credits </label>
                                <input
                                    className="form-control"
                                    id="credits"
                                    name="credits"
                                    type="text"
                                    onChange={creditform.handleChange}
                                    value={creditform.values.credits}
                                />
                                {creditform.touched.credits && creditform.errors.credits ? (
                                    <span className="error">{creditform.errors.credits}</span>
                                ) : null}
                            </div>
                            <div className="form-group ">
                                <label htmlFor="rate">Rate</label>
                                <input
                                    className="form-control"
                                    id="valuePrCredit"
                                    name="valuePrCredit"
                                    type="text"
                                    value={creditform.values.valuePrCredit}
                                    readOnly
                                />
                                {creditform.touched.valuePrCredit && creditform.errors.valuePrCredit ? (
                                    <span className="error">{creditform.errors.valuePrCredit}</span>
                                ) : null}
                            </div>
                            <div className="form-group ">
                                <label htmlFor="rate">GST</label>
                                <input
                                    className="form-control"
                                    id="gst"
                                    name="gst"
                                    type="text"
                                    value={creditform.values.credits ? creditform.values.credits * creditform.values.valuePrCredit * 0.18 : ""}
                                    readOnly
                                />
                            </div>
                            <div className="form-group ">
                                <label htmlFor="total">Total </label>
                                <input
                                    className="form-control"
                                    id="totalAmount"
                                    name="totalAmount"
                                    type="totalAmount"
                                    value={creditform.values.credits ? creditform.values.credits * creditform.values.valuePrCredit * 1.18 : ""}
                                    readOnly
                                />
                                {creditform.touched.totalAmount && creditform.errors.totalAmount ? (
                                    <span className="error">{creditform.errors.totalAmount}</span>
                                ) : null}
                            </div>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setbuycreditmodalShow(false); creditform.resetForm(); }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "MAKE PAYMENT"}
                        </Button>
                    </Modal.Footer>
                </form>    
            </Modal>
        </>
    )
}

export default Credit;