import React from 'react'

const Thirdpartyongoingassignment = () => {
  return (
    <>  <div className="thirdparty_box">
    <div className="main_tabs">
      <div className="master_box">
        <div className="master_boxinfo">
          <div className="tittle_box p-2 border mb-4">
            <a>Dashboard</a>
          </div>
          <h6>Ongoing Assignments</h6>
          <div className="table-responsive pt-2 pb-5">
            <div className="add_btns d-none">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal5"
              >
                <i
                  className="fa fa-plus-circle"
                  aria-hidden="true"
                ></i>{" "}
                BUY CREDITS
              </button>
            </div>
            {/* <i
              className="fa fa-search search_icon"
              aria-hidden="true"
            ></i> */}
            <table id="datatable13" className="table">
              <thead>
                <tr>
                  <th>Sl. No</th>
                  <th>Store Type</th>
                  <th>Auditor’s Firm</th>
                  <th>Auditors</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Book Stock Uploaded</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>001</td>
                  <td>John</td>
                  <td>12assa</td>
                  <td>01/04/2022</td>
                  <td>01/04/2022</td>
                  <td>Chicago</td>
                  <td>
                    <i
                      className="fa fa-check-square-o"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td className="edit_icons">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                        <a className="dropdown-item">
                          <i className="fas fa-user-check me-1"></i>Assign user
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BookStock"
                          ><i className="fa fa-upload me-1" aria-hidden="true"></i>
                            Upload Book Stock
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>002</td>
                  <td>John</td>
                  <td>12assa</td>
                  <td>01/04/2022</td>
                  <td>01/04/2022</td>
                  <td>Chicago</td>
                  <td>
                    <i
                      className="fa fa-check-square-o"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td className="edit_icons">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                        <a className="dropdown-item">
                          <i className="fas fa-user-check me-1"></i>Assign user
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BookStock"
                          ><i className="fa fa-upload me-1" aria-hidden="true"></i>
                            Upload Book Stock
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>003</td>
                  <td>John</td>
                  <td>12assa</td>
                  <td>01/04/2022</td>
                  <td>01/04/2022</td>
                  <td>Chicago</td>
                  <td>
                    <i
                      className="fa fa-window-close-o"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td className="edit_icons">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item">
                          <i className="fas fa-user-check me-1"></i>Assign user
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BookStock"
                          ><i className="fa fa-upload me-1" aria-hidden="true"></i>
                            Upload Book Stock
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>004</td>
                  <td>John</td>
                  <td>12assa</td>
                  <td>01/04/2022</td>
                  <td>01/04/2022</td>
                  <td>Chicago</td>
                  <td>
                    <i
                      className="fa fa-window-close-o"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td className="edit_icons">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item">
                          <i className="fas fa-user-check me-1"></i>Assign user
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BookStock"
                          ><i className="fa fa-upload me-1" aria-hidden="true"></i>
                            Upload Book Stock
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>005</td>
                  <td>John</td>
                  <td>12assa</td>
                  <td>01/04/2022</td>
                  <td>01/04/2022</td>
                  <td>Chicago</td>
                  <td>
                    <i
                      className="fa fa-window-close-o"
                      aria-hidden="true"
                    ></i>
                  </td>
                  <td className="edit_icons">
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul
                        className="dropdown-menu p-1"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item">
                          <i className="fas fa-user-check me-1"></i>Assign user
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BookStock"
                          ><i className="fa fa-upload me-1" aria-hidden="true"></i>
                            Upload Book Stock
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default Thirdpartyongoingassignment