import React from 'react'
import { Button, Modal } from "react-bootstrap";
import './sampleitem.css'
import moment from "moment";

const Sampleitem = ({ sampledetails, showdetails, Setshowdetails }) => {
    var myJsonObj = {}
    myJsonObj = sampledetails && JSON?.parse(sampledetails?.sampleRule);

    return (
        <>
            <Modal show={showdetails}>
                <Modal.Header>
                    <Modal.Title>Sample Details</Modal.Title>
                    <div
                        onClick={() => {
                            Setshowdetails(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="pb-1">
                    <div className='row'>
                        <div className='col-md-4'>
                            {sampledetails?.partNo1 && <p className='detailslable'>Part Numer</p>}
                            {sampledetails?.partNo2 && <p className='detailslable'>Sub Part No.1</p>}
                            {sampledetails?.partNo3 && <p className='detailslable'>Sub Part No.2</p>}
                            {sampledetails?.partNo4 && <p className='detailslable'>Sub Part No.3</p>}
                            {sampledetails?.partNo5 && <p className='detailslable'>Sub Part No.4</p>}
                            {sampledetails?.storeTypeName && <p className='detailslable'>Store Type</p>}
                            {sampledetails?.stockTypeName && <p className='detailslable'>Stock Type</p>}
                            {sampledetails?.StoreType?.storeTypeName && <p className='detailslable'>Store Type</p>}
                            {sampledetails?.StockType?.stockTypeName && <p className='detailslable'>Stock Type</p>}
                            {sampledetails?.materialName && <p className='detailslable'>Material Name</p>}
                            {sampledetails?.materialName1 && <p className='detailslable'>Material Sub Name 1</p>}
                            {sampledetails?.materialName2 && <p className='detailslable'>Material Sub Name 2</p>}
                            {sampledetails?.materialName3 && <p className='detailslable'>Material Sub Name 3</p>}
                            {sampledetails?.materialName4 && <p className='detailslable'>Material Sub Name 4</p>}
                            {sampledetails?.location1 && <p className='detailslable'>Location 1</p>}
                            {sampledetails?.location2 && <p className='detailslable'>Location 2</p>}
                            {sampledetails?.location3 && <p className='detailslable'>Location 3</p>}
                            {sampledetails?.location4 && <p className='detailslable'>Location 4</p>}
                            {sampledetails?.location5 && <p className='detailslable'>Location 5</p>}
                            {sampledetails?.uom && <p className='detailslable'>UOM</p>}
                            {sampledetails?.quantity && <p className='detailslable'><b> Book Quantity</b></p>}
                            {sampledetails?.rate && <p className='detailslable'>Rate</p>}
                            {sampledetails?.value && <p className='detailslable'>Value</p>}
                            {sampledetails?.expiryDate && <p className='detailslable'>Expiry Date</p>}
                            {sampledetails?.lastReceiptDate && <p className='detailslable'>Last Receipt Date</p>}
                            {sampledetails?.lastIssueDate && <p className='detailslable'>Last Issue Date</p>}
                            {sampledetails?.remarks && <p className='detailslable'>Remarks</p>}
                            {sampledetails?.sampleRule && <p className='detailslable'>Rule</p>}
                        </div>



                        <div className='col-md-8'>
                            {sampledetails?.partNo1 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.partNo1}</p>}
                            {sampledetails?.partNo2 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.partNo2}</p>}
                            {sampledetails?.partNo3 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.partNo3}</p>}
                            {sampledetails?.partNo4 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.partNo4}</p>}
                            {sampledetails?.partNo5 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.partNo5}</p>}
                            {sampledetails?.storeTypeName && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.storeTypeName}</p>}
                            {sampledetails?.stockTypeName && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.stockTypeName}</p>}
                            {sampledetails?.StoreType?.storeTypeName && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.StoreType?.storeTypeName}</p>}
                            {sampledetails?.StockType?.stockTypeName && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.StockType?.stockTypeName}</p>}
                            {sampledetails?.materialName && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.materialName}</p>}
                            {sampledetails?.materialName1 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.materialName1}</p>}
                            {sampledetails?.materialName2 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.materialName2}</p>}
                            {sampledetails?.materialName3 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.materialName3}</p>}
                            {sampledetails?.materialName4 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.materialName4}</p>}
                            {sampledetails?.location1 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.location1}</p>}
                            {sampledetails?.location2 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.location2}</p>}
                            {sampledetails?.location3 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.location3}</p>}
                            {sampledetails?.location4 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.location4}</p>}
                            {sampledetails?.location5 && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.location5}</p>}
                            {sampledetails?.uom && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.uom}</p>}
                            {sampledetails?.quantity && <p className='detailslable '><span className='pe-4'>:</span><b> {sampledetails?.quantity}</b></p>}
                            {sampledetails?.rate && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.rate}</p>}
                            {sampledetails?.value && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.value}</p>}
                            {sampledetails?.expiryDate && <p className='detailslable '><span className='pe-4'>:</span> {moment(sampledetails?.expiryDate).utc().format('DD-MM-YYYY')}</p>}
                            {sampledetails?.lastReceiptDate && <p className='detailslable '><span className='pe-4'>:</span> {moment(sampledetails?.lastReceiptDate).utc().format('DD-MM-YYYY')}</p>}
                            {sampledetails?.lastIssueDate && <p className='detailslable '><span className='pe-4'>:</span> {moment(sampledetails?.lastIssueDate).utc().format('DD-MM-YYYY')}</p>}
                            {sampledetails?.remarks && <p className='detailslable '><span className='pe-4'>:</span> {sampledetails?.remarks}</p>}
                            {sampledetails?.sampleRule && <p className='detailslable '><span className='pe-4'>:</span>
                            <span  className='span_list'>
                                    <span >{myJsonObj?.criteria}</span>
                                    <span>{myJsonObj?.actualvalue}</span>
                                    <span>{myJsonObj?.operation} by</span>
                                    <span>{myJsonObj?.sortingValue}</span>
                                    <span>{myJsonObj?.isBetween ? "between" : null}</span>
                                    <span>{myJsonObj?.min}</span>
                                    <span>{myJsonObj?.isBetween ? "to" : null}</span>
                                    <span>{myJsonObj?.max}</span>
                            </span>
                                </p>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Sampleitem