import React, {useState, useEffect} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Table } from '../../Table/Table';
import { logout } from '../../../redux/action';
import "../assignmentstyle.css"
import CommonLoader from '../../../commonComponent/loader/commonLoader';
import * as Constants from '../../../Component/common/Global/constants';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import AssignmentListDetails from '../../../Component/common/assignmentListDetails/assignmentListDetails';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../Component/common/Global/commonFunctions';
import ReconciliationDetail from './reconciliationDetail';

const Deviation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignment = location?.state?.assignmentListDetails;
  const user = useSelector((state) => state?.user);
  const token = useSelector((state) => state.token);
  const { assignmentId } = useParams();
  const formatter = new Intl.NumberFormat('en-IN');
  const [deviationList, setDeviationList] = useState({});
  const [downloadReportData, setDownloadReportData] = useState([]);
  const [reconciliationModalShow, setReconciliationModalShow] = useState(false);
  const [reconciliationItem, setReconciliationItem] = useState([]);
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [stockData, setStockData] = useState([]);
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [deviationDetailsLoading, setDeviationDetailsLoading] = useState(false);
  const [fileName, setFileName] = useState("Deviation Reports");
  const store = storeId;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockId, "label": stockItem?.stockTypeName });
    });
  });

  useEffect(() => {
    getOngoingDeviation()
    getFilterStoreStockList()
  }, [])

  // Update the downloadReportData state with the mapped deviation list data for report
  useEffect(() => {
    setDownloadReportData(deviationList?.records?.map((item, index) => {
      return {
        No: index + 1,
        key: index,
        PartNo: item?.partNo1 ?? "",
        SubPartNo1: item?.partNo2 ?? "",
        SubPartNo2: item?.partNo3 ?? "",
        SubPartNo3: item?.partNo4 ?? "",
        SubPartNo4: item?.partNo5 ?? "",
        StockType: item?.stockTypeName ?? "",
        Materialname: item?.materialName ?? "",
        MaterialSubname1: item?.materialName1 ?? "",
        MaterialSubname2: item?.materialName2 ?? "",
        MaterialSubname3: item?.materialName3 ?? "",
        MaterialSubname4: item?.materialName4 ?? "",
        Location: item?.location1 ?? "",
        Location1: item?.Location2 ?? "",
        Location2: item?.location3 ?? "",
        Location3: item?.location4 ?? "",
        Location4: item?.location5 ?? "",
        UOM: item?.uom ?? "",
        Rate: item?.rate ?? "-",
        Value: item?.value ?? "",
        BookQuantity: item?.quantity ?? "-",
        ActualStockquantity: item?.actualStockQuantity ?? "",
        Diffrance: item?.difference ?? "",
        ExpiryDate: item?.expiryDate ?? "",
        LastReceiptDate: item?.BookStock?.lastReceiptDate ?? "",
        LastIssueDate: item?.lastIssueDate ?? "",
        ReconcilationObservations: deviationList?.reconcilationData?.filter(recoItem => recoItem.actualStockId == item.actualStockId) ?? [],
        Remarks: item?.remarks ?? "",
        // FilePath: item?.filepath ?? "",
      }
    }))
  }, [deviationList])

  // Fetch the ongoing deviation data
  const getOngoingDeviation = () => { 
    setDeviationDetailsLoading(true);
    fetch(Constants.API_URL.getOngoingDeviation, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        assignmentId: parseInt(assignmentId),
        userId: user.userId,
        page: 1,
        limit: 15
      })
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setDeviationList(data?.data);
        isComponentDidMountFlag(true);
        setDeviationDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setDeviationDetailsLoading(false);
        navigate(`/Client/ongoingassignment/${assignmentId}/deviation/${randomStringGenerator(30)}`);
      }else{
        setDeviationDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  }
  
  const getFilterStoreStockList = () => {
    fetch(Constants.BASE_URL_API + `bookstock/${assignmentId}/getFilterStoreStockList`, {
      method:"GET",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setStockData(data?.storeType);
      }else{
        // toast.error(`${data?.message}`)
      }
    });
  }

  // Function that fetches the deviation list based on the provided storeId and stockId
  const getStoreStockFilterList = (storeId, stockId) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
      page: 1,
      limit: 15
    }
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    if(search) {
      body.search = encodeURIComponent(search);
    }
    if(!isEmptyVariable(sort)) {
      body.sortColumn = sort;
    }
    if(!isEmptyVariable(sortOrder)) {
      body.sortType = sortOrder;
    }
    fetch(Constants.API_URL.getOngoingDeviation, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setDeviationList(data?.data);
        isComponentDidMountFlag(true);
        setDeviationDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setDeviationDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/deviation/${randomStringGenerator(30)}`);
      }else{
        setDeviationDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  }

  // Handles changes in the table, such as search, sort, and pagination
  const handleTableChange = (type, newState) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
    }
    // storeType and stockType to the request body based on storeId and stockId
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    // Reset the page to 1 when search or sort action is triggered
    if(type === 'search' || type === 'sort'){
        body.page = 1
    }else{
        body.page = newState.page;
    }
    // Set the limit in the request body if sizePerPage exists in the newState
    if (newState.sizePerPage) {
        body.limit = newState.sizePerPage;
    }
    // Encode and add the search text to the request body if it exists in the newState
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      body.search = encodeURIComponent(newState.searchText);
    }else{
      setSearch('');
    }
    // Conditionally set the sortColumn and sortOrder in the request body based on newState
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === 'PartNo') {
          body.sortColumn = 'partNo1';
      }
      if (newState.sortField === 'Materialname') {
          body.sortColumn = 'materialName';
      }
      if (newState.sortField === 'uom') {
        body.sortColumn = 'uom';
      }
      if (newState.sortField === 'rate') {
        body.sortColumn = 'rate';
      }
      if (newState.sortField === 'value') {
        body.sortColumn = 'value';
      }
      if (newState.sortField === 'bookstockquantity') {
        body.sortColumn = 'quantity';
      }
      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        body.sortType = newState.sortOrder;
      }
    }else{
      setSort('');
      setSortOrder('');
    }
    fetch(Constants.API_URL.getOngoingDeviation, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setDeviationList(data?.data);
        isComponentDidMountFlag(true);
        setDeviationDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setDeviationDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/deviation/${randomStringGenerator(30)}`);
      }else{
        setDeviationDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  };

  // Function to export data to a excel file
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(downloadReportData);
    const wb = { Sheets: { 'Deviation': ws }, SheetNames: ['Deviation'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // Handles changes to the store dropdown menu
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "")
      setSelectedStoreType(selected.label)
    }
    showStoreTypeDropdownItem(false);
  };

  // Handles changes to the stock dropdown menu
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value)
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };

  // Store the list item data based on row click for reconciliation details 
  const handleReconcilationItem = (e, item) => {
    setReconciliationItem(item);
    setReconciliationModalShow(true);
  }

  // Handling the closing event of the Reconciliation modal
  const handleReconciliationModalClose = (reload) => {
    setReconciliationItem([]);
    setReconciliationModalShow(false);
    if(reload){
      getOngoingDeviation();
    }
  }

  // Function to clear all filters and set default values
  const clearFilter = () => {
    isComponentDidMountFlag(false);
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setSearch('');
    setSort('');
    setSortOrder('');
    setDeviationList({});
    getOngoingDeviation();
  }

  const deviationColumn = [
    {
      dataField: "index",
      text: "No",
      headerClasses: "sr_no",
    },
    {
      dataField: "PartNo",
      text: "Part No.",
      sort: true,
    },
    {
      dataField: "Materialname",
      text: "Name",
      sort: true,
    },
    {
      dataField: "uom",
      text: "UOM",
      sort: true,
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
    },
    {
      dataField: "bookstockquantity",
      text: "Book Qty",
      sort: true,
    },
    {
      dataField: "actualquantity",
      text: "Actual Qty",
      sort: true,
    },
    {
      dataField: "reconciliation",
      text: "Reco.",
      sort: true,
      formatter: (cell, item) => {
        return (
          <div className='d-flex align-items-center'>
            <a
                    onClick={(e) => handleReconcilationItem(e, item)}
                  > <i className="fa fa-list me-2" aria-hidden="true"></i>
                  </a>
            <span className=''>{isEmptyVariable(item?.totalRecoQty) ? "-" : item?.totalRecoQty}</span>
        </div>
        )
      },
    },
    {
      dataField: "difference",
      text: "Difference",
      sort: true,
      style: { color: "red", cursor: "pointer" },
    },
  ]

  return (
    <>
      {assignment && 
        <AssignmentListDetails
          assignment={assignment}
        />
      }
      {deviationDetailsLoading &&
        <CommonLoader loading={deviationDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
            <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/ongoingassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
            </a>
            <a className="btn btn-primary" href="deviation">
              {" "}
              Deviation
            </a>
          </div>
          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
              {componentDidMountFlag &&
                <>
                  <div className="add_btns bookstock_dropdown_width">
                    {(search != "" || sort != "" || storeId != "" || stockId != "") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                    }
                    <RegularDropDown 
                      placeholder={selectedStoreType}
                      dropdownArr={storeOptions}
                      labelParam="label"
                      onDropDownItemClick={handleStoreChange}
                      isFocusRequired={true}
                      show={isShowStoreTypeDropDownItem}
                      onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                      defaultPlaceholderDropDown={"Select Store Type"}
                    />
                    <RegularDropDown 
                      placeholder={selectedStockType}
                      dropdownArr={stockOptions}
                      labelParam="label"
                      onDropDownItemClick={handleStockChange}
                      isFocusRequired={true}
                      show={isShowStockTypeDropDownItem}
                      onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                      defaultPlaceholderDropDown={"Select Stock Type"}
                    />
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={(e) => exportToCSV()}
                    >
                      <i
                        className="fa fa-download"
                        aria-hidden="true"
                      ></i>{" "}
                      Download
                    </button>
                  </div>
                  <i className="fa fa-search search_icon" aria-hidden="true"></i>
                </>
              }
              
              {componentDidMountFlag && deviationList?.records && (
                <Table
                  data={
                    deviationList?.records?.map((item, index) => {
                      return {
                        ...item,
                        index: (deviationList?.page - 1) * deviationList.limit + index + 1,
                        id: item.id,
                        PartNo: item?.partNo1 ?? "-",
                        Materialname: item?.materialName ?? "-",
                        uom: item?.uom ?? "",
                        rate: isEmptyVariable(item?.rate) ? "-" : formatter.format(item?.rate),
                        bookstockquantity: isEmptyVariable(item?.quantity) ? "-" : item?.quantity,
                        actualquantity: isEmptyVariable(item?.actualStockQuantity) ? "-" : item?.actualStockQuantity,
                        reconciliation: isEmptyVariable(item?.totalRecoQty) ? "-" : item?.totalRecoQty,
                        difference: isEmptyVariable(item?.difference) ? "-" : item?.difference,
                        key: index,
                      };
                    }) ?? []
                  }
                  columns={deviationColumn}
                  handleTableChange={handleTableChange}
                  totalSize={deviationList?.totalRecords ?? 0}
                  currentPage={deviationList?.page ?? 1}
                  sizePerPage={deviationList?.limit ?? 15}
                />
              )} 
              {componentDidMountFlag && isEmptyArray(deviationList?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {reconciliationModalShow &&
        <ReconciliationDetail
          reconcilation={reconciliationModalShow}
          assignment={assignment}
          handleOnClose={handleReconciliationModalClose}
          reconcilationitem={reconciliationItem}
          reconcilationData={deviationList.reconcilationData}
          // assignmentId={assignmentId}
        />
      }
    </>
  );
}

export default Deviation