import React,{useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import "../ClientDashboard/clientdashboardstyle.css";
import { UPDATE_USER,APIRequest } from "../../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { logout } from '../../redux/action';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import RegularDropDown from "../../Component/common/DropdownMenus/RegularDropdown";
import * as Constants from "../../Component/common/Global/constants";

const EditUser = ({ useredit, setUserEdit, isThirdParty, usereditdata, user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedTitleOption, setSelectedTitleOption] = useState('');
    const [isShowTitleDropDownItem, showTitleDropdownItem] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [isShowUserDropDown, showUserDropdown] = useState(false);
    const useraddform = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: usereditdata?.firstName ?? "",
            middleName: usereditdata?.middleName ?? "",
            lastName: usereditdata?.lastName ?? "",
            title: usereditdata?.title ?? "",
            email: usereditdata?.email ?? "",
            mobileNumber: usereditdata?.mobileNumber ?? "",
           // unitName: usereditdata?.unit ?? "",
            //storeName: usereditdata?.store ?? "",
            role:usereditdata?.userrole ?? "",
            updateBy:user.userId
        },
        validationSchema: Yup.object().shape({
            title: Yup.string().required("Select Title"),
            firstName: Yup.string().required("Please enter First Name"),
            lastName: Yup.string().required("Please enter Last Name"),
            email: Yup.string()
                .email("Invalid email address")
                .required("Please enter Email"),
            mobileNumber: Yup.string().length(10, " Enter 10 Digit Number").required("Please Enter Mobile Number").matches(
                /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                "Phone number is not valid"
            ),
            role: Yup.string().required("Select Role")
            //unitName: Yup.string().required("Select UnitName"),
            //storeName: Yup.string().required("Select storeName")

        }),
        onSubmit: (values) => {
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(UPDATE_USER)
                .jsonParams(values)
                .reqURL(`auth/updateUser/${usereditdata.userId}`)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();

        },
    });
    
    const onResponse = (response, reqId) => {
        switch (reqId) {
            case UPDATE_USER:
                setLoading(false);
                toast.success(response.data.message);
                setUserEdit(true);  
                setSelectedTitleOption('')
                setSelectedUser('')
                useraddform.resetForm();                   
                break;           
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case UPDATE_USER:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    response?.data?.message && toast.error(`${response?.data?.message}`)
                }
                break;           
            default:
                break;
        }
    };

    const handleTitleChange = (selected) => {
        setSelectedTitleOption(selected.title)
        useraddform.setFieldValue("title",selected.value)
        showTitleDropdownItem(false)
    }

    const handleUserChange = (selected) => {
        setSelectedUser(selected.type)
        useraddform.setFieldValue("role",selected.role)
        showUserDropdown(false)
    }

    return (
        <>
            <Modal show={useredit}>
                <form onSubmit={useraddform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Update User</Modal.Title>
                        <div 
                            onClick={() => {
                                setUserEdit(); 
                                useraddform.resetForm();
                                setSelectedTitleOption('')
                                setSelectedUser('')                                 
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row dropdown_width">
                            <div className="col-md-6">
                                <label htmlFor="title" className="form-label">
                                    Title*{" "}
                                </label>
                                <RegularDropDown 
                                    placeholder={selectedTitleOption==''?useraddform.values.title:selectedTitleOption}
                                    dropdownArr={Constants.editTitle}
                                    labelParam="title"
                                    onDropDownItemClick={handleTitleChange}
                                    isFocusRequired={true}
                                    show={isShowTitleDropDownItem}
                                    onToggle={(isOpen) => showTitleDropdownItem(isOpen)}
                                    defaultPlaceholderDropDown={Constants.placeholder.selectTitle}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="firstName" className="form-label">
                                    First Name*
                                </label>
                                <input
                                    className="form-control"
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    onChange={useraddform.handleChange}
                                    value={useraddform.values.firstName}
                                />
                                {useraddform.touched.firstName && useraddform.errors.firstName ? (
                                    <span className="error">{useraddform.errors.firstName}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="middleName" className="form-label">
                                    Middle Name
                                </label>
                                <input
                                    className="form-control"
                                    id="middleName"
                                    name="middleName"
                                    type="text"
                                    onChange={useraddform.handleChange}
                                    value={useraddform.values.middleName}
                                />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="lastName" className="form-label">
                                    Last Name*
                                </label>
                                <input
                                    className="form-control"
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    onChange={useraddform.handleChange}
                                    value={useraddform.values.lastName}
                                />
                                {useraddform.touched.lastName && useraddform.errors.lastName ? (
                                    <span className="error">{useraddform.errors.lastName}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="email" className="form-label">
                                    Email Id*
                                </label>
                                <input
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={useraddform.handleChange}
                                    value={useraddform.values.email}
                                />
                                {useraddform.touched.email && useraddform.errors.email ? (
                                    <span className="error">{useraddform.errors.email}</span>
                                ) : null}
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="mobileNumber" className="form-label">
                                    Mobile*
                                </label>
                                <input
                                    className="form-control"
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    type="number"
                                    onWheel={e => e.currentTarget.blur()}
                                    onChange={useraddform.handleChange}
                                    value={useraddform.values.mobileNumber}
                                />
                                {useraddform.touched.mobileNumber && useraddform.errors.mobileNumber ? (
                                    <span className="error">{useraddform.errors.mobileNumber}</span>
                                ) : null}
                            </div>
                            {usereditdata?.userrole === "SITE_ADMIN" &&
                                <div className="col-md-6">
                                    <label htmlFor="userunit" className="form-label">
                                        User Type*{" "}
                                    </label>
                                    <RegularDropDown 
                                        placeholder={selectedUser == "" ? "Admin" : selectedUser}
                                        dropdownArr={Constants.userSiteType}
                                        labelParam="type"
                                        onDropDownItemClick={handleUserChange}
                                        isFocusRequired={true}
                                        show={isShowUserDropDown}
                                        onToggle={(isOpen) => showUserDropdown(isOpen)}
                                        defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                                    />
                                </div>}
                            {usereditdata?.userrole === "SITE_USER" &&
                                <div className="col-md-6">
                                    <label htmlFor="userunit" className="form-label">
                                        User Type*{" "}
                                    </label>
                                    <RegularDropDown 
                                        placeholder={selectedUser == "" ? "User" : selectedUser}
                                        dropdownArr={Constants.userSiteType}
                                        labelParam="type"
                                        onDropDownItemClick={handleUserChange}
                                        isFocusRequired={true}
                                        show={isShowUserDropDown}
                                        onToggle={(isOpen) => showUserDropdown(isOpen)}
                                        defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                                    />
                                </div>}
                            {usereditdata?.userrole === "SUBSCRIBER_ADMIN" &&
                                <div className="col-md-6">
                                    <label htmlFor="userunit" className="form-label">
                                         User Type*{" "}
                                    </label>
                                    <RegularDropDown 
                                        placeholder={selectedUser == "" ? "Admin" : selectedUser}
                                        dropdownArr={Constants.userSubscriberType}
                                        labelParam="type"
                                        onDropDownItemClick={handleUserChange}
                                        isFocusRequired={true}
                                        show={isShowUserDropDown}
                                        onToggle={(isOpen) => showUserDropdown(isOpen)}
                                        defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                                    />
                                </div>}
                                {usereditdata?.userrole === "SUBSCRIBER_USER" &&
                                <div className="col-md-6">
                                    <label htmlFor="userunit" className="form-label">
                                         User Type*{" "}
                                    </label>
                                    <RegularDropDown 
                                        placeholder={selectedUser == "" ? "User" : selectedUser}
                                        dropdownArr={Constants.userSubscriberType}
                                        labelParam="type"
                                        onDropDownItemClick={handleUserChange}
                                        isFocusRequired={true}
                                        show={isShowUserDropDown}
                                        onToggle={(isOpen) => showUserDropdown(isOpen)}
                                        defaultPlaceholderDropDown={Constants.placeholder.userTypePlaceholder}
                                    />
                                </div>}
                            {useraddform.touched.role && useraddform.errors.role ? (
                                <span className="error">{useraddform.errors.role}</span>
                            ) : null}
                            {/* {isThirdParty && unit &&
                                <div className="col-md-6">
                                    <label htmlFor="userunit" className="form-label">
                                        Unit Name{" "}
                                    </label>
                                    <select
                                        name="unitName"
                                        className="form-control"
                                        value={useraddform.values.userunit}
                                        onChange={useraddform.handleChange}
                                    >{unit.map(e =>
                                        <option value={e.id}>{e.name}</option>
                                    )}
                                    </select>
                                    {useraddform.touched.unitName && useraddform.errors.unitName ? (
                                        <span className="error">{useraddform.errors.unitName}</span>
                                    ) : null}
                                </div>}
                            {store && isThirdParty && <div className="col-md-6">
                                <label htmlFor="userstore" className="form-label">
                                    Store Name{" "}
                                </label>
                                <select
                                    name="storeName"
                                    className="form-control"
                                    value={useraddform.values.userstore}
                                    onChange={useraddform.handleChange}
                                >
                                    {store.map(e =>
                                        <option value={e.id}>{e.name}</option>
                                    )}
                                </select>
                                {useraddform.touched.storeName && useraddform.errors.storeName ? (
                                    <span className="error">{useraddform.errors.storeName}</span>
                                ) : null}
                            </div>} */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setUserEdit()}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                        {loading ? <Spinner animation="border"  variant="light" className='spinner-border-sm' /> :"Save"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>

    )
}

export default EditUser