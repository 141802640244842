import React from 'react'
import Modal from "react-bootstrap/Modal";

const Employeedetails = ({ employeedetails, Setemployeedetailsshow, employeedetailsshow }) => {

    return (
        <>
            <Modal show={employeedetailsshow}>
                <Modal.Header className='bookstockheader'>
                    <Modal.Title>Employee Details</Modal.Title>
                        <div 
                            onClick={() => {
                                Setemployeedetailsshow(false);                                 
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Name</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{employeedetails?.name}</p>
                        </div>
                    </div>
                    {employeedetails?.email &&
                        <div className='row'>
                            <div className='col-md-4'>
                                <p className='detailslable'>Email</p>
                            </div>
                            <div className='col-md-8'>
                                <p className='detailslable '><span className='pe-4'>:</span>{employeedetails?.email}</p>
                            </div>
                        </div>}
                    {employeedetails?.mobileNumber && <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Phone number</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{employeedetails?.mobileNumber}</p>
                        </div>
                    </div>}
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Store </p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{employeedetails?.ClientUnitEmployeeStoreMap?.map((item, id) => { return <span key={`demo_snap_${id}`}>{(id ? ', ' : '') + item.StoreType?.storeTypeName}</span> })}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Employeedetails