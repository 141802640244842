import React from 'react'
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const Bookstockitem = ({ bookStockDetailsShow, setBookStockDetailsShow, details, actualquantity }) => {
    return (
        <>
            <Modal show={bookStockDetailsShow} centered dialogClassName="my-modal" >
                <Modal.Header className='bookstockheader'>
                    <Modal.Title> Stock Details</Modal.Title>
                    <div
                        onClick={() => {
                            setBookStockDetailsShow(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='detail table-responsive'>
                        <table className="table">
                            <tbody>
                                {details?.partNo1 && 
                                    <tr>
                                        <th><p className='detailslable'>Part Number</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.partNo1}</p></td>
                                    </tr>
                                }
                                {details?.partNo2 && 
                                    <tr>
                                        <th><p className='detailslable'>Sub Part No.1</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.partNo2}</p></td>
                                    </tr>
                                }
                                {details?.partNo3 && 
                                    <tr>
                                        <th><p className='detailslable'>Sub Part No.2</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.partNo3}</p></td>
                                    </tr>
                                }
                                {details?.partNo4 && 
                                    <tr>
                                        <th><p className='detailslable'>Sub Part No.3</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.partNo4}</p></td>
                                    </tr>
                                }
                                {details?.partNo5 && 
                                    <tr>
                                        <th><p className='detailslable'>Sub Part No.4</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.partNo5}</p></td>
                                    </tr>
                                }
                                {details?.StoreType?.storeTypeName && 
                                    <tr>
                                        <th><p className='detailslable'>Store Type</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.StoreType?.storeTypeName}</p></td>
                                    </tr>
                                }
                                {details?.StockType?.stockTypeName && 
                                    <tr>
                                        <th><p className='detailslable'>Stock Type</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.StockType?.stockTypeName}</p></td>
                                    </tr>
                                }
                                {details?.storeTypeName && 
                                    <tr>
                                        <th><p className='detailslable'>Store Type</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.storeTypeName}</p></td>
                                    </tr>
                                }
                                {details?.stockTypeName && 
                                    <tr>
                                        <th><p className='detailslable'>Stock Type</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.stockTypeName}</p></td>
                                    </tr>
                                }
                                {details?.materialName && 
                                    <tr>
                                        <th><p className='detailslable'>Material Name </p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.materialName}</p></td>
                                    </tr>
                                }
                                {details?.materialName1 && 
                                    <tr>
                                        <th><p className='detailslable'>Material Sub Name 1</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.materialName1}</p></td>
                                    </tr>
                                }
                                {details?.materialName2 && 
                                    <tr>
                                        <th><p className='detailslable'>Material Sub Name 2</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.materialName2}</p></td>
                                    </tr>
                                }
                                {details?.materialName3 && 
                                    <tr>
                                        <th><p className='detailslable'>Material Sub Name 3</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.materialName3}</p></td>
                                    </tr>
                                }
                                {details?.materialName4 && 
                                <tr>
                                    <th><p className='detailslable'>Material Sub Name 4</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                    <td><p className='detailslable'>{details?.materialName4}</p></td>
                                </tr>
                                }
                                {details?.location1 && 
                                    <tr>
                                        <th><p className='detailslable'>Location 1</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.location1}</p></td>
                                    </tr>
                                }
                                {details?.location2 && 
                                    <tr>
                                        <th><p className='detailslable'>Location 2</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.location2}</p></td>
                                    </tr>
                                }
                                {details?.location3 && 
                                    <tr>
                                        <th><p className='detailslable'>Location 3</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.location3}</p></td>
                                    </tr>
                                }
                                {details?.location4 && 
                                    <tr>
                                        <th><p className='detailslable'>Location 4</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.location4}</p></td>
                                    </tr>
                                }
                                {details?.location5 && 
                                    <tr>
                                        <th><p className='detailslable'>Location 5</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.location5}</p></td>
                                    </tr>
                                }
                                {details?.uom && 
                                    <tr>
                                        <th><p className='detailslable'>UOM</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.uom}</p></td>
                                    </tr>
                                }
                                {details?.rate && 
                                    <tr>
                                        <th><p className='detailslable'>Rate</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.rate}</p></td>
                                    </tr>
                                }
                                {details?.value && 
                                    <tr>
                                        <th><p className='detailslable'>Value</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.value}</p></td>
                                    </tr>
                                }
                                {details?.expiryDate && 
                                    <tr>
                                        <th><p className='detailslable'>Expiry Date</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{moment(details?.expiryDate).utc().format('DD-MM-YYYY')}</p></td>
                                    </tr>
                                }
                                {details?.lastReceiptDate && 
                                    <tr>
                                        <th><p className='detailslable'>Last Receipt Date</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{moment(details?.lastReceiptDate).utc().format('DD-MM-YYYY')}</p></td>
                                    </tr>
                                }
                                {details?.lastIssueDate && 
                                    <tr>
                                        <th><p className='detailslable'>Last Issue Date</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{moment(details?.lastIssueDate).utc().format('DD-MM-YYYY')}</p></td>
                                    </tr>
                                }
                                {details?.remarks && 
                                    <tr>
                                        <th><p className='detailslable'>Remarks</p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'>{details?.remarks}</p></td>
                                    </tr>
                                }
                                {details?.quantity && 
                                    <tr>
                                        <th><p className='detailslable'><b>Book Quantity</b></p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'><b>{details?.quantity}</b></p></td>
                                    </tr>
                                }
                                {actualquantity && 
                                    <tr>
                                        <th><p className='detailslable'><b>Actual Stock Qty</b></p></th>
                                        <th className='colon'><p className='detailslable'>:</p></th>
                                        <td><p className='detailslable'><b>{actualquantity}</b></p></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Bookstockitem