import React, { useEffect, useState } from 'react'
import { APIRequest, TOTAL_CREDIT, NAME_INFO, SEND_FEEDBACK } from '../../api'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { logout } from "../../redux/action";
import '../ClientDashboard/clientdashboardstyle.css'
import { Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Constants from '../../Component/common/Global/constants';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from "yup";
import ReactQuill from 'react-quill';

function ClientDashboard() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { assignmentId, clientId } = useParams();
    const token = useSelector((state) => state.token)
    const user = useSelector((state) => state.user)
    const credits = useSelector((state) => state.credit)
    const [username, Setusername] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [link, setLink] = useState("");
    const [focusObj, setFocusObj] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [totalcredit, SetTotalcredit] = useState("0")

    useEffect(() => {
        if (!token) {
            navigate("/Login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const logOut = () => {
        dispatch(logout())
        navigate("/Login");
    }
    const location = useLocation();

  
    const feedbackForm = useFormik({
        initialValues: {
            subject: "",
            message: "",
            attachmentFiles: ""
        },
        validationSchema: Yup.object().shape({
            subject: Yup.string().required('Write a subject'),
            message: Yup.string().required('Write a message'),
        }),
        onSubmit: values => {
            var feedbackFormData = new FormData();
            feedbackFormData.append('userId', user.userId);
            feedbackFormData.append('subject', values.subject);
            feedbackFormData.append("message", values.message);
            selectedFiles.forEach((file) => {
                feedbackFormData.append('attachmentFiles', file);
            });
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(SEND_FEEDBACK)
                .reqURL("auth/sendFeedback")
                .jsonParams(feedbackFormData)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        },
    });

    useEffect(() => {
        new APIRequest.Builder()
            .get()
            .setReqId(TOTAL_CREDIT)
            .reqURL(`billsAndPayments/getBillAndPaymentBalance/${user?.organisationId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
        new APIRequest.Builder()
            .post()
            .setReqId(NAME_INFO)
            .reqURL("auth/getOrganisationName")
            .jsonParams({ organisationId: `${user?.organisationId}` })
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credits])

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case TOTAL_CREDIT:
                SetTotalcredit(response?.data?.totalCredit);
                break;
            case NAME_INFO:
                Setusername(response.data.name);
                break;
            case SEND_FEEDBACK:
                setLoading(false);
                setFocusObj({});
                toast.success(response?.data?.message);
                setShowModal(false);
                feedbackForm.resetForm();
                break;
            default:
                break;
        }
    }


    const onError = (response, reqId) => {
        switch (reqId) {
            case TOTAL_CREDIT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else { }
                break;
            case NAME_INFO:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`);
                }
                break;
            case SEND_FEEDBACK:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            default:
                break;
        }
    }
    const onMessageFocus = () => {
        let focusObj = handleFocus("messageFocused");
		setFocusObj({...focusObj});
    }
    const handleFocus = (focusedFieldName) => {
        if (focusedFieldName ==  "") {
			return {
				messageFocused: false
			};
		} else {
			return {
				messageFocused: false,
				[focusedFieldName]: true,
			};
		}
    }
    const modules = {
		toolbar: [
			[{ header: [1, 2, 3, 4, false] }],
			["bold", "italic", "underline", "strike"],
			[{ list: "ordered" }, { list: "bullet" }, { 'indent': '-1'}, { 'indent': '+1' }, { 'align': [] }],
            ["blockquote", { 'font': [] }],
			["clean"],
		],
	};

    const handleFileChange = (event) => {
        for (let i = 0; i < event.target.files.length; i++)
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    }

    const checkUrl = () => {
        switch (location.pathname){
            case `/Client`:
            case `/Client/ongoingassignment/${assignmentId}`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/ongoing_assignments/");
                break;
            case `/Client/ongoingassignment/${assignmentId}/bookstock`:
            case `/Client/ongoingassignment/${assignmentId}/bookstock/bookstocktable`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/ongoing_assignments/upload-book-stock");
                break;
            case `/Client/ongoingassignment/${assignmentId}/sampling`:
            case `/Client/ongoingassignment/${assignmentId}/sampledetails`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/ongoing_assignments/Sampling");
                break;
            case `/Client/ongoingassignment/${assignmentId}/bookstockdetails`:
            case `/Client/ongoingassignment/${assignmentId}/verification`:
            case `/Client/ongoingassignment/${assignmentId}/deviation`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/ongoing_assignments/finalize-stock");
                break;
            case `/Client/ongoingassignment/c/materialmovement`:
            case `/Client/ongoingassignment/${assignmentId}/materailnotused`:
            case `/Client/ongoingassignment/${assignmentId}/materialexpiery`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/ongoing_assignments/Stock-analysis");
                break;
            case `/Client/assignment`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/assignments/");
                break;
            case `/Client/completedassignment/${assignmentId}`:
            case `/Client/completedassignment/${assignmentId}/bookstockdetails`:
            case `/Client/completedassignment/${assignmentId}/verification`:
            case `/Client/completedassignment/${assignmentId}/deviation`:
            case `/Client/completedassignment/${assignmentId}/sampling`:
            case `/Client/completedassignment/${assignmentId}/sampledetails`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/assignments/complete");
                break;
            case `/Client/billandpayment`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/Bills&Payments");
                break;
            case `/Client/client`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/Clients/");
                break;
            case `/Client/Unitmaster/${clientId}`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/Clients/Add_units");
                break;
            case `/Client/admin`:
                setShowHelpModal(true);
                setLink("https://docs.takestock.in/docs/Admin");
                break;
            default:
                setShowHelpModal(false);
                break; 
        }
    }

    return (
        <>
            <nav className="navbar navbar-light bg-white fixed-top">
                <div className="container-fluid">
                    <a href="/Client" className="navbar-brand takelogo ">
                        <img src='/assets/images/talke-logo.jpg' className='img-fluid me-3 ms-3' alt=''></img>
                    </a>
                    <h5 className='mt-1 mb-0 mx-5'>{username.organisation}</h5>
                    <div className="main_user_nav">

                        <h5 className='mb-0 me-2'>Credit <b>{totalcredit}</b></h5>
                        {/* <a> <img src='assets/images/bell.png' className='img-fluid me-3 ms-3' alt=''></img> </a> */}
                        <a onClick={checkUrl} href="javascript:void(0)">
                            <i className="fa fa-question-circle" aria-hidden="true"></i></a>
                        <a onClick={() => setShowModal(true)}><i className="fa fa-envelope" aria-hidden="true"></i></a>
                        <div className='user_box'>
                            {/* <img src='assets/images/user.png' className='img-fluid'></img> */}
                            <h5 className='mb-0 ms-1'>{user.firstName + ' ' + user.lastName}</h5>
                            <div className='px-2' onClick={logOut}><i className="fa fa-sign-out" aria-hidden="true"></i></div>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='container-fluid ps-0'>
                <div className='main_tabs'>
                    <div className="row">
                        <div className='col-md-2'>
                            <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                <button className={location.pathname === "/Client" ? "nav-link active" : " nav-link"} id="v-pills-home-tab" data-bs-toggle="pill" onClick={() => navigate("/Client")} data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                    <i className="fa fa-tachometer me-3" aria-hidden="true"></i>
                                    <span>Ongoing Assignments</span>
                                </button>
                                <button className={location.pathname === "/Client/assignment" ? "nav-link active" : " nav-link"} id="v-pills-profile-tab" data-bs-toggle="pill" onClick={() => navigate("/Client/assignment")} data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                    <i className="fa fa-list me-3" aria-hidden="true"></i>
                                    <span>Assignments</span>
                                </button>
                                {user.role === "SUBSCRIBER_ADMIN" && <button className={location.pathname === "/Client/billandpayment" ? "nav-link active" : " nav-link"} id="v-pills-messages-tab" data-bs-toggle="pill" onClick={() => navigate("/Client/billandpayment")} data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                    <i className="fa fa-credit-card me-3" aria-hidden="true"></i>
                                    <span>Bills & Payments</span>
                                </button>}
                                {user.role === "SUBSCRIBER_ADMIN" && <button className={location.pathname === "/Client/client" ? "nav-link active" : " nav-link"} id="v-pills-settings-tab" data-bs-toggle="pill" onClick={() => navigate("/Client/client")} data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                                    <i className="fa fa-users" aria-hidden="true"></i>
                                    <span>Clients</span>
                                </button>}
                                {user.role === "SUBSCRIBER_ADMIN" && <button className={location.pathname === "/Client/admin" ? "nav-link active" : " nav-link"} id="v-pills-messages-tab2" data-bs-toggle="pill" onClick={() => navigate("/Client/admin")} data-bs-target="#v-pills-messages2" type="button" role="tab" aria-controls="v-pills-messages2" aria-selected="false">
                                    <i className="fa fa-user-circle-o" aria-hidden="true"></i>
                                    <span>Admin </span>
                                </button>}
                            </div>
                        </div>
                        <div className='col-md-10 new_conetent-col'>
                            <div className="tab-content" id="v-pills-tabContent">
                            </div>
                            <Outlet />
                        </div>

                    </div>

                </div>

            </div>
        {showModal &&
            <Modal show={showModal} size='lg'>
                <form onSubmit={feedbackForm.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Feedback</Modal.Title>
                        <div onClick={() => {
                            feedbackForm.resetForm();
                            setShowModal(false);
                            setFocusObj({});
                            setSelectedFiles([]);
                        }} data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <label htmlFor="exampleInput7" className="form-label">Subject</label>
                            <input className="form-control subjectInput" style={{borderColor: "#ccc"}} type="text" value={feedbackForm.values.subject} onChange={(event) => feedbackForm.setFieldValue("subject",event.target.value)} />
                        </div>
                        {feedbackForm.touched.subject && feedbackForm.errors.subject ? (
                            <span className="error">{feedbackForm.errors.subject}</span>
                        ) : null}
                        <div>
                            <label htmlFor="exampleInput7" className="form-label">Message</label>
                            <ReactQuill
                                className={focusObj.messageFocused?"feedback-focus":"feedback"}   
                                value={feedbackForm.values.message}
                                onChange={(event) => {
                                    feedbackForm.setFieldValue('message', event)
                                }}
                                onFocus={onMessageFocus}
                                modules={modules}
                            />
                        </div>
                        {feedbackForm.touched.message && feedbackForm.errors.message ? (
                            <span className="error">{feedbackForm.errors.message}</span>
                        ) : null}
                        <div>
                            <label htmlFor="exampleInput7" className="form-label">Upload File</label>
                            <input
                                type="file"
                                className="form-control w-50 mb-2"
                                id="file"
                                multiple
                                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx"
                                onChange={handleFileChange} />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            feedbackForm.resetForm();
                            setShowModal(false);
                            setFocusObj({});
                            setSelectedFiles([]);
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Send"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        }
        {showHelpModal &&
            <Modal show={showHelpModal} size='xl' className='modal-help'>
                <Modal.Header>
                    <Modal.Title>Help</Modal.Title>
                    <div onClick={() => {
                        setShowHelpModal(false);
                    }} data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <iframe src={link} width="100%" height="100%"></iframe>
                </Modal.Body>
            </Modal>
        }
        </>
    );
}

export default ClientDashboard;