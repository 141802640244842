import React, { useEffect, useState } from 'react';
import "react-quill/dist/quill.snow.css";
import './textstyle.css';
import { Modal } from 'react-bootstrap';
import Spinner from "react-bootstrap/Spinner";
import { APIRequest, OBSERVATIONS_LIST, UPDATE_OBSERVATION_ORDER } from '../../api'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';
import * as Constants from '../common/Global/constants';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { CustomTableRow } from '../../Global/globalStyles.style';
import CommonButton from '../../commonComponent/button/commonButton';

function ObservationsOrder({ orderObservationsModalShow, orderObservationsModalClose }) {

    const { assignmentId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [observationlist, setObservationlist] = useState([]);

    useEffect(() => {
        new APIRequest.Builder()
        .get()
        .setReqId(OBSERVATIONS_LIST)
        .reqURL(`assignments/getObservatoin/${assignmentId}`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest()
    },[]);

    const stripHtmlTags = (html) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        return div.textContent || div.innerText || '';
    };

    const observationsOrder = () => {
        setLoading(true);
        const observation = observationlist.map((item,index) => ({
            orderId: index+1,
            assignmentObservationsId: item.assignmentObservationsId
        }));
        
        const finalValue = {
            assignmentId:assignmentId,
            observationData:JSON.stringify(observation)
        }
        new APIRequest.Builder()
            .post()
            .setReqId(UPDATE_OBSERVATION_ORDER)
            .reqURL("assignmentsObservatoinCycle/updateObservationOrder")
            .jsonParams(finalValue)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case OBSERVATIONS_LIST:
                const observationData = response.data?.data?.records;
                setObservationlist(observationData)
                break;
            case UPDATE_OBSERVATION_ORDER:
                toast.success(response?.data?.message);
                orderObservationsModalClose("save");
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case OBSERVATIONS_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                }
                break;
            case UPDATE_OBSERVATION_ORDER:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                }
                break;
            default:
                break;
        }
    }
    
    //Drag & Drop functionality
    const onDragEnd = (result) => {
        const {destination, source} = result;
        //Outside the droppable
        if(!destination){
            return
        }

        //if draggable is dropped in same droppable and in same position
        if(destination.droppableId === source.droppableId &&
        destination.index === source.index){
            return
        }

        let newPosition = destination.index;
        let oldPosition = source.index;
        let observation = observationlist;

        let fieldObj = observation[oldPosition];
        observation.splice(oldPosition,1)//remove from old position
        observation.splice(newPosition,0,fieldObj);//move the field Obj to new pos

        let observationDataNew = observation.map((item,idx)=>{
            item.orderId = idx+1;
            return item;
        })
        setObservationlist(observationDataNew);
    }

    return (
        <>
            <Modal size='lg' show={orderObservationsModalShow} className="scrollable-modal">
                <Modal.Header>
                    <Modal.Title>Observations</Modal.Title>
                    <div
                        onClick={() => {orderObservationsModalClose()}}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className="modal-body">
                    <div className="body-wrapper mt-2">
                    <div className="common-tab-bg">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div className="common-table" style={{width:"100%",borderRadius:4}}>
                                <div className="table-custom-layout">
                                    <div className="table-custom-header">
                                        <div className="table-custom-header-row">
                                            <p style={{width:"4%",justifyContent:"center", minHeight: "42px"}}>No.</p>
                                            <p style={{width:"70%",marginRight:10}}>Observations Details</p>
                                            <p style={{width:"26%",marginRight:10}}>Reviewer</p>
                                        </div>
                                    </div>
                                    <Droppable droppableId={"droppable"}>
                                        {(provided)=>(
                                            <div className="table-custom-body"
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}>
                                                {Object.values(observationlist).map((item,idx)=>{
                                                return(
                                                    <Draggable draggableId={item?.orderId+""} index={idx} key={item?.orderId+""} >
                                                        {(provided,snapshot) => (
                                                            <CustomTableRow
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                isDragging={snapshot.isDragging}
                                                            >
                                                                <p style={{width:"4%", justifyContent:"center"}}>{item.orderId}</p>
                                                                <p style={{width:"70%",marginRight:10}}>{stripHtmlTags(item?.observationDetails)}</p>
                                                                <p style={{width:"26%",marginRight:10}}>{item?.currentObservation?.map(item => item?.title + "" + item?.firstName + " " + item.lastName) ?? ""}</p>
                                                            </CustomTableRow>
                                                        )}
                                                    </Draggable>
                                                )
                                            })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </DragDropContext>
                    </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <CommonButton
                        style={{marginRight: 10}}
                        loading={loading}
                        label={'Save'}
                        onClick={() => observationsOrder()}
                    />
                    <CommonButton
                        label={'Cancel'}
                        onClick={() => orderObservationsModalClose()}
                    />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ObservationsOrder;