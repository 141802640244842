import React, { useState, useEffect } from 'react';
import '../dashboardstyle.css';
import CityMaster from "./CityMaster";
import { APIRequest, DELETE_CITY, LIST_CITY, LIST_COUNTRY, LIST_STATE, CITY_EXIST_OR_NOT, RECONCILATION_LIST, CHANGE_RECONCILATION_ACTIVE_STATUS, RECONCILATION_EXIST_OR_NOT } from "../../../api";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Table } from '../../Table/Table';
import { toast } from "react-toastify";
import MasterMain from './MasterMain';
import UpdateCItymaster from './updateCItymaster';
import moment from "moment";
import Addreconcilation from './Reconcilation/addreconcilation';
import Editreconcilation from './Reconcilation/editreconcilation';
import { Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/action';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ReusableModal from '../../Modal/reusablemodel';
import RegularDropDown from '../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../Component/common/Global/constants";
import { isEmptyArray } from '../../../Component/common/Global/commonFunctions';

const DashboardMaster = () => {
    const [Id, setId] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user);
    const [isCityMaster, setIsCityMaster] = useState(false);
    const [confrimDialogForReco, setConfrimDialog] = useState(false);
    const [confrimCityDialog, setConfrimCityDialog] = useState(false);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState([]);
    const [cityList, setCityList] = useState(
        {
            total: 0,
            records: [],
            page: 1,
            limit: 15
        }
    );
    const [currentEditCityRecord, setCurrentEditCityRecord] = useState(null);
    const [editCityModal, SeteditcityModal] = useState(false);
    const [reconcilation, Setreconcilation] = useState([]);
    const [addreconcilation, Setaddreconcilation] = useState(false);
    const [editreconcilation, Seteditconcilation] = useState(false)
    const [reconcilationdata, Setreconcilationdata] = useState([]);
    const [cityactive, Setcityactive] = useState(true);
    const [reconactive, Setreconactive] = useState(true);
    const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
    const [selectedRecoOption, setSelectedRecoOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [componentDidMountCityFlag, isComponentDidMountCityFlag] = useState(false);
    const [componentDidMountRecoFlag, isComponentDidMountRecoFlag] = useState(false);
    const [deleteCityLoading, setDeleteCityLoading] = useState(false);
    const [deleteRecoLoading, setDeleteRecoLoading] = useState(false);

    useEffect(() => {
        loadMasterList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cityactive, reconactive]);

    const loadMasterList = () => {
        new APIRequest.Builder()
            .get()
            .setReqId(LIST_COUNTRY)
            .reqURL("country/getCountryList")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
        new APIRequest.Builder()
            .get()
            .setReqId(LIST_STATE)
            .reqURL("state/getStateList")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        !cityactive && new APIRequest.Builder()
            .post()
            .setReqId(LIST_CITY)
            .reqURL("city/getCityList?isActive=false")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        cityactive && new APIRequest.Builder()
            .post()
            .setReqId(LIST_CITY)
            .reqURL("city/getCityList?page=1&limit=15")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();

        reconactive && new APIRequest.Builder()
            .get()
            .setReqId(RECONCILATION_LIST)
            .reqURL("reconcilation/getReconcilationCommentsList?page=1&limit=15")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();


        !reconactive && new APIRequest.Builder()
            .get()
            .setReqId(RECONCILATION_LIST)
            .reqURL("reconcilation/getReconcilationCommentsList?isActive=false&page=1&limit=15")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case LIST_COUNTRY:
                setCountry(response.data?.data?.records);
                break;
            case LIST_STATE:
                setState(response.data?.data?.records);
                break;
            case LIST_CITY:
                setCityList(response.data?.data);
                isComponentDidMountCityFlag(true);
                break;
            case RECONCILATION_LIST:
                Setreconcilation(response.data?.data);
                isComponentDidMountRecoFlag(true);
                break;
            case RECONCILATION_EXIST_OR_NOT:
                setConfrimDialog(true);
                setId(response.data?.reconcilationCommentsId);
                break;
            case CITY_EXIST_OR_NOT:
                setConfrimCityDialog(true);
                setId(response.data?.cityId);
                break;
            case DELETE_CITY:
                loadMasterList();
                setConfrimCityDialog(false);
                toast.success(response?.data?.message);
                setDeleteCityLoading(false);
                break;
            case CHANGE_RECONCILATION_ACTIVE_STATUS:
                loadMasterList();
                setConfrimDialog(false);
                toast.success(response?.data?.message);
                setDeleteRecoLoading(false);
                break;

            default:
                break;
        }
    };

    const handleDeleteReconcilation = () => {
        setDeleteRecoLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(CHANGE_RECONCILATION_ACTIVE_STATUS)
            .jsonParams({
                isActive: "false",
                userId: user?.userId
            })
            .reqURL(`reconcilation/changeReconcilationIsActiveStatus/${Id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const handleReactiveteReconcilation = () => {
        setDeleteRecoLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(CHANGE_RECONCILATION_ACTIVE_STATUS)
            .jsonParams({
                isActive: "true",
                userId: user?.userId
            })
            .reqURL(`reconcilation/changeReconcilationIsActiveStatus/${Id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const handleDeleteCity = () => {
        setDeleteCityLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(DELETE_CITY)
            .jsonParams({
                isActive: "false",
                userId: user?.userId
            })
            .reqURL(`city/changeCityIsActiveStatus/${Id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const handleReactiveteCity = () => {
        setDeleteCityLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(DELETE_CITY)
            .jsonParams({
                isActive: "true",
                userId: user?.userId
            })
            .reqURL(`city/changeCityIsActiveStatus/${Id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case LIST_COUNTRY:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case LIST_STATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case LIST_CITY:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case RECONCILATION_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case RECONCILATION_EXIST_OR_NOT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case CITY_EXIST_OR_NOT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                }
                break;
            case DELETE_CITY:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                    setDeleteCityLoading(false);
                }
                break;
            case CHANGE_RECONCILATION_ACTIVE_STATUS:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(response?.data?.message);
                    setDeleteRecoLoading(false);
                }
                break;
            default:
                break;
        }
    };

    const handleTableChangeCity = (type, newState) => {
        let url = cityactive ? `city/getCityList?isActive=true&page=${(type === "search" || type === "sort") ? "1" : newState.page}` :
            `city/getCityList?isActive=false&page=${(type === "search" || type === "sort") ? "1" : newState.page}`
        // let url = `city/getCityList?page=${(type === "search" || type === "sort") ? "1" : newState.page}&`

        if (type === "sort") {
            if (newState.sortField === "name") {
                url = url + `&sortColumn=city`
            }
            if (newState.sortField === "state_name") {
                url = url + `&sortColumn=state`
            }
            if (newState.sortField === "country_name") {
                url = url + `&sortColumn=country`
            }
            if (newState.sortOrder) {
                url = url + `&sortType=${newState.sortOrder}`
            }
        }

        if (newState.sizePerPage) {
            url = url + `&limit=${newState.sizePerPage}`
        }
        // Create a new API request builder
        const apiRequestBuilder = new APIRequest.Builder()
            .post()
            .setReqId(LIST_CITY)
            .reqURL(url)
            .response(onResponse)
            .error(onError);

        // If the type is "search" and there is a search text
        if (type === "search" && newState.searchText) {
            // Add a JSON parameter to the request
            // The parameter is an object with a "search" property
            // The value of the "search" property is the search text, encoded as a URI component
            apiRequestBuilder.jsonParams({
                search: encodeURIComponent(newState.searchText)
            });
        }
        // Build the API request and execute it
        apiRequestBuilder.build().doRequest();
    }

    const handleTableChange = (type, newState) => {
        let url = reconactive ? `reconcilation/getReconcilationCommentsList?page=${(type === "search" || type === "sort") ? "1" : newState.page}&` :
            `reconcilation/getReconcilationCommentsList?isActive=false&page=${(type === "search" || type === "sort") ? "1" : newState.page}&`;
        if (type === "search" && newState.searchText) {
            url = url + `search=${encodeURIComponent(newState.searchText)}&`
        }
        if (type === "sort") {
            if (newState.sortField === "operation") {
                url = url + `sortColumn=operation&`
            } if (newState.sortField === "comment") {
                url = url + `sortColumn=comment&`
            }
            if (newState.sortOrder) {
                url = url + `sortType=${newState.sortOrder}&`
            }
        }

        if (newState.sizePerPage) {
            url = url + `limit=${newState.sizePerPage}&`
        }

        new APIRequest.Builder()
            .get()
            .setReqId(RECONCILATION_LIST)
            .reqURL(url)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const deleteReconcilation = (id) => {
        new APIRequest.Builder()
            .post()
            .setReqId(RECONCILATION_EXIST_OR_NOT)
            .jsonParams({
                reconcilationCommentsId: id
            })
            .reqURL(`reconcilation/isReconcilationCommentExisting`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const handleCityDelete = (record) => {
        new APIRequest.Builder()
            .post()
            .setReqId(CITY_EXIST_OR_NOT)
            .jsonParams({
                cityId: record?.cityId
            })
            .reqURL(`city/isCityExisting`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const handleActiveInactiveChange = (selected) => {
        selected.status === "Inactive" ? Setcityactive(false) : Setcityactive(true);
        setSelectedOption(selected.status)
        showDropdownItem(false)
    }

    const handleRecoActiveInactiveChange = (selected) => {
        selected.status === "Inactive" ? Setreconactive(false) : Setreconactive(true);
        setSelectedRecoOption(selected.status)
        showDropdownItem(false)
    }
    
    return (
        <>
            <div className='master_box'>
                <div className='containers p-0'>
                    <div className='master_boxinfo'>

                        <Tabs defaultActiveKey="first">
                            <Tab eventKey="first" title="City Master">
                                <div className='table-responsive pt-2 pb-5 mt-2'>
                                    <div className='deactivelist'>
                                        <RegularDropDown 
                                            placeholder={selectedOption}
                                            dropdownArr={Constants.activeInactiveStatus}
                                            labelParam="status"
                                            onDropDownItemClick={handleActiveInactiveChange}
                                            isFocusRequired={true}
                                            show={isShowDropDownItem}
                                            onToggle={(isOpen) => showDropdownItem(isOpen)}
                                            defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                                        />
                                    </div>
                                    <div className='add_btns '>

                                        {cityactive && <button type='button' className='btn btn-primary' data-bs-toggle="#modal" onClick={() => setIsCityMaster(true)}><i className="fa fa-plus-circle" aria-hidden="true"></i> Add New</button>}

                                    </div>
                                    <i className="fa fa-search search_icon" aria-hidden="true"></i>
                                    {componentDidMountCityFlag && cityList?.records &&
                                        <Table data={cityList?.records?.map((item, index) => {
                                            return {
                                                ...item,
                                                key: index,
                                                index: (cityList?.page - 1) * cityList.limit + index + 1,
                                                id: item.id,
                                                name: item?.cityName,
                                                state_name: item?.State.stateName,
                                                country_name: item?.State.Country.countryName
                                            }
                                        })} columns={[
                                            {
                                                dataField: 'index',
                                                text: 'No',
                                                headerClasses: 'sr_no'
                                            }, {
                                                dataField: 'name',
                                                text: 'City',
                                                sort: true,
                                            }, {
                                                dataField: 'state_name',
                                                text: 'State',
                                                sort: true,
                                            },
                                            {
                                                dataField: 'country_name',
                                                text: 'Country',
                                                sort: true,
                                            },
                                            {
                                                dataField: 'action',
                                                text: 'Action',
                                                headerClasses: 'action',
                                                formatter: (cell, item) => {
                                                    return (
                                                        item?.isActive === "true" ?
                                                            <div className='edit_icons'>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                                                                        <li><a className="dropdown-item" data-bs-toggle="#modal" data-bs-target="#exampleModal"
                                                                            onClick={() => {
                                                                                setCurrentEditCityRecord(item);
                                                                                SeteditcityModal(true);
                                                                            }}><i className="fa fa-pencil me-1" aria-hidden="true"></i> Edit</a></li>
                                                                        <li><a className="dropdown-item" onClick={() => {
                                                                            handleCityDelete(item);
                                                                        }}><i className="fa fa-trash me-1" aria-hidden="true"></i> Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div> :
                                                            <div className='edit_icons'>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                                                                        <li><a className="dropdown-item" onClick={() => {
                                                                            setId(item?.cityId)
                                                                            setConfrimCityDialog(true);
                                                                        }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                    )
                                                }
                                            }
                                        ]}
                                            handleTableChange={handleTableChangeCity}
                                            totalSize={cityList?.total ?? 0}
                                            currentPage={cityList?.page ?? 1}
                                            sizePerPage={cityList?.limit ?? 15}
                                        ></Table>
                                    }
                                    {componentDidMountCityFlag && isEmptyArray(cityList?.records) && 
                                        <div className="no-items-layout">
                                            <div className="no-items-card">
                                                <h6>
                                                {
                                                    Constants.message.noRecordsWarning
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Tab>
                            <Tab eventKey="second" title="Stock Type">
                                <MasterMain type="Stock" />
                            </Tab>
                            <Tab eventKey="third" title="Unit Type">
                                <MasterMain type="Unit" />
                            </Tab>
                            <Tab eventKey="four" title="Store Type">
                                <MasterMain type="Store" />
                            </Tab>
                            <Tab eventKey="fifth" title="Reconciliation">
                                <div className='table-responsive pt-2 pb-5 mt-2'>
                                    <div className='deactivelist'>
                                        <RegularDropDown 
                                            placeholder={selectedRecoOption}
                                            dropdownArr={Constants.activeInactiveStatus}
                                            labelParam="status"
                                            onDropDownItemClick={handleRecoActiveInactiveChange}
                                            isFocusRequired={true}
                                            show={isShowDropDownItem}
                                            onToggle={(isOpen) => showDropdownItem(isOpen)}
                                            defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                                        />
                                    </div>
                                    <div className='add_btns'>
                                        {reconactive && <button type='button' className='btn btn-primary' onClick={() => Setaddreconcilation(true)}>
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i> Add New</button>}
                                    </div>
                                    <i className="fa fa-search search_icon" aria-hidden="true"></i>
                                    {componentDidMountRecoFlag && reconcilation?.records &&
                                        <Table data={reconcilation?.records?.map((item, index) => {
                                            return {
                                                ...item,
                                                key: index,
                                                index: (reconcilation?.page - 1) * reconcilation.limit + index + 1,
                                                id: item?.reconcilationCommentsId,
                                                operation: item?.operation == "add" ? "Add" : "Substract" ?? "",
                                                comment: item?.comment ?? "",
                                            }
                                        }) ?? []} columns={[
                                            {
                                                dataField: 'index',
                                                text: 'No',
                                                headerClasses: 'sr_no'
                                            }, {
                                                dataField: 'operation',
                                                text: 'Operation',
                                                sort: true,
                                            }, {
                                                dataField: 'comment',
                                                text: 'Comments',
                                                sort: true,
                                            },
                                            {
                                                dataField: 'action',
                                                text: 'Action',
                                                headerClasses: 'action',
                                                formatter: (cell, item) => {

                                                    return (
                                                        item?.isActive === "true" ?
                                                            <div className='edit_icons'>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                                                                        <li><a className="dropdown-item" data-bs-toggle="#modal" onClick={() => { Setreconcilationdata(item); Seteditconcilation(true) }} ><i className="fa fa-pencil me-1" aria-hidden="true"></i> Edit</a></li>
                                                                        <li><a className="dropdown-item" onClick={() => { deleteReconcilation(item?.reconcilationCommentsId) }}><i className="fa fa-trash me-1" aria-hidden="true" ></i> Delete</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='edit_icons'>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                                                                    </button>
                                                                    <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                                                                        <li><a className="dropdown-item" onClick={() => {
                                                                            setId(item?.reconcilationCommentsId)
                                                                            setConfrimDialog(true);
                                                                        }}><i className="fa fa-repeat me-1" aria-hidden="true"></i>Reactivate</a></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                    )
                                                }
                                            }
                                        ]}
                                            handleTableChange={handleTableChange}
                                            totalSize={reconcilation?.total ?? 0}
                                            currentPage={reconcilation?.page ?? 1}
                                            sizePerPage={reconcilation?.limit ?? 15}
                                        ></Table>
                                    }
                                    {componentDidMountRecoFlag && isEmptyArray(reconcilation?.records) && 
                                        <div className="no-items-layout">
                                            <div className="no-items-card">
                                                <h6>
                                                {
                                                    Constants.message.noRecordsWarning
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Tab>
                        </Tabs>

                        <CityMaster isCityMaster={isCityMaster} setIsCityMaster={setIsCityMaster}
                            country={country} state={state} refreshMasterList={loadMasterList}
                            currentEditCityRecord={currentEditCityRecord}
                        />

                        <UpdateCItymaster editCityModal={editCityModal} SeteditcityModal={SeteditcityModal}
                            country={country} state={state} refreshMasterList={loadMasterList}
                            currentEditCityRecord={currentEditCityRecord} />

                        <Addreconcilation Setaddreconcilation={Setaddreconcilation} addreconcilation={addreconcilation} refreshMasterList={loadMasterList} />
                        <Editreconcilation reconcilationdata={reconcilationdata} editreconcilation={editreconcilation} Seteditconcilation={Seteditconcilation} refreshMasterList={loadMasterList} />
                        <ReusableModal
                            show={confrimDialogForReco}
                            onClose={() => setConfrimDialog(false)}
                            loading={deleteRecoLoading}
                            onConfirm={reconactive ? handleDeleteReconcilation : handleReactiveteReconcilation}
                            message={reconactive ? "Are you sure, you want to delete the reconciliation comment?" : "Are you sure, you want to reactivate the reconciliation comment?"}
                        />
                        <ReusableModal
                            show={confrimCityDialog}
                            onClose={() => setConfrimCityDialog(false)}
                            loading={deleteCityLoading}
                            onConfirm={cityactive ? handleDeleteCity : handleReactiveteCity}
                            message={cityactive ? "Are you sure, you want to delete the city?" : "Are you sure, you want to reactivate the city?"}
                        />

                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardMaster