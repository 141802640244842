import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { logout } from '../../../../../../redux/action';
import '../../../../../OngoingAssignment/assignmentstyle.css';
import "../../../../../OngoingAssignment/reconcilation.css";
import Bookstockitem from '../../../../../OngoingAssignment/Detailspage/bookstockitem';
import * as Constants from '../../../../../../Component/common/Global/constants';
import { isEmptyVariable } from '../../../../../../Component/common/Global/commonFunctions';

const ReconciliationDetail = ({ reconcilation, handleOnClose, reconcilationitem, assignment }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const [minusValue, setMinusValue] = useState(0);
    const [plusValue, setPlusValue] = useState(0);
    const [uplodedData, setUplodedData] = useState([]);
    const [stockDetailsShow, setStockDetailsShow] = useState(false);
    const [reconcilationComment, setReconcilationComment] = useState([]);
 
    useEffect(() => {
        getCompletedActualReconcilationData();
    }, [])

    // Calculate the add and less total based on operator
    useEffect(() => {
        setPlusValue(sumfunction(reconcilationComment.filter(item => item.operator == 'add')))
        setMinusValue(sumfunction(reconcilationComment.filter(item => item.operator == 'substract')))
    }, [reconcilationComment])

    // Fetch the reconciliation comment and uploaded file
    const getCompletedActualReconcilationData = () => {
        reconcilationitem?.actualStockId && 
        fetch(Constants.API_URL.getCompletedActualReconcilationData, {
            method:"POST",  
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                actualStockId: reconcilationitem?.actualStockId,
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setReconcilationComment(data?.data?.records);
                setUplodedData(data?.data?.uploadedFiles)
            }else{
              toast.error(`${data?.message}`);
            }
        });
    }

    // Generate file URL for download
    const generateFileUrl = (fileName) => {
        fetch(Constants.API_URL.generateFileUrl, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                fileName:fileName,
                type:Constants.fileType.reconcilations
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                const link = document.createElement('a');
                link.href = data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }else{
                toast.error(`${data?.message}`);
            }
        });
    }

    // Function to calculate the sum of 'quantity' properties in an array
    const sumfunction = (value) => {
        var sum = 0;
        value?.forEach(subData => { 
            if(subData?.quantity != undefined){
                sum += +subData?.quantity
            }
        });
        return sum;
    }
    
    // Function to remove everything after '#' in a filename
    function removeValueAfterHash(filename) {
        // Use regular expression to replace any characters between '#' and the end of the string with '.'
        const cleanedFilename = filename.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    // Calculate the balance based on difference, plusValue, and minusValue
    const difference = reconcilationitem?.actualStockQuantity - reconcilationitem?.quantity;
    const balance = difference + (isNaN(plusValue) ? 0 : plusValue) - (isNaN(minusValue) ? 0 : minusValue);

    return (
        <>
            <Modal show={reconcilation} size="md" >
                <Modal.Header>
                    <Modal.Title>Reconciliation Detail</Modal.Title>
                    <div
                        onClick={() => {
                            handleOnClose();
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='pb-0 position-relative'>
                    <div className='row'>
                        <div className='col md-12 text-center'>
                            <h6>Part Number : {!isEmptyVariable(reconcilationitem?.partNo1) ? reconcilationitem?.partNo1 : '-'}
                                <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                    style={{ 
                                        fontSize: "20px",
                                        color: "#552B84"
                                    }}
                                    onClick={() => {
                                        setStockDetailsShow(true)
                                    }}
                                />
                            </h6>
                            <h6>Material Name : {!isEmptyVariable(reconcilationitem?.materialName) ? reconcilationitem?.materialName : '-'}</h6>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Actual stock</p>
                            <p>{reconcilationitem?.actualquantity}</p>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Book Stock</p>
                            <p>{reconcilationitem?.quantity}</p>
                        </div>
                        <div className='modal-labels reconlable'>
                            <p>Difference</p>
                            <p>{isNaN(difference) ? 0 : difference}</p>
                        </div>
                        <div className='col-md-12'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className='mb-2 fw-bold'>Add</span>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {reconcilationComment.map(item => {
                                    return (
                                        item.operator == 'add' &&
                                        <>
                                            <div className='col-md-9 mb-2 reconlable'>
                                                <p>{item?.reconcilationCommentOther}</p>
                                            </div>
                                            <div className='col-md-3 mb-2 reconlable'>
                                                <p>{item?.quantity ?? ""}</p>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Add total</p>
                                <p style={{ fontSize: "12px" }}>
                                    {!isNaN(plusValue) ? plusValue : 0}
                                </p>
                            </div>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <span className='mb-2 fw-bold'>Less</span>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {reconcilationComment.map(item => {
                                    return (
                                        item.operator == 'substract' &&
                                        <>
                                            <div className='col-md-9 mb-2 reconlable'>
                                                <p>{item?.reconcilationCommentOther}</p>
                                            </div>
                                            <div className='col-md-3 mb-2 reconlable'>
                                                <p>{item?.quantity ?? ""}</p>
                                            </div>
                                        </>
                                    )
                                })}
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Less total</p>
                                <p style={{ fontSize: "12px" }}>
                                    {!isNaN(minusValue) ? minusValue : 0}
                                </p>
                            </div>
                            <div className='col-md-12'>
                                <Dropdown.Divider />
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Balance</p>
                                <p>{isNaN(balance) ? "0" : balance}</p>
                            </div>
                            {uplodedData?.length > 0 && 
                                <>
                                    <div>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Description</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {uplodedData.map((e, index) =>
                                                    <>
                                                        <tr key={index}>
                                                            <td>
                                                                <a href="javascript:void(0);" 
                                                                    onClick={() => generateFileUrl(e.uploadedFilePath)}
                                                                    style={{ 
                                                                        fontSize: "12px",
                                                                        color: "purple",
                                                                        textDecoration: "none",
                                                                        wordWrap: "break-word",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    {removeValueAfterHash(e.uploadedFilePath)}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {e?.description}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {stockDetailsShow &&
                <Bookstockitem
                    bookStockDetailsShow={stockDetailsShow}
                    setBookStockDetailsShow={setStockDetailsShow}
                    details={reconcilationitem}
                    actualquantity={reconcilationitem.actualquantity}
                />
            }
        </>
    )
}

export default ReconciliationDetail;