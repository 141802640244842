import { useState, useEffect } from "react";
import { APIRequest, LIST_CLIENT, ORGANISATION_NAME, SUBSCRIBER_ADMIN_LIST} from "../../../.././api";
import { Table } from "../../.././Table/Table";
import { useNavigate } from "react-router-dom";
import Issuecredit from "./issuecredit";
import { Clientdetails } from "./clientdetails";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/action";
import { toast } from "react-toastify";
import { SubscriberAdmin } from "./subscriberAdmin";
import * as Constants from '../../../../Component/common/Global/constants';
import { isEmptyArray, isEmptyVariable } from "../../../../Component/common/Global/commonFunctions";

const Client = () => {
  let navigate = useNavigate();
  const dispatch =useDispatch();
  const [data, setData] = useState({});
  const [issuecredit ,Setissuecredit]=useState(false)
  const [clientid ,Setclientid] =useState()
  const [clientmodal ,Setclientmodal]= useState(false)
  const [subscriber ,setSubscriber]= useState(false)
  const [details ,Setdetails]=useState();
  const [detailssubscriber ,Setdetailssubscriber]=useState();
  const [organisationname ,setOrgName]=useState({});
  const [subscriberadmindata, setSubscriberAdminData] = useState([]);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [componentDidMountSubscriberFlag, isComponentDidMountSubscriberFlag] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [organisationId, setOrganisationId] = useState('');

  useEffect(() => {
    getRegisterSubscriberList();
  }, []);

  const getRegisterSubscriberList = () => {
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_CLIENT)
      .reqURL("user/getRegisterSubscriberList?page=1&limit=15")
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const creditutilize = (e) => {
    Setclientid(e.organisationId);
    new APIRequest.Builder()
      .post()
      .setReqId(ORGANISATION_NAME)
      .reqURL("auth/getOrganisationName")
      .jsonParams({"organisationId": e.organisationId})
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
    Setissuecredit(true);
  }

  const subscriberAdmin=(organisationId)=>{
    new APIRequest.Builder()
      .get()
      .setReqId(SUBSCRIBER_ADMIN_LIST)
      .reqURL(`user/getSubscriberByOrg/${organisationId}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case ORGANISATION_NAME:
        setOrgName(response.data?.name);
        break;
      case SUBSCRIBER_ADMIN_LIST:
        setSubscriberAdminData(response.data?.data);
        isComponentDidMountSubscriberFlag(true);
      break;
      case LIST_CLIENT:     
        setData(response.data?.data);
        isComponentDidMountFlag(true);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case ORGANISATION_NAME:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
      break;
      case SUBSCRIBER_ADMIN_LIST:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
        break;
      case LIST_CLIENT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
        break;
      default:
        break;
    }
  };

  const handleTableChange = (type, newState) => {
    let url = `user/getRegisterSubscriberList?page=${type === "search" || type === "sort" ? "1" : newState.page}`;
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === "client_name") {
        url = url + `&sortColumn=organisation`
      }
      if (newState.sortField === "city") {
        url = url + `&sortColumn=city`
      }
      if (newState.sortField === "state") {
        url = url + `&sortColumn=state`
      }
      if (newState.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_CLIENT)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const handleOnIssueCreditClose = (reload) => {
    Setissuecredit(false);
    if(reload){
      getRegisterSubscriberList();
    }
  }

  const handleSubscriberAdmin = () => {
    subscriberAdmin(organisationId);
  }

  const clearFilter = () => {
    setSearch('');
    setSort('');
    setSortOrder('');
    isComponentDidMountFlag(false);
    getRegisterSubscriberList();
  }

  return (
    <>
    <div className="master_box">
    <div className="master_boxinfo">
      <div className="clients_menu">
        <a className='btn btn-primary mb-2' href="/Dashboard/client"> Clients </a>
      </div>
      <div className="table-responsive pt-2 pb-5">
      <div className="add_btns">
        {(search != "" || sort != "") && 
          <button
            type="button"
            className="btn btn-primary"
            variant="primary"
            onClick={() => clearFilter()}
          >
            <i
              className="fa fa-filter"
              aria-hidden="true"
            ></i>{" "}
            Clear
          </button>
        }
      </div>
      <i className="fa fa-search search_icon" aria-hidden="true"></i>
      {componentDidMountFlag && data?.records && (
        <Table
        data={data?.records?.map((item, index) => {
            return {
              ...item,
              key: index+1,
              id: item.id,
              index: (data.page-1) * data.limit + index + 1,
              client_name: item?.organisation,             
              city: item?.City?.cityName  ??  "-",
              state:item.City?.State?.stateName ?? "-",
              credits: item.credit   ?? 0,
            };
          }) ?? []}
          columns={[
            {
              dataField: "index",
              text: "No",
              style:{  cursor: "pointer" },
              headerClasses: "sr_no",
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  Setdetails(row); Setclientmodal(true)
                },
              }
            },
            {
              dataField: "client_name",
              text: "Organisation Name",
              style:{  cursor: "pointer" },
              sort: true,
              onclick: true,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  Setdetails(row); Setclientmodal(true)
                },
              }
            },           
            {
              dataField: "city",
              text: "City",
              style:{  cursor: "pointer" },
              sort: true,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  Setdetails(row); Setclientmodal(true)
                },
              }
            },
            {
              dataField: "state",
              text: "State",
              style:{  cursor: "pointer" },
              sort: false,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  Setdetails(row); Setclientmodal(true)
                },
              }
            },
            {
              dataField: "credits",
              text: "Credits",
              style:{  cursor: "pointer" },
              sort: true,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                  Setdetails(row); Setclientmodal(true)
                },
              }
            },
            {
              dataField: "action",
              text: "Action",
              headerClasses: "action",
              style: { cursor: "pointer" },
              formatter: (cell, e) => {
                  return (
                  <div className="edit_icons"  >
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={e=>e.stopPropagation()}
                      >
                        <i
                          className="fa fa-ellipsis-v"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                        <li><a className="dropdown-item" onClick={(v) => {v.stopPropagation()
                                                                               navigate(`/Dashboard/billandpayment/${e.organisationId}`,{ state: { organisationId: e.organisationId } })} }><i className="fa fa-list-alt me-1" aria-hidden="true"></i>Account Summary</a></li>
                        <li><a className="dropdown-item" onClick={(v)=>{v.stopPropagation()                     
                                                                       creditutilize(e)}}><i className="fa fa-credit-card-alt me-1" aria-hidden="true"></i>issue/Utilized Credit</a></li>
                        <li><a className="dropdown-item" onClick={(v)=>{v.stopPropagation()}}><i className="fa fa-spinner me-1" aria-hidden="true"></i>Process Refund </a></li>
                        <li><a className="dropdown-item" variant="primary" onClick={(v)=>{v.stopPropagation();  setOrganisationId(e?.organisationId)
                                                                                   setSubscriber(true); subscriberAdmin(e?.organisationId); }}><i className='fa fa-black-tie me-1'></i>Subscriber Admin</a></li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          ]}
          handleTableChange={handleTableChange}
          totalSize={data?.total ?? 0}
          currentPage={data?.page ?? 1}
          sizePerPage={data?.limit ?? 15}
        />
      )}
      {componentDidMountFlag && isEmptyArray(data?.records) && 
        <div className="no-items-layout">
          <div className="no-items-card">
            <h6>
              {
                Constants.message.noRecordsWarning
              }
            </h6>
          </div>
        </div>
      }
      </div>
      </div>
      </div>
      {issuecredit &&
        <Issuecredit issuecredit={issuecredit} Setissuecredit={handleOnIssueCreditClose} clientid={clientid} organisationname={organisationname.organisation}/>
      }
      <Clientdetails clientmodal={clientmodal} Setclientmodal={Setclientmodal} details={details}/>     
      {subscriber && <SubscriberAdmin subscriberAdmin={subscriberAdmin} organisationId={organisationId} subscriber={subscriber} setSubscriber={setSubscriber} subscriberadmindata={subscriberadmindata} setSubscriberAdminData={setSubscriberAdminData} componentDidMountFlag={componentDidMountSubscriberFlag}/>}
    </>
  );
};

export default Client;
