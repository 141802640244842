//Check variable is empty
export  const isEmptyVariable = (variableName) => {
    if(variableName === ''){
        return true;
    }
        
    if(variableName === undefined){
        return true;
    }

    if(variableName === null){
        return true;
    }

    if(variableName === "null"){
        return true;
    }
    
    return false;
}
//Check if array is empty
export const isEmptyArray = (arrayName) => {
    if(arrayName === ''){
        return true;
    }
        
    if(arrayName === undefined){
        return true;
    }

    if(arrayName === null){
        return true;
    }

    if(!Array.isArray(arrayName)){
        return true;
    }

    if(arrayName.length === 0){
        return true;
    }
    
    return false;
}
export const randomStringGenerator = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}
export const localDateExtractor = (date) => {
    var date = new Date(date);
    return date.toLocaleDateString('en-GB').replace(/\//g, "-");
}
export const localTimeExtractor = (date) => {
    var date = new Date(date);
    return date.toLocaleTimeString('en-GB', {hour: '2-digit', minute: '2-digit', hour12: true});
}