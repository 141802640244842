import React, { Component } from "react";
import { Alert } from "antd";

class CommonAlert extends Component {
    constructor(props) {
        super(props);
        // Define the default style properties for the alert
        this.alertStyle = {
            marginTop: 10, 
            borderRadius: 5
        };
    }

    // This function takes an object 'styleProperties' as an argument and returns the same object with additional properties.
    addStylePropertiesToAlert(styleProperties) {
        // Check if the 'backgroundColor' property exists in the 'styleProperties' object.
        if(styleProperties?.backgroundColor){
            // If it exists, set the 'borderColor' property of 'styleProperties' object to the same value as 'backgroundColor'.
            styleProperties.borderColor = styleProperties.backgroundColor
        }
        // Iterate over the entries in the 'this.alertStyle' object using 'Object.entries()' method.
        Object.entries(this.alertStyle).map(([key, value]) => {
            // Check if the current key does not exist in the 'styleProperties' object.
            if(!styleProperties[key]){
                // If it doesn't exist, add the key-value pair from 'this.alertStyle' object to 'styleProperties' object.
                styleProperties[key] = value
            }
        });
        // Finally, return the updated 'styleProperties' object.
        return styleProperties;
    }

    // Function getAlertCustomStyle() used to get the custom style for a alert
    // If the alert has a prop 'style' then the custom style will be applied to the alert
    //  If the alert does not have a propstyle' then the style will be used
    getAlertCustomStyle() {
        // If the alert has a prop 'style' then check if it exists
        if(this.props.style){
            // If the prop 'style' exists then add the style properties to the alert
            return this.addStylePropertiesToAlert(this.props.style);
        }else{
            // If the prop 'style' does not exist then use the default style
            return this.alertStyle;
        }
    }

    render() {
        return (
            <Alert 
                message={this.props.message}
                type={this.props.type ?? "info"}
                showIcon
                closable={this.props.closable ?? true}
                onClose={this.props.handleAlertClose}
                style={this.getAlertCustomStyle()}
            />
        );
    }
}

export default CommonAlert;