import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Table } from '../../../../../Table/Table';
import { logout } from '../../../../../../redux/action';
import CommonLoader from '../../../../../../commonComponent/loader/commonLoader';
import * as Constants from '../../../../../../Component/common/Global/constants';
import RegularDropDown from '../../../../../../Component/common/DropdownMenus/RegularDropdown';
import AssignmentListDetails from '../../../../../../Component/common/assignmentListDetails/assignmentListDetails';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../../../../Component/common/Global/commonFunctions';
import Bookstockitem from '../../../../../OngoingAssignment/Detailspage/bookstockitem';
import ActualStockModal from '../actualStockModal';
import ReconciliationDetail from '../deviations/reconciliationDetail';

const VerificationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const assignment = location?.state?.assignmentListDetails;
  const user = useSelector((state) => state?.user);
  const token = useSelector((state) => state.token);
  const { assignmentId } = useParams();
  const formatter = new Intl.NumberFormat('en-IN');
  const [verificationList, setVerificationList] = useState({});
  const [downloadReportData, setDownloadReportData] = useState([]);
  const [reconciliationModalShow, setReconciliationModalShow] = useState(false);
  const [reconciliationItem, setReconciliationItem] = useState([]);
  const [actualStockModalShow, setActualStockModalShow] = useState(false);
  const [actualStockDetails, setActualStockDetails] = useState();
  const [stockDetailsShow, setStockDetailsShow] = useState(false);
  const [stockDetails, setStockDetails] = useState([]);
  const [storeId, setStoreId] = useState('');
  const [stockId, setStockId] = useState('');
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [stockData, setStockData] = useState([])
  const [selectedStoreType, setSelectedStoreType] = useState("Select Store Type");
  const [isShowStoreTypeDropDownItem, showStoreTypeDropdownItem] = useState(false);
  const [selectedStockType, setSelectedStockType] = useState("Select Stock Type");
  const [isShowStockTypeDropDownItem, showStockTypeDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [verifiedDetailsLoading, setVerifiedDetailsLoading] = useState(false);
  const [fileName, setFileName] = useState("Verification Reports");
  const store = storeId;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const stockOptions = [];
  const storeOptions = stockData?.map((item, i) => { return { "value": item?.storeTypeId, "label": item?.storeTypeName } }) ?? [];
  const filteredItems = stockData?.filter((e) => e?.storeTypeId == store);
  filteredItems?.forEach((item) => {
    item?.stockType?.forEach((stockItem) => {
      stockOptions.push({ "value": stockItem?.stockTypeId, "label": stockItem?.stockTypeName });
    });
  });

  // Handle the Store Stock filter options based on storeStockTypeData
  useEffect(() => {
    if (assignment?.storeStockTypeData != undefined) {
      const storeStockData = assignment?.storeStockTypeData;
      setStockData(storeStockData)
    }
  }, [assignment])

  useEffect(() => {
    getCompletedVerification()
  }, [])

  // Update the downloadReportData state with the mapped verification list data for report
  useEffect(() => {
    setDownloadReportData(verificationList?.records?.map((item, index) => {
      return {
        key: index,
        No: index + 1,
        PartNo: item?.partNo1 ?? "",
        SubPartNo1: item?.partNo2 ?? "",
        SubPartNo2: item?.partNo3 ?? "",
        SubPartNo3: item?.partNo4 ?? "",
        SubPartNo4: item?.partNo5 ?? "",
        StockType: item?.stockTypeName ?? "",
        Materialname: item?.materialName ?? "",
        MaterialSubname1: item?.materialName1 ?? "",
        MaterialSubname2: item?.materialName2 ?? "",
        MaterialSubname3: item?.materialName3 ?? "",
        MaterialSubname4: item?.materialName4 ?? "",
        Location: item?.location1 ?? "",
        Location1: item?.Location2 ?? "",
        Location2: item?.location3 ?? "",
        Location3: item?.location4 ?? "",
        Location4: item?.location5 ?? "",
        UOM: item?.uom ?? "",
        Rate: item?.rate ?? "-",
        Value: item?.value ?? "",
        BookQuantity: item?.quantity ?? "-",
        ActualStockquantity: item?.actualStockQuantity ?? "",
        difference: item?.difference ?? "",
        ExpiryDate: item?.expiryDate ?? "",
        LastReceiptDate: item?.lastReceiptDate ?? "",
        LastIssueDate: item?.lastIssueDate ?? "",
        ReconcilationObservations: verificationList?.reconcilationData.filter(recoItem => recoItem.actualStockId == item.actualStockId) ?? [],
        Remarks: item?.remarks ?? ""
      }
    }) ?? [])
  }, [verificationList])

  // Fetch the completed verification data
  const getCompletedVerification = () => {
    setVerifiedDetailsLoading(true);
    fetch(Constants.API_URL.getCompletedVerification, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        assignmentId: parseInt(assignmentId),
        userId: user.userId,
        page: 1,
        limit: 15
      })
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setVerificationList(data?.data);
        isComponentDidMountFlag(true);
        setVerifiedDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setVerifiedDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/verification/${randomStringGenerator(30)}`);
      }else{
        setVerifiedDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  }

  // Function that fetches the bookstock list based on the provided storeId and stockId
  const getStoreStockFilterList = (storeId, stockId) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
      page: 1,
      limit: 15
    }
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    if(search) {
      body.search = encodeURIComponent(search);
    }
    if(!isEmptyVariable(sort)) {
      body.sortColumn = sort;
    }
    if(!isEmptyVariable(sortOrder)) {
      body.sortType = sortOrder;
    }
    fetch(Constants.API_URL.getCompletedVerification, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setVerificationList(data?.data);
        isComponentDidMountFlag(true);
        setVerifiedDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setVerifiedDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/verification/${randomStringGenerator(30)}`);
      }else{
        setVerifiedDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  }

  // Handles changes in the table, such as search, sort, and pagination
  const handleTableChange = (type, newState) => {
    let body = {
      assignmentId: parseInt(assignmentId),
      userId: user.userId,
    }
    // storeType and stockType to the request body based on storeId and stockId
    if (storeId != "" && stockId == "") {
      body.storeType = storeId;
    }else if (stockId != "" && storeId == "") {
      body.stockType = stockId;
    } else if (storeId != "" && stockId != "") {
      body.storeType = storeId;
      body.stockType = stockId;
    }
    // Reset the page to 1 when search or sort action is triggered
    if(type === 'search' || type === 'sort'){
        body.page = 1
    }else{
        body.page = newState.page;
    }
    // Set the limit in the request body if sizePerPage exists in the newState
    if (newState.sizePerPage) {
        body.limit = newState.sizePerPage;
    }
    // Encode and add the search text to the request body if it exists in the newState
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      body.search = encodeURIComponent(newState.searchText);
    }else{
      setSearch('');
    }
    // Conditionally set the sortColumn and sortOrder in the request body based on newState
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === 'PartNo') {
          body.sortColumn = 'partNo1';
      }
      if (newState.sortField === 'Materialname') {
          body.sortColumn = 'materialName';
      }
      if (newState.sortField === 'uom') {
        body.sortColumn = 'uom';
      }
      if (newState.sortField === 'rate') {
        body.sortColumn = 'rate';
      }
      if (newState.sortField === 'value') {
        body.sortColumn = 'value';
      }
      if (newState.sortField === 'bookstockquantity') {
        body.sortColumn = 'quantity';
      }
      if(newState.sortField === 'actualquantity'){
        body.sortColumn = 'actualStockQuantity';
      }
      if (newState?.sortOrder) {
        setSortOrder(newState?.sortOrder);
        body.sortType = newState.sortOrder;
      }
    }else{
      setSort('');
      setSortOrder('');
    }
  
    fetch(Constants.API_URL.getCompletedVerification, {
      method:"POST",
      mode:"cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(body)
    })
    .then(response => { return response.json(); } )
    .then(data => {
      if(data.status === Constants.status.codeAccessTokenUnauthorized){
        localStorage.clear();
        navigate("/Login");
        dispatch(logout());
      }else if(data.status === Constants.status.success){
        setVerificationList(data?.data);
        isComponentDidMountFlag(true);
        setVerifiedDetailsLoading(false);
      }else if(data?.errorStatus === Constants.status.codeNotAccess){
        setVerifiedDetailsLoading(false);
        navigate(`/Client/completedassignment/${assignmentId}/verification/${randomStringGenerator(30)}`);
      }else{
        setVerifiedDetailsLoading(false);
        toast.error(`${data?.message}`)
      }
    });
  };

  // Function to export data to a excel file
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(downloadReportData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx', type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // Handles changes to the store dropdown menu
  const handleStoreChange = (selected) => {
    setStockId('');
    setSelectedStockType('Select Stock Type')
    if (selected.value !== '') {
      setStoreId(selected.value);
      getStoreStockFilterList(selected.value, "")
      setSelectedStoreType(selected.label)
    }
    showStoreTypeDropdownItem(false);
  };

  // Handles changes to the stock dropdown menu
  const handleStockChange = (e) => {
    if (e.value !== '') {
      setStockId(e.value);
      getStoreStockFilterList(storeId, e.value)
      setSelectedStockType(e.label)
    }
    showStockTypeDropdownItem(false);
  };

  // Store the list item data based on row click for reconciliation details 
  const handleReconcilationItem = (e, item) => {
    setReconciliationItem(item);
    setReconciliationModalShow(true);
  }

  // Handling the closing event of the Reconciliation modal
  const handleReconciliationModalClose = (reload) => {
    setReconciliationModalShow(false);
    if(reload){
      getCompletedVerification();
    }
  }

  // Handling the closing event of the actual stock modal
  const handleActualStockModalClose = (reload) => {
    setActualStockModalShow(false);
    if(reload){
      getCompletedVerification();
    }
  }

  // Function to clear all filters and set default values
  const clearFilter = () => {
    isComponentDidMountFlag(false);
    setStockId('');
    setSelectedStockType('Select Stock Type');
    setStoreId('');
    setSelectedStoreType('Select Store Type');
    setSearch('');
    setSort('');
    setSortOrder('');
    setVerificationList({});
    getCompletedVerification();
  }
  
  const verificationColumn = [
    {
      dataField: "index",
      text: "No",
      headerClasses: "sr_no",
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "partNo1",
      text: "Part No.",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "materialName",
      text: "Name",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "uom",
      text: "UOM",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "bookstockquantity",
      text: "Book Qty",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      },
    },
    {
      dataField: "actualquantity",
      text: "Actual Qty",
      sort: true,
      style: { cursor: "pointer" },
      formatter: (cell, item) => {
        return (
          item?.actualStockQuantity != "multiple" ? <div className='d-flex align-items-center'>
          <a
            onClick={() => {
              setActualStockModalShow(true);
              setActualStockDetails(item);
            }}
          >
            <i className="fa fa-eye me-2" aria-hidden="true"></i>
          </a>
            <span className=''>{item?.actualStockQuantity}</span>
        </div> : "multiple"
        )
      },
    },
    {
      dataField: "reconciliation",
      text: "Reco.",
      sort: true,
      style: { cursor: "pointer" },
      formatter: (cell, item) => {
        return (
          item?.actualStockQuantity != "multiple" ? 
          <div className='d-flex align-items-center'>
            {!isEmptyVariable(item?.totalRecoQty) &&
              <a onClick={(e) => handleReconcilationItem(e, item)}>
                <i className="fa fa-list me-2" aria-hidden="true"></i>
              </a>
            }
            <span className=''>{isEmptyVariable(item?.totalRecoQty) ? "-" : item?.totalRecoQty}</span>
          </div>:"multiple"
        )
      },
    },
    {
      dataField: "value",
      text: "Value",
      sort: true,
      style: { cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setStockDetails(row); setStockDetailsShow(true)
        },
      }
    },
    {
      dataField: "difference",
      text: "Difference",
      sort: true,
      style: "difference" !== null ? { color: 'red', cursor: "pointer" } : { color: 'black', cursor: "pointer" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          setReconciliationItem(row); setReconciliationModalShow(true)
        },
      }
    }
  ]

  return (
    <>
      {assignment && 
        <AssignmentListDetails
            assignment={assignment}
        />
      }
      {verifiedDetailsLoading &&
        <CommonLoader loading={verifiedDetailsLoading} />
      }
      <div className="main_tabs">
        <div className="master_box">
          <div className="clients_menu my-3">
            <a className='btn btn-primary me-2' 
              onClick={() => {
                navigate(`/Client/completedassignment/${assignmentId}`, {
                  state: {
                    assignmentListDetails: assignment
                  }
                })
              }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
            <a className='btn btn-primary' href="verification"> Verified </a>
          </div>
          <div className="master_boxinfo">
            <div className="table-responsive pt-2 pb-5 table-height">
              {componentDidMountFlag && 
              <>
                <div className="add_btns bookstock_dropdown_width">
                  {(search != ""  || sort != "" || storeId != "" || stockId != "") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                  <RegularDropDown 
                    placeholder={selectedStoreType}
                    dropdownArr={storeOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStoreChange}
                    isFocusRequired={true}
                    show={isShowStoreTypeDropDownItem}
                    onToggle={(isOpen) => showStoreTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Store Type"}
                  />
                  <RegularDropDown 
                    placeholder={selectedStockType}
                    dropdownArr={stockOptions}
                    labelParam="label"
                    onDropDownItemClick={handleStockChange}
                    isFocusRequired={true}
                    show={isShowStockTypeDropDownItem}
                    onToggle={(isOpen) => showStockTypeDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={"Select Stock Type"}
                  />
                  <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={(e) => exportToCSV()}
                  >
                    <i
                      className="fa fa-download"
                      aria-hidden="true"
                    ></i>{" "}
                    Download
                  </button>
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
              }
              {componentDidMountFlag && (
                <Table
                  data={verificationList?.records?.map((item, index) => {
                    return {
                      ...item,
                      index: (verificationList?.page - 1) * verificationList.limit + index + 1,
                      id: item.id,
                      partNo1: item?.partNo1 ?? "-",
                      materialName: item?.materialName ?? "-",
                      uom: item?.uom ?? "-",
                      rate: isEmptyVariable(item?.rate) ? "-" : formatter.format(item?.rate),
                      bookstockquantity: isEmptyVariable(item?.quantity) ? "-" : item?.quantity,
                      actualquantity: isEmptyVariable(item?.actualStockQuantity) ? "-" : item?.actualStockQuantity,
                      reconciliation: isEmptyVariable(item?.totalRecoQty) ? "-" : item?.totalRecoQty,
                      status: 'completed',
                      difference: item?.difference == null ? "multiple" : item?.difference,
                      value: isEmptyVariable(item?.value) ? "-" : formatter.format(item?.value),
                      key: index,
                    };
                  }) ?? []}
                  columns={verificationColumn}
                  handleTableChange={handleTableChange}
                  totalSize={verificationList?.totalRecords ?? 0}
                  currentPage={verificationList?.page ?? 1}
                  sizePerPage={verificationList?.limit ?? 15}
                />
              )}
              {componentDidMountFlag && isEmptyArray(verificationList?.records) && 
                <div className="no-items-layout">
                  <div className="no-items-card">
                    <h6>
                      {
                        Constants.message.noRecordsWarning
                      }
                    </h6>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      {actualStockModalShow &&
        <ActualStockModal
          handleActualStockModalClose={handleActualStockModalClose}
          showActualStockModal={actualStockModalShow}
          bookStockDetails={actualStockDetails}
          clientName={assignment?.clientName}
        />
      }
      {reconciliationModalShow && 
        <ReconciliationDetail
          assignment={assignment}
          reconcilation={reconciliationModalShow}
          handleOnClose={handleReconciliationModalClose}
          reconcilationitem={reconciliationItem}
          assignmentId={assignmentId}
        />
      }
      {stockDetailsShow &&
          <Bookstockitem
            bookStockDetailsShow={stockDetailsShow}
            setBookStockDetailsShow={setStockDetailsShow}
            details={stockDetails}
            actualquantity={stockDetails.actualquantity}
          />
      }
    </>
  )
}

export default VerificationDetails;