import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  APIRequest,
  LIST_CLIENT,
  CREATE_CLIENT,
  LIST_CITY,
  LIST_UNIT,
  DELETE_CLIENT,
  CLIENT_EXIST_OR_NOT
} from "../../../../api";
import CreateClient from "./createClient";
import EditClient from "./editClient";
import { toast } from "react-toastify";
import { Table } from "../../../Table/Table";
import { logout } from "../../../../redux/action";
import Clientdetails from "./clientdetails";
import ReusableModal from "../../../Modal/reusablemodel";
import RegularDropDown from "../../../../Component/common/DropdownMenus/RegularDropdown";
import * as Constants from "../../../../Component/common/Global/constants";
import { isEmptyArray, isEmptyVariable } from "../../../../Component/common/Global/commonFunctions";
import CommonLoader from "../../../../commonComponent/loader/commonLoader";

const Index = () => { 
  const dispatch = useDispatch();
  const [clientsEdit, setclientEditShow] = useState(false);
  const [clientsAdd, setclientAddShow] = useState(false);
  const [clientsData, setclientsData] = useState({});
  const [city, setCity] = useState([]);
  const [unit, setUnit] = useState([]);
  const [editData, seteditData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientdetails, Setclientdetails] = useState();
  const [clientshow, Setclientshow] = useState(false);
  const [clientactive, Setclientactive] = useState(true);
  const user = useSelector((state) => state.user);
  const [confrimDialog, setConfrimDialog] = useState(false);
  const [Id, setId] = useState();
  const [selectedOption, setSelectedOption] = useState(Constants.placeholder.activeInactiveDefaultPlaceholder);
  const [isShowDropDownItem, showDropdownItem] = useState(false);
  const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [deleteClientLoading, setDeleteClientLoading] = useState(false);
  let navigate = useNavigate();

  const loadMasterList = (searchText) => {
    if(searchText.length >= 3){
      new APIRequest.Builder()
        .post()
        .setReqId(LIST_CITY)
        .reqURL("city/getCityList")
        .jsonParams({ search: searchText})
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    }
  }

  const fetchCityMasterData = () => {

    new APIRequest.Builder()
      .get()
      .setReqId(LIST_UNIT)
      .reqURL("unitType/getUnitTypeList")
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const showEditModel = (e) => {
    setIsEdit(true);
    seteditData(e);
    setclientEditShow(true); 
  };

  useEffect(() => {
    setclientsData({});
    fetchCityMasterData();
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClient = () => {
    setClientLoading(true);
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_CLIENT)
      .reqURL(`client/getClientList/${user.organisationId}?page=1&limit=15`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const getClientActiveInactiveList = (clientactive = true) => {
    let url = clientactive ? `client/getClientList/${user.organisationId}?page=1&limit=15` : `client/getClientList/${user.organisationId}?isActive=false&page=1&limit=15`;
    if (search) {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if(!isEmptyVariable(sort)){
      url = url + `&sortColumn=${sort}`
    }
    if(!isEmptyVariable(sortOrder)) {
      url = url + `&sortType=${sortOrder}`
    }
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_CLIENT)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()
  }

  const changeStatus = (id) => {
    new APIRequest.Builder()
      .post()
      .setReqId(CLIENT_EXIST_OR_NOT)
      .jsonParams({ "clientId": id })
      .reqURL(`/client/isClientExistingAssignments`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const handleDeactivateClient = () =>{
    setDeleteClientLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(DELETE_CLIENT)
      .jsonParams({
        "isActive": "false",
        "organisationId": user.organisationId,
        "userId": user.userId
      })
      .reqURL(`client/changeClientIsActiveStatus/${Id}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const handleReactiveteClient = () =>{
    setDeleteClientLoading(true);
    new APIRequest.Builder()
      .post()
      .setReqId(DELETE_CLIENT)
      .jsonParams({
        "isActive": "true",
        "organisationId": user.organisationId,
        "userId": user.userId
      })
      .reqURL(`client/changeClientIsActiveStatus/${Id}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case LIST_CLIENT:
        setclientsData(response.data?.data);
        isComponentDidMountFlag(true);
        setClientLoading(false);
        break;
      case CREATE_CLIENT:
        toast.success(response.data?.message);
        setclientAddShow(false);
        fetchClient();
        break;
      case CLIENT_EXIST_OR_NOT:
        setConfrimDialog(true);
        setId(response.data?.clientId);
        break;
      case LIST_CITY:
        setCity(response?.data.data.records);
        break;
      case LIST_UNIT:
        setUnit(response?.data.data.records);
        break;
      case DELETE_CLIENT:
        toast.success(response.data?.message);
        setConfrimDialog(false);
        Setclientactive(true);
        fetchClient(true);
        setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
        setDeleteClientLoading(false);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case LIST_CLIENT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          setClientLoading(false);
          toast.error(`${response?.data?.message}`)
        }
        break;
      case CLIENT_EXIST_OR_NOT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      case DELETE_CLIENT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
          setDeleteClientLoading(false);
        }
        break;
      case LIST_CITY:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      case LIST_UNIT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
      default:
        break;
    }
  };
  
  const handleTableChange = (type, newState, pageInfo) => {
    let url =  clientactive == true ? `client/getClientList/${user.organisationId}?isActive=true&page=${type === "search" || type === "sort" ? newState.page : newState.page
      }` : `client/getClientList/${user.organisationId}?isActive=false&page=${type === "search" || type === "sort" ? newState.page : newState.page
    }` ;
    if (newState.sizePerPage) {
      url = url + `&limit=${newState.sizePerPage}`;
    }
    if (!isEmptyVariable(newState?.searchText)) {
      setSearch(newState.searchText);
      url = url + `&search=${encodeURIComponent(newState.searchText)}`;
    }else{
      setSearch('');
    }
    if (!isEmptyVariable(newState?.sortField)) {
      setSort(newState?.sortField);
      if (newState.sortField === "company_name") {
        url = url + `&sortColumn=clientName`
      }
      if (newState.sortField === "city") {
        url = url + `&sortColumn=city`
      }
      if (newState.sortField === "state") {
        url = url + `&sortColumn=state`
      }

      if (newState.sortOrder) {
        setSortOrder(newState?.sortOrder);
        url = url + `&sortType=${newState.sortOrder}`
      }
    }else{
      setSort('');
      setSortOrder('');
    }

    setIsLoading(true);
    new APIRequest.Builder()
      .get()
      .setReqId(LIST_CLIENT)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  };

  const handleClientActive = (record) => {
    const response = window.confirm("Are you sure, you want to Active this Client?");
    if (response) {
      const onResponse = (response, reqId) => {
        toast.success(`${response.data.message}`);
        fetchClient();
      }
      new APIRequest.Builder()
      .post()
      .setReqId(DELETE_CLIENT)
      .jsonParams({
        "isActive": "true",
        "organisationId": user.organisationId,
        "userId": user.userId
      })
      .reqURL(`client/changeClientIsActiveStatus/${record.clientId}`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
    }
  }

  const handleActiveInactiveChange = (selected) => {
    selected.status === "Inactive" ? Setclientactive(false) : Setclientactive(true);
    getClientActiveInactiveList(selected.status === "Inactive" ? false : true)
    setSelectedOption(selected.status)
    showDropdownItem(false);
  }

  const handleOnCreateClient = (reload) => {
    setclientAddShow(false);
    if(reload){
      fetchClient();
    }
  }

  const handleOnEditClient = (reload) => {
    setclientEditShow(false);
    if(reload){
      fetchClient();
    }
  }

  const clearFilter = () => {
    Setclientactive(true);
    setSelectedOption(Constants.placeholder.activeInactiveDefaultPlaceholder);
    setSearch('');
    setSort('');
    setSortOrder('');
    isComponentDidMountFlag(false);
    fetchClient();
  }

  return (
    <>
      <div className="master_box">
        <div className="master_boxinfo">
          <div className="clients_menu">
            <a className='btn btn-primary' href="/Client/client">Clients</a>
          </div>
          {clientLoading &&
            <CommonLoader loading={clientLoading} />
          }
          <div className="table-responsive pt-2 pb-5 mt-2">
            {componentDidMountFlag &&
              <>
                <div className='deactivelist'>
                  <RegularDropDown 
                    placeholder={selectedOption}
                    dropdownArr={Constants.activeInactiveStatus}
                    labelParam="status"
                    onDropDownItemClick={handleActiveInactiveChange}
                    isFocusRequired={true}
                    show={isShowDropDownItem}
                    onToggle={(isOpen) => showDropdownItem(isOpen)}
                    defaultPlaceholderDropDown={Constants.placeholder.activeInactiveDefaultPlaceholder}
                  />
                </div>
                <div className="add_btns">
                  {!isEmptyArray(clientsData?.records) && 
                  (search != "" || sort != "" || selectedOption == "Inactive") && 
                    <button
                      type="button"
                      className="btn btn-primary"
                      variant="primary"
                      onClick={() => clearFilter()}
                    >
                      <i
                        className="fa fa-filter"
                        aria-hidden="true"
                      ></i>{" "}
                      Clear
                    </button>
                  }
                {clientactive && <button
                    type="button"
                    className="btn btn-primary"
                    variant="primary"
                    onClick={setclientAddShow}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Client
                  </button>}
                </div>
                <i className="fa fa-search search_icon" aria-hidden="true"></i>
              </>
            }
            {componentDidMountFlag && clientsData?.records && (
              <Table
                data={clientsData?.records?.map((item, index) => {
                  return {
                    ...item,
                    key: index,
                    index: (clientsData?.page - 1) * clientsData.limit + index + 1,
                    id: item.clientId,
                    company_name: item.clientName,
                    city: item.City?.cityName,
                    state: item.City?.State?.stateName,
                  };
                })}
                columns={[
                  {
                    dataField: "index",
                    text: "No",
                    headerClasses: "sr_no",
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setclientdetails(row); Setclientshow(true)
                      },
                    }
                  },
                  {
                    dataField: "company_name",
                    text: "Company Name",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setclientdetails(row); Setclientshow(true)
                      },
                    }
                  },
                  {
                    dataField: "city",
                    text: "City",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setclientdetails(row); Setclientshow(true)
                      },
                    }
                  },
                  {
                    dataField: "state",
                    text: "State",
                    sort: true,
                    style: { cursor: "pointer" },
                    events: {
                      onClick: (e, column, columnIndex, row, rowIndex) => {
                        Setclientdetails(row); Setclientshow(true)
                      },
                    }
                  },
                  {
                    dataField: "action",
                    text: "Action",
                    headerClasses: "action",
                    style: { cursor: "pointer" },
                    formatter: (cell, e) => {
                      return (
                        e?.isActive === "true" ?
                          <div className="edit_icons">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul
                                className="dropdown-menu p-1"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => showEditModel(e)}
                                  ><i className="fa fa-pencil me-1" aria-hidden="true"></i>
                                    Edit
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() => { navigate(`/Client/Unitmaster/${e.id}`) }}

                                  ><i className="fa fa-eye me-1" aria-hidden="true"></i>
                                    View Units
                                  </a>
                                </li>
                                <li>
                                  <a
                                    className="dropdown-item"
                                    onClick={() =>{ changeStatus(e.id);
                                      }}
                                  ><i className="fa fa-trash me-1" aria-hidden="true"></i>
                                    Delete
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div> :
                          <div className='edit_icons'>
                            <div className="dropdown">
                              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                              </button>
                              <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a className="dropdown-item" onClick={() => {                                
                                  setId(e?.id)
                                  setConfrimDialog(true);
                                }}><i className="fa fa-repeat" aria-hidden="true"></i> &nbsp;Reactivate</a></li>
                              </ul>
                            </div>
                          </div>
                      );
                    },
                  },
                ]}
                handleTableChange={handleTableChange}
                totalSize={clientsData?.total ?? 0}
                currentPage={clientsData?.page ?? 1}
                sizePerPage={clientsData?.limit ?? 15}
              />
            )}
            {componentDidMountFlag && isEmptyArray(clientsData?.records) && 
              <div className="no-items-layout">
                <div className="no-items-card">
                  <h6>
                    {
                      Constants.message.noRecordsWarning
                    }
                  </h6>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
      {clientsAdd &&
        <CreateClient
          clientsdetails={clientsAdd}
          setclientsdetailsShow={handleOnCreateClient}
          user={user}
          city={city}
          unit={unit}
          loadMasterList={loadMasterList}
        />
      }
      {clientsEdit && (
        <EditClient
          clientsdetails={clientsEdit}
          setclientsdetailsShow={handleOnEditClient}
          editData={editData}
          user={user}
          city={city}
          unit={unit}
          loadMasterList={loadMasterList}
        />
      )}
      {clientshow &&
        <Clientdetails clientshow={clientshow} Setclientshow={Setclientshow} clientdetails={clientdetails} />
      }
      {confrimDialog &&
        <ReusableModal
          show={confrimDialog}
          onClose={() => setConfrimDialog(false)}
          loading={deleteClientLoading}
          onConfirm={clientactive ? handleDeactivateClient : handleReactiveteClient}
          message={clientactive ? "Are you sure, you want to delete this client?" : "Are you sure, you want to reactivate this client?"}
        />
      }
    </>

  );
};

export default Index;
