export const BASE_URL_API = process.env.REACT_APP_API_URL;

export const API_URL = {
  // Ongoing Assignment
  assignmentRelatedMasterCounts: BASE_URL_API + "assignments/assignmentRelatedMasterCounts",
  getObservation: BASE_URL_API + "assignments/getObservatoin",
  wordDownloadReports: BASE_URL_API + "bookstock/wordDownloadReports",
  getFinalizeReviewerList: BASE_URL_API + "assignmentsObservatoinCycle/getFinalizeRevieweList",
  submitObservationReviwer: BASE_URL_API + "assignmentsObservatoinCycle/submitObservatoinReviwer",
  validateBeforeFreezeAssignment: BASE_URL_API + "bookstock/validateBeforeFreezeAssignment",
  getActualBookStockResolve: BASE_URL_API + "bookstock/getActualBookStockResolve",
  getBookstockByOne: BASE_URL_API + "bookstock/getBookstockByOne",
  deleteObservation: BASE_URL_API + "assignments/deleteObservatoin",
  freezeAssignment: BASE_URL_API + "bookstock/freezeAssignment",
  getOneObservation: BASE_URL_API + "assignments/getOneObservation/",
  submitObservationSelectedRequestChangeReviwer: BASE_URL_API + "assignmentsObservatoinCycle/submitObservatoinSelectedRequestChangeReviwer",
  getOngoingDeviation: BASE_URL_API + "getOngoingDeviation",
  getOngoingActualReconcilationData: BASE_URL_API + "getOngoingActualReconcilationData",
  assignmentGenerateNewReport: BASE_URL_API + "assignmentGenerateNewReport",
  getOngoingReportUrl: BASE_URL_API + "getOngoingReportUrl",
  getTotalBookStockCount: BASE_URL_API + "getTotalBookStockCount",

  //Completed Assignment
  getCompletedAssignments: BASE_URL_API + "getCompletedAssignments",
  completedAssignmentRelatedMasterCounts: BASE_URL_API + "completedAssignmentRelatedMasterCounts",
  getCompletedBookStock: BASE_URL_API + "getCompletedBookStock",
  getCompletedDeviation: BASE_URL_API + "getCompletedDeviation",
  getCompletedVerification: BASE_URL_API + "getCompletedVerification",
  getCompletedSamplings: BASE_URL_API + "getCompletedSamplings",
  getCompletedSamplingBookStockData: BASE_URL_API + "getCompletedSamplingBookStockData",
  getCompletedActualReconcilationData: BASE_URL_API + "getCompletedActualReconcilationData",
  getCompletedMaterialMovementAnalysis: BASE_URL_API + "getCompletedMaterialMovementAnalysis",
  getCompletedMaterialNotUsedAnalysis: BASE_URL_API + "getCompletedMaterialNotUsedAnalysis",
  getCompletedExpiryAnalysis: BASE_URL_API + "getCompletedExpiryAnalysis",
  getFinalizeReportUrl: BASE_URL_API + "getFinalizeReportUrl",
  getCompletedSingleActualData: BASE_URL_API + "getCompletedSingleActualData",
  finalizeWordDownloadReports: BASE_URL_API + "bookstock/finalizeWordDownloadReports",
  
  //Movement Analysis
  getBookStockMaterialMovementAnalysis: BASE_URL_API + "bookstock/getBookStockMaterialMovementAnalysis",
  getBookStockMaterialMovementAnalysisStoreStockList: BASE_URL_API + "bookstock/getBookStockMaterialMovementAnalysisStoreStockList",
  calculateMaterialMovementAnalysis: BASE_URL_API + "bookstock/calculateMaterialMovementAnalysis",
  clearMaterialMovementAnalysis: BASE_URL_API + "bookstock/clearMaterialMovementAnalysis",

  //Expiry Analysis
  getBookStockMaterialExpiryAnalysis: BASE_URL_API + "bookstock/getBookStockMaterialExpiryAnalysis",
  getBookStockMaterialExpiryAnalysisStoreStockList: BASE_URL_API + "bookstock/getBookStockMaterialExpiryAnalysisStoreStockList",
  calculateExpiryItemsAnalysis: BASE_URL_API + "bookstock/calculateExpiryItemsAnalysis",
  clearMaterialNearExpiryAnalysis: BASE_URL_API + "bookstock/clearMaterialNearExpiryAnalysis",

  //Not Used Analysis
  getBookStockMaterialNotUsedAnalysis: BASE_URL_API + "bookstock/getBookStockMaterialNotusedAnalysis",
  getBookStockMaterialNotUsedAnalysisStoreStockList: BASE_URL_API + "bookstock/getBookStockMaterialNotusedAnalysisStoreStockList",
  calculateMaterialNotUsedAnalysis: BASE_URL_API + "bookstock/calculateMaterialNotUsedAnalysis",
  clearMaterialNotUsedAnalysis: BASE_URL_API + "bookstock/clearMaterialNotusedAnalysis",

  //common
  generateFileUrl: BASE_URL_API + "generateFileUrl",

  //Actual Stock
  organisationCreditCheck: BASE_URL_API + "bookstock/organisationCreditCheck",
  addActualStock: BASE_URL_API + "bookstock/addActualstock",
  getActualStocksOne: BASE_URL_API + "bookstock/getActualStocksOne",
  uploadedFiles: BASE_URL_API + "bookstock/uploadedFiles/",
  deleteActualStock: BASE_URL_API + "bookstock/deleteActualStock",
}
export const placeholder = {
  activeInactiveDefaultPlaceholder: "Active",
  monthPlaceholder : "Current Month",
  countryPlaceholder : "Select Country",
  select : "Select",
  selectTitle : "Select Title",
  selectDateFormat : "Select Date Format",
  selectFormat : "Select Format",
  userTypePlaceholder : "User Type"
}
export const activeInactiveStatus = [
  {
    status: "Active"
  },
  {
    status: "Inactive"
  }
];
export const month = [
  {
    value: "Current Month"
  },
  {
    value: "Previous Month"
  },
  {
    value: "Current Quarter"
  },
  {
    value: "Previous Quarter"
  },
  {
    value:"Current Year"
  },
  {
    value: "Previous Year"
  }
];
export const rateType = [
  { 
    rateType: "Select",
    value: ""
  },
  { 
    rateType: "Per Day",
    value: "perDay"
  }
];
export const transactionType = [
  {
    transactionType: "Select",
    value: ""
  },
  {
    transactionType: "Issue Credit",
    value: "issued"
  },
  {
    transactionType: "Reduce Credit",
    value: "reduced"
  }
];
export const operation = [
  {
    operation: "Substract"
  },
  {
    operation: "Add"
  }
];
export const samplingOption = [
  {
    value: "Value",
    label: "Value"
  },
  {
    value: "Rate",
    label: "Rate"
  },
  {
    value: "Quantity",
    label: "Quantity"
  }
];
export const samplingRandomPercentageOption = [
  {
    value: "Value",
    label: "Value"
  },
  {
    value: "Quantity",
    label: "Quantity"
  }
];
export const title = [
  {
    title: "Select Title",
    value: ""
  },
  {
    title: "Mr.",
    value: "Mr."
  },
  {
    title: "Ms.",
    value: "Ms."
  }
];
export const editTitle = [
  {
    title: "Mr.",
    value: "Mr."
  },
  {
    title: "Ms.",
    value: "Ms."
  }
];
export const userSiteType = [
  {
    type: "User",
    role: "SITE_USER"
  },
  {
    type: "Admin",
    role: "SITE_ADMIN"
  }
];
export const userSubscriberType = [
  {
    type: "User",
    role: "SUBSCRIBER_USER"
  },
  {
    type: "Admin",
    role: "SUBSCRIBER_ADMIN"
  }
];
export const dateFormat = [
  {
    label: "dd/mm/yyyy",
    value: "dd/mm/yyyy"
  },
  {
    label: "mm/dd/yyyy",
    value: "mm/dd/yyyy"
  },
  {
    label: "yyyy/mm/dd",
    value: "yyyy/mm/dd"
  },
  {
    label: "yyyy/dd/mm",
    value: "yyyy/dd/mm"
  }
];
export const format = [
  {
    label: "dot",
    value: "dot"
  },
  {
    label: "comma",
    value: "comma"
  },
];
export const fileType = {
    billsAndPayments:"billsAndPayments",
    actuals:"actuals",
    logo:'logo',
    reconcilations:"reconcilations",
    annexure:'annexure',
    supportings:'supportings',
}
export const sortingType = {
  asc: 'asc',
  desc: 'desc'
}
export const pageSize = 10;
export const timeOut = {
  errorMsgTimeout: 3000
}
export const message = {
  noRecordsWarning: "No records found",
  emptySearchWarning: "There aren't any results for that query.",
  addBookStockFileInQueueSuccessMessage: "The Excel file is successfully uploaded and is being processed. Please refresh after a while."
};
export const status = {
  codeAccessTokenUnauthorized: 'failed',
  codeNotAccess: 209,
  success: 'success',
  inProcess: 'In Process',
  error: 'Error',
  failed: 'Failed',
  completed: 'Completed',
  verified: 'Verified'
}
export const badgeStatus = {
  blue: 'blue', // In process
  red: 'red', // Failed | error
  lime: 'lime', // Completed
  green: 'green', //  Verified
}