import React, { Component } from 'react';
import { Tabs } from 'antd';
import '../tabs/commonTabs.css';

class CommonTabs extends Component {
    constructor(props) {
        super(props);
        // Define the default style properties for the tabs
        this.tabsStyle = {
            
        };
    }

    componentDidMount(){
        this.centerInkBarOnActiveTab(1);
    }

    // Merges the given style properties with the existing style properties of the tabs.
    addStylePropertiesToTabs(styleProperties) {
        Object.entries(this.tabsStyle).map(([key, value]) => {
            if(!styleProperties[key]){
                styleProperties[key] = value
            }
        });
        return styleProperties;
    }

    getDropdownCustomStyle() {
        // If the tabs has a prop 'style' then check if it exists
        if(this.props.style){
            return this.addStylePropertiesToTabs(this.props.style);
        }else{
            return this.tabsStyle;
        }
    }
    // Calculates the left position of the ink bar (the line that shows the currently selected tab)
    centerInkBarOnActiveTab = (activeTabKey) => {
        const activeTab = document.querySelector(`.ant-tabs-tab:nth-child(${activeTabKey})`);
        const activeTabLeft = activeTab.offsetLeft;
        const activeTabWidth = activeTab.offsetWidth;
        const inkBar = document.querySelector('.ant-tabs-ink-bar');
        const inkBarWidth = inkBar.offsetWidth;
        const inkBarLeft = activeTabLeft + (activeTabWidth / 2) - (inkBarWidth / 2);

        // Remove existing custom style if present
        const existingStyle = document.getElementById('custom-ink-bar-style');
        if (existingStyle) {
            existingStyle.parentNode.removeChild(existingStyle);
        }
        // Create and inject custom style
        const styleElement = document.createElement('style');
        styleElement.id = 'custom-ink-bar-style';
        styleElement.innerHTML = `.ant-tabs-ink-bar { left: ${inkBarLeft}px !important; }`;
        document.head.appendChild(styleElement);
    };

    render(){
        const { items, onChange, centered, size, type, tabPosition } = this.props;
        return(
            <Tabs 
                defaultActiveKey="1"
                centered={centered ? centered : false}
                size={size ? size : 'middle'} // small | middle | large
                items={items}
                type={type} //card | editable-card 
                tabPosition={tabPosition ? tabPosition : "top"} // top | bottom | left | right
                style={this.getDropdownCustomStyle()}
                onChange={onChange}
                onTabClick={this.centerInkBarOnActiveTab}
            />
        )
    }
}

export default CommonTabs;