import React, { useEffect } from 'react';
import '../Login/loginstyle.css'
import { APIRequest, API_RESET_PASSWORD } from "../../api";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from "react-toastify";
import * as Yup from "yup";

function ResetPassword() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const onResponse = (response, reqId) => {
    switch (reqId) {
      case API_RESET_PASSWORD:
        toast.success(response.data.message)
        navigate("/");
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case API_RESET_PASSWORD:
        toast.error(response.data[0].msg);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate("/ClientDashboard");
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem('token')])

  const formik = useFormik({
    initialValues: {
      token: searchParams.get('token') ?? '',
      password: '',
      cPassword: ''
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Please enter password"),
      cPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: values => {
      if (values.password === values.cPassword) {
        new APIRequest.Builder()
          .post()
          .setReqId(API_RESET_PASSWORD)
          .jsonParams(values)
          .reqURL("auth/resetPassword")
          .response(onResponse)
          .error(onError)
          .build()
          .doRequest();
      } else {
        toast.error('Password and confirm password did not match.')
      }
    },
  });
  return (
    <>
      <div className='container'>
        <div className='login_box'>
          <div className='login_details'>
            <div className='text-center take_logo'><img src='assets/images/logoTakeStock.png' className='img-fluid mb-4' alt=''></img></div>

            <h1>Reset Your Password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">New Password*</label>
                <input type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                   />
                  {formik.touched.password && formik.errors.password ? (
                          <span className="error">{formik.errors.password}</span>
                        ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputPassword1" className="form-label">Confirm New Password*</label>
                <input type="password"
                  id="cPassword"
                  name="cPassword"
                  className="form-control"
                  onChange={formik.handleChange}
                  value={formik.values.cPassword} />
                  {formik.touched.cPassword && formik.errors.cPassword ? (
                          <span className="error">{formik.errors.cPassword}</span>
                        ) : null}
              </div>

              <div className='text-center'>
                <button type="submit" className="btn btn-primary w-auto">Set new password</button>
              </div>
              <div className="Mandotary">
                    {/* <p>* Fields Are Mandatory Reqired</p> */}
                  </div>
            </form>
          </div>
        </div>
      </div>
    </>

  );
}

export default ResetPassword;