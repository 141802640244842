import { useFormik } from 'formik';
import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { APIRequest, CREDIT_UTILIZED ,CREDIT_PURCHASE} from '../../../../api';
import { logout } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RegularDropDown from '../../../../Component/common/DropdownMenus/RegularDropdown';
import * as Constants from "../../../../Component/common/Global/constants";

const Issuecredit = ({ issuecredit, Setissuecredit, clientid ,organisationname}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();    
    const [selectedOption, setSelectedOption] = useState('');
    const [isShowDropDownItem, showDropdownItem] = useState(false);
    const [loading, setLoading] = useState(false);
    const User = useSelector((state) => state.user);
    const credit = useFormik({
        enableReinitialize: true,
        initialValues: {
            organisationId: clientid,
            transactionType: "",
            credits: "",
            adminRemarks: "",
            // transactionDocument: ""

        }, validationSchema: Yup.object().shape({
            credits: Yup.string().required("Amount Need"),
            transactionType: Yup.string().required("Select Type Credit"),
        }),
        onSubmit: values => {
            setLoading(true);
            var bodyFormData = new FormData();
            bodyFormData.append('organisationId',clientid);
            bodyFormData.append('transactionType',values.transactionType);
            bodyFormData.append('credits', values.credits);
            bodyFormData.append('adminRemarks', values.adminRemarks);
            bodyFormData.append('userId', User.userId);
            // bodyFormData.append('transactionDocument', values.transactionDocument); 
            if (values.type === "utilized") {
                new APIRequest.Builder()
                    .post()
                    .setReqId(CREDIT_UTILIZED)
                    .reqURL("billsAndPayments/utilized")
                    .jsonParams(values)
                    .response(onResponse)
                    .error(onError)
                    .build()
                    .doRequest()
            } else {
                new APIRequest.Builder()
                    .post()
                    .setReqId(CREDIT_PURCHASE)
                    .reqURL("billsAndPayments/manageIssuedAndReducedBillsAndPayments")
                    .jsonParams(bodyFormData)
                    .response(onResponse)
                    .error(onError)
                    .build()
                    .doRequest()
            }
        }
    })

    const onResponse = (response, reqId) => {
        switch (reqId) {
            case CREDIT_UTILIZED:
                toast.success(response.data.message);
                setLoading(false);
                Setissuecredit(true);
                credit.resetForm();
                setSelectedOption('')
                break;
            case CREDIT_PURCHASE:
                toast.success(response.data.message);
                setLoading(false);
                Setissuecredit(true);
                credit.resetForm();
                setSelectedOption('')
                break;
            default:
                break;
        }
    }

    const onError = (response, reqId) => {
        switch (reqId) {
            case CREDIT_UTILIZED:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                    setLoading(false);
                }
                break;
            case CREDIT_PURCHASE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                    setLoading(false);
                }
                break;
            default:
                break;
        }
    }

    const handleCreditChange = (selected) => {
        setSelectedOption(selected);
        credit.setFieldValue("transactionType",selected.value);
        showDropdownItem(false);
    }

    return (
        <>
            <Modal size="sm" show={issuecredit}>
                <form onSubmit={credit.handleSubmit} >
                    <Modal.Header>
                        <Modal.Title>Issue/Utilized Credit</Modal.Title>
                        <div 
                            onClick={() => {
                                Setissuecredit()
                                credit.resetForm()
                                setSelectedOption('')
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='col-md-12'>
                           <h6 className='text-center'>{organisationname}</h6>
                            <div className="dropdown_width">
                                <label htmlFor="exampleInput7" className="form-label">Assign To</label>
                                <RegularDropDown 
                                    placeholder={selectedOption==''?Constants.placeholder.select:selectedOption.transactionType}
                                    dropdownArr={Constants.transactionType}
                                    labelParam="transactionType"
                                    onDropDownItemClick={handleCreditChange}
                                    isFocusRequired={true}
                                    show={isShowDropDownItem}
                                    onToggle={(isOpen) => showDropdownItem(isOpen)}
                                    defaultPlaceholderDropDown={Constants.placeholder.select}
                                />
                                {credit.touched.transactionType && credit.errors.transactionType ? (
                                    <span className="error">{credit.errors.transactionType}</span>
                                ) : null}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="mobileNumber" className="form-label">
                            Credits
                            </label>
                            <input
                                className="form-control"
                                id="credits"
                                name="credits"
                                type="number"
                                onWheel={e => e.currentTarget.blur()}
                                onChange={credit.handleChange}
                                value={credit.values.credits}
                            />
                            {credit.touched.credits && credit.errors.credits ? (
                                <span className="error">{credit.errors.credits}</span>
                            ) : null}
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="mobileNumber" className="form-label">
                            Remark
                            </label>
                            <input
                                className="form-control"
                                id="adminRemarks"
                                name="adminRemarks"
                                type="text"
                                onChange={credit.handleChange}
                                value={credit.values.adminRemarks}
                            />
                            {credit.touched.adminRemarks && credit.errors.adminRemarks ? (
                                <span className="error">{credit.errors.adminRemarks}</span>
                            ) : null}
                        </div>
                        {/* <div className="col-md-12">
                            <label htmlFor="mobileNumber" className="form-label">
                            Transaction Document
                            </label>
                            <input
                                type="file"
                                className="form-control"
                                id="transactionDocument"
                                onChange={(value) => { credit.setFieldValue('transactionDocument', value.target.files[0]) }}
                            />
                            {credit.touched.transactionDocument && credit.errors.transactionDocument ? (
                                <span className="error">{credit.errors.transactionDocument}</span>
                            ) : null}
                        </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            Setissuecredit()
                            credit.resetForm();
                            setSelectedOption('')
                        }}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Save"}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default Issuecredit