import React from "react";
import "../ClientDashboard/clientdashboardstyle.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { APIRequest, EDIT_EMPLOYEE } from "../../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "../EmployeeMaster/employee.css";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';
import { useNavigate } from 'react-router-dom';

const EditEmployee = ({ updateemployees, setUpdateemployeesShow, editData, store, updateid }) => {
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const addEmployeesform = useFormik({
        enableReinitialize: true,
        initialValues: {
            employeeName: editData?.employeeName ?? "",
            email: editData?.email ?? null,
            clientUnitId: editData?.clientUnitId ?? 1,
            storeType: editData?.ClientUnitEmployeeStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } }) ?? "" ?? null,
            mobileNumber: editData?.mobileNumber ?? null,
            createdBy: user?.organisationId,
            updatedBy: user?.organisationId
        },
        validationSchema: Yup.object().shape({
            employeeName: Yup.string().required(" Enter Name").min(3, "Enter Minimum 3 Charactar")
        }),

        onSubmit: (values) => {
            let _step1Value = {
                ...values,
                storeType: values.storeType?.map((item, id) => { return item.value })
            }
            const finalValue = { ..._step1Value }

            new APIRequest.Builder()
                .post()
                .setReqId(EDIT_EMPLOYEE)
                .jsonParams(finalValue)
                .reqURL(`employee/updateEmployee/${updateid}`)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();


        },
    });
    const onResponse = (response, reqId) => {
        switch (reqId) {
            case EDIT_EMPLOYEE:
                toast.success(response.data?.message);
                setUpdateemployeesShow(false)
                addEmployeesform.resetForm();
                break;
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case EDIT_EMPLOYEE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response?.data?.message}`)
                }
                break;
            default:
                break;
        }
    };

    const storeoption = store[0]?.ClientUnitStoreMap?.map((e) => { return { 'value': e.StoreType.storeTypeId, 'label': e.StoreType.storeTypeName } })

    return (
        <>
            <Modal show={updateemployees}>
                <form onSubmit={addEmployeesform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Update Employees Details</Modal.Title>
                        <div
                            onClick={() => {
                                setUpdateemployeesShow(false);
                                addEmployeesform.resetForm();
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-1">
                        <div className="addclients_details">
                            <div className="row dropdown_box">
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="unitname"
                                            className="form-label"
                                        >
                                            Name*{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="employeeName"
                                            name="employeeName"
                                            type="text"
                                            onChange={addEmployeesform.handleChange}
                                            value={addEmployeesform.values.employeeName}
                                        />
                                        {addEmployeesform.touched.employeeName && addEmployeesform.errors.employeeName ? (
                                            <span className="error">{addEmployeesform.errors.employeeName}</span>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="employeesemail"
                                            className="form-label"
                                        >
                                            Email{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={addEmployeesform.handleChange}
                                            value={
                                                addEmployeesform.values.email
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="employeesephone"
                                            className="form-label"
                                        >
                                            Phone{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            type="number"
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={addEmployeesform.handleChange}
                                            value={
                                                addEmployeesform.values.mobileNumber
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="storetype"
                                            className="form-label"
                                        >
                                            Store Type{" "}
                                        </label>
                                        <Select
                                            placeholder="Select"
                                            className='reduceFonts'
                                            options={storeoption}
                                            defaultValue={editData?.ClientUnitEmployeeStoreMap?.map((item, id) => { return { 'value': item.StoreType?.storeTypeId, 'label': item.StoreType?.storeTypeName } })}
                                            isMulti
                                            onChange={e => { addEmployeesform.setFieldValue("storeType", e) }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Mandotary">
                            {/* <p>* Fields Are Mandatory Reqired</p> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setUpdateemployeesShow(false)}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Update
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default EditEmployee