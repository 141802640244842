import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { APIRequest, RECONCILATION_LIST, RECONCILATION_CREATE, UPLODED_DOCUMENTS_DATA, ACTUALSTOCK_FILE_DELETE, UPLOAD_FILES_DATA, DELETE_COMMENT, GENERATE_FILE_URL, DELETE_RECO, DELETE_RECONCILATION } from '../../api';
import './assignmentstyle.css';
import Select from 'react-select';
import "./reconcilation.css";
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import Spinner from "react-bootstrap/Spinner";
import ReusableModal from '../Modal/reusablemodel';
import Bookstockitem from './Detailspage/bookstockitem';
import { isEmptyVariable } from '../../Component/common/Global/commonFunctions';

const Reconcilation = ({ reconcilation, handleOnClose, reconcilationitem, assignment }) => {
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const { assignmentId } = useParams();
    const user = useSelector((state) => state?.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [fileId, setFileId] = useState();
    const [confrimDialog, setConfrimDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [descriptionArray, setDescriptionArray] = useState([]);
    const [editDescriptionArray, setEditDescriptionArray] = useState([]);
    const [addDescriptionArray, setAddDescriptionArray] = useState([]);
    const [selectOption, setSelectOption] = useState([]);
    const [plusValue, setPlusValue] = useState(0);
    const [selectedData, setSelectedData] = useState([]);
    const [addValueData, setAddValueData] = useState([]);
    const [addCommetOption, setAddCommetOption] = useState([]);
    const [UplodedData, setUplodedData] = useState([]);
    const [detailsShow, setDetailsShow] = useState(false);
    const [isDeleteComment, setDeleteComment] = useState(false);
    const [reconcilationData, setReconcilationData] = useState({})
    const [deleteRecoConfirm, setDeleteRecoConfirmDialog] = useState(false);
    const [deleteRecociliationId, setDeleteReconcilationId] = useState([]);
    const [deleteRecoLoading, setDeleteRecoLoading] = useState(false);
    const [deleteFileLoading, setDeleteFileLoading] = useState(false);
    useEffect(() => {
        if (reconcilationitem?.ReconcilationObservations?.length == 0) {
            setFieldsDisabled(false);
        } else {
            setFieldsDisabled(true);
        }
        setReconcilationData(reconcilationitem)
        setValidation({
            commentQuntityMessage: "",
            commentMessage: "",
            quntityMessage: ""
        })
    }, [reconcilation]);
    const [del_addcommet, Setdel_addcommet] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationCommentId: "",
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "substract",
        quantity: 0
    }]);
    const [addcommet, Setaddcommet] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationCommentId: "",
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "add",
        quantity: 0,
    }])

    const [othercomment, Setothercomment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "add",
        quantity: 0
    }]);

    const [del_othercomment, Setdel_othercomment] = useState([{
        actualStockId: reconcilationitem?.actualStockId,
        bookStockId: reconcilationitem?.bookStockId,
        reconcilationObservationsId: "",
        reconcilationCommentOther: "",
        operator: "substract",
        quantity: 0
    }]);

    // for Minus Reconcilation

    const [del_selecteddata, Setdel_selecteddata] = useState([]);
    const [del_addvaluedata, Setdel_addvaluedata] = useState([]);
    const [del_selectoption, Setdel_Selectoption] = useState([]);
    const [minusValue, setMinusValue] = useState(0);
    const [del_addcommetoption, Setdel_addcommetoption] = useState([]);
    const addcommetdefaultvalue = reconcilationitem?.ReconcilationObservations?.filter((item, id) => item?.ReconcilationComments?.operation === "add")
    const del_addcommetdefaultvalue = reconcilationitem?.ReconcilationObservations?.filter((item, id) => item?.ReconcilationComments?.operation === "substract")
    const othercommentdefaultvalue = reconcilationitem?.ReconcilationObservations?.filter((item, id) => item?.operator === 'add' && item?.ReconcilationComments === null)
    const del_othercommentdefaultvalue = reconcilationitem?.ReconcilationObservations?.filter((item, id) => item?.operator === 'substract' && item?.ReconcilationComments === null)
    const [validation, setValidation] = useState({
        commentQuntityMessage: "",
        commentMessage: "",
        quntityMessage: "",
    });
    const [finaldata, setfinaldata] = useState([]);
    const [addComment, setAddcomment] = useState([]);
    const [deleteAddComment, setDeleteAddcomment] = useState([]);
    const [otherComment, setOthercomment] = useState([]);
    const [deleteOtherComment, setDeleteOthercomment] = useState([]);
    const [isValidate, setIsValidate] = useState(true);

    useEffect(() => {
        let finalvalue = [];
        finalvalue = [...addcommet, ...del_addcommet, ...othercomment, ...del_othercomment]
        Object.keys(finalvalue).forEach(key => {
            if (finalvalue[key].quantity === undefined || finalvalue[key].quantity === 0 || finalvalue[key].quantity === null || finalvalue[key].reconcilationCommentOther === undefined) {
                delete finalvalue[key];
            }
        });
        let finaldata = finalvalue.filter(function (element) {
            return element !== undefined;
        });
        setfinaldata(finaldata);
        setAddcomment(addcommet);
        setDeleteAddcomment(del_addcommet);
        setOthercomment(othercomment);
        setDeleteOthercomment(del_othercomment);
    }, [addcommet, del_addcommet, othercomment, del_othercomment, validation, isValidate])

    const validateForms = (comment) => {
        let errors = {};
        if (finaldata?.length == 0) {
            errors.commentQuntityMessage = "Please enter the reconciliation comment and its quantity";
            setIsValidate(false);
        } else {
            setIsValidate(true);
            comment.forEach((item) => {
                const { reconcilationCommentOther, quantity } = item;
                if (!reconcilationCommentOther && (quantity != 0 && quantity != undefined)) {
                    if (!errors.commentMessage) {
                        errors.commentMessage = "Please enter comment";
                    }
                    setIsValidate(false);
                } else {
                    setIsValidate(true);
                }
                if (!quantity && (reconcilationCommentOther != "" && reconcilationCommentOther != undefined)) {
                    if (!errors.quntityMessage) {
                        errors.quntityMessage = "Please enter quantity";
                    }
                    setIsValidate(false);
                } else {
                    setIsValidate(true);
                }
            });
        }
        setValidation(errors);
        return errors;
    }

    useEffect(() => {
        setEditDescriptionArray([]);
        setDescriptionArray([]);
        setAddDescriptionArray([]);
        setSelectedFiles([]);
    }, [reconcilation])

    useEffect(() => {
        for (let i = 0; i < UplodedData?.length; i++) editDescriptionArray.push({ description: "", uploadedFileId: "" });
    }, [UplodedData])

    const handleChangeInput1 = (e, index, item) => {
        const updatedArray = [...editDescriptionArray];
        updatedArray[index].description = e.target.value;
        updatedArray[index].uploadedFileId = item?.uploadedFileId;
        setEditDescriptionArray([...updatedArray]);
    }
    useEffect(() => {
        getUploadedDocuments();
        new APIRequest.Builder()
            .get()
            .setReqId(RECONCILATION_LIST)
            .reqURL("reconcilation/getReconcilationCommentsList?page=1&limit=15")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }, [reconcilationitem])

    useEffect(() => {
        if (addcommetdefaultvalue?.length > 0) {
            Setaddcommet(addcommetdefaultvalue?.map((item, index) => {
                return ({
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.ReconcilationComments?.comment,
                    operator: item?.ReconcilationComments?.operation,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    quantity: item?.quantity
                })
            })
            );

            setSelectedData(addcommetdefaultvalue?.map((item, index) => {
                return ({
                    key: index,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.ReconcilationComments?.comment,
                    reconcilationObservationsId: item?.reconcilationObservationsId,

                })
            })
            );
            setAddValueData(addcommetdefaultvalue?.map((item, index) => {
                return ({ quantity: item?.quantity })
            })
            );
        }else{
            Setaddcommet([{ reconcilationCommentId: "", operator: "add", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
            setSelectedData([])
            setAddValueData([])
        }
    }, [reconcilationData])
    const getUploadedDocuments = () => {
        reconcilationitem?.actualStockId && new APIRequest.Builder()
            .post()
            .setReqId(UPLODED_DOCUMENTS_DATA)
            .jsonParams({ actualStockId: reconcilationitem?.actualStockId, bookStockId: reconcilationitem?.bookStockId })
            .reqURL("bookstock/getReconcilationDocumentType")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }
    useEffect(() => {
        if (othercommentdefaultvalue?.length > 0) {
            Setothercomment(othercommentdefaultvalue?.map((item, index) => {
                return ({
                    // key: index,
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    quantity: item?.quantity,
                    operator: item?.operator
                })
            }))
        }else{
            Setothercomment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentOther: "",
                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                operator: "add",
                quantity: 0
            }])
        }

        if (del_othercommentdefaultvalue?.length > 0) {
            Setdel_othercomment(del_othercommentdefaultvalue?.map((item, index) => {
                return ({
                    // key: index,
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item.reconcilationObservationsId,
                    quantity: item?.quantity,
                    operator: item?.operator
                })
            }))
        }else{
            Setdel_othercomment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentOther: "",
                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                operator: "substract",
                quantity: 0
            }]);
        }
    }, [reconcilationData])


    function removeValueAfterHash(filename) {
        const cleanedFilename = filename.replace(/-_-.+?\./, '.');
        return cleanedFilename;
    }

    const deleteFiles = (uplodedfileId) => {
        setFileId(uplodedfileId);
        setConfrimDialog(true);
    }
    const deleteUplodedFiles = () => {
        setDeleteFileLoading(true);
        new APIRequest.Builder()
            .delete()
            .setReqId(ACTUALSTOCK_FILE_DELETE)
            .reqURL(`bookstock/uploadedFiles/${fileId}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const deleteReconcilation = () => {
        setDeleteRecoLoading(true);
        new APIRequest.Builder()
            .post()
            .setReqId(DELETE_RECONCILATION)
            .jsonParams({
                userId: user.userId,
                assignmentId: parseInt(assignmentId),
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
            })
            .reqURL("bookstock/deleteReconcilation")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    const deleteFilesTamp = (i) => {
        const row = [...selectedFiles];
        row.splice(i, 1);
        const darray = [...addDescriptionArray];
        darray.splice(i, 1);
        setAddDescriptionArray(darray);
        setSelectedFiles(row);
    }
    useEffect(() => {
        setSelectedFiles(selectedFiles)
    }, [selectedFiles]);

    const handleFileChange = (event) => {
        for (let i = 0; i < event.target.files.length; i++) addDescriptionArray.push({ description: "", uploadedFileId: "" });
        setSelectedFiles([...selectedFiles, ...event.target.files]);
    }

    const handleChangeInput = (e, index) => {
        const updatedArray = [...addDescriptionArray];
        updatedArray[index].description = e.target.value;
        descriptionArray[index].uploadedFileId = null;
        setAddDescriptionArray([...updatedArray]);
    }

    const handleChangevalue = (idd, e) => {
        let nextaddcomment = [
            ...addValueData.slice(0, idd),
            { quantity: e.target.value },
            ...addValueData.slice(idd + 1)
        ];

        setAddValueData(nextaddcomment);
    };

    function handleChangeoption(idd, e, item) {
        // let Newaerry = [...del_othercomment];
        // Newaerry[id].reconcilationCommentOther = e.target.value;
        // Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
        // Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
        const nextaddcomment = [
            ...selectedData.slice(0, idd),
            {
                reconcilationCommentId: e.value,
                reconcilationCommentOther: e.label,
                reconcilationObservationsId: item?.reconcilationObservationsId
            },
            ...selectedData.slice(idd + 1)
        ];
        setSelectedData(nextaddcomment);
    }

    const addOptionFunction = () => {
        if (addcommet[addcommet.length - 1].quantity !== "" && addcommet[addcommet.length - 1].quantity !== undefined && addcommet[addcommet.length - 1].quantity !== null) {
            Setaddcommet(prev => [...prev, {
                reconcilationCommentId: null,
                reconcilationCommentOther: null,
                quantity: 0
            }])
        }
    }

    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element?.reconcilationCommentId === el?.id;
            });
        });
        return res;
    }
    useEffect(() => {
        Setaddcommet(previous =>
            previous?.map((elem, index) => {
                return ({
                    ...elem,
                    actualStockId: reconcilationitem?.actualStockId,
                    operator: "add",
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: selectedData[index]?.reconcilationCommentId,
                    reconcilationCommentOther: selectedData[index]?.reconcilationCommentOther,
                    reconcilationObservationsId: selectedData[index]?.reconcilationObservationsId,
                    quantity: addValueData[index]?.quantity
                })
            })
        );

        setSelectOption(filterByReference(addCommetOption, selectedData));
    }, [selectedData, addValueData, addCommetOption])

    const deleteComment = (id) => {
        new APIRequest.Builder()
            .delete()
            .setReqId(DELETE_COMMENT)
            .reqURL(`bookstock/deleteReconcilationObservation/${id}`)
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest();
    }

    const DeleteReconcilation = (idd, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (addcommet.length > 1) {
            let nextselecteddata = [
                ...selectedData.slice(0, idd),
                ...selectedData.slice(idd + 1)
            ]
            setSelectedData(nextselecteddata);

            let nextvaluedata = [
                ...addValueData.slice(0, idd),
                ...addValueData.slice(idd + 1)
            ]
            setAddValueData(nextvaluedata);

            let nextaddcomment = [
                ...addcommet.slice(0, idd),
                ...addcommet.slice(idd + 1)
            ];
            Setaddcommet(nextaddcomment);
        } else {
            Setaddcommet([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentId: "",
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "add",
                quantity: 0,
            }]);
            setSelectedData([]);
            setAddValueData([]);
        }
    }
    const othercommentdelete = (id, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (othercomment.length > 1) {

            if (id !== 0) {
                let deletedata = [
                    ...othercomment.slice(0, id),
                    ...othercomment.slice(id + 1)
                ]
                Setothercomment(deletedata)
            }
        }
        else {
            Setothercomment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "add",
                quantity: 0
            }])

        }
    }

    const del_othercommentdelete = (id, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (del_othercomment.length > 1) {
            if (id !== 0) {
                let deletedata = [
                    ...del_othercomment.slice(0, id),
                    ...del_othercomment.slice(id + 1)
                ]
                Setdel_othercomment(deletedata)
            }
        } else {
            Setdel_othercomment([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "substract",
                quantity: 0
            }]);
        }
    }
    const Deleteminusconcilation = (idd, reId) => {
        // deleteComment(reId);
        setDeleteReconcilationId([...deleteRecociliationId, reId]);
        if (del_addcommet.length > 1) {
            let dnextselecteddata = [
                ...del_selecteddata.slice(0, idd),
                ...del_selecteddata.slice(idd + 1)
            ]
            Setdel_selecteddata(dnextselecteddata);

            let dnextvaluedata = [
                ...del_addvaluedata.slice(0, idd),
                ...del_addvaluedata.slice(idd + 1)
            ]
            Setdel_addvaluedata(dnextvaluedata);

            let dnextaddcomment = [
                ...del_addcommet.slice(0, idd),
                ...del_addcommet.slice(idd + 1)
            ];
            Setdel_addcommet(dnextaddcomment);
        }
        else {
            Setdel_addcommet([{
                actualStockId: reconcilationitem?.actualStockId,
                bookStockId: reconcilationitem?.bookStockId,
                reconcilationCommentId: "",
                reconcilationObservationsId: "",
                reconcilationCommentOther: "",
                operator: "substract",
                quantity: 0
            }])
            Setdel_selecteddata([]);
            Setdel_addvaluedata([]);
        }

    }


    useEffect(() => {
        if (del_addcommetdefaultvalue?.length > 0) {
            Setdel_addcommet(del_addcommetdefaultvalue?.map((item, index) => {
                return ({
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.ReconcilationComments?.comment,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                    operator: item.ReconcilationComments.operation,
                    quantity: item?.quantity
                })
            })
            );

            Setdel_selecteddata(del_addcommetdefaultvalue?.map((item, index) => {
                return ({
                    key: index,
                    reconcilationCommentId: item?.reconcilationCommentId,
                    reconcilationCommentOther: item?.reconcilationCommentOther,
                    reconcilationObservationsId: item?.reconcilationObservationsId,
                })
            })
            );
            Setdel_addvaluedata(del_addcommetdefaultvalue?.map((item, index) => {
                return ({
                    key: index,
                    quantity: item?.quantity
                })
            })
            );
        }else{
            Setdel_addcommet([{ reconcilationCommentId: "", operator: "substract", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
            Setdel_selecteddata([])
            Setdel_addvaluedata([])
        }
    }, [reconcilationData])


    const minusOptionFunction = () => {
        if (del_addcommet[del_addcommet.length - 1].quantity !== "" && del_addcommet[del_addcommet.length - 1].quantity !== undefined && del_addcommet[del_addcommet.length - 1].quantity !== null) {
            Setdel_addcommet(prev => [...prev, {
                reconcilationCommentId: null,
                reconcilationCommentOther: null,
                reconcilationObservationsId: null,
                quantity: 0
            }])
        }
    }
    const del_handleChangevalue = (idd, e) => {
        let nextaddcomment = [
            ...del_addvaluedata.slice(0, idd),
            { quantity: e.target.value },
            ...del_addvaluedata.slice(idd + 1)

        ];
        Setdel_addvaluedata(nextaddcomment);
    };

    function del_handleChangeoption(idd, e, item) {
        const nextaddcomment = [
            ...del_selecteddata.slice(0, idd),
            { reconcilationCommentId: e.value, reconcilationCommentOther: e.label, reconcilationObservationsId: item?.reconcilationObservationsId },
            ...del_selecteddata.slice(idd + 1)
        ];
        Setdel_selecteddata(nextaddcomment);
    }
    useEffect(() => {
        Setdel_addcommet(previous =>
            previous?.map((elem, index) => {
                return ({
                    ...elem,
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentId: del_selecteddata[index]?.reconcilationCommentId,
                    reconcilationCommentOther: del_selecteddata[index]?.reconcilationCommentOther,
                    quantity: del_addvaluedata[index]?.quantity,
                    reconcilationObservationsId: del_selecteddata[index]?.reconcilationObservationsId,
                    operator: "substract"
                })
            })
        )

        Setdel_Selectoption(filterByReference(del_addcommetoption, del_selecteddata))
    }, [del_addvaluedata, del_selecteddata, del_addcommetoption])

    const sumfunction = (value) => {
        var sum = 0;
        value?.forEach(subData => { if (subData?.quantity != undefined) { sum += +subData?.quantity } });
        return sum;
    }

    useEffect(() => {
        setPlusValue(sumfunction([...addcommet, ...othercomment]))
        setMinusValue(sumfunction([...del_addcommet, ...del_othercomment]))
    }, [addcommet, del_addcommet, , del_addvaluedata, addValueData, othercomment, del_othercomment])

    const generateFileUrl = (filename) => {
        new APIRequest.Builder()
            .post()
            .setReqId(GENERATE_FILE_URL)
            .jsonParams({
                fileName:filename,
                type:Constants.fileType.reconcilations
            })
            .reqURL("generateFileUrl")
            .response(onResponse)
            .error(onError)
            .build()
            .doRequest()
    }

    // submit function
    const submitHandler = () => {
        let finalvalue = [];
        finalvalue = [...addcommet, ...del_addcommet, ...othercomment, ...del_othercomment]
        
        Object.keys(finalvalue).forEach(key => {
            
            if (finalvalue[key].quantity === undefined || finalvalue[key].quantity === 0 || finalvalue[key].quantity === null || finalvalue[key].reconcilationCommentOther === undefined) {
                delete finalvalue[key];
            }
        });
        let finaldata = finalvalue.filter(function (element) {
            return element !== undefined;
        });
        const filteredDescriptions = editDescriptionArray.filter(item => item.description !== "" || item.uploadedFileId !== "");
        const mergedArray = [...addDescriptionArray, ...filteredDescriptions];
        var reconcilationFormData = new FormData();
        reconcilationFormData.append('assignmentId', assignmentId);
        reconcilationFormData.append(`operations`, JSON.stringify(finaldata));
        selectedFiles.forEach((file) => {
            reconcilationFormData.append('reconcilationDocumentType', file);
        });
        reconcilationFormData.append("descriptions", JSON.stringify(mergedArray));
        const errors = validateForms([...addcommet,...del_addcommet,...othercomment,...del_othercomment]);
        
        if (Object.keys(errors).length === 0) {
            if(deleteRecociliationId.length > 0){
                deleteRecociliationId.map(item => {
                    deleteComment(item);
                })
            }
            setValidation([{
                commentQuntityMessage: "",
                commentMessage: "",
                quntityMessage: "",
            }]);
            setLoading(true);
            new APIRequest.Builder()
                .post()
                .setReqId(RECONCILATION_CREATE)
                .reqURL("bookstock/addReconcilation")
                .jsonParams(reconcilationFormData)
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest()
        }else{
            setValidation(errors);
            setTimeout(() => {
                setValidation({
                    commentQuntityMessage: "",
                    commentMessage: "",
                    quntityMessage: ""
                })
            },3000)
            setLoading(false);
        }
    }

    const onResponse = (response, reqId) => {
        switch (reqId) {

            case RECONCILATION_CREATE:
                setLoading(false);
                toast.success(`${response?.data.message}`);
                handleOnClose(true);
                Setdel_addcommet([{ reconcilationCommentId: "", reconcilationObservationsId: "", reconcilationCommentOther: "", operator: "substract", quantity: 0 }]);
                Setaddcommet([{ reconcilationCommentId: "", reconcilationCommentOther: "", reconcilationObservationsId: "", operator: "add", quantity: 0 }]);
                Setdel_Selectoption([]);
                Setdel_addvaluedata([]);
                Setdel_selecteddata([]);
                setSelectOption([]);
                setAddValueData([]);
                setSelectedData([]);
                setEditDescriptionArray([]);
                Setothercomment([{
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentOther: reconcilationitem.reconcilationCommentOther,
                    reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                    operator: "add",
                    quantity: 0
                }]); Setdel_othercomment([{
                    actualStockId: reconcilationitem?.actualStockId,
                    bookStockId: reconcilationitem?.bookStockId,
                    reconcilationCommentOther: reconcilationitem.reconcilationCommentOther,
                    reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                    operator: "substract",
                    quantity: 0
                }]);
                break;
            case RECONCILATION_LIST:
                Setdel_addcommetoption(response?.data.data?.records?.filter((value) => value.operation === "substract"))
                setAddCommetOption(response?.data.data?.records?.filter((value) => value.operation === "add"));
                break;
            case DELETE_COMMENT:
                // toast.success(response?.data.message);
                setDeleteComment(true);
                setDeleteReconcilationId([]);
                break;
            case UPLODED_DOCUMENTS_DATA:
                setUplodedData(response?.data?.Files)
                break;
            case ACTUALSTOCK_FILE_DELETE:
                toast.success(response?.data?.message);
                setConfrimDialog(false);
                getUploadedDocuments();
                setDeleteFileLoading(false);
                break;
            case GENERATE_FILE_URL:
                const link = document.createElement('a');
                link.href = response.data.data;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                break;
            case DELETE_RECONCILATION:
                toast.success(`${response.data?.message}`);
                setDeleteRecoConfirmDialog(false);
                handleOnClose(true);
                setDeleteRecoLoading(false);
            default:
                break;
        }
    };

    const onError = (response, reqId) => {
        switch (reqId) {
            case ACTUALSTOCK_FILE_DELETE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data?.message}`);
                    setConfrimDialog(false);
                    setDeleteFileLoading(false);
                }
                break;
            case DELETE_COMMENT:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    toast.error(`${response.data?.message}`);
                }
                break;
            case UPLODED_DOCUMENTS_DATA:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {

                }
                break;

            case RECONCILATION_LIST:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {

                }
                break;
            case RECONCILATION_CREATE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    // toast.error(`${response?.data?.message}`)

                }
                break;
            case GENERATE_FILE_URL:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setLoading(false);
                    toast.error(`${response?.data?.message}`)
                }
                break;
            case DELETE_RECONCILATION:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    setDeleteRecoLoading(false);
                }
                break;
            // case UPLOAD_FILES:
            //     if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
            //         localStorage.clear();
            //         navigate("/Login");
            //         dispatch(logout());
            //     } else {
            //         toast.success(response?.data?.message);
            //     }
            //     break;
            default:
                break;
        }
    };
    const selectcomment = selectOption.filter(e => !selectedData.find(item => (item.reconcilationCommentId === e.reconcilationCommentsId)))
        .map((item) => ({ "label": item.comment, "value": item.reconcilationCommentsId }));
    const del_selectcomment = del_selectoption.filter(e => !del_selecteddata.find(item => (item.reconcilationCommentId === e.reconcilationCommentsId)))
        .map((item) => ({ "label": item.comment, "value": item.reconcilationCommentsId }));
    const difference = reconcilationitem?.actualStockQuantity - reconcilationitem?.BookStock?.quantity;
    const balance = difference + (isNaN(plusValue) ? 0 : plusValue) - (isNaN(minusValue) ? 0 : minusValue);

    return (
        <>
            <Modal show={reconcilation} size="md" >
                <Modal.Header>
                    <Modal.Title>Reconciliation Detail</Modal.Title>
                    <div
                        onClick={() => {
                            Setdel_addcommet([{ reconcilationCommentId: "", operator: "substract", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
                            Setaddcommet([{ reconcilationCommentId: "", operator: "add", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
                            Setdel_Selectoption([]);
                            Setdel_addvaluedata([]);
                            Setdel_selecteddata([]);
                            setSelectOption([]);
                            setAddValueData([]);
                            setSelectedData([]);
                            setReconcilationData({});
                            Setothercomment([{
                                actualStockId: reconcilationitem?.actualStockId,
                                bookStockId: reconcilationitem?.bookStockId,
                                reconcilationCommentOther: "",
                                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                operator: "add",
                                quantity: 0
                            }]); Setdel_othercomment([{
                                actualStockId: reconcilationitem?.actualStockId,
                                bookStockId: reconcilationitem?.bookStockId,
                                reconcilationCommentOther: "",
                                reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                operator: "substract",
                                quantity: 0
                            }]);
                            if(isDeleteComment){
                                handleOnClose(true);
                            }else{
                                handleOnClose();
                            }
                            setSelectedFiles([]);
                            setDescriptionArray([]);
                            setAddDescriptionArray([]);
                            setEditDescriptionArray([]);
                            setDeleteReconcilationId([]);
                        }}

                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body className='pb-0 position-relative'>
                    <div>
                        <div className='row'>
                            <div className='col md-12 text-center'>
                                <h6>Part Number : {!isEmptyVariable(reconcilationitem?.BookStock?.partNo1) ? reconcilationitem?.BookStock?.partNo1 : '-'}
                                    <i className="fa fa-info-circle" aria-hidden="true" style={{ fontSize: "20px", color: "#552B84" }} onClick={() => { setDetailsShow(true) }}></i></h6>
                                <h6>Material Name : {!isEmptyVariable(reconcilationitem?.BookStock?.materialName) ? reconcilationitem?.BookStock?.materialName : '-'}</h6>
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Actual stock</p>
                                <p>{reconcilationitem?.actualquantity}</p>
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Book Stock</p>
                                <p>{reconcilationitem?.BookStock?.quantity}</p>
                            </div>
                            <div className='modal-labels reconlable'>
                                <p>Difference</p>
                                <p>{isNaN(difference) ? 0 : difference}</p>
                            </div>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button type="button" disabled={fieldsDisabled} className="btn btn-primary mb-2" variant="primary" onClick={() => addOptionFunction()} >
                                            {fieldsDisabled ? "" : <i className="fa fa-plus-circle" aria-hidden="true" ></i>}{" "}
                                            Add
                                        </button>
                                    </div>
                                    <div className='col-md-12'>
                                        <Dropdown.Divider />
                                    </div>
                                    {addcommet?.map((item, idd) => {
                                        return (
                                            <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                                <div className='col-md-8 mb-2 reconcilation-select'>
                                                    <Select
                                                        className='reduceFonts'
                                                        id="comment"
                                                        name="comment"
                                                        options={selectcomment}
                                                        isDisabled={fieldsDisabled}
                                                        onChange={value => {
                                                            handleChangeoption(idd, value, item);
                                                        }}
                                                        value={{ "label": item?.reconcilationCommentOther ?? "Select Comment" }}/>
                                                </div>
                                                <div className='col-md-1'>
                                                    {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => DeleteReconcilation(idd, item?.reconcilationObservationsId)}></i>}
                                                </div>
                                                <div className='col-md-2'>
                                                    <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                        disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                        onChange={e => {
                                                            handleChangevalue(idd, e);
                                                        }}
                                                    />
                                                </div></> : ""}
                                            </>
                                        )
                                    }
                                    )}
                                    <label>Other</label>
                                    {othercomment?.map((item, id) => {
                                        return (
                                            <>
                                                {((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ?
                                                    <>
                                                        <div className='col-md-8 mb-2'>
                                                            <input className='form-control' name='other' id='other' type="text" value={item?.reconcilationCommentOther}
                                                                onChange={e => {
                                                                    let Newaerry = [...othercomment]; Newaerry[id].reconcilationCommentOther = e.target.value;
                                                                    Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
                                                                    Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
                                                                    Setothercomment(Newaerry);
                                                                }}
                                                                disabled={fieldsDisabled}
                                                            />
                                                        </div>
                                                        <div className='col-md-1'>
                                                            <div className='d-flex modal-delet-icon align-items-center'>
                                                                {fieldsDisabled ? "" : <i className="fa fa-plus" aria-hidden="true" type='button' onClick={() => {
                                                                    if (othercomment[othercomment.length - 1].quantity !== 0 && othercomment[othercomment.length - 1].quantity !== undefined && othercomment[othercomment.length - 1].quantity !== null) {
                                                                        Setothercomment(prev => [...prev, {
                                                                            actualStockId: reconcilationitem?.actualStockId,
                                                                            bookStockId: reconcilationitem?.bookStockId,
                                                                            reconcilationCommentOther: "",
                                                                            reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                                                            operator: "add",
                                                                            quantity: 0
                                                                        }])
                                                                    }
                                                                }}></i>
                                                                } &nbsp;
                                                                {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => othercommentdelete(id, item?.reconcilationObservationsId)}></i>}
                                                            </div>
                                                        </div>
                                                        <div className='col-md-2'>
                                                            <input className="form-control" id="name" name="name" type="number"
                                                                value={item?.quantity}
                                                                onWheel={e => e.currentTarget.blur()}
                                                                onChange={e => {
                                                                    let Newaerry = [...othercomment];
                                                                    Newaerry[id].quantity = e.target.value;
                                                                    Setothercomment(Newaerry);
                                                                }}
                                                                disabled={fieldsDisabled}
                                                            />
                                                        </div>
                                                    </> : ""}</>
                                        )
                                    })}
                                </div>
                                <div className='modal-labels reconlable'>
                                    <p>Add total</p>
                                    <p style={{ fontSize: "12px" }}>{!isNaN(plusValue) ? plusValue : 0}</p>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <button
                                            disabled={fieldsDisabled}
                                            type="button"
                                            className="btn btn-primary mb-2"
                                            variant="primary" onClick={() => minusOptionFunction()} >
                                            {fieldsDisabled ? "" : <i className="fa fa-minus-circle" aria-hidden="true" ></i>}{" "}
                                            Less
                                        </button>
                                    </div>
                                    <div className='col-md-12'>
                                        <Dropdown.Divider />
                                    </div>
                                    {del_addcommet?.map((item, id) => {
                                        return (
                                            <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                                <div className='col-md-8 mb-2 reconcilation-select'>
                                                    <Select
                                                        disabled={fieldsDisabled}
                                                        className='reduceFonts'
                                                        id="comment"
                                                        name="comment"
                                                        isDisabled={fieldsDisabled}
                                                        options={del_selectcomment}
                                                        onChange={value => {
                                                            del_handleChangeoption(id, value, item);
                                                        }}
                                                        value={{ "label": item?.reconcilationCommentOther ?? "Select Comment" }}/>
                                                </div>
                                                <div className='col-md-1'>
                                                    {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => Deleteminusconcilation(id, item?.reconcilationObservationsId)}></i>}
                                                </div>
                                                <div className='col-md-2'>
                                                    <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                        disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                        onChange={e => {
                                                            del_handleChangevalue(id, e);
                                                        }} />
                                                </div>
                                            </> : ""}</>
                                        )
                                    }
                                    )}
                                    <label>Other</label>
                                    {del_othercomment.map((item, id) => {
                                        return (
                                            <>{((item?.reconcilationCommentOther !== undefined && item?.reconcilationCommentOther !== '') || (item.quantity !== 0 && item.quantity !== undefined)) || fieldsDisabled == false ? <>
                                                <div className='col-md-8 mb-2'>
                                                    <input className='form-control' name='other' id='other ' value={item?.reconcilationCommentOther}
                                                        disabled={fieldsDisabled}
                                                        onChange={e => {
                                                            let Newaerry = [...del_othercomment];
                                                            Newaerry[id].reconcilationCommentOther = e.target.value;
                                                            Newaerry[id].actualStockId = reconcilationitem?.actualStockId;
                                                            Newaerry[id].bookStockId = reconcilationitem?.bookStockId;
                                                            Setdel_othercomment(Newaerry);
                                                        }} ></input>
                                                </div>
                                                <div className='col-md-1'>
                                                    <div className='d-flex modal-delet-icon align-items-center'>
                                                        {fieldsDisabled ? "" : <i className="fa fa-plus" aria-hidden="true" type='button' onClick={() => {
                                                            if (del_othercomment[del_othercomment.length - 1].quantity !== 0 && del_othercomment[del_othercomment.length - 1].quantity !== undefined && del_othercomment[del_othercomment.length - 1].quantity !== null)
                                                                Setdel_othercomment(prev => [...prev, {
                                                                    actualStockId: reconcilationitem?.actualStockId,
                                                                    bookStockId: reconcilationitem?.bookStockId,
                                                                    reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                                                    reconcilationCommentOther: "",
                                                                    operator: "substract",
                                                                    quantity: 0
                                                                }])
                                                        }}></i>}&nbsp;
                                                        {fieldsDisabled ? "" : <i className="fa fa-trash" aria-hidden="true" type='button' onClick={() => del_othercommentdelete(id, item.reconcilationObservationsId)}></i>}
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <input className="form-control" id="name" name="name" type="number" value={item?.quantity}
                                                        disabled={fieldsDisabled} onWheel={e => e.currentTarget.blur()}
                                                        onChange={e => {
                                                            let Newaerry = [...del_othercomment];
                                                            Newaerry[id].quantity = e.target.value;
                                                            Setdel_othercomment(Newaerry);
                                                        }}
                                                    />
                                                </div>
                                            </> : ""}</>
                                        )
                                    }
                                    )}
                                    {/* <div className='col-md-1 mt-2'>
                                        <p style={{ fontSize: "12px" }}>{!isNaN(minusValue) ? minusValue : 0}</p>
                                    </div> */}
                                </div>
                                <div className='modal-labels reconlable'>
                                    <p>Less total</p>
                                    <p style={{ fontSize: "12px" }}>{!isNaN(minusValue) ? minusValue : 0}</p>
                                </div>
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                <div className='modal-labels reconlable'>
                                    <p>Balance</p>
                                    <p>{isNaN(balance) ? 0 : balance}</p>
                                </div>
                                {/* <p className='text-center mb-0 mt-2 pb-0 ' style={{ fontSize: "13px" }}>Uploaded Files</p> */}
                                <div className='col-md-12'>
                                    <Dropdown.Divider />
                                </div>
                                {/* {UploadedData.map(e => { return (<p style={{ fontSize: "12px" }}>{e}<br /></p>) })}
                            <div>
                                <input type="file" name="annexure" className='form-control mb-2' id="annexure" onChange={FileUpload} />
                            </div> */}
                                {fieldsDisabled ? "" : <div className='actual-upload-btn text-center'>
                                    <label htmlFor="inputGroupFile01" className='text-dark'>Upload Photo/Document</label>
                                    <input
                                        type="file"
                                        className="form-control w-50 mb-2 m-auto"
                                        id="logofile"
                                        multiple
                                        onChange={handleFileChange} />
                                </div>}
                                <div>
                                    {selectedFiles?.map((file, index) => (
                                        <>
                                            <div className='row d-flex'>
                                                <div className='col-md-4'>
                                                    <p style={{ fontSize: "12px", color: "black" }}>{file.name}</p>
                                                </div>
                                                <div className='col-md-6'>
                                                    <input
                                                        type="text"
                                                        name={`descriptions.${index}.description`}
                                                        // value={stockform.values.descriptions[index]?.description ?? ""}
                                                        onChange={(e) => handleChangeInput(e, index)}
                                                        className="form-control mb-2  m-auto"
                                                    />
                                                </div>
                                                <div className='col-md-2'>
                                                    <span id="list" onClick={() => deleteFilesTamp(index)}>
                                                        <i className="fa fa-trash ms-1"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                                {UplodedData?.length > 0 && <>
                                    <div>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>File Name</th>
                                                    <th>Description</th>
                                                    {fieldsDisabled ? "" : <th className='text-center'>Action</th>}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {UplodedData.map((e, index) =>
                                                (<>
                                                    <tr key={index}>
                                                        <td>
                                                            <a href="javascript:void(0);" onClick={() => generateFileUrl(e.uploadedFilePath)} style={{ fontSize: "12px", color: "purple", textDecoration: "none", wordWrap: "break-word", textAlign: "left"}}>{removeValueAfterHash(e.uploadedFilePath)}</a>
                                                        </td>
                                                        <td className='w-50'>
                                                            <input
                                                                type="text"
                                                                name={e?.description}
                                                                defaultValue={e?.description}
                                                                onChange={(event) => handleChangeInput1(event, index, e)}
                                                                className="form-control"
                                                                disabled={fieldsDisabled}
                                                            />
                                                        </td>
                                                        <td>
                                                            {fieldsDisabled ? "" : <span onClick={() => deleteFiles(e.uploadedFileId)}>
                                                                <i className="fa fa-trash text-center ms-5"></i>
                                                            </span>}
                                                        </td>
                                                    </tr>
                                                </>)
                                                )}
                                            </tbody>
                                        </table>
                                    </div></>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    <div className="row d-flex">
                        <div className='col-12 text-center'>
                            {validation?.commentQuntityMessage && finaldata?.length == 0 ?
                                <>
                                    <p className='error'>{validation?.commentQuntityMessage}</p>
                                </>
                                : ""}
                            {validation?.commentMessage ?
                                <>
                                    <p className='error'>{validation?.commentMessage}</p>
                                </>
                                : ""}
                            {validation?.quntityMessage ?
                                <>
                                    <p className='error'>{validation?.quntityMessage}</p>
                                </>
                                : ""}
                        </div>
                        <div className='col-12 mt-2 text-center'>

                            {fieldsDisabled && 
                                <>
                                    <Button className='px-2 mx-2' 
                                        onClick={() => {
                                            setFieldsDisabled(false);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button variant="primary" 
                                        onClick={() => {
                                            setDeleteRecoConfirmDialog(true);
                                            handleOnClose();
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </>
                            }
                            {!fieldsDisabled &&
                                <Button
                                    className='mx-2'
                                    variant="secondary"
                                    onClick={() => {
                                        if(isDeleteComment){
                                            handleOnClose(true);
                                        }else{
                                            handleOnClose();
                                        }
                                        setSelectedFiles([]);
                                        setDescriptionArray([]);
                                        setAddDescriptionArray([]);
                                        setEditDescriptionArray([]);
                                        Setdel_addcommet([{ reconcilationCommentId: "", operator: "substract", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
                                        Setaddcommet([{ reconcilationCommentId: "", operator: "add", reconcilationObservationsId: "", reconcilationCommentOther: "", quantity: 0 }]);
                                        Setdel_Selectoption([]);
                                        Setdel_addvaluedata([]);
                                        Setdel_selecteddata([]);
                                        setSelectOption([]);
                                        setAddValueData([]);
                                        setSelectedData([]);
                                        setReconcilationData({});
                                        setDeleteReconcilationId([]);
                                        Setothercomment([{
                                            actualStockId: reconcilationitem?.actualStockId,
                                            bookStockId: reconcilationitem?.bookStockId,
                                            reconcilationCommentOther: "",
                                            reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                            operator: "add",
                                            quantity: 0
                                        }]); Setdel_othercomment([{
                                            actualStockId: reconcilationitem?.actualStockId,
                                            bookStockId: reconcilationitem?.bookStockId,
                                            reconcilationCommentOther: "",
                                            reconcilationObservationsId: reconcilationitem.reconcilationObservationsId,
                                            operator: "substract",
                                            quantity: 0
                                        }]);
                                    }}
                                >
                                    Cancel
                                </Button>
                            }
                            {fieldsDisabled ? "" : <Button variant="primary" onClick={() => submitHandler()}>
                                {loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : "Submit"}
                            </Button>}

                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
            <ReusableModal
                show={confrimDialog}
                onClose={() => setConfrimDialog(false)}
                loading={deleteFileLoading}
                onConfirm={deleteUplodedFiles}
                message={"Are you sure, you want to delete this file?"}
            />
            {deleteRecoConfirm &&
                <ReusableModal
                    show={deleteRecoConfirm}
                    onClose={() => setDeleteRecoConfirmDialog(false)}
                    loading={deleteRecoLoading}
                    onConfirm={deleteReconcilation}
                    message={"Are you sure, you want to delete reconciliation? Data deleted including uploaded files cannot be retrieved afterwards."}
                />
            }
            {detailsShow &&
                <Bookstockitem bookStockDetailsShow={detailsShow} setBookStockDetailsShow={setDetailsShow} details={reconcilationitem.BookStock} actualquantity={reconcilationitem.actualquantity}/>
            }
        </>
    )
}

export default Reconcilation