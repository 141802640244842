import React from 'react'
import Modal from "react-bootstrap/Modal";

const Detailsunit = ({ unitdetailsshow, Setunitdetailsshow, unitdetails }) => {
    return (
        <>
            <Modal show={unitdetailsshow}>
                <Modal.Header className='bookstockheader'>
                    <Modal.Title>Unit Details</Modal.Title>
                    <div
                        onClick={() => {
                            Setunitdetailsshow(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Unit Type</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.UnitType?.unitTypeName}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Name</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.clientUnitName}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Address 1</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.address1}</p>
                        </div>
                    </div>
                    {unitdetails?.address2 !== "" &&
                        <div className='row'>
                            <div className='col-md-4'>
                                <p className='detailslable'>Address Line 2</p>
                            </div>
                            <div className='col-md-8'>
                                <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.address2}</p>
                            </div>
                        </div>}
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>City</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.city}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>State</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.state}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Country</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.City?.State?.Country?.countryName}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Pin code</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{unitdetails?.pincode}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Store Type</p>
                        </div>
                        <div className='col-md-8'>
                            <div className='detailslable w-100 d-flex'>
                                <p>:</p>
                                <div className='text-break w-100 ps-4'>
                                    {unitdetails?.ClientUnitStoreMap?.map((item, id) => { return <span key={`demo_snap_${id}`}>{(id ? ', ' : '') + item.StoreType?.storeTypeName}</span> })}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Detailsunit