import React from "react";
import "../ClientDashboard/clientdashboardstyle.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { APIRequest, CREATE_EMPLOYEE } from "../../api";
import { toast } from "react-toastify";
import * as Yup from "yup"
import Select from 'react-select';
import "../EmployeeMaster/employee.css";
import { useDispatch,useSelector } from "react-redux";
import * as Constants from '../../Component/common/Global/constants';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/action';

const CreateEmployee = ({ addemployees, setaddemployeesShow, store }) => {

    const { employeeId } = useParams();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const addEmployeesform = useFormik({
        enableReinitialize:true,
        initialValues: {
            employeeName: "",
            email:null,
            clientUnitId: employeeId,
            storeType: "",
            mobileNumber:null,
            createdBy:user?.organisationId,
            updatedBy:user?.organisationId
        },
        validationSchema: Yup.object().shape({
            employeeName: Yup.string().required("Enter Name")
        }),

        onSubmit: (values) => {
    
            let _step1Value = {
                ...values,              
                storeType:values?.storeType ? values?.storeType?.map((item, id) => { return item?.value }):[]
            }
            const finalValue = { ..._step1Value }
            new APIRequest.Builder()
                .post()
                .setReqId(CREATE_EMPLOYEE)
                .jsonParams(finalValue)
                .reqURL("employee/addEmployee")
                .response(onResponse)
                .error(onError)
                .build()
                .doRequest();

        }

    }
    );
    const onResponse = (response, reqId) => {
        switch (reqId) {
            case CREATE_EMPLOYEE:
                toast.success(response.data?.message);
                setaddemployeesShow(false);
                addEmployeesform.resetForm()
                break;        
            default:
                break;

        }
    }
    const onError = (response, reqId) => {
        switch (reqId) {
            case CREATE_EMPLOYEE:
                if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                } else {
                    response?.data?.message?.employeeName && toast.error(`${response?.data?.message?.employeeName}`);
                    toast.error(`${response?.data?.message}`);
                }
                break;
            default:
                break;
        }
    }
    
    const storeoption = store[0]?.ClientUnitStoreMap?.map((e) => { return { 'value': e.StoreType.storeTypeId , 'label': e.StoreType.storeTypeName } })

    return (
        <>
            <Modal show={addemployees}>
                <form onSubmit={addEmployeesform.handleSubmit}>
                    <Modal.Header>
                        <Modal.Title>Add Employees Details</Modal.Title>
                        <div 
                            onClick={() => {
                                setaddemployeesShow(false); 
                                addEmployeesform.resetForm()                           
                            }}
                            data-dismiss="modal">
                            <i className="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="pb-1">
                        <div className="addclients_details">
                            <div className="row dropdown_box">
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="unitname"
                                            className="form-label"
                                        >
                                            Name*{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="employeeName"
                                            name="employeeName"
                                            type="text"
                                            onChange={addEmployeesform.handleChange}
                                            value={addEmployeesform.values.employeeName}
                                        />
                                        {addEmployeesform.touched.employeeName && addEmployeesform.errors.employeeName ? (
                                            <span className="error">{addEmployeesform.errors.employeeName}</span>
                                        ) : null}

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="employeesemail"
                                            className="form-label"
                                        >
                                            Email{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            type="email"
                                            onChange={addEmployeesform.handleChange}
                                            value={
                                                addEmployeesform.values.email
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="employeesephone"
                                            className="form-label"
                                        >
                                            Phone{" "}
                                        </label>
                                        <input
                                            className="form-control"
                                            id="mobileNumber"
                                            name="mobileNumber"
                                            type="number"
                                            onWheel={e => e.currentTarget.blur()}
                                            onChange={addEmployeesform.handleChange}
                                            value={addEmployeesform.values.mobileNumber}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <label
                                            htmlFor="storetype"
                                            className="form-label"
                                        >
                                            Store Type{" "}
                                        </label>
                                        <Select
                                            placeholder="Select"
                                            className='reduceFonts'
                                            options={storeoption}
                                            value={addEmployeesform.values.storeType}
                                            isMulti
                                            onChange={e => { addEmployeesform.setFieldValue("storeType", e) }} 
                                        />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="Mandotary">
                        {/* <p>* Fields Are Mandatory Reqired</p> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            setaddemployeesShow(false)
                            addEmployeesform.resetForm()
                        }}
                    >
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                        Add
                    </Button>
                </Modal.Footer>

            </form>

        </Modal>
        </>
    )
}

export default CreateEmployee;