import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import { APIRequest, CREATE_CITY, CREATE_COUNTRY, CREATE_STATE } from "../../../api";
import { toast } from "react-toastify";
import Select from 'react-select';
import './CityMaster.css';
import { useDispatch, useSelector } from "react-redux";
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import { useNavigate } from 'react-router-dom';

const CityMaster = ({ isCityMaster, setIsCityMaster, country, state, refreshMasterList, currentEditCityRecord }) => {

  const [countrymodal, setcountrymodalShow] = useState(false);
  const [statemodal, setstatemodalShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      country: "",
      stateId: "",
      createdBy: user?.userId,
      updatedBy: user?.userId,
      cityName: "",
      cityShortName: "",

    },
    validate: (values) => {
      let errors = {};
      if (!values.country) {
        errors.country = "Please select country";
      }
      if (!values.stateId) {
        errors.stateId = "Please select state";
      }
      if (!values.cityName) {
        errors.cityName = "Please enter city";
      }
      return errors;
    },
    onSubmit: (values) => {
      delete values.country
      new APIRequest.Builder()
        .post()
        .setReqId(CREATE_CITY)
        .jsonParams(values)
        .reqURL("city/addCity")
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    },
  });
  const countryForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryShortName: "",
      countryName: "",
      createdBy: user?.userId,
      updatedBy: user?.userId,
    },
    validate: (values) => {
      let errors = {};
      if (!values.countryName) {
        errors.countryName = "Please select country";
      }
      /* if (!values.short_name) {
        errors.short_name = "Required";
      } */
      return errors;
    },
    onSubmit: (values) => {
      new APIRequest.Builder()
        .post()
        .setReqId(CREATE_COUNTRY)
        .jsonParams(values)
        .reqURL("country/addCountry")
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();
    },
  });
  const stateForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      countryId: "",
      stateName: "",
      stateShortName: "",
      createdBy: user?.userId,
      updatedBy: user?.userId,
    },
    validate: (values) => {
      let errors = {};
      if (!values.countryId) {
        errors.countryId = "Please select country";
      }
      if (!values.stateName) {
        errors.stateName = "Please select state";
      }
      /* if (!values.short_name) {
        errors.short_name = "Required";
      } */
      return errors;
    },
    onSubmit: (values) => {
      let _step1Value = {
        ...values,
        countryId: values.countryId.value
      }
      const finalValue = { ..._step1Value}
      new APIRequest.Builder()
        .post()
        .setReqId(CREATE_STATE)
        .jsonParams(finalValue)
        .reqURL("state/addState")
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();

    },
  });

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case CREATE_CITY:
        setIsCityMaster(false);
        toast.success(response?.data?.message);
        refreshMasterList();
        formik.resetForm();
        break;
      case CREATE_COUNTRY:
        toast.success(response?.data?.message);
        setcountrymodalShow(false);
        refreshMasterList();
        countryForm.resetForm()
        break;
      case CREATE_STATE:
        toast.success(response?.data?.message);
        setstatemodalShow(false);
        stateForm.resetForm();
        refreshMasterList();
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case CREATE_COUNTRY:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`);
        }
        break;
      case CREATE_CITY:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`);
        }
        break;
      case CREATE_STATE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(response?.data?.message);
        }
        break;
      default:
        break;
    }
  };

  const stateOptions = state.filter((item) => Number(item.countryId) === Number(formik.values?.country?.value)).map((e, key) => { return { 'value': e.stateId, 'label': e.stateName } })

  const countryOption = country.map((e, key) => { return { 'value': e.countryId, 'label': e.countryName } })

  return (
    <>
      <Modal show={isCityMaster}>
        <Modal.Header>
          <Modal.Title>City Master</Modal.Title>
          <div
            onClick={() => {
              setIsCityMaster(false);
              formik.resetForm();
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body className="pb-0">
            <div className="addcitybox uploadbook_box">
              <div className="">
                <label htmlFor="exampleInputcountry" className="form-label">
                  Country*
                </label>
                <div className="d-flex addcity_btn">
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="country"
                    name="country"
                    options={countryOption}
                    onChange={value => { 
                      formik.setFieldValue('country', value) 
                      setSelectedValue(null);
                      formik.setFieldValue('stateId', "") 
                    }}
                    value={formik.values.country}
                    aria-label="country"
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    onClick={() => {
                      setcountrymodalShow(true);
                      setIsCityMaster(false);
                    }}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                    New
                  </button>
                </div>
                {
                  formik.errors.country && formik.touched.country &&
                  <span className="error">{formik.errors.country}</span>
                }
              </div>
              <div className="">
                <label htmlFor="exampleInputcountry" className="form-label">
                  State*
                </label>
                <div className="d-flex addcity_btn">
                  <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="stateId"
                    name="stateId"
                    options={stateOptions}
                    onChange={value => {
                      formik.setFieldValue('stateId', value.value)
                      setSelectedValue(value)}}
                    value={selectedValue}
                  />
                  <button
                    type="button"
                    className="btn btn-primary ms-3"
                    onClick={() => {
                      setstatemodalShow(true);
                      setIsCityMaster(false);
                    }}
                  >
                    <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                    New
                  </button>
                  </div>
                  {
                    formik.errors.stateId && formik.touched.stateId &&
                    <span className="error">{formik.errors.stateId}</span>
                  }

              </div>
              <div className="">
                <label htmlFor="city" className="form-label">
                  City*
                </label>
                <input
                  className="form-control"
                  id="cityName"
                  name="cityName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.cityName}
                ></input>
              </div>
              {
                formik.errors.cityName && formik.touched.cityName &&
                <span className="error">{formik.errors.cityName}</span>
              }
              <div>
                <label htmlFor="short_name" className="form-label">
                  Alternate Name
                </label>
                <input
                  className="form-control"
                  id=" cityShortName"
                  name="cityShortName"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.cityShortName}
                />
              </div>
              {
                formik.errors.cityShortName && formik.touched.cityShortName &&
                <span className="error">{formik.errors.cityShortName}</span>
              }
            </div>
            <div className="Mandotary">
              {/* <p>* Fields Are Mandatory Reqired</p> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => {
              formik.resetForm();
              setIsCityMaster(false);
            }}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={countrymodal}>
        <form onSubmit={countryForm.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Country</Modal.Title>
            <div
              onClick={() => {
                setcountrymodalShow(false);
                countryForm.resetForm();
                formik.resetForm();
                stateForm.resetForm();
              }}
              data-dismiss="modal">
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <label htmlFor="name" className="form-label">
                Country Name
              </label>
              <input
                className="form-control"
                id="countryname"
                name="countryName"
                type="text"
                onChange={countryForm.handleChange}
                value={countryForm.values.countryName}
              />
              {
                countryForm.errors.countryName && countryForm.touched.countryName &&
                <span className="error">{countryForm.errors.countryName}</span>
              }
              <div>
                <label htmlFor="short_name" className="form-label">
                  Alternate Name
                </label>
                <input
                  className="form-control"
                  id="countryShortName"
                  name="countryShortName"
                  type="text"
                  onChange={countryForm.handleChange}
                  value={countryForm.values.countryShortName}
                />
              </div>
              {
                countryForm.errors.countryShortName && countryForm.touched.countryShortName &&
                <span className="error">{countryForm.errors.countryShortName}</span>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setcountrymodalShow(false)
                countryForm.resetForm();
                formik.resetForm();
                stateForm.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={statemodal}>
        <form onSubmit={stateForm.handleSubmit}>
          <Modal.Header>
            <Modal.Title>State</Modal.Title>
            <div
              onClick={() => {
                setstatemodalShow(false);
                formik.resetForm();
                stateForm.resetForm();
              }}
              data-dismiss="modal">
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div className="uploadbook_box">
              <label htmlFor="exampleInputcountry" className="form-label">
                Country*
              </label>
              <div className="d-flex addcity_btn">
                <Select
                    placeholder="Select"
                    className='reduceFonts'
                    id="countryId"
                    name="countryId"
                    options={countryOption}
                    onChange={value => { stateForm.setFieldValue('countryId', value) }}
                    value={stateForm.values.countryId}
                    aria-label="countryId"
                  />
                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  onClick={() => {
                    setcountrymodalShow(true);
                    setstatemodalShow(false);
                  }}
                >
                  <i className="fa fa-plus-circle" aria-hidden="true"></i> Add
                  New
                </button>
              </div>
              {
                stateForm.errors.countryId && stateForm.touched.countryId &&
                <span className="error">{stateForm.errors.countryId}</span>
              }
            </div>
            <div className="">
              <label htmlFor="name" className="form-label">
                Name*
              </label>
              <input
                className="form-control"
                id="stateName"
                name="stateName"
                type="text"
                onChange={stateForm.handleChange}
                value={stateForm.values.stateName}
              ></input>
            </div>
            {
              stateForm.errors.stateName && stateForm.touched.stateName &&
              <span className="error">{stateForm.errors.stateName}</span>
            }
            <div>
              <label htmlFor="short_name" className="form-label">
                Alternate Name
              </label>
              <input
                className="form-control"
                id="stateShortName"
                name="stateShortName"
                type="text"
                onChange={stateForm.handleChange}
                value={stateForm.values.stateShortName}
              />
            </div>
            <div className="Mandotary">
              {/* <p>* Fields Are Mandatory Reqired</p> */}
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setstatemodalShow(false)
                formik.resetForm();
                stateForm.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default CityMaster;
