import React from 'react'
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const Assignmentdetails = ({ assign_details, Setdetailsshow, detailsshow }) => {

    return (
        <>
            <Modal show={detailsshow}>
                <Modal.Header className='bookstockheader'>
                    <Modal.Title>  Assignment Details</Modal.Title>
                    <div
                        onClick={() => {
                            Setdetailsshow(false);
                        }}
                        data-dismiss="modal">
                        <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Client Name</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.name}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Unit</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.ClientUnit?.clientUnitName}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Store</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.AssignmentStoreMapping?.length > 0 ?assign_details?.AssignmentStoreMapping?.map((item, id) => { return <span key={`demo_snap_${id}`}>{(id ? ', ' : '') + item.StoreType?.storeTypeName}</span> }):""}</p></div>
                    </div>
                    <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Start Date</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.assignmentStartDate}</p>
                        </div>
                    </div>
                    
                        <div className='row'>
                            <div className='col-md-4'>
                                <p className='detailslable'>End Date</p>
                            </div>
                            <div className='col-md-8'>
                                <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.assignmentEndDate !== null ? moment(assign_details?.assignmentEndDate).utc().format('DD-MM-YYYY') : ""}</p>
                            </div>
                        </div>
                    {assign_details?.teamMembers[0]?.User?.firstName && <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Team Member</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.teamMembers?.map((item, id) => { return <span key={`demo_snap_${id}`}>{(id ? ', ' : '') + item.User?.title + " " + item.User?.firstName}</span> })}</p>
                        </div>
                    </div>}
                    {assign_details?.TeamLead?.title && <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Team Leader</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.TeamLead?.title + " " + assign_details?.TeamLead?.firstName}</p>
                        </div>
                    </div>}
                    {assign_details?.reviewers[0]?.User?.firstName && <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Reviewer 1</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.reviewers[0]?.User?.title + " " + assign_details?.reviewers[0]?.User?.firstName}</p>
                        </div>
                    </div>}
                    {assign_details?.reviewers[1]?.User?.firstName && <div className='row'>
                        <div className='col-md-4'>
                            <p className='detailslable'>Reviewer 2</p>
                        </div>
                        <div className='col-md-8'>
                            <p className='detailslable '><span className='pe-4'>:</span>{assign_details?.reviewers[1]?.User?.title + " " + assign_details?.reviewers[1]?.User?.firstName}</p>
                        </div>
                    </div>}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Assignmentdetails