import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Constants from '../../../Component/common/Global/constants';
import { logout } from '../../../redux/action';
import ReusableModal from '../../Modal/reusablemodel';
import { isEmptyArray, isEmptyVariable, randomStringGenerator } from '../../../Component/common/Global/commonFunctions';
import AssignmentListDetails from '../../../Component/common/assignmentListDetails/assignmentListDetails';
import CommonButton from '../../../commonComponent/button/commonButton';
import CommonLoader from '../../../commonComponent/loader/commonLoader';

const Materialnotused = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const status = location?.pathname?.split('/')[2] == "ongoingassignment" ? "Started" : "completed";
    const assignment = location?.state?.assignmentListDetails;
    const user = useSelector((state) => state?.user);
    const token = useSelector((state) => state.token);
    const { assignmentId } = useParams();
    const [storeStockList, setStoreStockList] = useState([]);
    const [calculateData, setCalculateData] = useState([]);
    const [calculateDay, setCalculateDay] = useState();
    const [noOfDays1, setNoOfDays1] = useState("");
    const [noOfDays2, setNoOfDays2] = useState("");
    const [noOfDays3, setNoOfDays3] = useState("");
    const [noOfDays4, setNoOfDays4] = useState("");
    const [noOfDays5, setNoOfDays5] = useState("");
    const [storeId, setStoreId] = useState([]);
    const [materialNotUsedAnalysis, setMaterialNotUsedAnalysis] = useState([]);
    const [stockId, setStockId] = useState([]);
    let store = storeId?.map(item => { return item.value });
    const formatter = new Intl.NumberFormat('en-IN');
    const [saveDisable, isSaveDisable] = useState(false);
    const [errors, setErrors] = useState({});
    const [saveLoading, setSaveLoading] = useState(false);
    const [calculateLoading, setCalculateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [materialNotUsedId, setMaterialNotUsedId] = useState();
    const [deleteMaterialNotUsedConfirmDialog, setDeleteMaterialNotUsedConfirmDialog] = useState(false);
    const [deleteMaterialLoading, setDeleteMaterialLoading] = useState(false);
    const [isNoteVisible, setIsNoteVisible] = useState(true);
    const [componentDidMountFlag, isComponentDidMountFlag] = useState(false);

    useEffect(() => {
        if(assignment?.status == "Started"){
            getInitData();
        }else{
            getCompletedMaterialNotUsedAnalysis();
        }
    }, []);

    const getInitData = () => {
        setLoading(true);
        Promise.all([
            fetch(Constants.API_URL.getBookStockMaterialNotUsedAnalysis, {
                method:"POST",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    "assignmentId": parseInt(assignmentId),
                    "createdBy": user.userId,
                    "userId": user.userId,
                    "status": status
                })
            }),
            fetch(Constants.API_URL.getBookStockMaterialNotUsedAnalysisStoreStockList, {
                method:"POST",
                mode:"cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    "assignmentId": parseInt(assignmentId),
                    "createdBy": user.userId
                })
            }),
        ])
        .then(([materialNotUsedAnalysisRes,storeStockListRes]) => {
            return Promise.all([materialNotUsedAnalysisRes.json(), storeStockListRes.json()]) 
        })
        .then(([materialNotUsedAnalysisRes,storeStockListRes]) => {
            if(materialNotUsedAnalysisRes?.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(materialNotUsedAnalysisRes.status === Constants.status.success){
                setMaterialNotUsedAnalysis(materialNotUsedAnalysisRes.data);
            }else if(materialNotUsedAnalysisRes?.errorStatus === Constants.status.codeNotAccess){
                if(location?.pathname?.split('/')[2] == "ongoingassignment"){
                    navigate(`/Client/ongoingassignment/${assignmentId}/materailnotused/${randomStringGenerator(30)}`);
                }else{
                    navigate(`/Client/completedassignment/${assignmentId}/materailnotused/${randomStringGenerator(30)}`);
                }
            }else{
                setMaterialNotUsedAnalysis([]);
            }
            if(storeStockListRes.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                window.location="/";
            }else if(storeStockListRes.status === Constants.status.success){
                setStoreStockList(storeStockListRes.data);
            }else{
                setStoreStockList([]);
            }
            isComponentDidMountFlag(true);
            setLoading(false);
        });
    }

    const getCompletedMaterialNotUsedAnalysis = () => {
        fetch(Constants.API_URL.getCompletedMaterialNotUsedAnalysis, {
            method:"POST",
            mode:"cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                assignmentId: parseInt(assignmentId),
                userId: user.userId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                setMaterialNotUsedAnalysis(data.data);
                isComponentDidMountFlag(true);
                setLoading(false);
            }else{
                setMaterialNotUsedAnalysis([]);
                toast.error(`${data?.message}`)
            }
        });
    }

    // Function to toggle the visibility of the note
    const toggleNoteVisible = () => {
        setIsNoteVisible(!isNoteVisible);
    };

    // Destructuring and processing the state variables into an array 'days'
    let days = [noOfDays1, noOfDays2, noOfDays3, noOfDays4, noOfDays5]

    // Mapping the 'days' array and converting each element to integer type using the 'parseInt' function
    let noOfDays = days.map(day => parseInt(day, 10)).filter(function (element) { return !isNaN(element) && element !== '' });
    
    // Function to calculate material not used analysis
    const calculateMaterialNotUsedAnalysis = (isSave) => {
        let isError = false;
        let errorMessageTemp = {};
        // Check if the storeId field is empty
        if (isEmptyArray(storeId)) {
            isError = true;
            errorMessageTemp['storeId'] = "Store is required";
        }
        if (isEmptyVariable(noOfDays1)) {
            isError = true;
            errorMessageTemp['noOfDays'] = "Number of days is required";
        }
        // If there's an error, show it and stop the process
        if (isError) {
            setSaveLoading(false);
            setErrors(errorMessageTemp);
            setTimeout(() => {
                setErrors("");
            },Constants.timeOut.errorMsgTimeout);
        } else {
            let postParam = {
                "days": noOfDays,
                "storeTypeList": storeId?.map(item => { return item.value }),
                "stockTypeList": stockId?.map(item => { return item.value }),
                "assignmentId": parseInt(assignmentId),
                "createdBy": user.userId,
            }
            if(isSave){
                setSaveLoading(true);
                postParam.save =  isSave;
            }else{
                setCalculateLoading(true);
            }
            fetch(Constants.API_URL.calculateMaterialNotUsedAnalysis, {
                method: "POST",
                mode:'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.status === Constants.status.codeAccessTokenUnauthorized){
                    localStorage.clear();
                    navigate("/Login");
                    dispatch(logout());
                }else if(data.status === Constants.status.success){
                    if(isSave) {
                        setSaveLoading(false);
                        toast.success(data.message);
                        clearData();
                        setCalculateDay();
                        getInitData();
                    }else{
                        setCalculateLoading(false);
                        setCalculateData(Object.values(data.data))
                        setCalculateDay(Object.keys(data.data))
                        if (Object.keys(data.data).length === 0) {
                            toast.success("No Data Found")
                        }
                        isSaveDisable(true);
                    }
                }else{
                    setSaveLoading(false);
                    setCalculateLoading(false);
                    toast.error(`${data?.message}`)
                }
            });
        }
    }

    // Function to clear data
    const clearData = () => {
        isSaveDisable(false);
        setCalculateData([]);
        setStoreId([]);
        setStockId([]);
        setNoOfDays1("");
        setNoOfDays2("");
        setNoOfDays3("");
        setNoOfDays4("");
        setNoOfDays5("");
    }

    const stockOptions = [];
    const storeOptions = storeStockList?.map((item, i) => { return { "value": item.storeTypeId, "label": item.storeTypeName } }) ?? [];
    const listItems = storeStockList?.filter((e) => { return store?.includes(e.storeTypeId) }).map(item => {
        return item.stockType.forEach((item) => {
            stockOptions.push({ "value": item.stockTypeId, "label": item.stockTypeName });
            return stockOptions;
        })
    })

    // Function to delete material not used analysis data
    const deleteMaterialNotUsedData = (materialNotUsedId) => {
        setMaterialNotUsedId(materialNotUsedId)
        setDeleteMaterialNotUsedConfirmDialog(true);
    }

    // Function to clear material not used analysis
    const clearMaterialNotUsedAnalysis = () => {
        setDeleteMaterialLoading(true);
        fetch(Constants.API_URL.clearMaterialNotUsedAnalysis, {
            method: "POST",
            mode:'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                "materialnotusedId": materialNotUsedId
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.status === Constants.status.codeAccessTokenUnauthorized){
                localStorage.clear();
                navigate("/Login");
                dispatch(logout());
            }else if(data.status === Constants.status.success){
                getInitData();
                setMaterialNotUsedAnalysis([]);
                toast.success(data?.message);
                setDeleteMaterialNotUsedConfirmDialog(false);
                setDeleteMaterialLoading(false);
            }else{
                toast.error(data?.message);
                setDeleteMaterialNotUsedConfirmDialog(false);
                setDeleteMaterialLoading(false);
            }
        });
    }

    return (
        <>
            {assignment && 
                <AssignmentListDetails
                    assignment={assignment}
                />
            }
            <div className="d-flex">
                <div className="clients_menu my-3">
                    <a className='btn btn-primary me-2' onClick={() => navigate(-1)} >
                        <i className="fa fa-arrow-left" aria-hidden="true" /> </a>
                </div>
                <div>
                    <h6 style={{ marginTop: "23px" }}>Material Not Used Analysis</h6>
                </div>
            </div>
            {loading && 
                <CommonLoader loading={loading} />
            }
            {!loading && componentDidMountFlag && (
                <>
                    {assignment?.status == "Started" && <>
                        <div className='dropdown_box'>
                            <div className='row w-100 mb-3'>
                                <div className='d-flex justify-content-center'>
                                    <div className=''>
                                        <label className='form-label'>Store Type</label>
                                    </div>
                                    <div className='mx-2 select-dropdown'>
                                        <Select
                                            placeholder="Select"
                                            isMulti
                                            className='reduceFonts'
                                            name='storeId'
                                            options={storeOptions}
                                            value={storeId}
                                            onChange={e => {
                                                setStoreId(e)
                                            }}
                                        />
                                        {!isEmptyVariable(errors['storeId']) &&
                                            <p className="error">{errors['storeId']}</p>}
                                    </div>
                                    <div className='ms-2'>
                                        <label className='form-label'>Stock Type</label>
                                    </div>
                                    <div className='mx-2 select-dropdown'>
                                        <Select
                                            placeholder="Select"
                                            isMulti
                                            className='reduceFonts'
                                            name='stockId'
                                            options={stockOptions}
                                            onChange={e => setStockId(e)}
                                            value={stockId}
                                        />
                                    </div>
                                    <div className='ms-2'>
                                        <label className='form-label'>Choose Number of Days</label>
                                    </div>
                                    <div className='d-inline-flex justify-content-center mx-2'>
                                        <div>
                                            <div className='d-inline-flex'>
                                                <input className='form-control ms-2 day-box' pattern="[0-9]*" min="1" step="any" value={noOfDays1} type="number" onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        if (/^[0-9]*$/.test(e.target.value)) {
                                                            setNoOfDays1(e.target.validity.valid ? e.target.value : noOfDays1)
                                                        }
                                                    }}
                                                />
                                                <input className='form-control ms-2 day-box' pattern="[0-9]*" min="1" step="any" value={noOfDays2} type="number" onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        if (/^[0-9]*$/.test(e.target.value)) {
                                                            setNoOfDays2(e.target.validity.valid ? e.target.value : noOfDays2)
                                                        }
                                                    }}
                                                />
                                                <input className='form-control ms-2 day-box' pattern="[0-9]*" min="1" step="any" value={noOfDays3} type="number" onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        if (/^[0-9]*$/.test(e.target.value)) {
                                                            setNoOfDays3(e.target.validity.valid ? e.target.value : noOfDays3)
                                                        }
                                                    }}
                                                />
                                                <input className='form-control ms-2 day-box' pattern="[0-9]*" min="1" step="any" value={noOfDays4} type="number" onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        if (/^[0-9]*$/.test(e.target.value)) {
                                                            setNoOfDays4(e.target.validity.valid ? e.target.value : noOfDays4)
                                                        }
                                                    }}
                                                />
                                                <input className='form-control ms-2 day-box' pattern="[0-9]*" min="1" step="any" value={noOfDays5} type="number" onWheel={e => e.currentTarget.blur()}
                                                    onChange={e => {
                                                        if (/^[0-9]*$/.test(e.target.value)) {
                                                            setNoOfDays5(e.target.validity.valid ? e.target.value : noOfDays5)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {!isEmptyVariable(errors['noOfDays']) &&
                                            <p className="error ms-2">{errors['noOfDays']}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-2 w-100'>
                            <div className='d-flex justify-content-center'>
                                <CommonButton
                                    label={"Reset"}
                                    onClick={() => clearData()}
                                    style={{ marginRight: 10, borderRadius: 5, height: 32 }}
                                />
                                <CommonButton
                                    label={"Calculate"}
                                    loading={calculateLoading}
                                    onClick={() => calculateMaterialNotUsedAnalysis()}
                                    style={{ marginRight: 10, borderRadius: 5, height: 32 }}
                                />
                                <CommonButton
                                    label={"Save"}
                                    disabled={!saveDisable}
                                    loading={saveLoading}
                                    onClick={() => calculateMaterialNotUsedAnalysis(true)}
                                    style={{ borderRadius: 5, height: 32, opacity: saveDisable ? 1 : 0.5 }}
                                />
                            </div>
                        </div>
                    </>}

                    {materialNotUsedAnalysis?.map((item) => {
                        const rule = JSON.parse(item?.response);
                        const key = Object.keys(rule)
                        const value = Object.values(rule)
                        return (
                            <>
                                <p className='mb-0 mt-3 mb-2 d-flex coustom-font-style'>
                                    {assignment?.status == "Started" ? <>
                                        <span className='me-5'> Store Type:  {item?.storeName}</span>
                                        <span className='me-5'> Stock Type:  {item?.stockName}</span>
                                    </> : <><span className='me-5'> Store Type:  {item.storeTypeName}</span>
                                        <span className='me-5'> Stock Type:   {item.stockTypeName}</span></>}
                                    {assignment?.status == "Started" && <i className="fa fa-trash" onClick={() => {
                                        deleteMaterialNotUsedData(item?.materialnotusedId)
                                    }}></i>}
                                </p>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>{key?.map((day, id) =>
                                            <th scope="col" className='text-center'>{day}</th>
                                        )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>{value?.map((value, id) =>
                                            <td className='text-end'>{Object.keys(value).length === 0 ? 0 : formatter.format(value)}</td>
                                        )}
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        )
                    })}

                    <div>
                        {calculateData?.length >= 1 && <>
                            <p className='mb-0 mt-3 mb-2 d-flex coustom-font-style'> <span className='me-5'> Store Type:  {storeId?.map(item => { return item.label })}</span>
                                <span> Stock Type:  {stockId?.map(item => { return item.label })}</span>
                            </p>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>{calculateDay?.map((day, id) =>
                                        <th scope="col" className='text-center'>{day}</th>
                                    )}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>{calculateData?.map((value, id) =>
                                        <td className='text-end'>{Object.keys(value).length === 0 ? 0 : formatter.format(value)}</td>
                                    )}
                                    </tr>
                                </tbody>
                            </table>
                        </>}
                    </div>
                    {isNoteVisible && (
                        <div className='header-container'>
                            <p className='material-header'>
                                Note : Material which are not issued since the last receipt of materials are considered
                                for the calculation. Difference between last receipt date and date of upload of
                                book stock is calculated in material not used analysis.
                                <span className='icon-container'>
                                    <i className='fa fa-times' onClick={toggleNoteVisible}></i>
                                </span>
                            </p>
                        </div>
                    )}
                    {deleteMaterialNotUsedConfirmDialog &&
                        <ReusableModal
                            show={deleteMaterialNotUsedConfirmDialog}
                            onClose={() => setDeleteMaterialNotUsedConfirmDialog(false)}
                            loading={deleteMaterialLoading}
                            onConfirm={clearMaterialNotUsedAnalysis}
                            message={"Are you sure, you want to delete this material not used?"}
                        />
                    }
                </>
            )}
        </>
    )
}

export default Materialnotused