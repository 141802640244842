import React, { useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import {
  APIRequest,
  CREATE_CLIENT,
  UNIT_TYPE
} from "../../../../api";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Select from 'react-select';
import '../../clientdashboardstyle.css';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Constants from '../../../../Component/common/Global/constants';
import { logout } from '../../../../redux/action';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from "react-bootstrap/Spinner";

const CreateClient = ({ clientsdetails, setclientsdetailsShow, user, city, loadMasterList }) => {
  const [unittypeData, setunittypeData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const clientsdetailsform = useFormik({
    enableReinitialize: true,
    initialValues: {
      organisationId: user?.organisationId,
      clientName: "",
      clientShortName: "",
      address1: "",
      address2: "",
      unit: "",
      country: "",
      city: "",
      state: "",
      pincode: "",
      logo: "",
      createdBy: user?.userId,
      updatedBy: user?.userId
    },
    validationSchema: Yup.object().shape({
      clientName: Yup.string().required("Enter Full Name"),
      address1: Yup.string().required('Enter address'),
      unit: Yup.string().required("Select Unit"),
      country: Yup.object().required("Select Country").nullable(true),
      state: Yup.object().required('Select State').nullable(true),
      city: Yup.object().required('Select City').nullable(true),
    }),
    onSubmit: (values) => {
      setLoading(true);
      let _step1Value = {
        ...values,
        country: values.country.value,
        state: values.state.value,
        city: values.city.value,
      }
      const finalValue = { ..._step1Value }
      var bodyFormData = new FormData();
      bodyFormData.append('clientName', finalValue.clientName);
      bodyFormData.append('createdBy', finalValue.createdBy);
      bodyFormData.append('updatedBy', finalValue.updatedBy);
      bodyFormData.append('organisationId', finalValue.organisationId);
      bodyFormData.append('clientShortName', finalValue.clientShortName);
      bodyFormData.append('address1', finalValue.address1);
      bodyFormData.append('address2', finalValue.address2);
      bodyFormData.append('unit', finalValue.unit);
      bodyFormData.append('country', finalValue.country);
      bodyFormData.append('state', finalValue.state)
      bodyFormData.append('city', finalValue.city);
      bodyFormData.append('pincode', finalValue.pincode);
      bodyFormData.append('logo', finalValue.logo);

      new APIRequest.Builder()
        .post()
        .setReqId(CREATE_CLIENT)
        .jsonParams(bodyFormData)
        .reqURL(`client/addClient`)
        .response(onResponse)
        .error(onError)
        .build()
        .doRequest();

    }
  });
  useEffect(() => {
    new APIRequest.Builder()
      .get()
      .setReqId(UNIT_TYPE)
      .reqURL(`/unitType/getUnitTypeListTop`)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest();
  }, [])

  const onResponse = (response, reqId) => {
    switch (reqId) {
      case CREATE_CLIENT:
        setclientsdetailsShow(true);
        setLoading(false);
        toast.success(response?.data?.message);
        clientsdetailsform.resetForm();
        break;
      case UNIT_TYPE:
        setunittypeData(response?.data?.data);
        break;
      default:
        break;
    }
  };

  const onError = (response, reqId) => {
    switch (reqId) {
      case CREATE_CLIENT:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          setLoading(false);
          toast.error(`${response?.data?.message}`)
      }
        break;
      case UNIT_TYPE:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
      } else {
          toast.error(`${response?.data?.message}`)
      }
        break;
      default:
        break;
    }
  };

  const stateOption = city?.filter(item => Number(item.cityId) === Number(clientsdetailsform.values.city.value))?.map((e) => { return { 'value': e.State.stateId, 'label': e.State.stateName } })

  const cityOption = city?.map((e) => { return { 'value': e.cityId, 'label': e.cityName } })

  const unitTypeOptions = unittypeData?.map((e) => { return { 'value': e.unitTypeId, 'label': e.unitTypeName } })

  const countryOption = city?.filter(item => Number(item.cityId) === Number(clientsdetailsform.values.city.value))?.map((e) => {
    return {
      'value': e.State.Country.countryId
      , 'label': e.State.Country.countryName
    }
  })

  useEffect(() => {
    if (countryOption[0] && stateOption[0]) {
      clientsdetailsform.setFieldValue('country', countryOption[0])
      clientsdetailsform.setFieldValue('state', stateOption[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [clientsdetailsform.values.city])

  return (
    <Modal show={clientsdetails}>
      <form onSubmit={clientsdetailsform.handleSubmit}>
        <Modal.Header>
          <Modal.Title>Add Client Details</Modal.Title>
          <div
            onClick={() => {
              setclientsdetailsShow();
              clientsdetailsform.resetForm();            
            }}
            data-dismiss="modal">
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
        </Modal.Header>
        <Modal.Body className='pb-0'>
          <div className="row uploadbook_box">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="clientShortName" className="form-label">
                  Short Name{" "}
                </label>
                <input
                  className="form-control"
                  id="clientShortName"
                  name="clientShortName"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.clientShortName}
                />
                {clientsdetailsform.touched.clientShortName && clientsdetailsform.errors.clientShortName ? (
                  <span className="error">{clientsdetailsform.errors.clientShortName}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="clientName" className="form-label">
                  Full Name*{" "}
                </label>
                <input
                  className="form-control"
                  id="clientName"
                  name="clientName"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.clientName}
                />
                {clientsdetailsform.touched.clientName && clientsdetailsform.errors.clientName ? (
                  <span className="error">{clientsdetailsform.errors.clientName}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="unit" className="form-label">
                  Unit Type*{" "}
                </label>
                <Select
                  placeholder="Select"
                  className='reduceFonts'
                  id="unit"
                  name="unit"
                  options={unitTypeOptions}
                  onChange={value => clientsdetailsform.setFieldValue('unit', value.value)}
                  defaultValue={clientsdetailsform.values.unit}
                />
                {clientsdetailsform.touched.unit && clientsdetailsform.errors.unit ? (
                  <span className="error">{clientsdetailsform.errors.unit}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address1" className="form-label">
                  Address Line 1*{" "}
                </label>
                <input
                  className="form-control"
                  id="address1"
                  name="address1"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.address1}
                />
                {clientsdetailsform.touched.address1 && clientsdetailsform.errors.address1 ? (
                  <span className="error">{clientsdetailsform.errors.address1}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="address2" className="form-label">
                  Address Line 2{" "}
                </label>
                <input
                  className="form-control"
                  id="address2"
                  name="address2"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.address2}
                />
                {clientsdetailsform.touched.address2 && clientsdetailsform.errors.address2 ? (
                  <span className="error">{clientsdetailsform.errors.address2}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="country" className="form-label">
                  City*
                </label>
                <Select
                  placeholder="Select"
                  className='reduceFonts'
                  id="city"
                  name="city"
                  options={cityOption}
                  onInputChange={loadMasterList}
                  onChange={value => clientsdetailsform.setFieldValue('city', value)}
                  defaultValue={clientsdetailsform.values.city}
                />
                {clientsdetailsform.touched.city && clientsdetailsform.errors.city ? (
                  <span className="error">{clientsdetailsform.errors.city}</span>
                ) : null}
              </div>

            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="state" className="form-label">
                  State*
                </label>
                <Select
                  placeholder="Select"
                  className='reduceFonts'
                  id="state"
                  name="state"
                  options={stateOption}
                  onChange={value => clientsdetailsform.setFieldValue('state', value)}
                  value={{ value: stateOption[0]?.value || '', label: stateOption[0]?.label || '' }}
                />
                {clientsdetailsform.touched.state && clientsdetailsform.errors.state ? (
                  <span className="error">{clientsdetailsform.errors.state}</span>
                ) : null}

              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group ">
                <label htmlFor="city" className="form-label">
                  Country*{" "}
                </label>
                <Select
                  placeholder="Select"
                  className='reduceFonts'
                  id="country"
                  name="country"
                  onChange={value => clientsdetailsform.setFieldValue('country', value)}
                  options={countryOption}
                  value={{ value: countryOption[0]?.value || '', label: countryOption[0]?.label || '' }}
                />
                {clientsdetailsform.touched.country && clientsdetailsform.errors.country ? (
                  <span className="error">{clientsdetailsform.errors.country}</span>
                ) : null}

              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="pincode" className="form-label">
                  Pin Code{" "}
                </label>
                <input
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.pincode}
                />
                {clientsdetailsform.touched.pincode && clientsdetailsform.errors.pincode ? (
                  <span className="error">{clientsdetailsform.errors.pincode}</span>
                ) : null}
              </div>
            </div>



            {/* <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email*
                </label>
                <input
                  className="form-control"
                  id="email"
                  name="email"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.email}
                />
                {clientsdetailsform.touched.email && clientsdetailsform.errors.email ? (
                  <span className="error">{clientsdetailsform.errors.email}</span>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="mobileNumber" className="form-label">
                  Mobile Number*
                </label>
                <input
                  className="form-control"
                  id="mobileNumber"
                  name="mobileNumber"
                  type="text"
                  onChange={clientsdetailsform.handleChange}
                  value={clientsdetailsform.values.mobileNumber}
                />
                {clientsdetailsform.touched.mobileNumber && clientsdetailsform.errors.mobileNumber ? (
                  <span className="error">{clientsdetailsform.errors.mobileNumber}</span>
                ) : null}
              </div>
            </div> */}
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="inputGroupFile01" className='form-label'>Upload logo</label>
                <input
                  type="file"
                  className="form-control"
                  id="logo"
                  onChange={(value) => { clientsdetailsform.setFieldValue('logo', value.target.files[0]) }}

                />
                {clientsdetailsform.touched.logofile && clientsdetailsform.errors.logofile ? (
                  <span className="error">{clientsdetailsform.errors.logofile}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="Mandotary">
            {/* <lable>* Fields Are Mandatory Reqired</lable> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setclientsdetailsShow()
              clientsdetailsform.resetForm()
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            {loading ? <Spinner animation="border"  variant="light" className='spinner-border-sm' /> : "Add"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default CreateClient;
