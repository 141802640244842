import React from "react";
import CommonButton from "../../../commonComponent/button/commonButton";

const AssignmentListDetails = ({assignment, finalizeButton, handleOnClick}) => {
    return(
        <div className='ongoing_box'>
            <div className='table-responsive' style={{ borderRadius: "8px" }}>
                <table className="table ongoing_table">
                    <tbody>
                        <tr>
                            <th scope="row" className='ps-2'>
                                <small>Client</small>
                                <span className='d-block'><b>{assignment?.clientName}</b></span>
                            </th>
                            <td>
                                <small>Unit</small>
                                <span className='d-block'><b>{assignment?.clientUnitName}</b></span>
                            </td>
                            <td>
                                <small>Location</small>
                                <span className='d-block'><b>{assignment?.city}</b></span>
                            </td>
                            <td>
                                <small>Start Date</small>
                                <span className='d-block'><b>{assignment?.assignmentStartDate}</b></span>
                            </td>
                            <td className='pe-2'>
                                <small>User</small>
                                {assignment?.status == "Started" ? 
                                <span className='d-block'><b>
                                    {""} {assignment?.teamMembers?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ',  ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> })}
                                    {""}&nbsp;
                                    {assignment?.reviewers?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ',  ' : '') + item.User?.title + "" + item.User?.firstName + " " + item.User?.lastName}</span> })}
                                </b></span> :
                                <span className='d-block'><b>
                                {assignment.teamMembers === undefined ? "" : assignment.teamMembers?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ' ' : '') + item?.title + "" + item?.firstName + " " + item?.lastName + ","}</span> })}
                                {assignment.reviewers === undefined ? "" : assignment?.reviewers?.map(function (item, index) { return <span key={`demo_snap_${index}`}>{(index ? ' ' : '') + item?.title + "" + item?.firstName + " " + item?.lastName}</span> })}
                            </b></span>}
                            </td>
                            {finalizeButton &&
                                <td align="right" className='pe-2 td-finalize'>
                                    <CommonButton
                                        style={{backgroundColor: '#cf7f59'}}
                                        label={finalizeButton}
                                        onClick={() => handleOnClick()}
                                    />
                                </td>
                            }
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default AssignmentListDetails;