import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import "../ClientDashboard/clientdashboardstyle.css";
import { useSelector, useDispatch } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { APIRequest, ASSIGNMENT_LIST } from "../../api";
import { Table } from "../Table/Table";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Updateassignment from "../ClientDashboard/Component/Assignments/updateassignment";
import Schedulednewassignment from "../ClientDashboard/Component/Assignments/schedulednewassignment";
import moment from "moment";
import { toast } from "react-toastify";
import * as Constants from '../../Component/common/Global/constants';
import { logout } from '../../redux/action';

const Thirdpartyassignment = () => {
  const [flex2, setFlex2] = useState("none");
  const [subflex, setSubflex] = useState("none");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const maskingmanage = (e) => {
    if (e.target.id === "flexRadioDefault2") {
      setFlex2("block")
    } else if (e.target.id === "flexRadioDefault1") {
      setFlex2("none")
    }

    if (e.target.id === "flexRadioDefault5") {
      setSubflex("block")
    } else if (e.target.id === "flexRadioDefault3" || e.target.id === "flexRadioDefault4") {
      setSubflex("none")
    }

  }
  const [newscheduled, setnewscheduledShow] = useState(false);
  const [masking, setmaskingShow] = useState(false);

  const maskingform = useFormik({
    initialValues: {
      sortname: '',
      stockname: '',
      fullname: ''
    },
    onSubmit: values => {
    },
  });
  const [complateDetails, setcomplateDetailsShow] = useState(false);
  const complateDetailsform = useFormik({
    initialValues: {

    },
    onSubmit: values => {
    },
  });


  const [assignment, Setassignment] = useState([]);
  const [editassignment, Seteditassignment] = useState(false);
  const [editAssignmentData, SeteditAssignmentData] = useState([]);



  const Editassignment = (e) => {
    Seteditassignment(true)
    SeteditAssignmentData(e);
  }


  useEffect(() => {
    new APIRequest.Builder()
      .get()
      .setReqId(ASSIGNMENT_LIST)
      .reqURL("assignments/list")
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()

  }, [])


  const onResponse = (response, reqId) => {
    switch (reqId) {
      case ASSIGNMENT_LIST:
        Setassignment(response.data?.data);
        break;
    }
  }


  const onError = (response, reqId) => {
    switch (reqId) {
      case ASSIGNMENT_LIST:
        if (response.data?.status === Constants.status.codeAccessTokenUnauthorized) {
          localStorage.clear();
          navigate("/Login");
          dispatch(logout());
        } else {
          toast.error(`${response?.data?.message}`)
        }
        break;
    }
  }

  const handleTableChange = (type, newState) => {
    let url = `assignments/list?page=${type === "search" || type === "sort" ? "1" : newState.page
      }&`;
    if (type === "search" && newState.searchText) {
      url = url + `search=${encodeURIComponent(newState.searchText)}&`;
    }
    // if (type === "sort") {
    //   if (newState.sortField === "name") {
    //     url = url + `sortColumn=city&`
    //   }
    //   if (newState.sortOrder) {
    //     url = url + `sortType=${newState.sortOrder}&`
    //   }
    // }

    if (newState.sizePerPage) {
      url = url + `limit=${newState.sizePerPage}&`;
    }



    new APIRequest.Builder()
      .get()
      .setReqId(ASSIGNMENT_LIST)
      .reqURL(url)
      .response(onResponse)
      .error(onError)
      .build()
      .doRequest()

  };
  return (
    <>
      <div className='master_boxinfo mt-3'>

        <Tabs defaultActiveKey="five">
          <Tab eventKey="five" title="Scheduled">
            <div className='table-responsive pt-2 pb-5'>
              <div className='add_btns '>
                <button type='button' className='btn btn-primary w-auto' variant="primary" onClick={setnewscheduledShow}><i className="fa fa-plus-circle" aria-hidden="true"></i> Scheduled New Assignments</button>
              </div>
              <i className="fa fa-search search_icon" aria-hidden="true"></i>
              {assignment?.records && (
                <Table
                  data={assignment?.records.map((item, index) => {
                    return {
                      ...item,
                      key: index,
                      id: item.id,
                      name: item.Client.fullName,
                      unit: item.ClientUnit.name,
                      date: moment(item.createdAt).utc().format('DD-MM-YYYY') ?? "-",
                      assign: item.AssignedTo.firstName
                    };
                  })}
                  columns={[
                    {
                      dataField: "id",
                      text: "No",
                      headerClasses: "sr_no",
                    },
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "unit",
                      text: "Unit",
                      sort: true,
                    },
                    {
                      dataField: "date",
                      text: "Date",
                      sort: true,
                    },
                    {
                      dataField: "assign",
                      text: "Assign to",
                      sort: true,
                    },
                    {
                      dataField: "action",
                      text: "Action",
                      headerClasses: "action",
                      style: { cursor: "pointer" },
                      formatter: (cell, e) => {
                        return (
                          <div className="edit_icons">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i
                                  className="fa fa-ellipsis-v"
                                  aria-hidden="true"
                                ></i>
                              </button>
                              <ul
                                className="dropdown-menu p-1"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a className="dropdown-item"
                                    data-bs-toggle="#modal"
                                    data-bs-target="#exampleModal3"
                                    onClick={() => Editassignment(e)}
                                  > <i className="fa fa-pencil me-1" aria-hidden="true"></i>Edit
                                  </a>

                                </li>
                                <li>
                                  <a className="dropdown-item"
                                  ><i className="fa fa-trash me-1" aria-hidden="true"></i>Delete
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item"
                                  ><i className="fa fa-step-forward me-1" aria-hidden="true"></i>Start Assignment
                                  </a>
                                </li>
                                <li>
                                  <a className="dropdown-item"
                                    onClick={setmaskingShow}
                                  ><i className="fa fa-folder me-1" aria-hidden="true"></i>Masking
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      },
                    },
                  ]}
                  handleTableChange={handleTableChange}
                  totalSize={assignment?.total ?? 0}
                  currentPage={assignment?.page ?? 1}
                  sizePerPage={assignment?.limit ?? 15}
                />
              )}

              <Updateassignment editassignment={editassignment} Seteditassignment={Seteditassignment} editAssignmentData={editAssignmentData} />

            </div>

            <Modal show={masking}>
              <form onSubmit={maskingform.handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Masking</Modal.Title>
                  <div
                    onClick={() => {
                      setmaskingShow(false);
                      maskingform.resetForm();
                    }}
                    data-dismiss="modal">
                    <i className="fa fa-close" aria-hidden="true"></i>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className='masking_box'>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={(e) => maskingmanage(e)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        No masking
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={(e) => maskingmanage(e)} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        Masking
                      </label>
                    </div>
                    <div className="sublist" style={{ "display": flex2 }}>
                      <div className="form-check ps-5">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault3" onClick={(e) => maskingmanage(e)} />
                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                          Open the Mask at Time (at time selection should be there)
                        </label>
                      </div>
                      <div className="form-check ps-5">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault4" onClick={(e) => maskingmanage(e)} />
                        <label className="form-check-label" htmlFor="flexRadioDefault4">
                          Open the Mask at Time (at time selection should be there)
                        </label>
                      </div>
                      <div className="form-check ps-5">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault5" onClick={(e) => maskingmanage(e)} />
                        <label className="form-check-label" htmlFor="flexRadioDefault5">
                          Open the Mask if the actual quantity matches
                        </label>
                      </div>
                      <div className='sub_masking_item' style={{ "display": subflex }}>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault6" />
                          <label className="form-check-label" htmlFor="flexRadioDefault6">
                            Open difference at time (at time selection should be there)
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault7" />
                          <label className="form-check-label" htmlFor="flexRadioDefault7">
                            Open unmasked by team leader. (All the unmasked will at once will get opened by team leader.)
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault8" />
                          <label className="form-check-label" htmlFor="flexRadioDefault8">
                            Open difference after ___ punch in.
                          </label>
                        </div>
                      </div>
                      <div className="form-check ps-5">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault9" />
                        <label className="form-check-label" htmlFor="flexRadioDefault9">
                          Open the mask when first time actual quantity is punched.
                        </label>
                      </div>
                      <div className="form-check ps-5">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault10" />
                        <label className="form-check-label" htmlFor="flexRadioDefault10">
                          Team leader to open masked items at once.
                        </label>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setmaskingShow(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
            <Schedulednewassignment newscheduled={newscheduled} setnewscheduledShow={setnewscheduledShow} />
          </Tab>
          <Tab eventKey="six" title="Completed">
            <div className='table-responsive pt-2 pb-5'>
              <div className='add_btns d-none'>
                <button type='button' className='btn btn-primary w-auto' data-bs-toggle="modal" data-bs-target="#newscheduled"><i className="fa fa-plus-circle" aria-hidden="true"></i> Scheduled New Assignments</button>
              </div>
              {/* <i className="fa fa-search search_icon" aria-hidden="true"></i> */}
              <table id="datatable16" className="table">

                <thead>
                  <tr>
                    <th>Sl. No</th>
                    <th>Client Name</th>
                    <th>Unit</th>
                    <th>Store Type</th>
                    <th>Completion Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td variant="primary" onClick={setcomplateDetailsShow}>1</td>
                    <td variant="primary" onClick={setcomplateDetailsShow}>John1</td>
                    <td variant="primary" onClick={setcomplateDetailsShow}>12assa</td>
                    <td variant="primary" onClick={setcomplateDetailsShow}>01/05/2022</td>
                    <td variant="primary" onClick={setcomplateDetailsShow}>Chicago</td>
                    <td className='edit_icons'>
                      <div className="dropdown">
                        <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                        <ul className="dropdown-menu p-1" aria-labelledby="dropdownMenuButton1">
                          <li><a className="dropdown-item" ><i className="fa-solid fa-file-export me-1"></i>Export Documents</a></li>
                          <li><a className="dropdown-item" ><i className="fa fa-download me-1" aria-hidden="true"></i>Download Report</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Modal show={complateDetails}>
              <form onSubmit={complateDetailsform.handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Complete Details</Modal.Title>
                  <div
                    onClick={() => {
                      setcomplateDetailsShow(false);
                      complateDetailsform.resetForm();
                    }}
                    data-dismiss="modal">
                    <i className="fa fa-close" aria-hidden="true"></i>
                  </div>
                </Modal.Header>
                <Modal.Body>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Client Name</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Unit</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Unit Type</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Store </div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Auditors</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Storekeeper</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>John</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Start Date</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>17/05/2022</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>End Date</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>25/06/2022</div></div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'><div className='regisdetails p-2'>Completion Date</div></div>
                    <div className='col-md-1'><div className='regisdetails p-2'>:</div></div>
                    <div className='col-md-8'><div className='regisdetails p-2'>26/07/2022</div></div>
                  </div>

                </Modal.Body>
                <Modal.Footer className='d-none'>
                  <Button variant="secondary" onClick={() => setcomplateDetailsShow(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" type="submit">
                    Add
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </Tab>
        </Tabs>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          </div>
          <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">

          </div>
        </div>
      </div>
    </>

  )
}

export default Thirdpartyassignment